import React, { useState, useEffect } from 'react';
import { monthlyPayment } from '@plending/validation/quote-utils';
import { jsx, NovunaHeading, Text } from 'compass-design';
import { Step } from '../../../../design-components/step';
import { ERROR_COMPONENTS } from '../../../Routing/Routes';
import { TailoringCard } from '../../../../design-components/tailoring';
import { getPath, useNavigation } from '../../../Routing/useNavigation';
import { ConvertQuote } from '../ConvertQuote';
import { FinalOfferRequestBody } from '../../../../store/finalOffer/types';
import { useSaveFinalOffer } from '../../../../store/finalOffer/actions';
import { useCallbackFinalOfferSelector, useFinalOfferSelector } from '../../../../store/finalOffer/selectors';
import { usePersonalisedQuoteSelector } from '../../../../store/personalisedQuote/selectors';
import { PersonalisedQuoteState } from '../../../../store/personalisedQuote/types';
import { fetchData } from '../../../../utils/serviceCall';
import { PageTitle } from '../../../../design-components/heading';
import { QuoteDescription } from './QuoteDescription';
import { LoanSummary } from './LoanSummary';
import { LoanAmend } from './LoanAmend';

export interface PersonalisedQuoteAcceptProps {
  isBesavvi?: boolean;
  applyNow: () => void;
}

export const PersonalisedQuoteAccept: React.FC<PersonalisedQuoteAcceptProps> = ({ isBesavvi, applyNow }) => {
  const [isValidQuote, setIsValidQuote] = useState<boolean | undefined>(undefined);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const {
    data: { acceptLikelihood, applicationId = '', offer } = {} as NonNullable<PersonalisedQuoteState['data']>,
  }: PersonalisedQuoteState = usePersonalisedQuoteSelector();
  const { monthlyPence, loanPence, totalPence, termMonths } = offer;
  const { loading: isFinalOfferLoading } = useFinalOfferSelector();
  const finalOfferSelector = useCallbackFinalOfferSelector();
  const saveFinalOffer = useSaveFinalOffer();
  const { navigateTo } = useNavigation();

  const [finalOffer, setFinalOffer] = useState<FinalOfferRequestBody>({
    termMonths,
    monthlyPence,
    totalPence,
  });

  useEffect(() => {
    fetchData('/validate-quote').then(
      () => {
        setIsDisabled(false);
      },
      () => {
        setIsValidQuote(false);
      }
    );
  }, []);

  const confirmFinalOffer = async (finalOffer: FinalOfferRequestBody) => {
    setIsDisabled(true);
    fetchData('/validate-quote').then(
      async () => {
        setIsValidQuote(true);
        await saveFinalOffer(true, finalOffer);

        const { error: { error } = {} } = finalOfferSelector();
        if (error) {
          navigateTo(getPath(ERROR_COMPONENTS.default, { code: 500 }));
        }
        applyNow();
      },
      () => {
        setIsValidQuote(false);
        setIsDisabled(true);
        window.scrollTo(0, 0);
      }
    );
  };

  const calculateTailoredOffer = (newTermMonths: number, loanPence: number, rpm: number) => {
    const monthlyPence = monthlyPayment(loanPence, newTermMonths, rpm);
    setFinalOffer({
      termMonths: newTermMonths,
      monthlyPence,
      totalPence: monthlyPence * newTermMonths,
    });
  };

  const { tailoring, offerTailoring } = offer;

  return (
    <>
      <Step step={5} />
      <PageTitle>Quote decision</PageTitle>
      <QuoteDescription acceptLikelihood={acceptLikelihood} isValidQuote={isValidQuote} />
      <LoanSummary
        applicationId={applicationId}
        acceptLikelihood={acceptLikelihood}
        isBesavvi={isBesavvi}
        offer={offer}
      />
      {offerTailoring && tailoring && tailoring.rpm && (
        <TailoringCard
          data-test-id="tailoring-card"
          minTerm={tailoring.minTermMonths}
          maxTerm={tailoring.maxTermMonths}
          offerDetails={finalOffer}
          apr={offer.apr}
          loanAmount={loanPence}
          onChange={(newTermMonths: number) => calculateTailoredOffer(newTermMonths, loanPence, tailoring.rpm)}
        />
      )}
      <LoanAmend isBesavvi={isBesavvi} />
      <NovunaHeading as="h3" variant="secondary" mb={2} mt={6}>
        Happy with the above loan?
      </NovunaHeading>
      <Text mb={6}>A full credit search will be performed upon applying, leaving a footprint on your credit file.</Text>
      <ConvertQuote
        applicationId={applicationId}
        offer={offer}
        isFinalOfferLoading={isFinalOfferLoading}
        confirmFinalOffer={confirmFinalOffer}
        finalOffer={finalOffer}
        isDisabled={isDisabled}
      />
    </>
  );
};
