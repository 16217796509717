import React from 'react';
import { jsx, Button, Continue, Modal, Styled, ReactModal } from 'compass-design';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useUserPrompt } from './useUserPrompt';
import { useInitialiseSession } from './useInitialiseSession';
import { useHeartbeat } from './useHeartbeat';

export const TimeoutHandler: React.FC = () => {
  useInitialiseSession();

  const exitHeartbeat = useHeartbeat();

  const { modalIsOpen, toggleModalIsOpen, closeModal, displayTime } = useUserPrompt(exitHeartbeat);
  const isBesavvi = useIsBeSavvi();

  return (
    <>
      {isBesavvi ? (
        <Modal
          data-test-id="ui-timeout-modal"
          title="Do you need more time?"
          isOpen={modalIsOpen}
          onSetIsOpen={toggleModalIsOpen}
          size="medium"
        >
          <Styled.p sx={{ fontSize: 1 }}>
            You appear to have been idle for a while, do you need more time to complete your application? This page will
            close in:
          </Styled.p>
          <Styled.p>{displayTime}</Styled.p>
          <Button onClick={closeModal}>
            Continue application <Continue />
          </Button>
        </Modal>
      ) : (
        <ReactModal
          data-test-id="ui-timeout-modal"
          title="Do you need more time?"
          isOpen={modalIsOpen}
          setIsOpen={toggleModalIsOpen}
          size="medium"
        >
          <Styled.p sx={{ fontSize: 1 }}>
            You appear to have been idle for a while, do you need more time to complete your application? This page will
            close in:
          </Styled.p>
          <Styled.p>{displayTime}</Styled.p>
          <Button onClick={closeModal}>
            Continue application <Continue />
          </Button>
        </ReactModal>
      )}
    </>
  );
};
