import { RootState } from '../rootState';
import { GlobalActionType, GlobalAction } from './types';

export function reducer(state = {} as RootState, { type }: GlobalAction): Partial<RootState> {
  switch (type) {
    case GlobalActionType.CLEAR_ALL_PERSONAL_DATA: {
      return {
        bootstrapData: state.bootstrapData,
        session: state.session,
      };
    }
    default:
      return state;
  }
}
