import { combineReducers } from 'redux';
import { remoteReducer } from '../utils/remoteReducer';
import { AuthActionType, AuthAction, AuthEndpointState } from './types';

const initialState = '';

function codeVerifierReducer(state = initialState, { type, payload }: AuthAction): string {
  switch (type) {
    case AuthActionType.SET_CODE_VERIFIER: {
      return payload.codeVerifier;
    }
    case AuthActionType.CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
}

export const reducer = combineReducers({
  codeVerifier: codeVerifierReducer,
  endpoints: remoteReducer<AuthEndpointState>(AuthActionType),
});
