import React from 'react';
import { Decision } from '@plending/interfaces/decision';
import { jsx } from 'compass-design';
import { ErrorPage } from '../../Errors/Error';
import { useDecisionSelector } from '../../../store/decision/selectors';

export const UnhandledDecision: React.FC = () => {
  const { data: { decision } = {} as Partial<Decision> } = useDecisionSelector();

  return <ErrorPage errorSource="decision" errorReason="unhandled" errorData={decision} />;
};
