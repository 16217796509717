import { SectionBuilder } from './SectionBuilder';

interface KeyFeaturesProps {
  loanPounds: string;
  term: string;
  termMonths: number;
  monthlyPounds: string;
  totalPounds: string;
}

export const buildKeyFeatures: SectionBuilder<KeyFeaturesProps> = ({
  loanPounds,
  term,
  termMonths,
  monthlyPounds,
  totalPounds,
}) => {
  return {
    title: 'Key features',
    description: 'For the Personal Loan including the total amount payable and the duration of the credit agreement',
    testId: 'key-features',
    items: [
      {
        label: 'Type of credit',
        value: 'Fixed sum loan agreement',
      },
      {
        label: 'The total amount of credit',
        value: { type: 'bold', value: loanPounds },
        description:
          'This means the amount of credit to be provided under the proposed credit agreement or the credit limit.',
      },
      {
        label: 'How and when credit would be provided',
        value: `We will pay the amount of the loan to your nominated account following receipt by us of the agreement signed by you.
      Alternatively, if, according to your instructions at the point of application, some of the credit is being used to settle one or more of your existing personal loans with us, we will carry out those instructions and pay the remaining balance to your nominated bank account.`,
      },
      {
        label: 'The duration of the credit agreement',
        value: { type: 'bold', value: term },
      },
      {
        label: 'Repayments',
        value: [
          { type: 'bold', value: String(termMonths) },
          ' consecutive monthly repayments of ',
          { type: 'bold', value: monthlyPounds },
        ],
      },
      {
        label: 'The total amount you will have to pay',
        description: 'This means the amount you have borrowed plus interest and other costs.',
        value: { type: 'bold', value: totalPounds },
      },
    ],
  };
};
