import React from 'react';
import { jsx, Link } from 'compass-design';
import { Novuna } from '../../utils/staticData';

const {
  loansTeamContact: { email: novunaEmail },
} = Novuna;

export const ErrorContact: React.FC = () => {
  const url = 'https://www.novunapersonalfinance.co.uk/contact-us/';
  const email = novunaEmail;
  return (
    <>
      If you would like to get in touch, please visit our&nbsp;
      <Link data-test-id="contact-us-link" sx={{ textDecoration: 'underline', cursor: 'pointer' }} href={url}>
        contact us
      </Link>{' '}
      page for more information on our opening hours.
      <br />
      Alternatively, you may also{' '}
      <Link sx={{ textDecoration: 'underline', cursor: 'pointer' }} href={`mailto:${email}`}>
        email us
      </Link>
      .
    </>
  );
};
