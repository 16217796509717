import { RemoteState } from '../../types';

export enum CreateApplicationActionType {
  CLEAR = 'create-agreement/CLEAR',
  REQUEST = 'create-agreement/REQUEST',
  LOAD = 'create-agreement/LOAD',
  FAILED = 'create-agreement/FAILED',
}

export interface CreateApplicationResponse {
  data?: string;
}

export interface CreateApplicationState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: string;
}
