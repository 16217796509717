import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function useScrollToTop() {
  const { action } = useHistory();
  const { pathname } = useLocation();

  // put this logic in `useEffect` rather than `history.listen` to ensure it is run *after* the render
  useEffect(() => {
    if (action === 'REPLACE') {
      // Delay the scroll slightly, to override the browser's attempt to "restore" the scroll position
      // `requestAnimationFrame` lets the browser choose a good instant to perform the action
      requestAnimationFrame(scrollToTop);
    } else {
      scrollToTop();
    }
  }, [action, pathname]);
}
