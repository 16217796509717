import { useSelector } from 'react-redux';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { AlternativeLoanState } from './types';

export const selectAlternativeLoanData = (s: RootState) => s.alternativeLoan;

export function useAlternativeLoanDataSelector(): AlternativeLoanState {
  return useSelector(selectAlternativeLoanData);
}

export function useCallbackAlternativeLoanDataSelector(): () => AlternativeLoanState {
  return useCallbackSelector(selectAlternativeLoanData);
}
