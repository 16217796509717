import React, { useCallback, useMemo, useState, useRef, Fragment, useEffect } from 'react';
import { FinancialDetails as FinancialDetailsState, AtSinceGroup } from '@plending/interfaces/application';
import {
  EmploymentStatus,
  EmploymentStatusList,
  NumberOfDependants,
  NumberOfDependantsList,
  workingEmploymentStatuses,
} from '@plending/interfaces/enums';
import {
  getConditionalFieldStatus,
  ValidateFinancialDetails,
  BANK_CHECK_ERROR_MESSAGE,
  FinancialDetailMaxLengthValidation,
} from '@plending/validation/financial-details-rules';
import { BankDetails } from '@plending/interfaces/bank-check';
import { jsx, Link, List, ListItem } from 'compass-design';

import { ContinueButton } from '../../design-components/button';
import { PageTitle, FormHeading } from '../../design-components/heading';
import { SingleInput } from '../../design-components/inputs/SingleInput';
import { useNavigation, getPath } from '../Routing/useNavigation';
import { LabelList } from '../../design-components/inputs/OptionList';
import {
  useStoreFinancialDetails,
  useStoreBankCheck,
  useSaveFinancialDetails,
} from '../../store/financialDetails/actions';
import {
  useCallbackFinancialDetailsSelector,
  useFinancialDetailsSelector,
} from '../../store/financialDetails/selectors';
import { usePersonalDetailsSelector } from '../../store/personalDetails/selectors';
import { useValidation } from '../../validation/useValidation';
import { SpreadSelect } from '../../design-components/inputs/SpreadSelect';
import { AtSinceInput } from '../../design-components/inputs/AtSinceInput';
import { FieldPropSet } from '../../validation/types';
import { BackLink } from '../../design-components/backLink';
import { Step } from '../../design-components/step';
import { ERROR_COMPONENTS } from '../Routing/Routes';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useFinancialDetailsFieldSpec } from '../../utils/specs';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useBootstrapDataSelector } from '../../store/bootstrap/selectors';
import { BankDetails as BankDetailsInput } from './BankDetails';

const CONTACT_URL = 'https://www.novunapersonalfinance.co.uk/contact-us/';

type LocalFormikBag = {
  setFieldTouched: FieldPropSet['setFieldTouched'];
};

export const numberOfDependantsLabels: LabelList<NumberOfDependants> = {
  '3': '3 or more',
};

function hasErrors(error?: string | Record<string, unknown>) {
  return error && Object.keys(error).length !== 0;
}

export const FinancialDetails: React.FC = () => {
  const storeFinancialDetails = useStoreFinancialDetails();
  const storeBankCheckDetails = useStoreBankCheck();
  const saveFinancialDetails = useSaveFinancialDetails();
  const financialDetailsSelector = useCallbackFinancialDetailsSelector();
  const { navigateToNextStep, replaceWith } = useNavigation();
  const [isBankDetailsImmutable, setIsBankDetailsImmutable] = useState(false);
  const { data: bootstrapData } = useBootstrapDataSelector();

  useEffect(() => {
    setIsBankDetailsImmutable(Boolean(bootstrapData?.config?.isBankDetailsImmutable));
  }, [bootstrapData]);

  const formikBag = useRef<LocalFormikBag>({
    setFieldTouched: () => {
      /* noop default */
    },
  });

  const {
    employmentStatus,
    partnerEmploymentStatus,
    partnerOccupation,
    employerName,
    occupation,
    workPhoneNumber,
    annualIncomeBeforeTax,
    numberOfDependants,
    bankHolderName,
    bankCheckDetails: { sortCode: initialSortCode, accountNumber: initalAccountNumber } = {} as BankDetails,
    withBankSince = {} as AtSinceGroup,
  }: FinancialDetailsState = useFinancialDetailsSelector();

  const { maritalStatus, dob } = usePersonalDetailsSelector();

  const [pageFields, updatePageFields] = useState(() =>
    getConditionalFieldStatus(maritalStatus, employmentStatus, partnerEmploymentStatus)
  );

  const setPageFields = useCallback(
    (employmentStatus: string) => {
      const conditionalFieldState = getConditionalFieldStatus(
        maritalStatus,
        employmentStatus as EmploymentStatus,
        partnerEmploymentStatus
      );

      updatePageFields(conditionalFieldState);
    },
    [maritalStatus, partnerEmploymentStatus]
  );

  const setShowPartnerOccupation = useCallback((partnerEmploymentStatus: string) => {
    const partnerOccupation = workingEmploymentStatuses.includes(partnerEmploymentStatus as EmploymentStatus);

    updatePageFields((existingState) => ({
      ...existingState,
      partnerOccupation,
    }));
  }, []);

  const [bankCheckLoading, setBankCheckLoading] = useState(false);
  const [bankCheckError, setBankCheckError] = useState({
    displayMessage: false,
    forceInputErrorStyles: false,
  });

  const updateBankCheckError = useCallback(() => {
    setBankCheckError((currentState) => ({
      ...currentState,
      forceInputErrorStyles: false,
    }));
  }, []);

  const onSubmit = useCallback(
    async ({
      employmentStatus,
      partnerEmploymentStatus,
      partnerOccupation,
      employerName,
      occupation,
      workPhoneNumber,
      annualIncomeBeforeTax,
      numberOfDependants,
      bankHolderName,
      sortCode,
      accountNumber,
      withBankSince,
    }) => {
      const {
        current: { setFieldTouched },
      } = formikBag;

      const newBankCheckDetails = { sortCode, accountNumber };
      const oldBankCheckDetails = {
        sortCode: initialSortCode,
        accountNumber: initalAccountNumber,
      };

      const toStore: FinancialDetailsState = {
        employmentStatus,
        workPhoneNumber,
        annualIncomeBeforeTax,
        numberOfDependants,
        bankHolderName,
        bankCheckDetails: oldBankCheckDetails,
        withBankSince,
      };

      if (pageFields.partnerEmploymentStatus) {
        toStore.partnerEmploymentStatus = partnerEmploymentStatus;
      }

      if (pageFields.partnerOccupation && pageFields.partnerEmploymentStatus) {
        toStore.partnerOccupation = partnerOccupation;
      }

      if (pageFields.employerName) {
        toStore.employerName = employerName;
      }

      if (pageFields.occupation) {
        toStore.occupation = occupation;
      }

      storeFinancialDetails(toStore);

      setBankCheckLoading(true);
      await saveFinancialDetails(true, {
        ...toStore,
        bankCheckDetails: newBankCheckDetails,
      });
      setBankCheckLoading(false);

      const { error: { error = {} } = {}, data } = financialDetailsSelector();

      if (error.bankCheckDetails === BANK_CHECK_ERROR_MESSAGE) {
        setBankCheckError({
          displayMessage: true,
          forceInputErrorStyles: true,
        });
        setFieldTouched('sortCode', false);
        setFieldTouched('accountNumber', false);
      } else if (!data || hasErrors(error)) {
        replaceWith(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string);
      } else {
        storeBankCheckDetails(data.bankCheckDetails);
        setBankCheckError({
          displayMessage: false,
          forceInputErrorStyles: false,
        });

        navigateToNextStep();
      }
    },
    [
      financialDetailsSelector,
      initalAccountNumber,
      initialSortCode,
      navigateToNextStep,
      pageFields.employerName,
      pageFields.occupation,
      pageFields.partnerEmploymentStatus,
      pageFields.partnerOccupation,
      replaceWith,
      saveFinancialDetails,
      storeBankCheckDetails,
      storeFinancialDetails,
    ]
  );

  const validate = useCallback((values) => ValidateFinancialDetails(values, pageFields), [pageFields]);

  const {
    employerNameSpec,
    occupationSpec,
    bankHolderNameSpec,
    sortCodeFieldSpec,
    accountNumberFieldSpec,
    withBankSinceSpec,
    numberOfDependantsSpec,
    annualIncomeBeforeTaxSpec,
    workPhoneNumberSpec,
    partnerOccupationSpec,
    partnerEmploymentStatusSpec,
    employmentStatusSpec,
  } = useFinancialDetailsFieldSpec({
    employerName,
    occupation,
    dob,
    sortCode: initialSortCode,
    accountNumber: initalAccountNumber,
    numberOfDependants,
    annualIncomeBeforeTax,
    workPhoneNumber,
    partnerOccupation,
    partnerEmploymentStatus,
    employmentStatus,
    bankHolderName,
    withBankSince,
  });

  const { handleSubmit, fieldProps, setFieldTouched } = useValidation(
    [
      employmentStatusSpec,
      partnerEmploymentStatusSpec,
      partnerOccupationSpec,
      employerNameSpec,
      occupationSpec,
      workPhoneNumberSpec,
      annualIncomeBeforeTaxSpec,
      numberOfDependantsSpec,
      bankHolderNameSpec,
      sortCodeFieldSpec,
      accountNumberFieldSpec,
      withBankSinceSpec,
    ],
    {
      onSubmit,
      validate,
    }
  );

  formikBag.current = useMemo(
    () => ({
      setFieldTouched,
    }),
    [setFieldTouched]
  );

  const annualIncomeBeforeTaxDisplayError = useMemo(() => {
    const { value, error } = fieldProps.annualIncomeBeforeTax;

    if (!error && parseInt(value) < 2500) {
      return 'Please make sure this is your annual income, before tax';
    }

    return error;
  }, [fieldProps.annualIncomeBeforeTax]);

  const isBesavvi = useIsBeSavvi();

  const details = (
    <>
      <Step step={3} />
      <PageTitle>Financial Details</PageTitle>
      <FormHeading>Employment</FormHeading>
      <form onSubmit={handleSubmit}>
        <SpreadSelect
          label="Employment status"
          values={EmploymentStatusList}
          labels={EmploymentStatus}
          onValueChange={setPageFields}
          {...fieldProps.employmentStatus}
        />

        <SingleInput
          label="Total annual income"
          description="Before tax and deductions, rounded up to the nearest pound"
          halfWidth
          prefix="£"
          maxLength={FinancialDetailMaxLengthValidation.ANNUAL_INCOME}
          placeholder="0"
          {...fieldProps.annualIncomeBeforeTax}
          error={annualIncomeBeforeTaxDisplayError}
        />

        {pageFields.partnerEmploymentStatus && (
          <SpreadSelect
            label="Spouse/Partner employment status"
            values={EmploymentStatusList}
            labels={EmploymentStatus}
            onValueChange={setShowPartnerOccupation}
            {...fieldProps.partnerEmploymentStatus}
          />
        )}

        {pageFields.partnerOccupation && pageFields.partnerEmploymentStatus && (
          <SingleInput
            label="Spouse/Partner occupation"
            maxLength={FinancialDetailMaxLengthValidation.PARTNER_OCCUPATION}
            {...fieldProps.partnerOccupation}
          />
        )}

        {pageFields.occupation && (
          <SingleInput
            label="Occupation"
            maxLength={FinancialDetailMaxLengthValidation.OCCUPATION}
            {...fieldProps.occupation}
          />
        )}

        {pageFields.employerName && (
          <SingleInput
            label="Employer name"
            {...fieldProps.employerName}
            maxLength={FinancialDetailMaxLengthValidation.EMPLOYER}
          />
        )}

        {pageFields.employerPhoneNumber && (
          <SingleInput
            short
            label="Employer's phone number"
            maxLength={FinancialDetailMaxLengthValidation.PHONE}
            halfWidth
            placeholder="Optional"
            {...fieldProps.workPhoneNumber}
          />
        )}

        <SpreadSelect
          label="Number of dependants"
          {...fieldProps.numberOfDependants}
          values={NumberOfDependantsList}
          labels={numberOfDependantsLabels}
        />

        <FormHeading sx={{ mb: 2 }}>Bank details</FormHeading>
        <List fontSize="1">
          <ListItem>It is important the details are correct as your loan will be paid into this bank account.</ListItem>
          <ListItem>Your monthly repayments by Direct Debit will be taken from this bank account</ListItem>
          <ListItem>This needs to be a personal current account and not a business account.</ListItem>
        </List>
        <SingleInput
          label="Account holder's name"
          description="Joint accounts can be used"
          placeholder="Full name"
          maxLength={FinancialDetailMaxLengthValidation.BANK_HOLDER_NAME}
          {...fieldProps.bankHolderName}
          disabled={isBankDetailsImmutable}
        />

        <BankDetailsInput
          accountNumberField={fieldProps.accountNumber}
          sortCodeField={fieldProps.sortCode}
          updateBankCheckError={updateBankCheckError}
          bankCheckError={bankCheckError}
          disabled={isBankDetailsImmutable}
        />

        <AtSinceInput label="With bank since" {...fieldProps.withBankSince} />

        {isBankDetailsImmutable && (
          <p data-test-id="self-service-bank-details-text">
            If the above Bank Details are incorrect please speak with an advisor via our{' '}
            <Link href={CONTACT_URL} target="_blank">
              Contact Us
            </Link>{' '}
            page
          </p>
        )}

        <p>
          <ContinueButton data-test-id="proceed-button" busy={bankCheckLoading}>
            Continue
          </ContinueButton>
        </p>
      </form>
    </>
  );

  if (isBesavvi) {
    return (
      <Fragment>
        <BackLink />
        {details}
      </Fragment>
    );
  }

  return <NovunaContainer link={<BackLink />}>{details}</NovunaContainer>;
};
