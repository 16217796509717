import { combineReducers } from 'redux';
import { ApplicationStatus } from '@plending/interfaces/application';
import { PersonalDetailsActionType } from '../personalDetails/types';
import { RemoteAction, RemoteState } from '../types';
import { remoteReducer } from '../utils/remoteReducer';
import { FinancialDetailsActionType } from '../financialDetails/types';
import { PcciActionType } from '../pcci/types';
import { ExplanationActionType } from '../explanation/types';
import { FinalOfferActionType } from '../finalOffer/types';
import { ApplicationState, ApplicationProgress, ApplicationActionType } from './types';

const defaultState: ApplicationState = {
  progress: {} as ApplicationProgress,
  applicationStatus: '' as ApplicationStatus,
};

const progressReducers = combineReducers({
  personalDetails: remoteReducer<RemoteState>(PersonalDetailsActionType),
  financialDetails: remoteReducer<RemoteState>(FinancialDetailsActionType),
  pcciDetails: remoteReducer<RemoteState>(PcciActionType),
  explanation: remoteReducer<RemoteState>(ExplanationActionType),
  finalOffer: remoteReducer<RemoteState>(FinalOfferActionType),
});

export function reducer(state = defaultState, { type, payload }: RemoteAction): ApplicationState {
  switch (type) {
    case ApplicationActionType.SET_STATUS: {
      return {
        ...state,
        applicationStatus: (payload && payload.data) as ApplicationStatus,
      };
    }
    default:
      const action = { type, payload };

      return {
        ...state,
        progress: progressReducers(state.progress, action),
      };
  }
}
