import { Revision, NewDecisionRequestNoBankDetails } from 'compass-shared-services/build/decision/types';
import { ApplicationStatus, LoanType } from './application';
import { DecisionOffer } from './quote';

export enum ApplicationType {
  NEW = 'NEW',
  LOAN_AMEND = 'LOAN_AMEND',
  REPROCESS = 'REPROCESS',
  QUOTE = 'QUOTE',
  REAL_RATE_SOFT_SEARCH = 'REAL_RATE_SOFT_SEARCH',
  CONVERT_REAL_RATE = 'CONVERT_REAL_RATE',
  CONVERT_QUOTE = 'CONVERT_QUOTE',
}

export interface DecisionIovationDevice {
  blackbox?: string;
  ipAddress?: string;
}

export interface ReasonCode {
  code?: string;
  text?: string;
}

export interface Decision {
  applicationId: string;
  decisionId: string;
  showAlternativeLoanProvider?: boolean;
  decision: ApplicationStatus;
  revisions?: Revision[];
}

export interface DecisionClientResponse extends Decision {
  offer?: DecisionOffer;
}

export interface FinalDecision {
  decision: DecisionType;
  overrideReasonType?: string;
  reviewReasonType?: string;
  updatedTimestamp?: string; // This only gets set for REFER decisions
}

export interface SystemDecision {
  decision: DecisionType;
  applicationScore?: number;
  maximumLoanAmount: number;
  errorSource?: string | null;
  powercurveId?: string;
  reasonCodes: ReasonCode[];
  eligibility?: number;
  creditDecision?: string | null;
  fraudDecision?: string | null;
}

export interface DecisionSelectedRate {
  apr: number;
  instalment: number;
}

export interface DecisionApplication {
  applicationId: string;
  searchConsent: boolean;
  applicationSource: string;
  applicants: DecisionApplicant[];
  channel?: string;
  subChannel?: string;
  goodsItems: DecisionGoodsItems[];
  supplier: DecisionApplicationSupplier;
  bankDetails: DecisionBankDetails;
  loanData: DecisionLoanData;
  product?: DecisionProduct;
  decisionId?: string;
  deliveryAddress?: [];
  revisions?: DecisionRevision[];
  device?: DecisionIovationDevice;
  loanType?: LoanType;
  aggregatorRanking?: number;
}

export interface DecisionUpdateResponse {
  applicationId: string;
  searchConsent: boolean;
  applicationSource: string;
  applicants: DecisionApplicant[];
  channel: string;
  subChannel: string;
  goodsItems: DecisionGoodsItems[];
  supplier: DecisionApplicationSupplier;
  bankDetails: DecisionResponseBankDetails;
  loanData: DecisionLoanData;
  product: DecisionProduct;
  decisionId: string;
  deliveryAddress: DecisionAddress[] | Record<string, never>;
  revisions: DecisionRevision[];
  finalDecision: FinalDecision;
  systemDecision: SystemDecision;
}

export interface DecisionApplicationSupplier {
  introducer?: string;
  name: string;
  userId: string;
  userName: string;
  supplierNumber: string;
}

export interface DecisionApplicant {
  title: string;
  forename: string;
  middleName: string | null;
  surname: string;
  formerSurname?: string | null;
  dateOfBirth: string;
  phoneNumber: string;
  emailAddress: string;
  employmentDetails: DecisionEmploymentDetails;
  financialDetails: DecisionFinancialDetails;
  dependants: string;
  maritalStatus: string;
  addresses: DecisionAddress[];
  proofOfId: string;
  proofOfIdEvidence?: string | null;
  residentialStatus: string;
}

export interface DecisionGoodsItems {
  goodsCode: string;
  goodsDescription: string;
}

export interface DecisionEmploymentDetails {
  employmentStatus: string;
  spouseEmploymentStatus?: string | null;
  occupation?: string;
  spouseOccupation?: string;
  employer?: string;
}

export interface DecisionProduct {
  serviceType: string;
}

export interface DecisionFinancialDetails {
  grossAnnualIncome: number;
  trueIncome: boolean;
  monthlyAccommodationCost?: number;
}

export interface DecisionBankDetails {
  accountHolderName: string;
  accountNumber: string;
  sortcode: string;
  timeWithBankMm: number;
  timeWithBankYy: number;
}

export interface DecisionResponseBankDetails {
  accountHolderName: string;
  accountNumber: string;
  sortcode: string;
  timeWithBankMm: number;
  timeWithBankYy: number;
}

export interface DecisionLoanData {
  loanAmount: string;
  instalment: string;
  term: number;
  goodsPrice: string;
  apr: number;
  deferralPeriod?: number;
  deposit?: string | null;
}

export interface DecisionAddress {
  addressType: string;
  houseNumber: string;
  street: string;
  postTown: string;
  postcode: string;
  timeAtAddressMonths: number;
  timeAtAddressYears: number;
  houseName: string;
  flat: string | null;
  county: string | null;
  movingToAddress?: boolean | null;
}

export interface DecisionResponse {
  applicationId: string;
  decisionId: string;
  decisionWorkflow: ApplicationType;
  finalDecision: FinalDecision;
  systemDecision: SystemDecision;
  selectedRate?: DecisionSelectedRate;
}

export interface DecisionRevision {
  decisionText: string;
  decisionWorkflow: ApplicationType;
  deposit: string;
  finalDecision: string;
  fraudDecision?: string;
  creditDecision?: string;
  instalment: string;
  loanAmount: string;
  maxLoanAmount: string;
  term: number;
  revisionDateTime: string;
  score?: number;
  reasonCodes: ReasonCode[];
}

export interface DecisionSoftSearchApplicant {
  title: string;
  forename: string;
  surname: string;
  dateOfBirth: string;
  emailAddress: string;
  employmentDetails: {
    employmentStatus: string;
  };
  financialDetails: {
    grossAnnualIncome: number;
    monthlyAccommodationCost?: number;
  };
  dependants: string;
  maritalStatus?: string;
  addresses: DecisionAddress[];
  residentialStatus: string;
}

/** @deprecated */
export interface DecisionSoftSearchRequest {
  applicationId: string;
  searchConsent: boolean;
  applicationSource: string;
  channel?: string;
  subChannel?: string;
  applicants: DecisionSoftSearchApplicant[];
  supplier: DecisionApplicationSupplier;
  loanData: {
    loanAmount: string;
    term: number;
  };
  goodsItems?: DecisionGoodsItems[];
  loanType?: LoanType;
}

export enum DecisionType {
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  Refer = 'REFER',
  DeclineAffordability = 'AFFORDABILITY_DECLINE',
  DeclineRefer = 'DECLINE_REFER',
  DeclineFraud = 'FRAUD_DECLINE',
  DeclinePolicy = 'POLICY_DECLINE',
  DeclineScore = 'SCORE_DECLINE',
  ReferAffordability = 'AFFORDABILITY_REFER',
  ReferFraud = 'FRAUD_REFER',
  AcceptConditional = 'CONDITIONAL_ACCEPT',
  AcceptProvisional = 'PROVISIONAL_ACCEPT',
  AppWithdrawn = 'APP_WITHDRAWN',
  Continue = 'CONTINUE',
  NA = 'N_A',
  Unknown = 'UNKNOWN',
  QuotationIneligible = 'QUOTATION_INELIGIBLE',
  QuotationDecline = 'QUOTATION_DECLINE',
  QuotationRefer = 'QUOTATION_REFER',
  QuotationAccept = 'QUOTATION_ACCEPT',
  QuotationUnavailable = 'QUOTATION_UNAVAILABLE',
  PreAcceptPause = 'PRE_ACCEPT_PAUSE',
  PreDeclinePause = 'PRE_DECLINE_PAUSE',
  PreReferPause = 'PRE_REFER_PAUSE',
  OpenBankingRequest = 'OPEN_BANKING_REQUEST',
  PrimaryRefer = 'PRIMARY_REFER',
}

export interface NewDecisionSoftSearchRequest extends NewDecisionRequestNoBankDetails {
  loanType?: LoanType;
}
