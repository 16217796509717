import { PersonalDetails } from '@plending/interfaces/application';
import { AddressResponse } from '@plending/interfaces/address-lookup';
import { RemoteState } from '../types';

export enum PersonalDetailsActionType {
  CLEAR = 'personal-details/CLEAR',
  STORE = 'personal-details/STORE',
  REQUEST = 'personal-details/REQUEST',
  LOAD = 'personal-details/LOAD',
  FAILED = 'personal-details/FAILED',
}

export interface PersonalDetailsAction {
  type: PersonalDetailsActionType;
  payload: PersonalDetails;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PersonalDetailsRemoteState extends RemoteState {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddressDetails extends AddressResponse {}
