export enum PreDecisionConfirmationActionType {
  STORE = 'preDecisionConfirmation/STORE',
}

export interface PreDecisionConfirmationAction {
  type: PreDecisionConfirmationActionType;
  payload?: PreDecisionConfirmationState;
}

export enum TopUpLoanType {
  topUpLoan = 'TOP_UP_LOAN',
  additionalLoan = 'ADDITIONAL_LOAN',
}

export interface PreDecisionConfirmationState {
  bankDetails: string;
  creditSearch: string;
  circumstances: string;
  alternativeLoanProviderOptIn: boolean;
  loanType?: TopUpLoanType;
}
