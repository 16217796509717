import { ThemeChannel } from '@plending/ui/src/design-components/Channels';
import { JourneyType } from './application';

export interface Supplier {
  name: string;
  id: string;
  alternativeLoanProviderEnabled?: boolean;
  address: string;
  isCreditIntermediary: boolean;
  legalEntityName: string;
  isPartner: boolean;
}

export interface BootstrapSupplier extends Supplier {
  isPersonalisedQuoteEnabled: boolean;
}

export interface Rate extends RateParameters {
  minAmountPence: number;
  maxAmountPence: number;
  minTermMonths: number;
  maxTermMonths: number;
  isTypical?: boolean;
}

export interface RateParameters {
  rpm: number;
  apr: number;
}

export interface Product {
  channel: ThemeChannel;
  // subChannel only used in analytics
  subChannel: string;
  rates: Rate[];
}

export enum ApplicationFlowType {
  TOP_UP = 'Top up',
  PERSONALISED_QUOTE = 'Personalised Quote',
  DIRECT = 'Direct',
}

export interface UiConfig {
  isBankDetailsImmutable?: boolean;
  inactivityTimeoutMs: number;
  feature: {
    payoutLogicEnabled: boolean;
  };
  loanAdvisor?: string;
  journeyType?: JourneyType;
  applicationFlowType?: ApplicationFlowType;
}

export interface BootstrapData {
  config: UiConfig;
  supplier: BootstrapSupplier;
  product: Product;
}
