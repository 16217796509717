import React, { useCallback } from 'react';
import { jsx, Box, ReadOnlyWrapper, TertiaryLink, TertiaryLinkDirection, TextButton } from 'compass-design';
import { addressToLines } from '../../../utils/formatters';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { AddressComponentProps } from './types';
import { AddressError } from './AddressError';

export const AddressConfirmed: React.FC<AddressComponentProps> = ({ mainError, name, value, setEditMode }) => {
  const setManualEntry = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const tone = mainError ? 'neutral' : 'positive';
  const isBesavvi = useIsBeSavvi();

  return (
    <Box mb={5} data-test-id={`confirmed-address-${name}`}>
      <AddressError name={name} error={mainError} />
      <ReadOnlyWrapper
        label="Address"
        tone={tone}
        action={
          isBesavvi ? (
            <TextButton onClick={setManualEntry}>Edit</TextButton>
          ) : (
            <TertiaryLink
              direction={TertiaryLinkDirection.FORWARDS}
              text="Edit"
              onClick={setManualEntry}
            ></TertiaryLink>
          )
        }
      >
        {addressToLines(value).map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </ReadOnlyWrapper>
    </Box>
  );
};
