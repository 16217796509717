import { FinancialDetailMaxLengthValidation } from '@plending/validation/financial-details-rules';
import React from 'react';
import { jsx } from 'compass-design';
import { FieldPropSet } from '../../validation/types';
import { SingleInput } from '../../design-components/inputs/SingleInput';
import { FormGroupError } from '../../design-components/error';
import { onNumberPaste } from '../../validation/utils';

interface BankDetailsProps {
  accountNumberField: FieldPropSet;
  sortCodeField: FieldPropSet;
  updateBankCheckError: () => void;
  bankCheckError: {
    displayMessage: boolean;
    forceInputErrorStyles: boolean;
  };
  disabled?: boolean;
}

export const BankDetails: React.FC<BankDetailsProps> = ({
  accountNumberField,
  sortCodeField,
  updateBankCheckError,
  bankCheckError,
  disabled,
}) => {
  return (
    <>
      {bankCheckError.displayMessage && (
        <FormGroupError name="bankCheckError" title="Bank not found">
          These bank details have not been found. Please check and try again.
        </FormGroupError>
      )}

      <SingleInput
        disabled={disabled}
        short
        label="Sort code"
        maxLength={FinancialDetailMaxLengthValidation.SORT_CODE}
        placeholder="123456"
        halfWidth
        forceNegativeInput={bankCheckError.forceInputErrorStyles}
        {...sortCodeField}
        onValueChange={updateBankCheckError}
        onPaste={(event) => {
          onNumberPaste(event, sortCodeField, FinancialDetailMaxLengthValidation.SORT_CODE);
        }}
      />
      <SingleInput
        disabled={disabled}
        short
        label="Account number"
        maxLength={FinancialDetailMaxLengthValidation.ACCOUNT_NUMBER}
        placeholder="12345678"
        halfWidth
        forceNegativeInput={bankCheckError.forceInputErrorStyles}
        {...accountNumberField}
        onValueChange={updateBankCheckError}
        onPaste={(event) => {
          onNumberPaste(event, accountNumberField, FinancialDetailMaxLengthValidation.ACCOUNT_NUMBER);
        }}
      />
    </>
  );
};
