import React from 'react';
import { jsx, InfoSegment, InfoBox, Box, SummaryRow } from 'compass-design';
import { penceToPoundsString, formatApr } from '@plending/validation/quote-utils';
import { DecisionOffer } from '@plending/interfaces/quote';
import { FormHeading } from '../../../../design-components/heading';

interface SoftSearchDetails {
  dataTestId: string;
  label: string;
  value: string;
}

interface LoanSummaryProps {
  applicationId: string;
  acceptLikelihood: number;
  isBesavvi: boolean | undefined;
  offer: DecisionOffer;
}

export const LoanSummary: React.FC<LoanSummaryProps> = ({ applicationId, acceptLikelihood, isBesavvi, offer }) => {
  const { monthlyPence, loanPence, totalPence, termMonths, apr } = offer;

  const monthlyRepayment = penceToPoundsString(monthlyPence);
  const loanAmount = penceToPoundsString(loanPence);
  const totalAmountPayable = penceToPoundsString(totalPence);
  const aprString = formatApr(apr || 0) + ' APR';
  const formattedTermInMonths = termMonths ? termMonths + ' Months' : '';

  const softSearchDetails: SoftSearchDetails[] = [
    { dataTestId: 'pq-eligibility', label: 'Eligibility', value: acceptLikelihood + '%' },
    { dataTestId: 'pq-loan-amount', label: 'Loan amount', value: loanAmount },
    { dataTestId: 'pq-term', label: 'Term', value: formattedTermInMonths },
    { dataTestId: 'pq-apr', label: 'APR', value: aprString },
    { dataTestId: 'pq-monthly-repayment', label: 'Monthly repayment', value: monthlyRepayment },
    { dataTestId: 'pq-total-payable', label: 'Total payable', value: totalAmountPayable },
    { dataTestId: 'pq-application-id', label: 'Application ID', value: (applicationId || '').toString() },
  ];

  return isBesavvi ? (
    <>
      <FormHeading>Loan summary</FormHeading>
      <InfoBox data-test-id="offer-info" sx={{ marginBottom: 6, maxWidth: '520px' }}>
        {softSearchDetails.map(({ dataTestId, label, value }) => (
          <InfoSegment key={dataTestId} data-test-id={dataTestId} label={label}>
            {value}
          </InfoSegment>
        ))}
      </InfoBox>
    </>
  ) : (
    <>
      <FormHeading>Loan summary</FormHeading>
      <Box>
        <Box mb={4}>
          {softSearchDetails.map(({ dataTestId, label, value }, index) => (
            <SummaryRow
              data-test-id={dataTestId}
              label={label}
              value={value}
              isWhite={index % 2 === 0}
              key={dataTestId}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
