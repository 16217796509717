import React from 'react';
import { Redirect } from 'react-router-dom';
import { jsx } from 'compass-design';
import { useAgreementDataSelector } from '../../store/agreement/selectors';
import { AgreementState } from '../../store/agreement/types';
import { PageTitle } from '../../design-components/heading';
import { ErrorBlock } from '../Errors/Error';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';

export const AgreementRequest: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const {
    loading,
    data: { redirectUrl } = {} as NonNullable<AgreementState['data']>,
    error,
  } = useAgreementDataSelector();

  const showLoading = loading;
  const showRedirect = !loading && !!redirectUrl && !error;
  const showError = !!error || (!loading && !redirectUrl);

  const applicationExpired = error === 'PL0049';
  if (applicationExpired) {
    return <Redirect to="/expired-decision/" />;
  }

  if (showRedirect) {
    window.location.replace(redirectUrl);
  }

  const loadingDetails = (
    <>
      <PageTitle data-test-id="loading-message">Preparing documents for e-signing...</PageTitle>
      <p>Please be patient, this might take a few seconds.</p>
    </>
  );

  const loadingComp = isBesavvi ? loadingDetails : <NovunaContainer>{loadingDetails}</NovunaContainer>;

  return (
    <>
      {showLoading && loadingComp}
      {showError && (
        <ErrorBlock
          data-test-id="signing-error"
          errorSource="signing-service"
          errorReason="preparation"
          title="There has been an error preparing your documents"
          message="Please re-click the link in your email to try again."
        />
      )}
    </>
  );
};
