import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFetchPost } from '../utils/fetch';
import { TopUpLoansQuoteActionType, TopUpLoansQuoteState } from './types';
import { useCallbackTopUpLoansQuoteSelector } from './selectors';

const extractTopUpLoans = (p: TopUpLoansQuoteState) => p.data;

export function useClearTopUpLoansQuote() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch({ type: TopUpLoansQuoteActionType.CLEAR }), [dispatch]);
}

export function useFetchTopUpLoansQuote() {
  return useFetchPost(
    '/top-up-eligibility',
    useCallbackTopUpLoansQuoteSelector(),
    TopUpLoansQuoteActionType,
    extractTopUpLoans
  );
}
