import { useFetchPost } from '../../utils/fetch';
import { useCallbackCreateApplicationDataSelector } from './selectors';
import { CreateApplicationActionType, CreateApplicationResponse } from './types';

const extractCreateApplicationData = (d: CreateApplicationResponse) => d.data;

export function useCreateApplication() {
  return useFetchPost(
    '/application',
    useCallbackCreateApplicationDataSelector(),
    CreateApplicationActionType,
    extractCreateApplicationData
  );
}
