import React from 'react';
import { jsx, Text } from 'compass-design';
import { useIsBeSavvi } from '../utils/useIsBesavvi';
import { useIsTopUpLoanSelector, usePersonalisedQuoteProvider } from '../store/bootstrap/selectors';

export type StepProps = {
  step: number;
  totalSteps?: number;
};

const getTotalSteps = (isPersonalisedQuote: boolean, isTopUpLoan: boolean): number => {
  switch (true) {
    case isTopUpLoan:
    case isPersonalisedQuote:
      return 6;
    default:
      return 5;
  }
};

export const Step: React.FC<StepProps> = ({ step, totalSteps, ...props }) => {
  const isBesavvi = useIsBeSavvi();
  const isPersonalisedQuote = usePersonalisedQuoteProvider();
  const isTopUpLoan = useIsTopUpLoanSelector();

  totalSteps = totalSteps ?? getTotalSteps(isPersonalisedQuote, isTopUpLoan);

  return (
    <Text
      sx={{
        color: 'monochrome.3',
        fontSize: isBesavvi ? 2 : 3,
        marginBottom: isBesavvi ? 3 : 2,
      }}
      {...props}
    >
      Step {step} of {totalSteps}
    </Text>
  );
};
