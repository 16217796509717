import { jsx, Flex, Message, Text, PurpleMail, NovunaHeading, Box } from 'compass-design';
import React from 'react';
import { useIsBeSavvi } from '../utils/useIsBesavvi';
import { FieldPropSet } from '../validation/types';
import { CheckboxButton } from './button';
import { FormCard } from './card';

interface MarketingPreferencesProps {
  marketingEmailFieldProps: FieldPropSet;
  marketingPostFieldProps: FieldPropSet;
}

export const MarketingPreferences: React.FC<MarketingPreferencesProps> = ({
  marketingEmailFieldProps,
  marketingPostFieldProps,
}) => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <FormCard title="Sign up for special discounts and lots more!">
        <Text mt={0} mb={3}>
          Novuna Personal Finance is a brand of Mitsubishi HC Capital UK PLC. Other brands under which we trade include
          Novuna Consumer Finance, Novuna Vehicle Solutions, Novuna Business Finance and Novuna Business Cash Flow.
        </Text>
        <Text my={3}>
          We would love to stay in touch and let you know about products and special offers from Novuna. Don't miss out!
          Tick the box to let us know how you want to hear from us.
        </Text>
        <Text mt={3} mb={4}>
          Please contact me by
        </Text>
        <Flex>
          <CheckboxButton {...marketingEmailFieldProps}>Email</CheckboxButton>
          <CheckboxButton {...marketingPostFieldProps}>Post</CheckboxButton>
        </Flex>
      </FormCard>
    );
  }

  return (
    <Box mb={4}>
      <Message variant="info" icon={<PurpleMail />}>
        <Box>
          <NovunaHeading as="h3" mb={2}>
            Sign up for special discounts and lots more!
          </NovunaHeading>
          <Text mb={3}>
            We would love to stay in touch and let you know about products and special offers from Novuna. Don't miss
            out! Tick the box to let us know how you want to hear from us.
          </Text>
          <Text mb={2} sx={{ fontWeight: 'bold' }}>
            Please contact me by
          </Text>
          <CheckboxButton dataTestId="marketingEmail" {...marketingEmailFieldProps}>
            Email
          </CheckboxButton>
          <CheckboxButton dataTestId="marketingPost" {...marketingPostFieldProps}>
            Post
          </CheckboxButton>
          <Text sx={{ fontSize: '14px' }}>
            Novuna Personal Finance is a brand of Mitsubishi HC Capital UK PLC. Other brands under which we trade
            include Novuna Consumer Finance, Novuna Vehicle Solutions, Novuna Business Finance and Novuna Business Cash
            Flow.
          </Text>
        </Box>
      </Message>
    </Box>
  );
};
