import { jsx, Card, Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { FormGroupError } from '../../design-components/error';

export const ExpiryPanel: React.FC<{ isBesavvi: boolean }> = ({ isBesavvi }) => {
  return isBesavvi ? (
    <Card variant="secondary" p={4} mt={4} mb={6}>
      <FormGroupError name="expiry-redirect-message" variant="warning" title="Application timed out" mb={0}>
        Sorry, your application has timed out due to inactivity. To keep your information secure it has been reset.
        Please start again with a new quotation.
      </FormGroupError>
    </Card>
  ) : (
    <Box mb={4}>
      <Message variant="warning">
        <NovunaHeading as="h3">Application timed out</NovunaHeading>
        <Text data-test-id="expiry-redirect-message">
          Sorry, your application has timed out due to inactivity. To keep your information secure it has been reset.
          Please start again with a new quotation.
        </Text>
      </Message>
    </Box>
  );
};
