import React, { useCallback, useEffect, useMemo, Fragment as F, Fragment } from 'react';
import { jsx, Box, ReadOnlyWrapper, Flex, TertiaryLink, TertiaryLinkDirection } from 'compass-design';
import { Title, MaritalStatus, AccommodationStatus, EmploymentStatus, PurposeOfLoan } from '@plending/interfaces/enums';
import { formatLabel } from '@plending/common/formatters';
import { useHistory } from 'react-router-dom';
import { Link } from '../../design-components/Link';
import { PageTitle, FormHeading } from '../../design-components/heading';
import { useClearDecision, useFetchDecision } from '../../store/decision/actions';
import { useCallbackDecisionRequestPayloadSelector } from '../../store/decision/selectors';
import { useFinancialDetailsSelector } from '../../store/financialDetails/selectors';
import { usePersonalDetailsSelector } from '../../store/personalDetails/selectors';
import { useStorePreDecisionConfirmation } from '../../store/preDecisionConfirmation/actions';
import {
  expandSortCode,
  sentenceCase,
  shortNamedDate,
  fullNamedDate,
  addressToLines,
  formatCurrency,
  formatPhoneNumber,
} from '../../utils/formatters';
import { useNavigation, getPath } from '../Routing/useNavigation';
import { Step } from '../../design-components/step';
import { BackLink } from '../../design-components/backLink';
import { DIRECT_COMPONENTS, PERSONALISED_QUOTE_COMPONENTS, TOP_UP_LOANS_COMPONENTS } from '../Routing/Routes';
import { PreDecisionConfirmationState } from '../../store/preDecisionConfirmation/types';
import { isoNow } from '../../utils/serverTime';
import { ConfirmationCard } from '../ConfirmationCard/ConfirmationCard';
import { Wrappable } from '../../design-components/Wrappable';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { getIovationFingerprint } from '../../utils/getIovationFingerprint';
import { usePersonalisedQuoteProvider, useIsTopUpLoanSelector } from '../../store/bootstrap/selectors';
import { useClearPersonalisedQuote, useFetchPersonalisedQuote } from '../../store/personalisedQuote/actions';
import { useClearTopUpLoansQuote, useFetchTopUpLoansQuote } from '../../store/topUp/actions';
import { useAppQueueingSubmission } from '../../store/app-queueing';

export const DecisionRequest: React.FC = () => {
  const appQueueing = useAppQueueingSubmission();

  const { navigateToNextStep, navigateTo } = useNavigation();
  const storeConfirmation = useStorePreDecisionConfirmation();
  const isPersonalisedQuoteEnabled = usePersonalisedQuoteProvider();

  const isTopUpLoan = useIsTopUpLoanSelector();

  const {
    title,
    firstName,
    middleNames,
    lastName,
    maritalStatus,
    currentAddress,
    secondAddress,
    thirdAddress,
    accommodationStatus,
    dob,
    email,
    phone,
    purposeOfLoan,
  } = usePersonalDetailsSelector();

  const {
    bankCheckDetails: { accountNumber, sortCode, bankName },
    numberOfDependants,
    employmentStatus,
    annualIncomeBeforeTax,
    occupation,
    employerName,
    workPhoneNumber,
    withBankSince,
    bankHolderName,
    partnerEmploymentStatus,
    partnerOccupation,
  } = useFinancialDetailsSelector();

  const fetchDecision = useFetchDecision();
  const clearDecision = useClearDecision();
  const fetchPersonalisedQuote = useFetchPersonalisedQuote();
  const clearPersonalisedQuote = useClearPersonalisedQuote();
  const getDecisionRequest = useCallbackDecisionRequestPayloadSelector();
  const clearTopUpLoan = useClearTopUpLoansQuote();
  const fetchTopUpLoan = useFetchTopUpLoansQuote();

  useEffect(() => {
    isPersonalisedQuoteEnabled && clearPersonalisedQuote();
    isTopUpLoan && clearTopUpLoan();
    clearDecision();
  }, [clearDecision, clearPersonalisedQuote, isPersonalisedQuoteEnabled, isTopUpLoan, clearTopUpLoan]);

  const onSubmit = useCallback(
    async ({ alternativeLoanProviderOptIn }) => {
      const toStore: PreDecisionConfirmationState = {
        bankDetails: isoNow(),
        creditSearch: isoNow(),
        circumstances: isoNow(),
        alternativeLoanProviderOptIn,
      };

      storeConfirmation(toStore);
      // Don't judge me...
      if (isTopUpLoan) {
        navigateTo(getPath(TOP_UP_LOANS_COMPONENTS.quoteResult, {}));
      } else if (isPersonalisedQuoteEnabled) {
        navigateTo(getPath(PERSONALISED_QUOTE_COMPONENTS.personalisedQuote, { personalisedQuote: 'true' }) as string);
      } else {
        navigateToNextStep();
      }

      if (!isPersonalisedQuoteEnabled && !isTopUpLoan) {
        if ((await appQueueing.submit({})) !== 'unhandled') return;
      }
      if (isTopUpLoan) {
        fetchTopUpLoan(true);
      } else if (isPersonalisedQuoteEnabled) {
        fetchPersonalisedQuote(true);
      } else {
        fetchDecision(true, { ...getDecisionRequest(), ...getIovationFingerprint() });
      }
    },
    [
      storeConfirmation,
      isTopUpLoan,
      fetchTopUpLoan,
      isPersonalisedQuoteEnabled,
      fetchPersonalisedQuote,
      fetchDecision,
      getDecisionRequest,
      navigateToNextStep,
      navigateTo,
      appQueueing,
    ]
  );

  const fullName = useMemo(
    () => [formatLabel(title, Title), firstName, middleNames, lastName].filter((i) => i).join(' '),
    [firstName, lastName, middleNames, title]
  );

  const dependents = useMemo(() => {
    if (numberOfDependants === '0') {
      return 'No dependants';
    }
    let dependentsMessage = `${numberOfDependants} dependant`;
    if (numberOfDependants === '1') {
      return dependentsMessage;
    }
    dependentsMessage += 's';

    return dependentsMessage;
  }, [numberOfDependants]);

  const isBesavvi = useIsBeSavvi();
  const history = useHistory();

  const details = (
    <F>
      <Step step={4} />
      <PageTitle>Review application</PageTitle>
      <Flex>
        <Box sx={{ marginRight: 'auto' }}>
          <FormHeading>Personal details</FormHeading>
        </Box>
        {isBesavvi ? (
          <Link
            data-test-id="editPersonalDetails"
            to={DIRECT_COMPONENTS.personalDetails.path}
            sx={{
              fontSize: 2,
              paddingTop: 3,
            }}
          >
            Edit
          </Link>
        ) : (
          <TertiaryLink
            direction={TertiaryLinkDirection.FORWARDS}
            text="Edit"
            dataTestId="editPersonalDetails"
            onClick={() => history.push(DIRECT_COMPONENTS.personalDetails.path)}
          />
        )}
      </Flex>

      <Box mb={5}>
        <ReadOnlyWrapper label="About you">
          <span data-test-id="applicantName">{fullName}</span>
          <br />
          <span data-test-id="applicantDob">{fullNamedDate(dob)}</span>
          <br />
          <span data-test-id="applicantMaritalStatus">{formatLabel(maritalStatus, MaritalStatus)}</span>
          <br />
          <span data-test-id="applicantDependents">{dependents}</span>
        </ReadOnlyWrapper>
      </Box>
      <Box mb={5}>
        <ReadOnlyWrapper label="Contact details">
          <span data-test-id="applicantEmail">
            <Wrappable>{email}</Wrappable>
          </span>
          <br />
          <span data-test-id="applicantPhone">{formatPhoneNumber(phone)}</span>
        </ReadOnlyWrapper>
      </Box>
      <Box mb={5}>
        <ReadOnlyWrapper label="Current address">
          <span data-test-id="accommodationStatus">{formatLabel(accommodationStatus, AccommodationStatus)}</span>
          <br />
          <span data-test-id="atCurrentAddressSince">{shortNamedDate(currentAddress.atAddressSince)} - Today</span>
          <br />
          <br />
          <span data-test-id="currentAddress">
            {addressToLines(currentAddress.address).map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </span>
        </ReadOnlyWrapper>
      </Box>
      {!!secondAddress && secondAddress.atAddressSince && (
        <Box mb={5}>
          <ReadOnlyWrapper label="Second address">
            <span data-test-id="atSecondAddressSince">
              {shortNamedDate(secondAddress.atAddressSince)} - {shortNamedDate(currentAddress.atAddressSince)}
            </span>
            <br />
            <br />
            <span data-test-id="secondAddress">
              {addressToLines(secondAddress.address).map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </span>
          </ReadOnlyWrapper>
        </Box>
      )}
      {!!thirdAddress && thirdAddress.atAddressSince && !!secondAddress && (
        <Box mb={5}>
          <ReadOnlyWrapper label="Third address">
            <span data-test-id="atThirdAddressSince">
              {shortNamedDate(thirdAddress.atAddressSince)} - {shortNamedDate(secondAddress.atAddressSince)}
            </span>
            <br />
            <br />
            <span data-test-id="thirdAddress">
              {addressToLines(thirdAddress.address).map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </span>
          </ReadOnlyWrapper>
        </Box>
      )}
      {purposeOfLoan && (
        <Box mb={5}>
          <ReadOnlyWrapper label="Purpose of loan">
            <span data-test-id="purposeOfLoan">{formatLabel(purposeOfLoan, PurposeOfLoan)}</span>
          </ReadOnlyWrapper>
        </Box>
      )}
      <Flex>
        <Box sx={{ marginRight: 'auto' }}>
          <FormHeading>Financial details</FormHeading>
        </Box>
        {isBesavvi ? (
          <Link
            data-test-id="editFinancialDetails"
            to={DIRECT_COMPONENTS.financialDetails.path}
            sx={{
              fontSize: 2,
              paddingTop: 3,
            }}
          >
            Edit
          </Link>
        ) : (
          <TertiaryLink
            dataTestId="editFinancialDetails"
            direction={TertiaryLinkDirection.FORWARDS}
            text="Edit"
            onClick={() => history.push(DIRECT_COMPONENTS.financialDetails.path)}
          />
        )}
      </Flex>
      <Box mb={5}>
        <ReadOnlyWrapper label="Employment">
          <span data-test-id="employmentStatus">{formatLabel(employmentStatus, EmploymentStatus)}</span>
          <br />
          <span data-test-id="annualIncome">{formatCurrency(annualIncomeBeforeTax)} annual income</span>
          <br />
          {occupation && (
            <F>
              <span data-test-id="occupation">{sentenceCase(occupation)}</span>
              <br />
            </F>
          )}
          {partnerEmploymentStatus && (
            <F>
              <span data-test-id="partnerEmploymentStatus">
                {formatLabel(partnerEmploymentStatus, EmploymentStatus)} (spouse/partner)
              </span>
              <br />
            </F>
          )}
          {partnerOccupation && (
            <F>
              <span data-test-id="partnerOccupation">{sentenceCase(partnerOccupation)} (spouse/partner)</span>
              <br />
            </F>
          )}
          {employerName && (
            <F>
              <span data-test-id="employerName">{employerName}</span>
              <br />
            </F>
          )}
          <span data-test-id="workPhoneNumber">{formatPhoneNumber(workPhoneNumber || '')}</span>
        </ReadOnlyWrapper>
      </Box>
      <Box mb={5}>
        <ReadOnlyWrapper label="Bank details">
          <span data-test-id="bankHolderName">{sentenceCase(bankHolderName)}</span>
          <br />
          <span data-test-id="bankName">{sentenceCase(bankName)}</span>
          <br />
          <span data-test-id="sortCode">{expandSortCode(sortCode)}</span>
          <br />
          <span data-test-id="accountNumber">{accountNumber}</span>
          <br />
          <span data-test-id="atBankSince">With bank since {shortNamedDate(withBankSince)}</span>
        </ReadOnlyWrapper>
      </Box>
      <ConfirmationCard onSubmit={onSubmit} isPersonalisedQuote={isPersonalisedQuoteEnabled} />
    </F>
  );

  if (isBesavvi) {
    return (
      <Fragment>
        <BackLink />
        {details}
      </Fragment>
    );
  }

  return <NovunaContainer link={<BackLink />}>{details}</NovunaContainer>;
};
