import { DateGroup } from '@plending/interfaces/application';

export function formatLabel(value: string, labelList: Partial<Record<string, string>>): string {
  return labelList[value] || value;
}

export function formatPostCode(postcode = '') {
  const uc = postcode.toUpperCase();

  // HPL-1479
  if (uc.match(/^BFPO.*/)) {
    return uc;
  }

  const ucFormatted = uc.replace(/[^A-Z0-9]/g, '');

  return `${ucFormatted.slice(0, -3)} ${ucFormatted.slice(-3)}`.trim();
}

export function transformISODateStringToDateGroup(dateString: string): DateGroup {
  return {
    year: dateString.substring(0, 4),
    month: dateString.substring(dateString[5] !== '0' ? 5 : 6, 7),
    day: dateString.substring(dateString[8] !== '0' ? 8 : 9, 10),
  };
}
