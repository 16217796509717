import React, { useMemo, Fragment } from 'react';
import { useParams } from 'react-router';
import { jsx, Box, Styled, NovunaHeading, Message } from 'compass-design';
import { useTestId, subTestId } from '../../utils/useDataTestId';
import { DIRECT_COMPONENTS } from '../Routing/Routes';
import { PageTitle } from '../../design-components/heading';
import { Link } from '../../design-components/Link';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { uiMocking } from '../../utils/mocking';
import { ErrorContact } from './ErrorContact';

type ErrorPageProps = {
  errorSource?: string;
  errorReason?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorData?: any;
};

interface ErrorPageParams {
  code?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorSource, errorReason, errorData, ...rest }) => {
  const { code }: ErrorPageParams = useParams();

  const messaging: Record<string, { title: string; message: React.ReactNode }> = useMemo(
    () => ({
      'default:404': {
        title: 'Page not found',
        message: 'The page you requested could not be found.',
      },
      'default:401': {
        title: 'There has been an error',
        message: 'We cannot provide the page or complete the process you requested.',
      },
      'default:500': {
        title: 'There has been an error.',
        message: 'We cannot provide the page you requested.',
      },
      'decision': {
        title: 'There has been an error while getting your decision',
        message: 'We could not complete the process.',
      },
      'signing-service:error': {
        title: 'There has been an error with your e-signing',
        message: 'We could not complete the process.',
      },
      'signing-service:timeout': {
        title: 'Your e-signing request took too long',
        message: (
          <Fragment>
            <p>We could not complete the process</p>
            <p>
              However, you can <Link to={DIRECT_COMPONENTS.preContractCreditInfo.path}>try again</Link>.
            </p>
          </Fragment>
        ),
      },
      'signing-service:user-cancel': {
        title: 'You cancelled your e-signing request',
        message: (
          <p>
            You can <Link to={DIRECT_COMPONENTS.preContractCreditInfo.path}>try again</Link>.
          </p>
        ),
      },
      'authentication:error': {
        title: 'There has been an error',
        message: 'Please re-click the link in your email to try again.',
      },
      'self-service:invalid-token': {
        title: 'This link is invalid.',
        message: 'Please return to MyNPF and try again.',
      },
      'self-service:expired-token': {
        title: 'This link has expired.',
        message: 'Please return to MyNPF and try again.',
      },
    }),
    []
  );

  const { title, message } = useMemo(() => {
    const id = `${errorSource}:${errorReason || code}`;

    return messaging[id] || messaging[`${errorSource}`] || messaging['default:500'];
  }, [code, errorReason, errorSource, messaging]);

  return (
    <ErrorBlock
      {...{
        title,
        message,
        code,
        errorReason,
        errorSource,
        errorData,
        ...rest,
      }}
    />
  );
};

type ErrorBlockProps = ErrorPageProps & {
  code?: string;
  title: string;
  message: React.ReactNode;
};

export const ErrorBlock: React.FC<ErrorBlockProps> = ({
  title,
  message,
  code,
  errorReason,
  errorSource,
  errorData,
  children,
  ...rest
}) => {
  // DO NOT add Context dependencies here (eg Redux, or navigation) - it is used by the <App> Error Boundary
  const testId = useTestId(rest, 'error');

  const codeMessage = useMemo(() => {
    const codes = [];

    if (code) {
      codes.push(`Status: ${code}`);
    }
    if (errorReason) {
      codes.push(`Reason: ${errorReason}`);
    }
    if (errorSource) {
      codes.push(`Source: ${errorSource}`);
    }
    if (uiMocking.useDevMocks && errorData) {
      codes.push(`Data: ${JSON.stringify(errorData)}`);
    }

    return codes.join(', ');
  }, [code, errorData, errorReason, errorSource]);

  return (
    <NovunaContainer>
      <PageTitle>{title}</PageTitle>
      <Message data-test-id="error-message" variant="error">
        <Box {...subTestId('title', testId)}>
          <NovunaHeading as="h3">{message}</NovunaHeading>
        </Box>
        {children}
        <p>
          <ErrorContact />
        </p>
      </Message>
      <Styled.p sx={{ fontSize: 0, color: 'monochrome.3' }}>{codeMessage}</Styled.p>
    </NovunaContainer>
  );
};
