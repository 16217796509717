import { useEffect } from 'react';
import { getToken } from '../../utils/tokens';
import { getData } from '../../utils/serviceCall';
import { REFRESH_SESSION_API } from './types';

export function useInitialiseSession() {
  useEffect(
    () => {
      const token = getToken();

      // Refresh any JWT based sessions, to handle user refreshing the browser
      if (token) {
        // TODO: catch errors and log them
        getData(REFRESH_SESSION_API);
      }
    },
    [] // strictly on mount
  );
}
