import React from 'react';
import { AtSinceGroup } from '@plending/interfaces/application';
import { jsx, Flex, TextField, InputWrapper, Box, Tone } from 'compass-design';
import { FieldPropSet } from '../../validation/types';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useTone, useAggregatedBlur, useAggregatedOnChange } from './hooks';

type AtSinceInputProps = {
  name: string;
  label: string;
  description?: string;
  value: AtSinceGroup;
  touched?: boolean;
  error?: string;
  setFieldTouched: FieldPropSet['setFieldTouched'];
  setFieldValue: FieldPropSet['setFieldValue'];
  onAtSinceChange?: (a: AtSinceGroup) => void;
  disabled?: boolean;
};

export const AtSinceInput: React.FC<AtSinceInputProps> = ({
  name,
  label,
  description,
  value = {} as AtSinceGroup,
  touched,
  error,
  setFieldTouched,
  setFieldValue,
  onAtSinceChange,
  disabled,
}) => {
  const handleChange = useAggregatedOnChange(name, value, setFieldValue, onAtSinceChange);
  const [blurRef, handleBlur] = useAggregatedBlur(name, setFieldTouched);

  const tone: Tone = useTone({ touched, error });
  const id = name;

  const { month = '', year = '' } = value;

  const isBesavvi = useIsBeSavvi();
  const novunaSx = !isBesavvi ? { width: ['100%', null, null, '149px'] } : {};

  return (
    <Box data-test-id={name} mb={description ? 3 : 5}>
      <InputWrapper
        {...{
          id,
          label,
          tone,
          error,
          description: isBesavvi ? description : 'Month and year',
        }}
      >
        <Flex ref={blurRef}>
          <TextField
            flexSx={{ ...novunaSx, mr: !isBesavvi ? 2 : 4 }}
            description={isBesavvi ? 'Month' : ''}
            placeholder="MM"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={2}
            value={month}
            id={`${name}-month`}
            name={`${name}-month`}
            tone={tone}
            disabled={disabled}
          />
          <TextField
            flexSx={{ ...novunaSx }}
            description={isBesavvi ? 'Year' : ''}
            placeholder="YYYY"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={4}
            value={year}
            id={`${name}-year`}
            name={`${name}-year`}
            tone={tone}
            disabled={disabled}
          />
        </Flex>
      </InputWrapper>
    </Box>
  );
};
