import React from 'react';
import VarelaRegular from 'compass-design/assets/fonts/Varela-Regular.woff';
import HindBold from 'compass-design/assets/fonts/Hind-Bold.woff';
import HindRegular from 'compass-design/assets/fonts/Hind-Regular.woff';
import HindLight from 'compass-design/assets/fonts/Hind-Light.woff';
import SilkaLight from 'compass-design/assets/fonts/Silka-Light.woff';
import SilkaRegular from 'compass-design/assets/fonts/Silka-Regular.woff';
import SilkaSemiBold from 'compass-design/assets/fonts/Silka-SemiBold.woff';
import { jsx, beSavviTheme, ThemeProvider, GlobalStyle, Theme, novunaTheme } from 'compass-design';
import { useProductConfig, useSupplierConfig } from '../store/bootstrap/selectors';
import { ThemeChannel, Themes } from './Channels';

interface FontConfig {
  fontFamily: string;
  light: string;
  regular: string;
  bold: string;
}

interface ThemeConfig {
  theme: Theme;
  fonts: FontConfig[];
}

const besavviThemeSupplierIds: ReadonlyArray<string> = ['CEA00000', 'CEB00000', 'CEC00000'];

const plendingTheme: ThemeConfig = {
  theme: novunaTheme,
  fonts: [
    {
      fontFamily: 'Silka',
      light: SilkaLight,
      regular: SilkaRegular,
      bold: SilkaSemiBold,
    },
  ],
};

const CHANNEL_THEME_CONFIG_MAP: Record<ThemeChannel, ThemeConfig> = {
  BESAVVI: {
    theme: beSavviTheme,
    fonts: [
      {
        fontFamily: 'Hind',
        light: HindLight,
        regular: HindRegular,
        bold: HindBold,
      },
      {
        fontFamily: 'Varela',
        light: VarelaRegular,
        regular: VarelaRegular,
        bold: VarelaRegular,
      },
    ],
  },
  LOANS: plendingTheme,
  HPF: plendingTheme,
  NOVUNA: plendingTheme,
};

interface GetThemeConfigFromChannelAndSupplier {
  channel: ThemeChannel;
  supplierId?: string;
}

const getThemeConfigFromChannelAndSupplier = ({
  channel,
  supplierId,
}: GetThemeConfigFromChannelAndSupplier): ThemeConfig => {
  if (besavviThemeSupplierIds.includes(String(supplierId).toUpperCase()) && CHANNEL_THEME_CONFIG_MAP[Themes.BESAVVI]) {
    return CHANNEL_THEME_CONFIG_MAP['BESAVVI'];
  }

  if (CHANNEL_THEME_CONFIG_MAP[channel]) {
    return CHANNEL_THEME_CONFIG_MAP[channel];
  }

  return CHANNEL_THEME_CONFIG_MAP['LOANS'];
};

export const GlobalThemeHolder: React.FC = ({ children }) => {
  const { channel } = useProductConfig();
  const { id: supplierId = '' } = useSupplierConfig();
  const { theme, fonts } = getThemeConfigFromChannelAndSupplier({ channel, supplierId });

  return (
    <ThemeProvider theme={theme}>
      {fonts.map(({ fontFamily, light, regular, bold }, index) => (
        <GlobalStyle
          key={index}
          fontFamily={fontFamily}
          lightFontUrl={light}
          regularFontUrl={regular}
          boldFontUrl={bold}
        />
      ))}
      {children}
    </ThemeProvider>
  );
};
