import { AlternativeLoanData } from '@plending/interfaces/alternative-loan';
import { RemoteAction, RemoteState } from '../types';

export enum AlternativeLoanActionType {
  CLEAR = 'alternativeLoan/CLEAR',
  REQUEST = 'alternativeLoan/REQUEST',
  LOAD = 'alternativeLoan/LOAD',
  FAILED = 'alternativeLoan/FAILED',
}

export interface AlternativeLoanAction extends RemoteAction {
  type: AlternativeLoanActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface AlternativeLoanState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: AlternativeLoanData;
}

export interface AlternativeLoanResponse {
  data: AlternativeLoanData;
}
