import React, { useMemo } from 'react';
import { jsx } from 'compass-design';

/**
import logo1x from './logo.png'
import logo2x from './logo@2x.png'
import logo3x from './logo@3x.png'

<Image
  alt="Logo"
  height="100"
  width="200"
  x1src={logo1x}
  x2src={logo2x}
  x3src={logo3x}
/>

 */

export type ImageProps = {
  alt: string;
  width: string | number;
  height: string | number;
  x1src: string;
  x2src?: string;
  x3src?: string;
};

export const Image: React.FC<ImageProps> = ({ x1src, x2src, x3src, alt, width, height, ...restProps }) => {
  const srcSet = useMemo(() => {
    const set = [`${x1src} 1x`];

    if (x2src) {
      set.push(`${x2src} 2x`);
    }

    if (x3src) {
      set.push(`${x3src} 3x`);
    }

    return set.join(', ');
  }, [x1src, x2src, x3src]);

  return <img alt={alt} src={x1src} srcSet={srcSet} width={width} height={height} {...restProps} />;
};
