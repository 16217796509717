import { QuotationAction, QuotationDetails, QuotationActionType } from './types';

export function reducer(state = {}, { type, payload }: QuotationAction): Partial<QuotationDetails> {
  switch (type) {
    case QuotationActionType.STORE: {
      return payload as QuotationDetails;
    }
    case QuotationActionType.CLEAR: {
      return {};
    }
    default:
      return state;
  }
}
