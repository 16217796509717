import { useSelector } from 'react-redux';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { DecisionState, DecisionRequestPayload } from './types';

const selectDecision = (s: RootState) => s.decision;

export function useDecisionSelector(): DecisionState {
  return useSelector(selectDecision);
}

export function useCallbackDecisionSelector(): () => DecisionState {
  return useCallbackSelector(selectDecision);
}

const selectDecisionRequestPayload = ({ preDecisionConfirmation }: RootState) => ({
  preDecisionConfirmation,
});

export function useCallbackDecisionRequestPayloadSelector(): () => DecisionRequestPayload {
  return useCallbackSelector(selectDecisionRequestPayload);
}
