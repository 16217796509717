import {
  jsx,
  Button,
  Continue,
  Search,
  CheckboxButton as BaseCheckboxButton,
  Box,
  InlineCheckbox,
} from 'compass-design';
import { ChangeEventHandler, useMemo } from 'react';
import { useIsBeSavvi } from '../utils/useIsBesavvi';
import { FieldPropSet } from '../validation/types';

export type ButtonProps = {
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  busy?: boolean;
};

export type ContinueButtonProps = ButtonProps & {
  hideInPrint?: boolean;
  isSecondary?: boolean;
};

const hideInPrintSx = {
  '@media print': {
    display: 'none',
  },
};

const buttonSx = {
  'minWidth': 'columns.2',
  '@media print': {
    display: 'none',
  },
};

const disabledSx = {
  ...buttonSx,
  'backgroundColor': 'disabledPrimary',
  'borderColor': 'disabledPrimary',
  'boxShadow': 'none',
  'cursor': 'default',
  '&:hover, &:focus': {
    backgroundColor: 'disabledPrimary',
    borderColor: 'disabledPrimary',
  },
};

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  children,
  disabled = false,
  busy = false,
  hideInPrint = false,
  isSecondary = false,
  ...props
}) => (
  <Button
    isJumbo
    variant={isSecondary ? 'secondary' : 'primary'}
    disabled={disabled || busy}
    type="submit"
    sx={{
      whiteSpace: 'nowrap',
      ...(disabled || busy ? disabledSx : buttonSx),
      ...(hideInPrint ? hideInPrintSx : {}),
    }}
    {...props}
  >
    {children}
    <Continue />
  </Button>
);

export const FindButton: React.FC<ButtonProps> = ({ children, busy = false, ...props }) => (
  <Button
    variant="secondary"
    type="button"
    sx={{
      whiteSpace: 'nowrap',
      minWidth: '6em',
      ...(busy
        ? {
            'color': 'disabledPrimary',
            'borderColor': 'disabledPrimary',
            'boxShadow': 'none',
            'cursor': 'default',
            '&:hover': {
              color: 'disabledPrimary',
              borderColor: 'disabledPrimary',
            },
          }
        : {}),
    }}
    disabled={busy}
    {...props}
  >
    {children}
    <Search />
  </Button>
);

type CheckBoxInputProps = {
  name: string;
  value: boolean;
  dataTestId?: string;
  setFieldValue: FieldPropSet['setFieldValue'];
  onBlur: FieldPropSet['onBlur'];
};

export const CheckboxButton: React.FC<CheckBoxInputProps> = ({
  children,
  name,
  value,
  dataTestId,
  setFieldValue,
  onBlur,
}) => {
  const handlers = useMemo(() => {
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      setFieldValue(name, e.target.checked);
    };

    return {
      onChange: changeHandler,
    };
  }, [name, setFieldValue]);

  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <Box data-test-id={name} mr={3}>
        <BaseCheckboxButton id={name} name={name} checked={value} onBlur={onBlur} {...handlers}>
          {children}
        </BaseCheckboxButton>
      </Box>
    );
  }
  return (
    <Box data-test-id={name} mb={3}>
      <InlineCheckbox dataTestId={dataTestId} id={name} name={name} checked={value} onBlur={onBlur} {...handlers}>
        {children}
      </InlineCheckbox>
    </Box>
  );
};
