import React, { ChangeEvent, useMemo, FocusEventHandler } from 'react';
import { jsx, InputWrapper, RadioGroup, Box, Tone } from 'compass-design';
import { FieldPropSet } from '../../validation/types';
import { LabelList } from './OptionList';
import { useTone, useAggregatedBlur, useHandleChange } from './hooks';

type SpreadSelectProps<TValues extends readonly string[] = readonly string[]> = {
  name: string;
  label: string;
  description?: string;
  value: string;
  values: TValues;
  labels?: LabelList<TValues[number]>;
  touched?: boolean;
  error?: string;
  onValueChange?: (v: string) => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur: FocusEventHandler<HTMLInputElement>;
  setFieldTouched: FieldPropSet['setFieldTouched'];
};

export const SpreadSelect: React.FC<SpreadSelectProps> = ({
  name,
  label,
  description,
  values,
  labels = {},
  value,
  touched,
  error,
  onChange,
  onValueChange,
  setFieldTouched,
}) => {
  const id = name;
  const tone: Tone = useTone({ touched, error });

  const optionList = useMemo(() => {
    return values.map((optionValue) => ({
      value: optionValue,
      label: labels[optionValue] || optionValue,
      checked: optionValue === value,
    }));
  }, [labels, value, values]);

  const handleChange = useHandleChange<HTMLInputElement>(onChange, onValueChange);

  const [blurRef, handleBlur] = useAggregatedBlur(name, setFieldTouched);

  return (
    <Box data-test-id={name} mb={3}>
      <InputWrapper
        descriptionTop
        fieldset
        {...{
          id,
          label,
          tone,
          error,
          description,
        }}
      >
        <RadioGroup ref={blurRef} name={name} options={optionList} onChange={handleChange} onBlur={handleBlur} />
      </InputWrapper>
    </Box>
  );
};
