import { UiConfig } from '@plending/interfaces/bootstrap-data';
import { adjustExpiryMsWithSafetyMargin } from '@plending/common/expiry-time';
import { useSelector } from 'react-redux';
import { selectBootstrapData } from '../bootstrap/selectors';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { SessionState, SessionDetails } from './types';

export function getExpiryTime(startedAt: number, inactivityTimeoutMs: number): number {
  const adjustedTimeout = adjustExpiryMsWithSafetyMargin(inactivityTimeoutMs);

  return startedAt + adjustedTimeout;
}

export function getSecondsLeft(expires: number) {
  const msLeft = expires - performance.now();

  return Math.round(msLeft / 1000);
}

// Derive data about session expiry from the whole state
export const selectSessionDetails = (s: RootState): SessionDetails => {
  const { session: { startedAt } = {} as SessionState } = s;

  const { data: { config: { inactivityTimeoutMs } = {} as UiConfig } = {} } = selectBootstrapData(s);

  let expiresAt;
  let secondsLeft;

  if (inactivityTimeoutMs && startedAt) {
    expiresAt = getExpiryTime(startedAt, inactivityTimeoutMs);
    secondsLeft = getSecondsLeft(expiresAt);
  }

  return {
    startedAt,
    inactivityTimeoutMs,
    expiresAt,
    secondsLeft,
  };
};

export function useSessionDetailsSelector(): SessionDetails {
  // Redux won't have updated, but we still need the derived data to update
  const state = useSelector((s: RootState) => s);

  return selectSessionDetails(state);
}

export function useCallbackSessionDetailsSelector(): () => SessionDetails {
  return useCallbackSelector(selectSessionDetails);
}
