import React from 'react';
import { DateGroup } from '@plending/interfaces/application';
import { jsx, Flex, TextField, InputWrapper, Box, Tone } from 'compass-design';
import { FieldPropSet } from '../../validation/types';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useTone, useAggregatedBlur, useAggregatedOnChange } from './hooks';

type DateInputProps = {
  name: string;
  label: string;
  description?: string;
  value: DateGroup;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  onDateChange?: (d: DateGroup) => void;
  setFieldTouched: FieldPropSet['setFieldTouched'];
  setFieldValue: FieldPropSet['setFieldValue'];
};

export const DateInput: React.FC<DateInputProps> = ({
  name,
  label,
  description,
  value = {} as DateGroup,
  touched,
  error,
  onDateChange,
  setFieldTouched,
  setFieldValue,
}) => {
  const handleChange = useAggregatedOnChange(name, value, setFieldValue, onDateChange);
  const [blurRef, handleBlur] = useAggregatedBlur(name, setFieldTouched);

  const tone: Tone = useTone({ touched, error });
  const id = name;
  const isBesavvi = useIsBeSavvi();

  const { day = '', month = '', year = '' } = value;
  const novunaSx = isBesavvi ? {} : { width: ['100%', null, null, '97px'] };

  return (
    <Box data-test-id={name} mb={description ? 3 : 5}>
      <InputWrapper
        {...{
          id,
          label,
          tone,
          error,
          description: isBesavvi ? description : 'Day, month and year',
        }}
      >
        <Flex ref={blurRef}>
          <TextField
            flexSx={{ ...novunaSx, mr: isBesavvi ? 4 : 2 }}
            description={isBesavvi ? 'Day' : ''}
            placeholder="DD"
            maxLength={2}
            onChange={handleChange}
            onBlur={handleBlur}
            value={day}
            id={`${name}-day`}
            name={`${name}-day`}
            tone={tone}
          />
          <TextField
            flexSx={{ ...novunaSx, mr: isBesavvi ? 4 : 2 }}
            description={isBesavvi ? 'Month' : ''}
            placeholder="MM"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={2}
            value={month}
            id={`${name}-month`}
            name={`${name}-month`}
            tone={tone}
          />
          <TextField
            flexSx={{ ...novunaSx }}
            description={isBesavvi ? 'Year' : ''}
            placeholder="YYYY"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={4}
            value={year}
            id={`${name}-year`}
            name={`${name}-year`}
            tone={tone}
          />
        </Flex>
      </InputWrapper>
    </Box>
  );
};
