import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { Required, MinValue, MaxValue, IsNumber } from '@plending/validation/rules';
import { AllOf } from '@plending/validation/utils';
import { penceToPoundsString, getDetails } from '@plending/validation/quote-utils';
import { Product, ApplicationFlowType } from '@plending/interfaces/bootstrap-data';
import { jsx, PrivacyPolicyCard, Box } from 'compass-design';
import { useNavigation } from '../Routing/useNavigation';
import { useBootstrapDataSelector, usePersonalisedQuoteProvider, useUiConfig } from '../../store/bootstrap/selectors';
import { QuotationDetails } from '../../store/quotation/types';
import { useStoreQuotation } from '../../store/quotation/actions';
import { useValidation } from '../../validation/useValidation';
import { useStorePrivacy } from '../../store/privacy/actions';
import { FieldPropSet, FieldErrors, RuleError } from '../../validation/types';
import { useQuotationSelector } from '../../store/quotation/selectors';
import { ContinueButton } from '../../design-components/button';
import { PageTitle } from '../../design-components/heading';
import { Step } from '../../design-components/step';
import { SingleInput } from '../../design-components/inputs/SingleInput';
import { DropdownInput } from '../../design-components/inputs/DropdownInput';
import { getToken } from '../../utils/tokens';
import { useStopSessionTimer } from '../../store/session/action';
import { useCallbackSessionDetailsSelector } from '../../store/session/selectors';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { ErrorPage } from '../Errors/Error';
import { useExistingAgreementDetailsSelector } from '../../store/existingAgreementDetails/selectors';
import { listTermLabels, findRateLimits } from './quotationHelpers';
import { QuotationSummary } from './QuotationSummary';
import { ExpiryPanel } from './ExpiryPanel';
import { SoftSearchMessage } from './SoftSearchMessage';

type LocalFormikBag = {
  // Formik status is used to keep track of the amount for which an invalid term has been cleared
  status: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  setStatus: (status?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  setFieldValue: FieldPropSet['setFieldValue'];
  errors: FieldErrors;
};

export const Quotation: React.FC = () => {
  const getSessionDetails = useCallbackSessionDetailsSelector();
  const stopSessionTimer = useStopSessionTimer();
  const isPersonalisedQuoteEnabled = usePersonalisedQuoteProvider();
  const [showExpiryPanel, setShowExpiryPanel] = useState(false);

  useEffect(
    () => {
      const token = getToken();
      const { secondsLeft } = getSessionDetails();

      // If there's the remnants of an expired session, show the message
      if (secondsLeft !== undefined && secondsLeft <= 0) {
        setShowExpiryPanel(true);
      }

      // if there's a non-JWT based session running, stop it
      if (!token) {
        stopSessionTimer();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // strictly on mount
  );

  const { data: { product: { rates } = {} as Product } = {} } = useBootstrapDataSelector();

  const { loanPence, termMonths } = useQuotationSelector();

  const prePopulatedLoanAmount = useMemo(() => {
    return loanPence ? `${loanPence / 100}` : '';
  }, [loanPence]);

  const [enteredAmount, setEnteredAmount] = useState<string>(prePopulatedLoanAmount);

  const { minValidLoanPence, maxValidLoanPence, minValidTerm, maxValidTerm, termList } = useMemo(
    () => findRateLimits(rates, enteredAmount),
    [enteredAmount, rates]
  );

  const termValues = useMemo(() => (termList || []).map((t) => `${t}`), [termList]);

  const termLabels = useMemo(() => listTermLabels(minValidTerm, maxValidTerm), [maxValidTerm, minValidTerm]);

  const minLoanPounds = penceToPoundsString(minValidLoanPence, false);
  const maxLoanPounds = penceToPoundsString(maxValidLoanPence, false);

  const formikBag = useRef<LocalFormikBag>({
    status: false,
    setStatus: () => {
      /* noop default */
    },
    setFieldValue: () => {
      /* noop default */
    },
    errors: {},
  });

  const amountRules = useMemo(
    () => AllOf(Required, IsNumber, MinValue(minValidLoanPence / 100), MaxValue(maxValidLoanPence / 100)),
    [maxValidLoanPence, minValidLoanPence]
  );

  const amountFieldSpec = useMemo(() => {
    const boundsMessage = `The loan amount should be between ${minLoanPounds} and ${maxLoanPounds}`;

    return {
      name: 'amount',
      initialValue: loanPence ? (loanPence / 100).toFixed(0) : '',
      rules: [],
      messages: {
        'Too small': boundsMessage,
        'Too large': boundsMessage,
      },
    };
  }, [loanPence, maxLoanPounds, minLoanPounds]);

  const termFieldSpec = useMemo(
    () => ({
      name: 'term',
      initialValue: termMonths ? termMonths.toFixed(0) : '',
      rules: [],
      messages: {
        Invalid: 'The loan amount is not available for the term selected. Please select another term.',
      },
    }),
    [termMonths]
  );

  const validate = useCallback(
    ({ amount, term }) => {
      let details;
      const intAmount: string = amount.replace(/\D/g, '');

      const {
        current: { status, setStatus, setFieldValue },
      } = formikBag;

      const nextErrors: { amount: RuleError; term: RuleError } = {
        amount: amountRules(intAmount),
        term: Required(term),
      };

      if (!nextErrors.amount && !nextErrors.term) {
        details = getDetails(rates, parseInt(intAmount, 10) * 100, parseInt(term, 10));

        if (!details) {
          setFieldValue('term', '', false);
          setStatus(intAmount);

          nextErrors.term = 'Invalid';
        }
      } else if (status && nextErrors.term === 'Required') {
        nextErrors.term = 'Invalid';
      }

      if (!nextErrors.term) {
        setStatus(false);
      }

      setQuote(details);

      return nextErrors;
    },
    [amountRules, rates]
  );

  const {
    handleSubmit,
    values,
    errors,
    status,
    setStatus,
    validateForm,
    submitForm,
    setFieldValue,
    setFieldError,
    fieldProps,
  } = useValidation([amountFieldSpec, termFieldSpec], {
    validate,
  });

  formikBag.current = useMemo(
    () => ({
      status,
      setStatus,
      setFieldValue,
      errors,
    }),
    [errors, setFieldValue, setStatus, status]
  );

  useEffect(() => {
    validateForm().then((d) => {
      if (Object.keys(d).length === 0) {
        submitForm();
      }
    });
  }, [submitForm, validateForm, values]);

  const [quote, setQuote] = useState<QuotationDetails | null>();

  const storeQuotation = useStoreQuotation();

  const storePrivacy = useStorePrivacy();

  const { navigateToNextStep } = useNavigation();

  const proceedWithQuote = useCallback(() => {
    if (quote) {
      storeQuotation(quote);
      storePrivacy();
      navigateToNextStep();
    }
  }, [navigateToNextStep, quote, storePrivacy, storeQuotation]);

  const onAmountChange = useCallback(
    (v) => {
      if (status && status !== v) {
        setStatus(false);
      }
      const intAmount = v.replace(/\D/g, '');
      setEnteredAmount(intAmount);
      setFieldError('term', undefined);
    },
    [setFieldError, setStatus, status]
  );

  const isBesavvi = useIsBeSavvi();

  const { applicationFlowType } = useUiConfig();
  const existingAgreement = useExistingAgreementDetailsSelector();
  if (applicationFlowType === ApplicationFlowType.TOP_UP && !existingAgreement) {
    return <ErrorPage />;
  }

  const TestRUMButtons = () => (
    <>
      {' '}
      <Box mt={!isBesavvi ? 5 : 0} mb={!isBesavvi ? 0 : 3}>
        <ContinueButton
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const t: any = {};
            // eslint-disable-next-line  no-console
            console.log(t.item.there);
          }}
        >
          Throw Error Access To Test RUM Again
        </ContinueButton>
      </Box>
      <Box mt={!isBesavvi ? 5 : 0} mb={!isBesavvi ? 0 : 3}>
        <ContinueButton
          onClick={() => {
            throw new Error('Throw error To Test RUM');
          }}
        >
          Throw Error To Test RUM
        </ContinueButton>
      </Box>
    </>
  );

  const details = (
    <>
      {showExpiryPanel && <ExpiryPanel isBesavvi={isBesavvi} />}
      {isPersonalisedQuoteEnabled && <SoftSearchMessage />}

      <Step step={1} />
      <PageTitle>Apply for a personal loan</PageTitle>

      <form onSubmit={handleSubmit}>
        <SingleInput
          label="How much would you like to borrow?"
          prefix="£"
          placeholder="0"
          onValueChange={onAmountChange}
          autoComplete="off"
          selectOnFocus
          stackedLabel={true}
          halfWidth={true}
          short
          {...fieldProps.amount}
          value={enteredAmount}
        />
        <DropdownInput
          label="Over how long?"
          values={termValues}
          labels={termLabels}
          placeholder="Please select"
          stackedLabel={true}
          short
          {...fieldProps.term}
        />
      </form>

      {!isPersonalisedQuoteEnabled && <QuotationSummary isBesavvi={isBesavvi} quote={quote} />}

      <Box my={isBesavvi ? 6 : 4} data-test-id="privacy-policy">
        <PrivacyPolicyCard
          headingVariant="tertiary"
          crainUrl="https://www.novunapersonalfinance.co.uk/crain/"
          fullPolicyUrl="https://www.novunapersonalfinance.co.uk/privacy-policy/"
          novunaOverride={!isBesavvi}
          isHardSearch={!isPersonalisedQuoteEnabled}
        />
      </Box>

      <Box mt={!isBesavvi ? 5 : 0} mb={!isBesavvi ? 0 : 3}>
        <ContinueButton disabled={!quote} data-test-id="proceed-button" onClick={proceedWithQuote}>
          Continue
        </ContinueButton>
      </Box>

      {process.env.REACT_APP_COMPASS_AUTH_SERVICE_CLIENT === 'plending-dev' && <TestRUMButtons />}
    </>
  );

  return isBesavvi ? details : <NovunaContainer>{details}</NovunaContainer>;
};
