import { IovationDevice } from '@plending/interfaces/application';
import { IovationWindow } from '@plending/interfaces/iovation';

export const getIovationFingerprint = (): IovationDevice | void => {
  const IGLOO = (window as unknown as IovationWindow)?.IGLOO;

  if (!IGLOO?.getBlackbox) {
    if (process.env.REACT_APP_STAGE === 'local') return { blackbox: 'true' };
    return;
  }

  const { blackbox, finished } = IGLOO.getBlackbox();

  if (!finished) return;

  return { blackbox };
};
