import { RemoteState } from '../types';

// The Data
export enum AgreementDownloadActionType {
  CLEAR = 'agreementdownload/CLEAR',
  REQUEST = 'agreementdownload/REQUEST',
  LOAD = 'agreementdownload/LOAD',
  FAILED = 'agreementdownload/FAILED',
}

export interface AgreementDownloadRemoteState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: {
    downloadUrl: string;
  };
}

// Retries
export enum AgreementDownloadRetriesActionType {
  CLEAR = 'agreementdownloadRetries/CLEAR',
  SET = 'agreementdownloadRetries/SET',
}

export interface AgreementDownloadRetriesAction {
  type: AgreementDownloadRetriesActionType;
  payload: AgreementDownloadRetriesState;
}

export type AgreementDownloadRetriesState = number;

// Combined state type
export type AgreementDownloadState = {
  remote: AgreementDownloadRemoteState;
  retries: AgreementDownloadRetriesState;
};
