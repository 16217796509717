import { ExistingAgreementDetails } from '@plending/interfaces/application';
import { useSelector } from 'react-redux';
import { RootState } from '../rootState';

const selectExistingAgreementDetails = ({
  existingAgreementDetails,
}: RootState): RootState['existingAgreementDetails'] => existingAgreementDetails;

export function useExistingAgreementDetailsSelector(): ExistingAgreementDetails {
  return useSelector(selectExistingAgreementDetails);
}
