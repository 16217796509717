import React from 'react';
import { jsx } from 'compass-design';
import { createBrowserHistory, History } from 'history';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { Page } from './components/Page/Page';
import { StaticPageHolder } from './components/Page/PageHolder';
import { ErrorBlock } from './components/Errors/Error';
import { createSafeStore } from './store/createSafeStore';
import { RootState } from './store/rootState';
import { uiMocking } from './utils/mocking';

declare function cwr(operation: string, payload: unknown): void;

// This has to be a class because Error boundaries have to be classes
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
// https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks
export class App extends React.Component<
  { history?: History; store?: Store<Partial<RootState>, AnyAction> },
  { error?: Error }
> {
  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public componentDidCatch(error: Error) {
    if (uiMocking.useDevMocks)
      // eslint-disable-next-line no-console
      console.error(error);
    if (typeof cwr !== 'undefined') {
      cwr('recordError', error);
    }
  }

  public state: { error?: Error } = {
    error: undefined,
  };

  public renderError() {
    const history = createBrowserHistory();
    const store = createSafeStore(history);

    const { error: { message = 'Unknown', name = 'Unknown' } = {} as Error } = this.state;

    return (
      <Provider store={store}>
        <StaticPageHolder>
          <ErrorBlock
            errorSource="error-boundary"
            data-test-id="error-boundary"
            errorReason={name}
            errorData={message}
            title="There has been an error."
            message="We cannot provide the page or complete the process you requested."
          />
        </StaticPageHolder>
      </Provider>
    );
  }

  public render() {
    if (this.state.error) {
      return this.renderError();
    }

    if (this.props.history || this.props.store) {
      return <Page history={this.props.history} store={this.props.store} />;
    }

    return <Page />;
  }
}
