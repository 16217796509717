import React from 'react';
import { Supplier } from '@plending/interfaces/bootstrap-data';
import { jsx, List, ListItem } from 'compass-design';
import { Link } from '../../design-components/Link';
import { useBootstrapDataSelector } from '../../store/bootstrap/selectors';
import { DIRECT_COMPONENTS } from '../Routing/Routes';
import { ErrorBlock } from './Error';

export const InvalidEntryPoint: React.FC = () => {
  const { data: { supplier: { id: supplierId } = {} as Supplier } = {} } = useBootstrapDataSelector();

  return (
    <ErrorBlock
      data-test-id="error"
      title="There has been an error."
      message="We cannot provide the page you requested."
    >
      <List fontSize="1">
        <ListItem>If you have submitted an application, please review it using the email you have been sent.</ListItem>
        {!!supplierId && (
          <ListItem>
            If not, you can{' '}
            <Link data-test-id="quote-link" to={DIRECT_COMPONENTS.quotation.path}>
              start again with a new quotation
            </Link>
            .
          </ListItem>
        )}
      </List>
    </ErrorBlock>
  );
};
