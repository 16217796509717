import { useState, useCallback } from 'react';
import { fetchData } from '../utils/serviceCall';

export function useDownload(downloadUrl: string, saveName: string): [() => void, boolean, boolean] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const expose = useCallback(
    (url: string) => {
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = saveName;

      document.body.appendChild(anchor);

      anchor.click();
      anchor.remove();

      setLoading(false);
    },
    [saveName]
  );

  const exposeBlob = useCallback(
    (data: Blob) => {
      const url = window.URL.createObjectURL(data);

      return expose(url);
    },
    [expose]
  );

  const exposeBase64 = useCallback((data: string) => expose(data), [expose]);

  const download = useCallback(() => {
    if (!loading) {
      setError(false);
      setLoading(true);

      fetchData<Blob | string>(downloadUrl, {
        headers: new Headers({
          Accept: 'application/pdf',
        }),
      } as Request)
        .then((data) => {
          if (typeof data === 'string') {
            return exposeBase64(data);
          }

          return exposeBlob(data);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [downloadUrl, exposeBase64, exposeBlob, loading]);

  return [download, loading, error];
}
