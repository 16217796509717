let AUTH_TOKEN = '';

// Really simple token change "event handler". No need for expensive pubsub since there will only ever be one handler.
export type TokenChangeHandler = (t?: string) => void;

let handleChange: TokenChangeHandler = () => {};

export const getToken = () => {
  if (!window.sessionStorage) {
    return AUTH_TOKEN;
  }

  try {
    const sessionToken = window.sessionStorage.getItem('jwt');
    if (!sessionToken) {
      return AUTH_TOKEN;
    }

    return sessionToken;
  } catch (error: any) {
    // Logging is not required as we can fallback to value in memory
    return AUTH_TOKEN;
  }
};

export const setToken = (token: string) => {
  AUTH_TOKEN = token;
  handleChange(token);

  try {
    window.sessionStorage.setItem('jwt', token);
  } catch (error: any) {
    // Logging is not required as we can fallback to value in memory
  }
};

export const deleteToken = () => {
  AUTH_TOKEN = '';
  handleChange(undefined);

  try {
    window.sessionStorage.removeItem('jwt');
  } catch (error: any) {
    // Logging is not required as we can fallback to value in memory
  }
};

export function setTokenChangeHandler(newHandler: TokenChangeHandler) {
  handleChange = newHandler;
}
