import { AddressDetails, DateGroup, AtSinceGroup } from '@plending/interfaces/application';
import { formatPostCode } from '@plending/common/formatters';
import { BankDetails } from '@plending/interfaces/bank-check';

export function sentenceCase(s = '') {
  return s
    .split(' ')
    .filter((i) => i)
    .map((s) => {
      return `${s[0].toUpperCase()}${s.toLowerCase().slice(1)}`;
    })
    .join(' ');
}

export function addressToLines(
  { houseNumber, houseName, flatName, street, town, county, postcode } = {} as AddressDetails
) {
  return [
    sentenceCase(flatName),
    sentenceCase(houseName),
    [houseNumber, sentenceCase(street)].filter((i) => i).join(' '),
    sentenceCase(town),
    sentenceCase(county),
    formatPostCode(postcode),
  ].filter((i) => i);
}

export function bankCheckToLines(
  { branchName, bankName, town, county, postCode, addressLine1, addressLine2, addressLine3 } = {} as BankDetails
) {
  return [
    sentenceCase(bankName),
    sentenceCase(branchName),
    sentenceCase(addressLine1),
    sentenceCase(addressLine2),
    sentenceCase(addressLine3),
    sentenceCase(town),
    sentenceCase(county),
    formatPostCode(postCode),
  ].filter((i) => i);
}

export function addressToLabel(addressObj: AddressDetails) {
  return addressToLines(addressObj).join(', ');
}

export function shortNamedDate({ month, year }: AtSinceGroup): string {
  const date = new Date(`${month}/1/${year}`);

  return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long' });
}

export function fullNamedDate({ day, month, year }: DateGroup): string {
  const date = new Date(`${month}/${day}/${year}`);

  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  });
}

export function expandSortCode(sortCode: string): string {
  const [a, b, c, d, e, f] = sortCode.split('');

  return `${a}${b}-${c}${d}-${e}${f}`;
}

export function formatCurrency(value: number): string {
  return value % 1 !== 0
    ? '£' +
        value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '£' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function addSpaces(charArray: string[], ...spacePositions: number[]): string {
  spacePositions.reverse().forEach((pos) => {
    charArray.splice(pos, 0, ' ');
  });

  return charArray.join('');
}

export function formatPhoneNumber(phoneNumber: string): string {
  const strippedPhoneNumber = `${phoneNumber}`.replace(/[^0-9]/g, '').split('');

  if (strippedPhoneNumber.length < 8) {
    return strippedPhoneNumber.join('');
  }

  switch (strippedPhoneNumber[1]) {
    case '2':
      return addSpaces(strippedPhoneNumber, 3, 7);
    case '3':
    case '8':
    case '9':
      return addSpaces(strippedPhoneNumber, 4, 7);
    case '1':
      if (strippedPhoneNumber[2] === '1' || strippedPhoneNumber[3] === '1') {
        return addSpaces(strippedPhoneNumber, 4, 7);
      }
  }

  return addSpaces(strippedPhoneNumber, 5);
}

export const internationaliseNumber = (number: string): string => {
  if (number.startsWith('0')) {
    return number.replace('0', '+44 ');
  }

  return '+44 ' + number;
};

export const removeWhiteSpace = (value: string) => value.replace(/\s/g, '');
