import { Tone } from 'compass-design';
import { useRef, useCallback, FocusEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { FieldPropSet } from '../../validation/types';

type toneMeta = {
  touched?: boolean;
  error?: string;
};

export function useTone({ touched, error }: toneMeta, errorOverride?: string) {
  let tone: Tone = 'neutral'; // memoising this is more expensive...

  if (errorOverride) {
    tone = 'negative';
  } else if (touched) {
    if (error) {
      tone = 'negative';
    } else {
      tone = 'positive';
    }
  }

  return tone;
}

export function useHandleChange<T extends HTMLInputElement | HTMLSelectElement>(
  onChange: (event: ChangeEvent<T>) => void,
  onValueChange?: (v: string) => void
) {
  const handleChange: ChangeEventHandler<T> = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }

      if (onValueChange) {
        onValueChange(e.target.value);
      }
    },
    [onChange, onValueChange]
  );

  return handleChange;
}

export function useAggregatedOnChange<T>(
  name: string,
  value: T,
  setFieldValue: FieldPropSet['setFieldValue'],
  onValueChange?: (d: T) => void
): ChangeEventHandler<HTMLInputElement> {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const srcName = e.target.name.substring(name.length + 1);
      const srcValue = e.target.value;

      const newValue = {
        ...value,
        [srcName]: srcValue,
      };

      setFieldValue(name, newValue);

      if (onValueChange) {
        onValueChange(newValue);
      }
    },
    [name, onValueChange, setFieldValue, value]
  );

  return handleChange;
}

export function useAggregatedBlur(
  name: string,
  setFieldTouched: FieldPropSet['setFieldTouched']
): [React.RefObject<HTMLDivElement>, FocusEventHandler<HTMLInputElement>] {
  const domHolder = useRef<HTMLDivElement>(null);

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setTimeout(
      // need to give the browser chance to fire the focus event on the next element
      () => {
        if (domHolder.current && !domHolder.current.contains(document.activeElement)) {
          setFieldTouched(name);
        }
      },
      100
    );
  }, [name, setFieldTouched]);

  return [domHolder, handleBlur];
}
