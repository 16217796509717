import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ApplicationStatus, PersonalDetails, FinancialDetails } from '@plending/interfaces/application';
import { Dispatch } from 'redux';
import { Quote } from '@plending/interfaces/quote';
import { isEmpty } from '@plending/validation/data-utils';
import { getData } from '../../utils/serviceCall';
import { PersonalDetailsActionType } from '../personalDetails/types';
import { ApplicationActionType } from '../application/types';
import { FinancialDetailsActionType } from '../financialDetails/types';
import { QuotationActionType } from '../quotation/types';
import { getPath } from '../../components/Routing/useNavigation';
import { DIRECT_COMPONENTS } from '../../components/Routing/Routes';
import { AggregatorActionType, AggregatorResponse } from './types';

function dispatchStoreActions(dispatch: Dispatch, data: AggregatorResponse['data']) {
  const {
    application: { applicant, supplier, quote },
  } = data;

  dispatch({
    type: AggregatorActionType.LOAD,
    payload: {
      data: {
        redirectUrl: getPath(DIRECT_COMPONENTS.quotation, {}),
      },
    },
  });

  if (
    !applicant ||
    isEmpty(applicant) ||
    !applicant.personalDetails ||
    isEmpty(applicant.personalDetails) ||
    !supplier ||
    isEmpty(supplier) ||
    !quote ||
    isEmpty(quote)
  ) {
    dispatch({
      type: AggregatorActionType.FAILED,
      payload: { error: 'Missing required data' },
    });

    return;
  }

  dispatch({
    type: QuotationActionType.STORE,
    payload: quote as Quote,
  });

  dispatch({
    type: PersonalDetailsActionType.STORE,
    payload: applicant.personalDetails as PersonalDetails,
  });

  if (applicant.financialDetails) {
    dispatch({
      type: FinancialDetailsActionType.STORE,
      payload: applicant.financialDetails as FinancialDetails,
    });
  }

  dispatch({
    type: ApplicationActionType.SET_STATUS,
    payload: {
      data: ApplicationStatus.DRAFT,
    },
  });
}

export function useFetchAggregatorApplication(hdToken: string, supplierId: string) {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch({ type: AggregatorActionType.REQUEST });

    return getData<AggregatorResponse>(`/aggregators/resume/${hdToken}/${supplierId}`).then(
      (newData) => {
        const data = newData.data;

        dispatchStoreActions(dispatch, data);

        return data;
      },
      (err) => {
        if (err.error === 'Invalid HD application') {
          dispatch({
            type: AggregatorActionType.LOAD,
            payload: {
              data: {
                redirectUrl: getPath(DIRECT_COMPONENTS.quotation, {}),
              },
            },
          });
        } else {
          dispatch({
            type: AggregatorActionType.FAILED,
            payload: { error: err },
          });
        }
      }
    );
  }, [dispatch, hdToken, supplierId]);
}
