import { jsx, Box, Container } from 'compass-design';
import React from 'react';
import AccessibilityBanner from '../../design-components/AccessibilityBanner';
import { BeSavviHeaderLogo } from './Logo';

/*
 Paddings, sizes, and colours are all unique to this part of the page.
 In particular, logos have precise size and exclusion zone requirements
   from the corporate style guide.
 */

export const Header: React.FC = () => {
  return (
    <>
      <Box variant="layout.header.box">
        <Container variant="layout.header">
          <BeSavviHeaderLogo />
        </Container>
      </Box>
      <AccessibilityBanner beSavvi={true} uiType="center" />
    </>
  );
};
