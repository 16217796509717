import { useCallback } from 'react';
import { useStartSessionTimer } from '../../store/session/action';
import { getData } from '../../utils/serviceCall';
import { getToken } from '../../utils/tokens';
import { REFRESH_SESSION_API } from './types';

export function useRefreshSession() {
  const refreshTimeout = useStartSessionTimer();

  return useCallback(() => {
    const token = getToken();

    if (token) {
      getData(REFRESH_SESSION_API);
    } else {
      refreshTimeout();
    }
  }, [refreshTimeout]);
}
