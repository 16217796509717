import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootState';
import { RemoteState } from '../store/types';

/**
 * Calls the function to fetch data when a component is mounted - even if the data is already in redux.
 * @param selector Selector function for extracting data from Redux (same as used by `useSelector`)
 * @param fetchRemoteData Function to call to fetch the required data. Assumed to be a remote fetch redux action
 */
export function useFetchOnMount<T extends RemoteState>(
  selector: (s: RootState) => T,
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchRemoteData: Function,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
) {
  const { loading, data, error } = useSelector(selector);
  const mounted = useRef(false);

  useEffect(() => {
    fetchRemoteData(!mounted.current, payload);
    mounted.current = true;
  }, [fetchRemoteData, payload]);

  const returnValue = useMemo(
    () => ({
      loading: loading || !mounted.current,
      data: mounted.current ? data : undefined,
      error: mounted.current ? error : undefined,
    }),
    [data, error, loading]
  );

  return returnValue;
}
