import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../utils/serviceCall';
import { AgreementDownloadActionType, AgreementDownloadRetriesActionType } from './types';

export function useFetchAgreementDownloadData() {
  const dispatch = useDispatch();
  const MAX_RETRIES = 2;

  const doFetch = useCallback(() => {
    let retries = 0;

    // There are two places where failure might happen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rejectWithError = (error: any) => {
      dispatch({
        type: AgreementDownloadActionType.FAILED,
        payload: { error },
      });

      // reject(error);
    };

    // the repeatable attempt to fetch the data
    const retry = () => {
      dispatch({
        type: AgreementDownloadRetriesActionType.SET,
        payload: retries,
      });

      return fetchData('/get-document-url').then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (newData: any) => {
          const data = newData.data;

          if (data.downloadUrl) {
            // good response, with the desired data, so store and resolve
            dispatch({
              type: AgreementDownloadActionType.LOAD,
              payload: {
                data,
              },
            });

            // resolve(data);
          } else {
            // good response, but missing the desired data, so...
            if (retries < MAX_RETRIES) {
              // ...retry if appropriate...
              retries++;
              retry();
            } else {
              // ...or error if not
              rejectWithError({ message: 'Too many retries' });
            }
          }
        },
        (error) => {
          // bad response
          rejectWithError(error);
        }
      );
    };

    // notify that the process is starting
    dispatch({
      type: AgreementDownloadActionType.REQUEST,
    });

    // then actually start it
    retry();

    return Promise.resolve();
  }, [dispatch]);

  return doFetch;
}

export function useClearAgreementDownloadData() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: AgreementDownloadActionType.CLEAR }), [dispatch]);
}
