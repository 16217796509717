import React, { useState } from 'react';
import { jsx } from 'compass-design';
import { AddressDetails } from '@plending/interfaces/application';
import { FieldPropSet } from '../../../validation/types';
import { AddressEntry } from './AddressEntry';
import { AddressSearch } from './AddressSearch';
import { AddressSelect } from './AddressSelect';
import { AddressConfirmed } from './AddressConfirmed';

type AddressInputProps = {
  name: string;
  value: AddressDetails;
  touched?: boolean;
  error?: string;
  setFieldValue: FieldPropSet['setFieldValue'];
  setFieldError: FieldPropSet['setFieldError'];
  setFieldTouched: FieldPropSet['setFieldTouched'];
  validateField: FieldPropSet['validateField'];
};

const MAIN_ADDRESS_ERROR = 'Please find or enter, and then confirm an address';

export const ADDRESS_ERROR_MESSAGES = {
  'Required': MAIN_ADDRESS_ERROR,
  'Postcode required': MAIN_ADDRESS_ERROR,
  'House required': MAIN_ADDRESS_ERROR,
  'Town required': MAIN_ADDRESS_ERROR,
};

export const AddressInput: React.FC<AddressInputProps> = ({
  name,
  value,
  touched,
  error,
  setFieldValue,
  setFieldTouched,
}) => {
  const [addressList, setAddressList] = useState<AddressDetails[]>([]);
  const [searchPostcode, setSearchPostcode] = useState((value && value.postcode) || '');

  const [editMode, setEditMode] = useState(false);

  let Component;

  if (editMode) {
    Component = AddressEntry;
  } else {
    if (value && Object.keys(value).length > 1) {
      Component = AddressConfirmed;
    } else if (addressList && addressList.length) {
      Component = AddressSelect;
    } else {
      Component = AddressSearch;
    }
  }

  let mainError;

  if (error && touched) {
    mainError = error;
  }

  return (
    <div>
      <Component
        {...{
          name,
          value,
          mainError,
          setFieldValue,
          setFieldTouched,
          searchPostcode,
          setSearchPostcode,
          addressList,
          setAddressList,
          editMode,
          setEditMode,
        }}
      />
    </div>
  );
};
