import { useSelector } from 'react-redux';
import { useFetchOnMount } from '../../utils/useFetchOnMount';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { useFetchSelfServiceData } from './actions';
import { SelfServiceState } from './types';

export const selectSelfServiceData = (s: RootState) => s.selfServiceData;

export function useSelfServiceDataSelector(): SelfServiceState {
  return useSelector(selectSelfServiceData);
}

export function useCallbackSelfServiceDataSelector(): () => SelfServiceState {
  return useCallbackSelector(selectSelfServiceData);
}

export function useGetSelfServiceData(token: string): SelfServiceState {
  const fetchSelfServiceData = useFetchSelfServiceData(token);

  return useFetchOnMount(selectSelfServiceData, fetchSelfServiceData);
}
