import { useSelector } from 'react-redux';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { AgreementDownloadRemoteState, AgreementDownloadRetriesState } from './types';

// The Data
const selectAgreementDownloadData = (a: RootState) => a.agreementDownload.remote;

export function useAgreementDownloadDataSelector(): AgreementDownloadRemoteState {
  return useSelector(selectAgreementDownloadData);
}

export function useCallbackAgreementDownloadDataSelector(): () => AgreementDownloadRemoteState {
  return useCallbackSelector(selectAgreementDownloadData);
}

// Retries
const selectAgreementDownloadRetries = (a: RootState) => a.agreementDownload.retries;

export function useAgreementDownloadRetriesSelector(): AgreementDownloadRetriesState {
  return useSelector(selectAgreementDownloadRetries);
}
