import { useSelector } from 'react-redux';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { BrokerFlags } from './actions';
import { BrokerState } from './types';

export const selectBrokerData = (s: RootState) => s.broker;

export function useBrokerDataSelector(): BrokerState {
  return useSelector(selectBrokerData);
}

export function useCallbackBrokerDataSelector(): () => BrokerState {
  return useCallbackSelector(selectBrokerData);
}

const selectBrokerFlags = (s: RootState) => ({
  ...s.brokerFlags,
});

export function useBrokerFlagsSelector(): BrokerFlags {
  return useSelector(selectBrokerFlags);
}
