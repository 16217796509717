import { ExistingAgreementDetails } from '@plending/interfaces/application';
import { ExistingAgreementDetailsAction, ExistingAgreementDetailsActionType } from './types';

export function reducer(
  state = {} as ExistingAgreementDetails,
  { type, payload }: ExistingAgreementDetailsAction
): ExistingAgreementDetails {
  switch (type) {
    case ExistingAgreementDetailsActionType.STORE: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}
