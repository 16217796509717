import { PersonalDetails } from '@plending/interfaces/application';
import { PersonalDetailsActionType, PersonalDetailsAction } from './types';

export function reducer(state = {} as PersonalDetails, { type, payload }: PersonalDetailsAction): PersonalDetails {
  switch (type) {
    case PersonalDetailsActionType.STORE: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}
