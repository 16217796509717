import { deleteToken, getToken, setToken } from './tokens';

export const SERVICE_BASE = process.env.REACT_APP_STAGE === 'local' ? process.env.REACT_APP_SERVICES_URL : '';
export type ResponseOptions = {
  type?: 'json';
};

const makeUrl = (path: string) => `${SERVICE_BASE}/api${path}`;

function getHeadersMap(headers: Headers): Record<string, unknown> {
  return Array.from(headers.keys()).reduce((acc, key) => ({ ...acc, [key]: headers.get(key) }), {});
}

function saveToken(response: Response) {
  if (response.headers) {
    const authToken = response.headers.get('x-auth-token');

    if (authToken) {
      setToken(authToken);
    }
  }
}

export async function fetchData<T>(path: string, requestOptions?: Request) {
  const newOptions: Partial<Request> = {
    ...requestOptions,
    headers: new Headers({
      ...getHeadersMap((requestOptions && requestOptions.headers) || new Headers()),
      authorization: `Bearer ${getToken()}`,
    }),
  };

  return new Promise<T>((resolve, reject) => {
    fetch(makeUrl(path), newOptions).then((response) => {
      const contentType = `${response.headers.get('content-type')}`;

      if (response.ok) {
        saveToken(response);

        if (contentType.includes('/json')) {
          return response.json().then(resolve, reject);
        } else if (contentType.includes('/pdf')) {
          return response.blob().then((value) => resolve(value as unknown as T), reject);
        } else if (contentType.includes('text')) {
          return response.text().then((value) => resolve(value as unknown as T), reject);
        }
      }

      if (contentType.includes('/json')) {
        return response
          .json()
          .then(reject)
          .catch(() => reject(response));
      }

      if (response.status === 401) {
        return response.json().then((value) => {
          if (value?.error === 'Invalid JWT') {
            deleteToken();
            window.location.replace('/error/401');
          } else {
            reject(value);
          }
        }, reject);
      }

      reject(response);
    }, reject);
  });
}

/**
 * Wrap a call to `fetch` simple GET requests and the resulting response in to
 * a unified Promise.
 * @param path The path of the GET call, excluding the host and and the `/app` prefix
 * @returns a promise that resolves with the fetched data, and rejects with any error
 */
export function getData<T>(path: string) {
  return fetchData<T>(path);
}

/**
 * Wrap a call to `fetch` POST requests and the resulting response in to
 * a unified Promise.
 * @param path The path of the GET call, excluding the host and and the `/app` prefix
 * @returns a promise that resolves with the fetched data, and rejects with any error
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postData<T>(path: string, body: any) {
  return fetchData<T>(path, {
    method: 'POST',
    body: JSON.stringify(body) as unknown,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  } as Request);
}
