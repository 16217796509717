import { ExplanationAction, ExplanationState, ExplanationActionType } from './types';

export function reducer(state = {}, { type, payload }: ExplanationAction): Partial<ExplanationState> {
  switch (type) {
    case ExplanationActionType.STORE: {
      return payload as ExplanationState;
    }
    default:
      return state;
  }
}
