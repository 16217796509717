import React, { useCallback, useMemo, useState, useEffect, Fragment } from 'react';
import { AddressDetails } from '@plending/interfaces/application';
import { Required, IsPostcode } from '@plending/validation/rules';
import {
  jsx,
  InputWrapper,
  Flex,
  TextField,
  Tone,
  TextButton,
  Box,
  TertiaryLink,
  TertiaryLinkDirection,
} from 'compass-design';
import { postcodeTransform } from '../../../validation/utils';
import { useValidation } from '../../../validation/useValidation';
import { getData } from '../../../utils/serviceCall';
import { FindButton } from '../../../design-components/button';
import { useTone } from '../hooks';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { AddressComponentProps } from './types';

const NOT_FOUND_ERROR_MSG = 'No addresses found for that postcode';

export const AddressSearch: React.FC<AddressComponentProps> = ({
  name,
  mainError,
  searchPostcode,
  setSearchPostcode,
  setAddressList,
  setFieldValue,
  setEditMode,
}) => {
  const isBesavvi = useIsBeSavvi();

  const [addressListLoading, setAddressListLoading] = useState(false);
  const fieldName = `${name}-postcodeSearch`;

  const postcodeFieldSpec = useMemo(
    () => ({
      name: fieldName,
      rules: [Required, IsPostcode],
      initialValue: searchPostcode,
      transform: postcodeTransform,
    }),
    [fieldName, searchPostcode]
  );

  // clear the main value on mount
  useEffect(() => {
    setFieldValue(name, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, validateField, setFieldTouched, setFieldError, fieldProps } = useValidation([postcodeFieldSpec]);

  const setManualEntry = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const findAddresses = useCallback(async () => {
    const postcode = values[fieldName];
    const postcodeError = await validateField(fieldName);
    setFieldTouched(fieldName);

    if (!postcodeError) {
      setSearchPostcode(postcode);

      setAddressListLoading(true);
      let addresses: AddressDetails[];

      try {
        ({ data: addresses } = await getData<{ data: AddressDetails[] }>(`/address/${postcode}`));
      } catch (e) {
        addresses = [];
      }

      setAddressListLoading(false);

      if (addresses && addresses.length) {
        setAddressList(addresses);

        if (addresses.length === 1) {
          setFieldValue(name, addresses[0]);
        }
      } else {
        setFieldError(fieldName, NOT_FOUND_ERROR_MSG);
      }
    }
  }, [
    fieldName,
    name,
    setAddressList,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setSearchPostcode,
    validateField,
    values,
  ]);

  const showManualEntryButton = fieldProps[fieldName].error === NOT_FOUND_ERROR_MSG;
  const { touched, error } = fieldProps[fieldName];
  const displayError = error || mainError;
  const tone: Tone = useTone({ touched, error }, mainError);

  const Component = (
    <Fragment>
      <TextField
        flexSx={{ marginRight: '4', width: isBesavvi ? 'columns.2' : ['100%', null, null, '306px'] }}
        tone={tone}
        {...fieldProps[fieldName]}
      />
      <FindButton
        data-test-id={`lookup-postcode-button-${name}`}
        onClick={findAddresses}
        busy={addressListLoading}
        sx={{ mt: !isBesavvi ? 4 : 0 }}
      >
        Find
      </FindButton>
    </Fragment>
  );

  const manualAddress = isBesavvi ? (
    <TextButton data-test-id={`manual-entry-button-${name}`} mt={1} onClick={setManualEntry}>
      Enter address manually
    </TextButton>
  ) : (
    <TertiaryLink
      dataTestId={`manual-entry-button-${name}`}
      direction={TertiaryLinkDirection.FORWARDS}
      onClick={setManualEntry}
      text="Enter address manually"
    />
  );

  return (
    <Box mb={5} data-test-id={fieldName}>
      <InputWrapper
        id={fieldName}
        label="Postcode"
        description="UK and BFPO addresses only"
        tone={tone}
        error={displayError}
      >
        {isBesavvi ? <Flex>{Component}</Flex> : <Box>{Component}</Box>}
        {showManualEntryButton && manualAddress}
      </InputWrapper>
    </Box>
  );
};
