import { jsx, Card } from 'compass-design';
import React from 'react';
import { ContentHeading } from './heading';

export type FormCardProps = {
  title: string;
};

export const FormCard: React.FC<FormCardProps> = ({ children, title, ...props }) => {
  return (
    <Card variant="secondary" my={6} padding={4} {...props}>
      <ContentHeading variant="tertiary">{title}</ContentHeading>
      {children}
    </Card>
  );
};
