import { Reducer } from 'redux';
import { RemoteAction } from '../types';
import { DecisionActionType, DecisionState } from './types';

export const reducer: Reducer = (
  state = {
    loading: false,
  },
  { type, payload: { data, error } = {} }: RemoteAction
): DecisionState => {
  switch (type) {
    case DecisionActionType.CLEAR: {
      return {
        ...state,
        loading: false,
        data: undefined,
        error: undefined,
        pendingLegacy: false,
      };
    }
    case DecisionActionType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: undefined,
        pendingLegacy: false,
      };
    }
    case DecisionActionType.LOAD: {
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
        pendingLegacy: false,
      };
    }
    case DecisionActionType.FAILED: {
      return {
        ...state,
        loading: false,
        data,
        error,
        pendingLegacy: false,
      };
    }
    case DecisionActionType.LEGACY: {
      return {
        ...state,
        loading: false,
        data: undefined,
        error: undefined,
        pendingLegacy: true,
      };
    }
    case DecisionActionType.BUSINESS_ERROR: {
      return {
        ...state,
        loading: false,
        data: undefined,
        error: 'BUSINESS_ERROR',
        pendingLegacy: false,
      };
    }
    default:
      return state;
  }
};
