import { combineReducers } from 'redux';
import { remoteReducer } from '../utils/remoteReducer';
import {
  AgreementDownloadRemoteState,
  AgreementDownloadActionType,
  AgreementDownloadRetriesState,
  AgreementDownloadRetriesActionType,
  AgreementDownloadRetriesAction,
} from './types';

const remote = remoteReducer<AgreementDownloadRemoteState>(AgreementDownloadActionType);

function retries(state = 0, { type, payload }: AgreementDownloadRetriesAction): Partial<AgreementDownloadRetriesState> {
  switch (type) {
    case AgreementDownloadRetriesActionType.SET: {
      return payload as AgreementDownloadRetriesState;
    }
    case AgreementDownloadRetriesActionType.CLEAR: {
      return 0;
    }
    default:
      return state;
  }
}

export const reducer = combineReducers({
  remote,
  retries,
});
