import { PcciAction, PcciState, PcciActionType } from './types';

export function reducer(state = {}, { type, payload }: PcciAction): Partial<PcciState> {
  switch (type) {
    case PcciActionType.STORE: {
      return payload as PcciState;
    }
    default:
      return state;
  }
}
