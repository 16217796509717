import { jsx, Box, Message, NovunaDocument, NovunaHeading, Styled, Text, TickOutline } from 'compass-design';
import React from 'react';
import { ContentHeading, PageTitle, PlainHeading, ProgressHeading } from '../../../design-components/heading';
import { LeftBox, LeftRightSplit, RightBox } from '../../../design-components/LeftRightSplit';
import { NovunaContainer } from '../../../design-components/NovunaContainer';
import { useCompleteDataSelector } from '../../../store/complete/selectors';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { DownloadAgreement } from './DownloadAgreement';

type WhatNextProps = {
  number: string;
  title: string;
};
const WhatNext: React.FC<WhatNextProps> = ({ number, title, children }) => (
  <Box mb={4} pl={4} sx={{ position: 'relative' }}>
    <ContentHeading
      variant="tertiary"
      sx={{
        marginBottom: 2,
        fontWeight: 'bold',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          color: 'text',
        }}
      >
        {number}
      </Box>
      {title}
    </ContentHeading>
    <Text>{children}</Text>
  </Box>
);

export const AcceptAgreement: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const {
    data: { applicationId },
  } = useCompleteDataSelector();

  const whatIsNext = [
    {
      id: '1',
      title: 'Receiving your loan',
      text: 'We will pay your loan into your bank account within two working days.',
    },
    { id: '2', title: 'Making your repayments', text: 'We will confirm by email your monthly loan repayment date.' },
    {
      id: '3',
      title: 'Managing your account',
      text: ' We will let you know by email in the next few days how to access your account.',
    },
  ];

  if (isBesavvi) {
    return (
      <div data-test-id="complete-page">
        <PageTitle>Application complete</PageTitle>

        <LeftRightSplit>
          <LeftBox>
            <ProgressHeading underline="full" icon={<TickOutline />}>
              All done
            </ProgressHeading>
            <Styled.p data-test-id="decision-message">
              Your credit agreement is signed and you have completed your application.
            </Styled.p>
            <Text sx={{ fontSize: 2 }}>Application ID: {applicationId}</Text>
          </LeftBox>
          <RightBox>
            <DownloadAgreement />
          </RightBox>
        </LeftRightSplit>

        <PlainHeading>What happens next?</PlainHeading>

        {whatIsNext.map(({ id, title, text }) => (
          <WhatNext number={id} title={title} key={id}>
            {text}
          </WhatNext>
        ))}
      </div>
    );
  }

  return (
    <NovunaContainer data-test-id="complete-page">
      <PageTitle>Application complete</PageTitle>
      <Box mb={4}>
        <Message variant="info" icon={<NovunaDocument />}>
          <Box>
            <NovunaHeading as="h3" mb={3}>
              All done
            </NovunaHeading>
            <Text data-test-id="download-message" mb={3}>
              Your credit agreement is signed and you have completed your application. Please download a copy for your
              records.
            </Text>
            <Text mb={3} sx={{ fontWeight: 'bold' }}>
              {applicationId}
            </Text>
            <DownloadAgreement />
          </Box>
        </Message>
      </Box>
      <NovunaHeading as="h2" mb={3}>
        What happens next?
      </NovunaHeading>
      {whatIsNext.map(({ id, title, text }, i) => (
        <Box key={id} mb={i === whatIsNext.length - 1 ? 0 : 4}>
          <NovunaHeading as="h3" mb={2}>
            {title}
          </NovunaHeading>
          <Text>{text}</Text>
        </Box>
      ))}
    </NovunaContainer>
  );
};
