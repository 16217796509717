import { AgreementResponse } from '@plending/interfaces/agreement';
import { useFetchPost } from '../utils/fetch';
import { useCallbackAgreementDataSelector } from './selectors';
import { AgreementActionType } from './types';

const extractAgreementData = (d: AgreementResponse) => d.data;

export function useFetchAgreementData() {
  return useFetchPost('/signing', useCallbackAgreementDataSelector(), AgreementActionType, extractAgreementData);
}
