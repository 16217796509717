import { useDispatch, useSelector } from 'react-redux';
import { UiConfig, Product, BootstrapSupplier, ApplicationFlowType } from '@plending/interfaces/bootstrap-data';
import { useCallback } from 'react';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { useFetchBootstrapData } from '../../store/bootstrap/actions';
import { useFetchOnMount } from '../../utils/useFetchOnMount';
import { BootstrapActionType, BootstrapState } from './types';

export const selectBootstrapData = (s: RootState) => s.bootstrapData;

export function useBootstrapDataSelector(): BootstrapState {
  return useSelector(selectBootstrapData);
}

export function useAlternativeLoanProvider(): boolean {
  const { data: { supplier: { alternativeLoanProviderEnabled = false } = {} as BootstrapSupplier } = {} } =
    useBootstrapDataSelector();

  return alternativeLoanProviderEnabled;
}

export function usePersonalisedQuoteProvider(): boolean {
  const { data: { supplier: { isPersonalisedQuoteEnabled = false } = {} as BootstrapSupplier } = {} } =
    useBootstrapDataSelector();

  return isPersonalisedQuoteEnabled;
}

export function useIsTopUpLoanSelector(): boolean {
  const { data: { config = {} as UiConfig } = {} } = useBootstrapDataSelector();
  return config?.applicationFlowType === ApplicationFlowType.TOP_UP;
}

export function useUiConfig() {
  const { data: { config = {} as UiConfig } = {} } = useBootstrapDataSelector();

  return config;
}

export function useProductConfig() {
  const { data: { product = {} as Product } = {} } = useBootstrapDataSelector();

  return product;
}

export function useSupplierConfig(): Partial<BootstrapSupplier> {
  const { data: { supplier = {} } = {} } = useBootstrapDataSelector();

  return supplier;
}

export function useCallbackBootstrapDataSelector(): () => BootstrapState {
  return useCallbackSelector(selectBootstrapData);
}

export function useGetBootstrapData(supplierId: string): BootstrapState {
  const fetchBootstrapData = useFetchBootstrapData(supplierId);

  return useFetchOnMount(selectBootstrapData, fetchBootstrapData);
}

export function useSetConfig(config: Partial<UiConfig>) {
  const state = useBootstrapDataSelector();
  const dispatch = useDispatch();

  return useCallback(() => {
    return dispatch({
      type: BootstrapActionType.LOAD,
      payload: {
        ...state,
        loading: false,
        data: {
          ...state.data,
          config: {
            ...state.data?.config,
            ...config,
          },
        },
      },
    });
  }, [config, dispatch, state]);
}
