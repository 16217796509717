import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { jsx } from 'compass-design';
import { useFetchAggregatorApplication } from '../../store/aggregator/actions';
import { useAggregatorSelector } from '../../store/aggregator/selectors';
import { AggregatorState } from '../../store/aggregator/types';
import { useGetBootstrapData } from '../../store/bootstrap/selectors';
import { useQueryParams } from '../../utils/useQueryParams';
import { ErrorBlock } from '../Errors/Error';
import { Loading } from '../Loading/Loading';
import { ERROR_COMPONENTS } from '../Routing/Routes';
import { getPath, useNavigation } from '../Routing/useNavigation';

interface AggregatorURLParams {
  hd_token?: string;
  supplierID?: string;
}

export const AggregatorResume: React.FC = () => {
  const { navigateTo } = useNavigation();
  const queryParameters = useQueryParams<AggregatorURLParams>();
  const pathParameters = useParams<AggregatorURLParams>();

  const hdToken = queryParameters.hd_token ? queryParameters.hd_token : pathParameters.hd_token || '';
  const supplierId = queryParameters.supplierID || '';

  if (!hdToken || !supplierId) {
    navigateTo(getPath(ERROR_COMPONENTS.default, { code: 404 }) as string);
  }

  const { replaceWith } = useNavigation();
  const { error: bootstrapError, loading: bootstrapLoading, data: bootstrapData } = useGetBootstrapData(supplierId);

  const aggregatorResume = useFetchAggregatorApplication(hdToken, supplierId);

  useEffect(() => {
    if (!bootstrapError && !bootstrapLoading) {
      aggregatorResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootstrapError, bootstrapLoading]);

  const {
    loading,
    error,
    data: { redirectUrl } = {} as NonNullable<AggregatorState['data']>,
  }: AggregatorState = useAggregatorSelector();

  const showError = useMemo(() => {
    return (!!error && !loading) || (!!bootstrapError && !bootstrapLoading);
  }, [error, loading, bootstrapError, bootstrapLoading]);

  useEffect(() => {
    if (!!redirectUrl && !loading && !bootstrapError && !bootstrapLoading && !!bootstrapData) {
      replaceWith(redirectUrl);
    }
  }, [loading, redirectUrl, bootstrapError, bootstrapLoading, bootstrapData, replaceWith]);

  return (
    <>
      {!showError && <Loading />}
      {showError && (
        <ErrorBlock
          data-test-id="aggregator-error"
          title="There has been an error retrieving your application"
          message="Please go back, refresh your results and choose Novuna again"
        />
      )}
    </>
  );
};
