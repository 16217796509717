import { AtSinceGroup, DurationGroup } from '@plending/interfaces/application';
import * as dateFns from 'date-fns';

export const getDate = (subject = new Date(Date.now())): AtSinceGroup => {
  return {
    year: `${subject.getFullYear()}`,
    month: `${subject.getMonth() + 1}`,
  };
};

const parse = (input: AtSinceGroup) => new Date(parseInt(input.year), parseInt(input.month) - 1);

// Please ensure a `to` date is passed if calculating duration where the `to` date should not be today
export const calculateDurationByMonthAndYear = (from: AtSinceGroup, to: AtSinceGroup = getDate()): DurationGroup => {
  const fromDate = parse(from);
  const toDate = parse(to);

  const months = dateFns.differenceInCalendarMonths(toDate, fromDate);

  return {
    years: Math.floor(months / 12).toString(),
    months: (months % 12).toString(),
  };
};

export const calculateMonthAndYearByDuration = (
  duration: DurationGroup,
  from: AtSinceGroup = getDate()
): AtSinceGroup => {
  const fromDate = parse(from);
  const parsedDuration = { months: parseInt(duration.months), years: parseInt(duration.years) };

  const result = dateFns.sub(fromDate, parsedDuration);
  return {
    year: result.getFullYear().toString(),
    month: (result.getMonth() + 1).toString(),
  };
};

export const transformDate = (date: Date): string => {
  return dateFns.format(date, 'dd-MM-yyyy');
};
