import { RuleFunction } from '@plending/ui/src/validation/types';
import { IsNumber, MaxLength, Required, IsAgreementNumber, IsDate, Is2Decimal, Is1Decimal } from './rules';

export const ExistingAgreementMaxLengthValidation = {
  AGREEMENT_NUMBER: 9,
  MONTHLY_INSTALMENT: 8,
  NEXT_PAYMENT_DATE: 8,
  APR: 5,
  REMAINING_TERM_MONTHS: 2,
  FIFTY_EIGHT_DAYS_SETTLEMENT_FIGURE: 8,
  OUTSTANDING_BALANCE: 8,
  FINAL_MONTHLY_INSTALMENT: 8,
};

export const ExistingAgreementDetailsRules: Record<string, RuleFunction[]> = {
  agreementNumber: [
    Required,
    IsNumber,
    MaxLength(ExistingAgreementMaxLengthValidation.AGREEMENT_NUMBER),
    IsAgreementNumber,
  ],
  monthlyInstalment: [Required, Is2Decimal, MaxLength(ExistingAgreementMaxLengthValidation.MONTHLY_INSTALMENT)],
  paymentDate: [Required, IsDate],
  apr: [Required, IsNumber, Is1Decimal, MaxLength(ExistingAgreementMaxLengthValidation.APR)],
  remainingTerm: [Required, IsNumber, MaxLength(ExistingAgreementMaxLengthValidation.REMAINING_TERM_MONTHS)],
  settlementFigure: [
    Required,
    IsNumber,
    MaxLength(ExistingAgreementMaxLengthValidation.FIFTY_EIGHT_DAYS_SETTLEMENT_FIGURE),
  ],
  outstandingBalance: [Required, Is2Decimal, MaxLength(ExistingAgreementMaxLengthValidation.OUTSTANDING_BALANCE)],
  finalMonthlyInstalment: [
    Required,
    IsNumber,
    MaxLength(ExistingAgreementMaxLengthValidation.FINAL_MONTHLY_INSTALMENT),
  ],
};
