import { useCallback, useState, useEffect, useMemo } from 'react';
import { createBrowserHistory, History } from 'history';
import { Supplier } from '@plending/interfaces/bootstrap-data';
import { DIRECT_COMPONENTS, ERROR_COMPONENTS } from '../Routing/Routes';
import { REDUX_SESSION_STORAGE_KEY } from '../../store/createSafeStore';
import { selectBootstrapData } from '../../store/bootstrap/selectors';
import { getRoute } from './useNavigation';

function storePath(pathname: string): void {
  if (window.sessionStorage) {
    sessionStorage.setItem('current-url', pathname);
  }
}

function getStoredPath(): string | null | undefined {
  if (window.sessionStorage) {
    return sessionStorage.getItem('current-url');
  }
}

function getStoredSupplierId(): string | undefined {
  // need to read it directly from storage, because Redux hasn't been set up when this code runs
  if (window.sessionStorage) {
    try {
      const serializedState = sessionStorage.getItem(REDUX_SESSION_STORAGE_KEY);

      if (serializedState !== null) {
        const { data: { supplier: { id: supplierId } = {} as Supplier } = {} } = selectBootstrapData(
          JSON.parse(serializedState)
        );

        return supplierId;
      }
    } catch (error: any) {}
  }
}

type WithPathname = { pathname: string };

function getMilestoneIndex({ pathname }: WithPathname) {
  const { milestoneIndex = -1 } = getRoute(pathname) || {};

  return milestoneIndex;
}

function isBackOverAMilestone(action: string, toLocation: WithPathname, fromLocation: WithPathname): boolean {
  const toMilestoneIndex = getMilestoneIndex(toLocation);
  const fromMilestoneIndex = getMilestoneIndex(fromLocation);

  // not interested in push or replace
  const isPop = action === 'POP';

  // not interested in errors etc
  const bothAreInJourney = toMilestoneIndex !== -1 && fromMilestoneIndex !== -1;

  // the milestone index is computed in Routes. A reducing milestoneIndex means the unwanted navigation
  const backOverAMilestone = toMilestoneIndex < fromMilestoneIndex;

  return isPop && bothAreInJourney && backOverAMilestone;
}

export function useSafeHistory(existingHistory?: History): [History, string | undefined, () => void] {
  // used by the navigation blocking API
  const [blockMessage, setBlockMessage] = useState<string>();

  const clearBlock = useCallback(() => {
    setBlockMessage(undefined);
  }, []);

  // create the history object (memoized so it's only created once)
  const history = useMemo(
    () =>
      existingHistory ||
      createBrowserHistory({
        getUserConfirmation: (message, callback) => {
          setBlockMessage(message);
          callback(false);
        },
      }),
    [existingHistory]
  );

  // store the path on every change, so that we can detect "refresh"
  useEffect(
    () =>
      history.listen(({ pathname }) => {
        storePath(pathname);
      }),
    [history]
  );

  // check the point of entry is valid
  useEffect(() => {
    const entryPath = history.location.pathname;
    const storedPath = getStoredPath();
    storePath(entryPath);

    if (entryPath !== storedPath) {
      // exclude refreshes
      const route = getRoute(entryPath) || {};

      if (route) {
        // only check the entry point of defined routes - the 404 catches the rest
        const { entryPoint = false } = route;
        if (!entryPoint) {
          // allow the quotation page as long as there's a supplier ID is storage
          if (entryPath !== DIRECT_COMPONENTS.quotation.path || !getStoredSupplierId()) {
            history.replace(ERROR_COMPONENTS.invalidEntryPoint.path);
          }
        }
      }
    }
  }, [history]);

  // Check pop actions (eg back button) don't reverse over a milestone
  useEffect(
    () =>
      history.block((location, action) => {
        if (isBackOverAMilestone(action, location, history.location)) {
          // This is need to work around a bug in `history`. If the user refreshes before
          // using the back button, the rendered content doesn't go back, but the URL does
          // so we detect that state, and fix it. But we need to wait for a render cycle,
          // hence the use of `requestAnimationFrame`.
          requestAnimationFrame(() => {
            const storedPath = getStoredPath();
            const windowPath = window.location.pathname;

            if (windowPath !== storedPath) {
              history.push(`${storedPath}`);
            }
          });

          // The return value is used by `history` to indicate a blocked transition
          return 'Unable to go back';
        }
      }),
    [history]
  );

  return [history, blockMessage, clearBlock];
}
