import { Quote } from '@plending/interfaces/quote';
import { RemoteState } from '../types';

export enum QuotationActionType {
  CLEAR = 'quotation/CLEAR',
  STORE = 'quotation/STORE',
}

export interface QuotationAction {
  type: QuotationActionType;
  payload?: QuotationDetails;
}

export interface QuotationDetails extends Quote {
  aprString: string;
}

export interface QuotationState extends RemoteState {
  loading: boolean;
  data: QuotationDetails;
}
