import { Reducer } from 'redux';
import { RemoteState, RemoteActionType, RemoteAction } from '../types';

export function remoteReducer<TState extends RemoteState>(ActionType: RemoteActionType): Reducer {
  return (
    state = {
      loading: false,
    },
    { type, payload: { data, error } = {} }: RemoteAction
  ): TState => {
    switch (type) {
      case ActionType.CLEAR: {
        return {
          ...state,
          loading: false,
          data: undefined,
          error: undefined,
        };
      }
      case ActionType.REQUEST: {
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      }
      case ActionType.LOAD: {
        return {
          ...state,
          loading: false,
          data,
          error: undefined,
        };
      }
      case ActionType.FAILED: {
        return {
          ...state,
          loading: false,
          data,
          error,
        };
      }
      default:
        return state;
    }
  };
}
