import { lazy } from 'react';
import { AdequateExplanation } from '../AdequateExplanation/AdequateExplanation';
import { AggregatorResume } from '../Aggregator/AggregatorResume';
import { AgreementRequest } from '../Agreement/Request';
import { ShowAgreement } from '../Agreement/Show';
import { Cancelled } from '../Cancel/Cancelled';
import { CompleteLanding } from '../CompleteLanding/CompleteLanding';
import { Expired } from '../Decision/Expired';
import { QuoteAcceptResumeRoute } from '../Decision/QuoteAcceptResumeRoute';
import { DecisionRequest } from '../Decision/Request';
import { PersonalisedQuote } from '../Decision/results/PersonalisedQuote/PersonalisedQuote';
import { ShowDecision } from '../Decision/Show';
import { ErrorPage } from '../Errors/Error';
import { ExpiredSession } from '../Errors/ExpiredSession';
import { InvalidEntryPoint } from '../Errors/InvalidEntryPoint';
import { OfferExpired } from '../Errors/OfferExpired';
import { FinancialDetails } from '../FinancialDetail/FinancialDetails';
import { Landing } from '../Landing/Landing';
import { PersonalDetails } from '../PersonalDetail/PersonalDetails';
import { PreContractCreditInfo } from '../PreContractCreditInfo/PreContractCreditInfo';
import { Quotation } from '../Quotation/Quotation';
import { uiMocking } from '../../utils/mocking';
import { TestUtils } from '../TestUtils/TestUtils';

export type RouteSpec = {
  path: string;
  Component: React.FC;
  componentProps?: Record<string, string>;
  clearStorage?: boolean;
  milestone?: boolean;
  milestoneIndex?: number; // gets derived
  entryPoint?: boolean;
  name?: string;
  personalisedQuote?: boolean;
};
export type RouteMap = Record<string, RouteSpec>;

export const DIRECT_COMPONENTS: RouteMap = {
  landing: {
    path: '/supplier/:supplierId?',
    Component: Landing,
    clearStorage: true,
    entryPoint: true,
  },
  quotation: {
    name: 'Quote',
    path: '/quotation',
    Component: Quotation,
  },
  personalDetails: {
    name: 'Personal details',
    path: '/personal-details',
    Component: PersonalDetails,
  },
  financialDetails: {
    name: 'Financial details',
    path: '/financial-details',
    Component: FinancialDetails,
  },
  decisionRequest: {
    path: '/decision',
    Component: DecisionRequest,
  },
  showDecision: {
    name: 'Decision',
    path: '/show',
    Component: ShowDecision,
    milestone: true,
  },
  adequateExplanation: {
    name: 'About the loan',
    path: '/adequate-explanation',
    Component: AdequateExplanation,
  },
  preContractCreditInfo: {
    name: 'PCCI',
    path: '/pre-contract-credit-information',
    Component: PreContractCreditInfo,
  },
  agreementRequest: {
    path: '/agreement',
    Component: AgreementRequest,
  },
  completeLanding: {
    name: 'Completed',
    // path is externally dictated
    path: '/apply/complete/:applicationId',
    Component: CompleteLanding,
    entryPoint: true,
  },
  showAgreement: {
    name: 'Show agreement',
    path: '/show-agreement',
    Component: ShowAgreement,
    milestone: true,
  },
};

if (uiMocking.useDevMocks)
  DIRECT_COMPONENTS.testUtils = {
    name: 'Test Utils',
    path: '/',
    Component: TestUtils,
    entryPoint: true,
  };

export const TOP_UP_LOANS_COMPONENTS: RouteMap = {
  landing: {
    path: '/acs/supplier/:supplierId?',
    Component: lazy(() => import('../TopUp/Landing')),
    clearStorage: true,
    entryPoint: true,
  },
  loanAgreement: {
    name: 'Agreement Details',
    path: '/acs',
    Component: lazy(() => import('../TopUp/LoanAgreement')),
  },
  quoteResult: {
    name: 'Quote Result',
    path: '/top-up-results',
    Component: lazy(() => import('../TopUp/QuoteResult')),
  },
};

export const PERSONALISED_QUOTE_COMPONENTS: RouteMap = {
  personalisedQuote: {
    name: 'Personalised quote application decision',
    path: '/personalised-quote',
    Component: PersonalisedQuote,
    personalisedQuote: true,
  },
};

export const RESUME_COMPONENTS: RouteMap = {
  authRequest: {
    path: '/application/resume/:applicationId?',
    Component: lazy(() => import('../Auth/AuthRequest')),
    clearStorage: true,
  },
  authHandler: {
    path: '/application/auth',
    Component: lazy(() => import('../Auth/AuthHandler')),
  },
  showQuote: {
    name: 'Show Quote Result',
    path: '/show-quote',
    Component: QuoteAcceptResumeRoute,
  },
};

export const LOAN_ADVISOR_RESUME_COMPONENTS: RouteMap = {
  advisorAuthHandler: {
    path: '/application/loanadvisor/resume',
    Component: lazy(() => import('../Auth/LoanAdvisorAuthHandler')),
    clearStorage: true,
  },
};

export const AGGREGATOR_COMPONENTS: RouteMap = {
  aggregatorResumePath: {
    path: '/aggregators/resume/:hd_token',
    Component: AggregatorResume,
    clearStorage: true,
  },
  aggregatorResumeBasicPath: {
    path: '/aggregators/resume',
    Component: AggregatorResume,
    clearStorage: true,
  },
};

export const BROKER_COMPONENTS: RouteMap = {
  brokerLanding: {
    path: '/brokers/landing/:token',
    Component: lazy(() => import('../Broker/BrokerLanding')),
    clearStorage: true,
    entryPoint: true,
  },
  brokerAuth: {
    path: '/brokers/auth',
    Component: lazy(() => import('../Broker/BrokerAuth')),
  },
  brokerQuotation: {
    path: '/brokers/quotation',
    name: 'Loan details',
    Component: lazy(() => import('../Broker/BrokerQuotation')),
  },
  brokerComplete: {
    path: '/brokers/complete',
    name: 'Complete your application',
    Component: lazy(() => import('../Broker/BrokerComplete')),
  },
  brokerSummary: {
    path: '/brokers/summary',
    name: 'Review application',
    Component: lazy(() => import('../Broker/BrokerSummary')),
  },
};

export const SHARED_COMPONENTS: RouteMap = {
  tokenLandingPage: {
    name: 'Token Landing Page',
    path: '/token-landing-page/:token?',
    Component: lazy(() => import('../Landing/TokenLandingPage')),
    clearStorage: true,
    entryPoint: true,
  },
};

export const ERROR_COMPONENTS: RouteMap = {
  default: {
    path: '/error/:code',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'default',
    },
    name: 'Error',
  },
  invalidEntryPoint: {
    path: '/invalid-entry',
    Component: InvalidEntryPoint,
  },
  signingCancelled: {
    // path is externally dictated
    path: '/apply/details/:applicationId',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'signing-service',
      errorReason: 'user-cancel',
    },
  },
  signingError: {
    // path is externally dictated
    path: '/application-error',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'signing-service',
      errorReason: 'error',
    },
  },
  signingTimeout: {
    // path is externally dictated
    path: '/timeout',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'signing-service',
      errorReason: 'timeout',
    },
  },
  authenticationError: {
    // path is externally dictated
    path: '/auth-error',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'authentication',
      errorReason: 'error',
    },
  },
  expiredToken: {
    // path is externally dictated
    path: '/expired-token',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'self-service',
      errorReason: 'expired-token',
    },
  },
  invalidToken: {
    // path is externally dictated
    path: '/invalid-token',
    Component: ErrorPage,
    componentProps: {
      errorSource: 'self-service',
      errorReason: 'invalid-token',
    },
  },
  expiredDecision: {
    name: 'Expired',
    path: '/expired-decision/:supplierId?',
    Component: Expired,
  },
  cancelledApplication: {
    name: 'Cancelled',
    path: '/cancelled-application/:applicationId?',
    Component: Cancelled,
  },
  expiredSession: {
    path: '/session-expired',
    Component: ExpiredSession,
  },
  offerExpired: {
    path: '/offer-expired',
    Component: OfferExpired,
  },
};

const listAdditionalRoutes = (routeMap: RouteMap, forceIsEntryPoint = true): RouteSpec[] => {
  const routes = Object.values(routeMap);

  if (forceIsEntryPoint) {
    routes.forEach((r) => (r.entryPoint = true));
  }

  return routes;
};

// the order is important - it-s the navigation order
export const DIRECT_JOURNEY: RouteSpec[] = [
  DIRECT_COMPONENTS.landing,
  DIRECT_COMPONENTS.quotation,
  DIRECT_COMPONENTS.personalDetails,
  DIRECT_COMPONENTS.financialDetails,
  DIRECT_COMPONENTS.decisionRequest,
  DIRECT_COMPONENTS.showDecision,
  DIRECT_COMPONENTS.adequateExplanation,
  DIRECT_COMPONENTS.preContractCreditInfo,
  DIRECT_COMPONENTS.agreementRequest,
  DIRECT_COMPONENTS.completeLanding,
  DIRECT_COMPONENTS.showAgreement,
];

if (uiMocking.useDevMocks) DIRECT_JOURNEY.unshift(DIRECT_COMPONENTS.testUtils);

export const TOP_UP_LOANS_JOURNEY: RouteSpec[] = [
  TOP_UP_LOANS_COMPONENTS.landing,
  TOP_UP_LOANS_COMPONENTS.loanAgreement,
  DIRECT_COMPONENTS.quotation,
  DIRECT_COMPONENTS.personalDetails,
  DIRECT_COMPONENTS.financialDetails,
  DIRECT_COMPONENTS.decisionRequest,
  TOP_UP_LOANS_COMPONENTS.quoteResult,
  DIRECT_COMPONENTS.showDecision,
  DIRECT_COMPONENTS.adequateExplanation,
  DIRECT_COMPONENTS.preContractCreditInfo,
  DIRECT_COMPONENTS.agreementRequest,
  DIRECT_COMPONENTS.completeLanding,
  DIRECT_COMPONENTS.showAgreement,
];

export const BROKER_JOURNEY: RouteSpec[] = [
  BROKER_COMPONENTS.brokerLanding,
  BROKER_COMPONENTS.brokerQuotation,
  BROKER_COMPONENTS.brokerComplete,
  BROKER_COMPONENTS.brokerSummary,
  DIRECT_COMPONENTS.showDecision,
];

export const LOAN_ADVISOR_RESUME_JOURNEY: RouteSpec[] = [
  LOAN_ADVISOR_RESUME_COMPONENTS.advisorAuthHandler,
  LOAN_ADVISOR_RESUME_COMPONENTS.landingPage,
];

// derive the milestoneIndex - starts at 0 and increments on every milestone route
[DIRECT_JOURNEY, TOP_UP_LOANS_JOURNEY, BROKER_JOURNEY].forEach((journey) => {
  let milestoneIndex = 0;
  journey.forEach((route) => {
    if (route.milestone) {
      milestoneIndex += 1;
    }
    route.milestoneIndex = milestoneIndex;
  });
});

//routes aren't actually registered without being present here.
export const ALL_ROUTES = [
  ...DIRECT_JOURNEY,
  ...listAdditionalRoutes(PERSONALISED_QUOTE_COMPONENTS),
  ...listAdditionalRoutes(TOP_UP_LOANS_COMPONENTS),
  ...listAdditionalRoutes(ERROR_COMPONENTS),
  ...listAdditionalRoutes(RESUME_COMPONENTS),
  ...listAdditionalRoutes(AGGREGATOR_COMPONENTS),
  ...listAdditionalRoutes(BROKER_COMPONENTS),
  ...listAdditionalRoutes(SHARED_COMPONENTS),
  ...listAdditionalRoutes(LOAN_ADVISOR_RESUME_COMPONENTS),
];
