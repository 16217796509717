import { RemoteState } from '../types';

export enum AuthActionType {
  CLEAR = 'auth/CLEAR',
  SET_CODE_VERIFIER = 'auth/SET_CODE_VERIFIER',
  REQUEST = 'auth/REQUEST',
  LOAD = 'auth/LOAD',
  FAILED = 'auth/FAILED',
}

export interface AuthAction {
  type: AuthActionType;
  payload: AuthState;
}

export interface AuthEndpoints {
  authEndpoint: string;
  tokenEndpoint: string;
}

export type AuthState = {
  codeVerifier: string;
  endpoints: AuthEndpointState;
};

export interface AuthEndpointState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: AuthEndpoints;
}

export interface AuthEndpointsResponse {
  data: AuthEndpoints;
}
