import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { isoNow } from '../../utils/serverTime';
import { useFetchPut } from '../utils/fetch';
import { ExplanationActionType } from './types';
import { useCallbackExplanationSelector } from './selectors';

const extractExplanation = (res: Record<string, string>) => res.data;

export function useStoreExplanation() {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch({
        type: ExplanationActionType.STORE,
        payload: {
          accepted: isoNow(),
        },
      }),
    [dispatch]
  );
}

export function useSaveExplanation() {
  return useFetchPut('/adex', useCallbackExplanationSelector(), ExplanationActionType, extractExplanation);
}
