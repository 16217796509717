import { Application } from '@plending/interfaces/application';
import { SoftSearchApplication, SoftSearchApplicationResponse } from '@plending/interfaces/soft-search';
import { SoftSearchApplicationErrors } from '@plending/interfaces/enum-errors';
import { Error } from '@plending/interfaces/error';
import { RemoteAction, RemoteState } from '../types';
import { BrokerFlags } from './actions';

export enum BrokerActionType {
  CLEAR = 'broker/CLEAR',
  STORE = 'broker/STORE',
  REQUEST = 'broker/REQUEST',
  LOAD = 'broker/LOAD',
  FAILED = 'broker/FAILED',
  STORE_FLAGS = 'broker/STORE_FLAGS',
}

export interface BrokerAction extends RemoteAction {
  type: BrokerActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface BrokerFlagsAction {
  type: BrokerActionType;
  payload?: BrokerFlags;
}

export interface BrokerError extends Error {
  errorType?: SoftSearchApplicationErrors;
}

export interface BrokerState extends RemoteState {
  loading: boolean;
  error?: BrokerError | Error;
  data?: SoftSearchApplicationResponse;
}

export interface BrokerResponse {
  data: SoftSearchApplication | RedirectUrl;
}

export interface RedirectUrl {
  redirectUrl: string;
}

export interface BrokerSaveResponse {
  data: Application;
}
