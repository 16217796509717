import { RemoteState } from '../types';

export enum CompleteActionType {
  CLEAR = 'complete/CLEAR',
  REQUEST = 'complete/REQUEST',
  LOAD = 'complete/LOAD',
  FAILED = 'complete/FAILED',
}

export interface CompleteState extends RemoteState {
  loading: boolean;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}
