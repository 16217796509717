import { jsx, Heading, HeadingProps, Box, Flex, Text, AlertOutline, Tone } from 'compass-design';
import React, { useMemo } from 'react';

type FormGroupErrorProps = {
  name: string;
  title: string;
  variant?: 'error' | 'warning';
  mt?: number;
  mb?: number;
};

type VariantStyles = {
  iconTone: Tone;
  textSx?: { color: string };
  headingProps?: {
    underline?: HeadingProps['underline'];
    underlineMargin?: string;
  };
};

export const FormGroupError: React.FC<FormGroupErrorProps> = ({
  name,
  title,
  variant = 'error',
  mt = 2,
  mb = 5,
  children,
}) => {
  const { iconTone, textSx, headingProps } = useMemo((): VariantStyles => {
    if (variant === 'error') {
      return {
        iconTone: 'negative',
        textSx: { color: 'tone.negative' },
        headingProps: {
          underlineMargin: '0.2em',
        },
      };
    }

    return {
      iconTone: 'positive',
      headingProps: {
        underline: 'none',
      },
    };
  }, [variant]);

  return (
    <Box mt={mt} mb={mb} data-test-id={name}>
      <Flex sx={{ alignItems: 'center' }}>
        <AlertOutline tone={iconTone} />
        <Heading visualSize={4} ml={3} mt={1} {...headingProps} sx={{ fontWeight: 'bold' }}>
          {title}
        </Heading>
      </Flex>
      <Text mt={3} sx={textSx}>
        {children}
      </Text>
    </Box>
  );
};
