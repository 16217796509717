import { createStore, compose, applyMiddleware, Store, AnyAction } from 'redux';
import { History, createBrowserHistory } from 'history';
import { useMemo } from 'react';
import { routerMiddleware } from 'connected-react-router';

import { getPath, getRoute } from '../components/Routing/useNavigation';
import { ERROR_COMPONENTS } from '../components/Routing/Routes';
import { dataLayerReduxEnhancer } from '../utils/analytics';
import { setTokenChangeHandler } from '../utils/tokens';
import { createRootReducer } from './rootReducer';
import { START_SESSION_ACTION } from './session/action';

export const REDUX_SESSION_STORAGE_KEY = 'redux-state';

export const browserHistory = createBrowserHistory();

function determineToClearStorage(history: History<unknown>) {
  const route = getRoute(history.location.pathname);

  return route && route.clearStorage;
}

export function createSafeStore(history: History<unknown>) {
  let persistedState;

  const middleware = compose(applyMiddleware(routerMiddleware(browserHistory)), dataLayerReduxEnhancer);

  // bail completely if session storage not supported
  if (!window.sessionStorage) {
    return createStore(createRootReducer(browserHistory), middleware);
  }

  const shouldClearStorage = determineToClearStorage(history);

  if (shouldClearStorage) {
    sessionStorage.clear();
  }

  try {
    const serializedState = sessionStorage.getItem(REDUX_SESSION_STORAGE_KEY);

    if (serializedState !== null && !shouldClearStorage) {
      persistedState = JSON.parse(serializedState);
    }
  } catch (error: any) {
    history.push(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string); // can't recover
  }

  const store = createStore(createRootReducer(browserHistory), persistedState, middleware);

  store.subscribe(() => {
    try {
      sessionStorage.setItem(REDUX_SESSION_STORAGE_KEY, JSON.stringify(store.getState()));
    } catch (error: any) {
      // ignore - can't recover, and it doesn't cause any errors at this point
    }
  });

  setTokenChangeHandler((token?: string) => {
    if (token) {
      store.dispatch(START_SESSION_ACTION);
    }
  });

  return store;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSafeStore(history: History<unknown>, store?: Store<any, AnyAction>) {
  return useMemo(() => {
    return store ? store : createSafeStore(history);
  }, [history, store]);
}
