import { jsx, Box, PrintCircle, NovunaHeading, Text, Button, NovunaDocument, Message } from 'compass-design';
import React, { useCallback } from 'react';
import { PanelButton } from './PanelButton';

export const NovunaPrintPanel = () => {
  const printPage = useCallback(() => {
    window.print();
  }, []);

  return (
    <Box mb={5}>
      <Message variant="info" icon={<NovunaDocument />}>
        <Box>
          <NovunaHeading as="h3" mb={2}>
            Your copy
          </NovunaHeading>
          <Text mb={3}>Print a copy of this explanation for your records</Text>
          <Button variant="secondary" onClick={printPage} data-test-id="print-panel">
            Print explanation
          </Button>
        </Box>
      </Message>
    </Box>
  );
};

export const PrintPanel: React.FC = ({ children, ...props }) => {
  const printPage = useCallback(() => {
    window.print();
  }, []);

  return (
    <Box
      mt={4}
      mb={6}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
      {...props}
    >
      <PanelButton
        data-test-id="print-panel"
        title="Your copy"
        label="Print"
        icon={<PrintCircle />}
        onClick={printPage}
      >
        {children}
      </PanelButton>
    </Box>
  );
};
