import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { isoNow } from '../../utils/serverTime';
import { PcciActionType, PcciAction } from './types';

export function useStorePcci() {
  const dispatch = useDispatch();

  return useCallback(
    (payload: PcciAction['payload']) =>
      dispatch({
        type: PcciActionType.STORE,
        payload: {
          ...payload,
          accepted: isoNow(),
        },
      }),
    [dispatch]
  );
}
