import { useCallback, useEffect, useRef } from 'react';
import { useSessionDetailsSelector } from '../../store/session/selectors';
import { HearbeatExit } from './types';
import { useRefreshSession } from './useRefreshSession';

const HEARTBEAT_INTERVAL_MS = 60_000;

export function useHeartbeat(): HearbeatExit {
  const { startedAt } = useSessionDetailsSelector();
  const refreshSession = useRefreshSession();

  const interactionCount = useRef(0);
  const intervalRef = useRef<number>();

  const trackInteraction = useCallback(() => {
    interactionCount.current++;
  }, []);

  useEffect(
    () => {
      interactionCount.current = 0;

      if (startedAt) {
        intervalRef.current = window.setInterval(() => {
          if (interactionCount.current > 0) {
            refreshSession();
          }
        }, HEARTBEAT_INTERVAL_MS);
      }

      return () => {
        window.clearInterval(intervalRef.current);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startedAt] // only when a new session starts
  );

  useEffect(() => {
    document.addEventListener('click', trackInteraction);
    document.addEventListener('keyup', trackInteraction);

    return () => {
      document.removeEventListener('click', trackInteraction);
      document.removeEventListener('keyup', trackInteraction);
    };
  }, [trackInteraction]);

  return useCallback(
    // allow other processes to exit the heartbeat
    () => {
      window.clearInterval(intervalRef.current);

      if (interactionCount.current > 0) {
        refreshSession();

        return { userInactive: false };
      }

      return { userInactive: true };
    },
    [refreshSession]
  );
}
