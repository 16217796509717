import { useIsModalOpen } from 'compass-design';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClearAllPersonalData } from '../../store/global/actions';
import { useCallbackSessionDetailsSelector, useSessionDetailsSelector } from '../../store/session/selectors';
import { deleteToken } from '../../utils/tokens';
import { useNavigation, getRoute } from '../Routing/useNavigation';
import { HearbeatExit } from './types';
import { useRefreshSession } from './useRefreshSession';

function formattedTimeLeft(secondsLeft = 0) {
  const left = Math.max(0, secondsLeft); // avoid unsightly -ve times
  const seconds = left % 60;
  const minutes = Math.floor(left / 60);

  const minutesString = minutes > 0 ? `${minutes} min ` : '';
  const secondsString = `${seconds} sec${seconds === 1 ? '' : 's'}`;

  return `${minutesString}${secondsString}`;
}

export const maxModalDurationMs = (inactivityTimeoutMs: number) => Math.min(180_000, inactivityTimeoutMs / 2);

const COUNTDOWN_INTERVAL_MS = 100;

export function useUserPrompt(exitHeartbeat: HearbeatExit) {
  const [modalIsOpen, toggleModal] = useIsModalOpen();
  const [displayTime, setDisplayTime] = useState<string>();
  const getSessionDetails = useCallbackSessionDetailsSelector();
  const refreshSession = useRefreshSession();
  const { expiresAt, inactivityTimeoutMs } = useSessionDetailsSelector();
  const history = useHistory();
  const { navigateTo, replaceWith } = useNavigation();
  const clearAllPersonalData = useClearAllPersonalData();

  ///////////////////////////////////////////
  // Manage the modal, once open

  // Handler for closing the modal with the side effect of refreshing the session timeout
  const toggleModalIsOpen = useCallback(
    (isOpen) => {
      toggleModal(isOpen);

      if (!isOpen) {
        refreshSession();
      }
    },
    [refreshSession, toggleModal]
  );

  // Click handler for the button in the modal
  const closeModal = useCallback(() => {
    toggleModalIsOpen(false);
  }, [toggleModalIsOpen]);

  const redirectOnExpiry = useCallback(() => {
    const { milestoneIndex } = getRoute(history.location.pathname);
    toggleModal(false);

    clearAllPersonalData();
    deleteToken();

    if (milestoneIndex) {
      replaceWith('/session-expired');
    } else {
      navigateTo('/quotation');
    }
  }, [clearAllPersonalData, history.location.pathname, navigateTo, replaceWith, toggleModal]);

  ///////////////////////////////////////////
  // timer to open the modal
  const timeoutRef = useRef<number>();

  useEffect(
    () => {
      // clear anything existing
      window.clearTimeout(timeoutRef.current);

      if (expiresAt && inactivityTimeoutMs) {
        const openModalInMs = expiresAt - performance.now() - maxModalDurationMs(inactivityTimeoutMs);

        timeoutRef.current = window.setTimeout(() => {
          const { userInactive } = exitHeartbeat();

          if (userInactive) {
            toggleModalIsOpen(true);
          }
        }, openModalInMs);
      }

      // tidy up the effect
      return () => {
        window.clearTimeout(timeoutRef.current);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expiresAt, inactivityTimeoutMs] // only if either of these change (and thus `expiresAt`)
  );

  ///////////////////////////////////////////
  // run the countdown in the modal
  const countdownIntervalRef = useRef<number>();

  useEffect(
    () => {
      // clear anything existing
      window.clearInterval(countdownIntervalRef.current);

      const { secondsLeft: initialSecondsLeft } = getSessionDetails();

      if (modalIsOpen) {
        setDisplayTime(formattedTimeLeft(initialSecondsLeft));

        countdownIntervalRef.current = window.setInterval(() => {
          const { secondsLeft = 0 } = getSessionDetails();

          setDisplayTime(formattedTimeLeft(secondsLeft));
          if (secondsLeft <= 0) {
            redirectOnExpiry();
          }
        }, COUNTDOWN_INTERVAL_MS);
      }

      // tidy up the effect
      return () => {
        window.clearInterval(countdownIntervalRef.current);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalIsOpen]
  );

  ///////////////////////////////////////////
  // tidy up on unmount
  useEffect(
    () => () => {
      window.clearInterval(countdownIntervalRef.current);
      window.clearTimeout(timeoutRef.current);
    },
    []
  );

  ///////////////////////////////////////////
  // Return values for the component to use

  const values = useMemo(
    () => ({
      modalIsOpen,
      toggleModalIsOpen,
      closeModal,
      displayTime,
    }),
    [closeModal, displayTime, modalIsOpen, toggleModalIsOpen]
  );

  return values;
}
