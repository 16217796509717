import { jsx, Message, NovunaHeading, Styled } from 'compass-design';
import React, { Fragment } from 'react';
import { NarrowContent } from '../../design-components/NarrowContent';
import { PageTitle } from '../../design-components/heading';

import { useBrokerDataSelector } from '../../store/broker/selectors';
import { BrokerState } from '../../store/broker/types';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';

export const OfferExpired: React.FC = () => {
  const { error: { error: errorMessage } = { error: '' } }: BrokerState = useBrokerDataSelector();
  const isBesavvi = useIsBeSavvi();

  return (
    <Fragment>
      {isBesavvi ? (
        <NarrowContent>
          <PageTitle>Quote unavailable</PageTitle>
          <Styled.p sx={{ fontSize: 3, fontWeight: 'bold' }}>Sorry the quote has expired.</Styled.p>
          <Styled.p sx={{ fontSize: 2 }}>{errorMessage}</Styled.p>
        </NarrowContent>
      ) : (
        <NovunaContainer>
          <PageTitle>Quote unavailable</PageTitle>
          <Message variant="error">
            <NovunaHeading as="h3">Sorry the quote has expired.</NovunaHeading>
            <p>{errorMessage}</p>
          </Message>
        </NovunaContainer>
      )}
    </Fragment>
  );
};
