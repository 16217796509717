import React, { Fragment as F } from 'react';
import { jsx } from 'compass-design';

// add a zero width space before the "@" to allow long email addresses some opportunity to wrap nicely
export const Wrappable: React.FC<{ children: string }> = ({ children }) => {
  const [username, domain] = children.split('@');

  return (
    <F>
      {username}
      <wbr />@{domain}
    </F>
  );
};
