import React from 'react';
import { Redirect } from 'react-router-dom';
import { DecisionClientResponse } from '@plending/interfaces/decision';
import { Supplier } from '@plending/interfaces/bootstrap-data';
import { useDecisionSelector } from '@plending/ui/src/store/decision/selectors';
import { DecisionState } from '@plending/ui/src/store/decision/types';
import { useQuotationSelector } from '@plending/ui/src/store/quotation/selectors';
import { getPath, useNavigation } from '@plending/ui/src/components/Routing/useNavigation';
import { useSaveFinalOffer } from '@plending/ui/src/store/finalOffer/actions';
import { useCallbackFinalOfferSelector, useFinalOfferSelector } from '@plending/ui/src/store/finalOffer/selectors';
import { useBootstrapDataSelector, useUiConfig } from '@plending/ui/src/store/bootstrap/selectors';
import { ERROR_COMPONENTS } from '@plending/ui/src/components/Routing/Routes';
import { FinalOfferRequestBody } from '@plending/ui/src/store/finalOffer/types';
import { jsx } from 'compass-design';
import { Accept } from './Accept';

export const DecisionAccept: React.FC = () => {
  const { data: { supplier: { isPartner } = {} as Supplier } = {} } = useBootstrapDataSelector();

  const { data: { offer, applicationId, decision: decisionString } = {} as DecisionClientResponse }: DecisionState =
    useDecisionSelector();

  const { monthlyPence } = offer || {};

  const { loading: isFinalOfferLoading } = useFinalOfferSelector();
  const { loanAdvisor } = useUiConfig();
  const { navigateToNextStep, navigateTo } = useNavigation();
  const saveFinalOffer = useSaveFinalOffer();
  const finalOfferSelector = useCallbackFinalOfferSelector();
  const { monthlyPence: offerInstalment } = useQuotationSelector();

  const isAgentJourney = !!loanAdvisor;
  const isProvisionalAccept = decisionString === 'PROVISIONAL_ACCEPT';
  const isNotTypical = offerInstalment !== monthlyPence;

  const confirmFinalOffer = async (finalOffer: FinalOfferRequestBody) => {
    await saveFinalOffer(true, finalOffer);

    const { error: { error } = {} } = finalOfferSelector();
    if (error) {
      navigateTo(getPath(ERROR_COMPONENTS.default, { code: 500 }));
    }

    navigateToNextStep();
  };

  if (!offer) {
    const path = getPath(ERROR_COMPONENTS.default, { code: 500 });
    return <Redirect to={path} />;
  }

  return (
    <Accept
      applicationId={applicationId}
      isAgentJourney={isAgentJourney}
      isProvisionalAccept={isProvisionalAccept}
      offer={offer}
      isPartner={isPartner}
      isNotTypical={isNotTypical}
      confirmFinalOffer={confirmFinalOffer}
      isFinalOfferLoading={isFinalOfferLoading}
    />
  );
};
