import { useMemo, PropsWithChildren } from 'react';

const ID = 'data-test-id';

type TestIdProp = {
  [ID]?: string;
};

export function useTestId({ [ID]: testId }: PropsWithChildren<TestIdProp>, defaultId?: string) {
  const testIdProp = useMemo(() => {
    const id = testId || defaultId;

    if (id) {
      return { [ID]: id };
    }
  }, [defaultId, testId]);

  return testIdProp;
}

export function subTestId(suffix: string, testId?: TestIdProp) {
  if (testId) {
    const { [ID]: baseId } = testId;

    return { [ID]: `${baseId}-${suffix}` };
  }
}
