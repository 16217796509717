import {
  jsx,
  InfoSegment,
  InfoBox,
  Styled,
  TickOutline,
  Modal,
  ReactModal,
  useIsModalOpen,
  Text,
  NovunaHeading,
  Box,
  SummaryRow,
  Message,
  NovunaTick,
} from 'compass-design';
import { FinalOfferRequestBody } from '@plending/ui/src/store/finalOffer/types';
import { DecisionOffer, TailoringConfig } from '@plending/interfaces/quote';
import { formatApr, monthlyPayment, penceToPoundsString } from '@plending/validation/quote-utils';
import React, { FC, Fragment, useState } from 'react';
import { ContinueButton } from '../../../../design-components/button';
import { PageTitle, ProgressHeading, FormHeading, ModalSubHeading } from '../../../../design-components/heading';
import { Step } from '../../../../design-components/step';
import { TailoringCard } from '../../../../design-components/tailoring';
import { useIsBeSavvi } from '../../../../utils/useIsBesavvi';
import { usePersonalisedQuoteProvider } from '../../../../store/bootstrap/selectors';
import { DecisionText, NextSteps } from './DecisionText';

export interface AcceptProps {
  offer: DecisionOffer;
  tailoring?: TailoringConfig;
  isNotTypical: boolean;
  applicationId: string;
  isAgentJourney: boolean;
  isProvisionalAccept: boolean;
  isPartner: boolean;
  isFinalOfferLoading: boolean;
  confirmFinalOffer: (finalOffer: FinalOfferRequestBody) => void;
}

export const Accept: FC<AcceptProps> = ({
  isAgentJourney,
  isProvisionalAccept,
  isPartner,
  isNotTypical,
  applicationId,
  confirmFinalOffer,
  isFinalOfferLoading,
  offer,
}) => {
  const isBesavvi = useIsBeSavvi();
  const isPersonalisedQuoteEnabled = usePersonalisedQuoteProvider();

  const { termMonths, monthlyPence, totalPence, apr, loanPence, tailoring, offerTailoring } = offer;
  const { rpm } = tailoring || {};

  const monthlyPounds = penceToPoundsString(monthlyPence);
  const totalPounds = penceToPoundsString(totalPence);
  const loanPounds = penceToPoundsString(loanPence, false);

  const [modalIsOpen, toggleModal] = useIsModalOpen();
  const [finalOffer, setFinalOffer] = useState<FinalOfferRequestBody>({
    termMonths,
    monthlyPence,
    totalPence,
  });

  const aprString = formatApr(apr);

  const handleTailoringModalContinue = (finalOffer: FinalOfferRequestBody) => {
    toggleModal(false);
    confirmFinalOffer(finalOffer);
  };

  const handleContinue = (finalOffer: FinalOfferRequestBody, termMonths: number) => {
    if (termMonths !== finalOffer.termMonths && !isPersonalisedQuoteEnabled) {
      toggleModal(true);
    } else {
      confirmFinalOffer(finalOffer);
    }
  };

  const calculateTailoredOffer = (newTermMonths: number, loanPence: number, rpm: number) => {
    const monthlyPence = monthlyPayment(loanPence, newTermMonths, rpm);
    setFinalOffer({
      termMonths: newTermMonths,
      monthlyPence,
      totalPence: monthlyPence * newTermMonths,
    });
  };

  const decisionText = (
    <DecisionText isProvisionalAccept={isProvisionalAccept} isPartner={isPartner} isNotTypical={isNotTypical} />
  );

  const loanDetails = [
    { dataTestId: 'loan-amount', label: 'Loan amount', value: loanPounds },
    { dataTestId: 'term-length', label: 'Months', value: termMonths },
    { dataTestId: 'monthly-amount', label: 'Monthly repayment', value: monthlyPounds },
    { dataTestId: 'total-amount', label: 'Total payable', value: totalPounds },
    { dataTestId: 'rep-apr', label: 'APR', value: aprString },
    { dataTestId: 'application-id', label: 'Application ID', value: applicationId },
  ];

  const novunaLoanDetails = [
    { dataTestId: 'application-id', label: 'Application ID', value: applicationId },
    { dataTestId: 'loan-amount', label: 'Loan amount', value: loanPounds },
    { dataTestId: 'term-length', label: 'Term', value: `${termMonths} Months` },
    { dataTestId: 'rep-apr', label: 'APR', value: aprString + ' APR' },
    { dataTestId: 'total-amount', label: 'Total payable', value: totalPounds },
    { dataTestId: 'monthly-amount', label: 'Monthly repayment', value: monthlyPounds },
  ];

  const loanSummary = isBesavvi ? (
    <InfoBox data-test-id="offer-info" sx={{ marginBottom: 6, maxWidth: '520px' }}>
      {loanDetails.map(({ dataTestId, label, value }) => (
        <InfoSegment key={dataTestId} data-test-id={dataTestId} label={label}>
          {value}
        </InfoSegment>
      ))}
    </InfoBox>
  ) : (
    <Box>
      <Box mb={4}>
        {novunaLoanDetails.map(({ dataTestId, label, value }, index) => (
          <SummaryRow
            data-test-id={dataTestId}
            label={label}
            value={value}
            isWhite={index % 2 === 0}
            key={dataTestId}
          />
        ))}
      </Box>
    </Box>
  );
  const content = isBesavvi ? (
    <>
      {' '}
      <Text sx={{ fontSize: 2, maxWidth: 'columns.6' }}>Check you are happy with your new loan details below.</Text>
      <ModalSubHeading sx={{ marginTop: 5 }}>Loan details</ModalSubHeading>
      <InfoBox data-test-id="tailoring-modal-offer-info" sx={{ marginTop: 5, marginBottom: 6, maxWidth: 'columns.6' }}>
        <InfoSegment data-test-id="tailoring-modal-loan-amount" label="Loan amount">
          {loanPounds}
        </InfoSegment>
        <InfoSegment data-test-id="tailoring-modal-term-length" label="Months">
          {finalOffer.termMonths}
        </InfoSegment>
        <InfoSegment data-test-id="tailoring-modal-monthly-amount" label="Monthly repayment">
          {penceToPoundsString(finalOffer.monthlyPence)}
        </InfoSegment>
        <InfoSegment data-test-id="tailoring-modal-total-amount" label="Total payable">
          {penceToPoundsString(finalOffer.totalPence)}
        </InfoSegment>
        <InfoSegment data-test-id="tailoring-modal-rep-apr" label="APR">
          {aprString}
        </InfoSegment>
        <InfoSegment label="Application ID">{applicationId}</InfoSegment>
      </InfoBox>
      <ContinueButton
        busy={isFinalOfferLoading}
        data-test-id="tailoring-modal-confirm-button"
        onClick={() => handleTailoringModalContinue(finalOffer)}
      >
        Confirm
      </ContinueButton>
    </>
  ) : (
    <>
      <Text sx={{ fontSize: 2, maxWidth: 'columns.6' }}>Check you are happy with your new loan details below.</Text>
      <ModalSubHeading sx={{ marginTop: 5 }}>Loan details</ModalSubHeading>
      <br></br>
      <Box mb={4}>
        <SummaryRow label="Application ID" value={applicationId} />
        <SummaryRow data-test-id="tailoring-modal-loan-amount" label="Loan amount" value={loanPounds} isWhite />
        <SummaryRow data-test-id="tailoring-modal-term-length" label="Months" value={String(finalOffer.termMonths)} />
        <SummaryRow data-test-id="tailoring-modal-rep-apr" label="APR" value={aprString} isWhite />
        <SummaryRow
          data-test-id="tailoring-modal-total-amount"
          label="Total payable"
          value={penceToPoundsString(finalOffer.totalPence)}
        />
        <SummaryRow
          data-test-id="tailoring-modal-monthly-amount"
          label="Monthly repayment"
          value={penceToPoundsString(finalOffer.monthlyPence)}
          isWhite
        />
        <br></br>
        <ContinueButton
          busy={isFinalOfferLoading}
          data-test-id="tailoring-modal-confirm-button"
          onClick={() => handleTailoringModalContinue(finalOffer)}
        >
          Confirm
        </ContinueButton>
      </Box>
    </>
  );
  const finalStepCount = isPersonalisedQuoteEnabled ? 6 : 5;

  return (
    <>
      <Step step={finalStepCount} />
      <PageTitle>Application decision</PageTitle>
      {isBesavvi ? (
        <Fragment>
          <ProgressHeading icon={<TickOutline />}>Accepted</ProgressHeading>
          <Styled.p data-test-id="decision-message">{decisionText}</Styled.p>
        </Fragment>
      ) : (
        <Box mb={5}>
          <Message variant="success" icon={<NovunaTick />}>
            <NovunaHeading as="h3">Accepted</NovunaHeading>
            <Text data-test-id="decision-message">{decisionText}</Text>
          </Message>
        </Box>
      )}
      <FormHeading>Loan summary</FormHeading>
      {loanSummary}
      {!isPersonalisedQuoteEnabled && offerTailoring && tailoring && rpm && (
        <>
          <TailoringCard
            data-test-id="tailoring-card"
            maxTerm={tailoring.maxTermMonths}
            minTerm={tailoring.minTermMonths}
            offerDetails={finalOffer}
            apr={offer.apr}
            loanAmount={loanPence}
            onChange={(newTermMonths) => calculateTailoredOffer(newTermMonths, loanPence, rpm)}
          />
        </>
      )}
      {isNotTypical && !isPartner && (
        <Styled.p data-test-id="next-steps-text">
          <NextSteps isProvisionalAccept={isProvisionalAccept} />
        </Styled.p>
      )}
      {!isAgentJourney && (
        <Box mt={isBesavvi ? 0 : 5}>
          <ContinueButton
            busy={isFinalOfferLoading}
            data-test-id="proceed-button"
            onClick={() => handleContinue(finalOffer, termMonths)}
          >
            Continue
          </ContinueButton>
        </Box>
      )}
      {isBesavvi ? (
        <Modal
          size="medium"
          data-test-id="tailoring-confirmation-modal"
          title="Loan confirmation"
          isOpen={modalIsOpen}
          onSetIsOpen={toggleModal}
        >
          {content}
        </Modal>
      ) : (
        <ReactModal
          isOpen={modalIsOpen}
          setIsOpen={toggleModal}
          size="medium"
          title="Loan confirmation"
          data-test-id="tailoring-confirmation-modal"
        >
          {content}
        </ReactModal>
      )}
    </>
  );
};
