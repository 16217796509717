import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { jsx, Box, Flex, NovunaMinus, NovunaPlus, Text } from 'compass-design';
import { darken } from '@theme-ui/color';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { ReactComponent as DecreaseArrowOutline } from './decrease-arrow-outline.svg';
import { ReactComponent as IncreaseArrowOutline } from './increase-arrow-outline.svg';
import { ReactComponent as IndicatorOutline } from './indicator-outline.svg';
import { ReactComponent as Handle } from './handle.svg';

// constrain a number to given limits
function bound(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

// to explicitly specify "px" to avoid Theme UI misinterpreting a number as a scale value
function toPx(n: number) {
  return `${n}px`;
}

const darkenedSecondary = darken('secondary', 0.08);

const BUTTON_RADIUS = 20; // SVG is 40px accross
const BUTTON_MARGIN = 5;
const BUTTON_SIZE = BUTTON_RADIUS * 2;

const HANDLE_RADIUS = BUTTON_RADIUS;
const HANDLE_SIZE = HANDLE_RADIUS * 2;

const INDICATOR_WIDTH = 72; // SVG is 72px wide...
const INDICATOR_HEIGHT = 75; // ...and 65px high, but we want 10px clickable "margin"

const NOVUNA_HANDLE_SIZE = HANDLE_RADIUS * 2;

const NOVUNA_INDICATOR_WIDTH = 100;
const NOVUNA_INDICATOR_HEIGHT = 45;

/**
 * There are two of these, so...
 */
export type MoveButtonProps = {
  incrementBy: 1 | -1;
  limit: number;
  value: number;
  isBesavvi: boolean;
  incrementValue: (d: 1 | -1) => void;
};

const MoveButton: React.FC<MoveButtonProps> = ({
  children,
  incrementBy,
  limit,
  value,
  isBesavvi,
  incrementValue,
  ...props
}) => {
  const onClick = useCallback(() => {
    incrementValue(incrementBy);
  }, [incrementBy, incrementValue]);

  let color = 'secondaryPurple';

  if (isBesavvi) {
    color = value === limit ? 'disabledSecondary' : 'secondary';
  }

  return (
    <Box
      as="button"
      sx={{
        'cursor': 'pointer',
        'padding': 0,
        'border': 'none',
        'background': 'none',
        'outline': 'none',
        'width': toPx(BUTTON_SIZE),
        'height': toPx(BUTTON_SIZE),
        color,
        '&:focus': {
          color: isBesavvi ? darkenedSecondary : 'secondaryPurple',
        },
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Box>
  );
};

interface SliderPathProps {
  isBesavvi: boolean;
  name: string;
  sliderX: number;
}

const SliderPath = ({ name, isBesavvi, sliderX }: SliderPathProps) => (
  <Fragment>
    <Box
      data-test-id={`${name}-empty-track`}
      sx={{
        height: '3px',
        borderRadius: '2px',
        position: 'absolute',
        top: '50%',
        backgroundColor: isBesavvi ? 'monochrome.4' : 'monochrome.5',
        left: toPx(sliderX),
        right: '0px',
        transform: 'translateY(-50%)',
      }}
    />
    <Box
      data-test-id={`${name}-filled track`}
      sx={{
        height: '3px',
        borderRadius: '2px',
        position: 'absolute',
        top: '50%',
        backgroundColor: isBesavvi ? 'disabledSecondary' : 'primary',
        left: '0px',
        width: toPx(sliderX),
        transform: 'translateY(-50%)',
      }}
    />
  </Fragment>
);

interface LabelProps {
  value: number;
  label: string;
  name: string;
  current?: boolean;
  isBesavvi?: boolean;
}

const StandardLabel = ({ value, label, name, current }: LabelProps) => (
  <Box
    data-test-id={`${name}-indicator`}
    sx={{
      width: toPx(INDICATOR_WIDTH),
      height: toPx(INDICATOR_HEIGHT),
      position: 'absolute',
      top: toPx(-INDICATOR_HEIGHT),
      left: toPx((HANDLE_SIZE - INDICATOR_WIDTH) / 2),
      userSelect: 'none',
      cursor: 'default',
    }}
  >
    <IndicatorOutline
      sx={{
        color: current ? darkenedSecondary : 'secondary',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: '1',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          fontSize: 3,
        }}
      >
        {value}
      </Box>
      <Box
        sx={{
          fontSize: 0,
        }}
      >
        {label}
      </Box>
    </Box>
  </Box>
);

const NovunaLabel = ({ value, label, name, isBesavvi }: LabelProps) => (
  <Box
    data-test-id={`${name}-indicator`}
    sx={{
      display: 'flex',
      width: toPx(NOVUNA_INDICATOR_WIDTH),
      height: toPx(NOVUNA_INDICATOR_HEIGHT),
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: toPx(-(NOVUNA_INDICATOR_HEIGHT + 4)),
      left: toPx((NOVUNA_HANDLE_SIZE - NOVUNA_INDICATOR_WIDTH) / 2),
      userSelect: 'none',
      cursor: 'default',
      border: '2px solid #999',
      backgroundColor: isBesavvi ? '' : 'white',
      borderRadius: '5px',
    }}
  >
    <Text>
      {value} {label}
    </Text>
  </Box>
);

interface SliderButtonProps {
  name: string;
  sliderX: number;
  isBesavvi: boolean;
  current: boolean;
  value: number;
  label: string;
}

const SliderButton = ({ current, name, sliderX, isBesavvi, value, label }: SliderButtonProps) => (
  <Box
    data-test-id={`${name}-handle`}
    sx={{
      width: toPx(HANDLE_SIZE),
      height: toPx(HANDLE_SIZE),
      position: 'absolute',
      top: '50%',
      left: toPx(sliderX),
      transform: 'translate(-50%, -50%)',
    }}
  >
    {isBesavvi ? (
      <Handle
        sx={{
          clipPath: 'circle(50%)',
          backgroundColor: isBesavvi ? 'background' : 'monochrome.7',
          color: current ? darkenedSecondary : 'secondary',
          cursor: 'pointer',
        }}
      />
    ) : (
      <Box
        sx={{
          width: '40px',
          height: '40px',
          border: '3px solid',
          borderColor: 'primary',
          borderRadius: '50%',
          backgroundColor: 'monochrome.7',
          cursor: 'pointer',
        }}
      />
    )}
    {isBesavvi ? (
      <StandardLabel name={name} isBesavvi={isBesavvi} value={value} label={label} current={current} />
    ) : (
      <NovunaLabel name={name} isBesavvi={isBesavvi} value={value} label={label} />
    )}
  </Box>
);

/**
 * The actual slider.
 * Doesn't present as a controlled, form component, because it doesn't need to behave as one
 * Usage, either...
 * <Slider min={12} max={15} onChange={sliderValueChange}/>
 * or...
 * <Slider min={minMonths} max={maxMonths} initialValue={storedMonths} onChange={sliderValueChange}/>
 */
export type SliderProps = {
  name?: string;
  label: string;
  min: number;
  max: number;
  initialValue?: number;
  onChange: (value: number) => void;
};

export const Slider: React.FC<SliderProps> = ({ name = 'range', label, min, max, initialValue = min, onChange }) => {
  const [value, setValue] = useState(bound(initialValue, min, max));
  const [sliderX, setSliderX] = useState(0);
  const dragging = useRef(false);
  const trackElement = useRef<HTMLDivElement>(null);
  const isBesavvi = useIsBeSavvi();

  // measure on demand
  const getDimensions = useCallback(() => {
    return (trackElement.current as HTMLDivElement).getBoundingClientRect();
  }, []);

  // reposition during drag
  const updateControl = useCallback(
    (mouseX) => {
      const { left, width } = getDimensions();
      const newPosition = bound(mouseX - left, 0, width);
      setSliderX(newPosition);
      const newValue = Math.round(min + (max - min) * (newPosition / width));
      setValue(newValue);
    },
    [getDimensions, max, min]
  );

  // fire the onChange whenever the value changes (*not* whenever it is set, which is a lot during drag)
  useEffect(
    () => {
      onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  // take a value and put the handle in the right place
  const positionSliderForValue = useCallback(
    (newValue: number) => {
      const { width } = getDimensions();
      const newPosition = ((newValue - min) * width) / (max - min);
      setSliderX(newPosition);
    },
    [getDimensions, max, min]
  );

  // event handler for drag
  const dragStart = useCallback(
    (e: PointerEvent) => {
      e.preventDefault();
      dragging.current = true;
      updateControl(e.clientX);
    },
    [updateControl]
  );

  // event handler for drag
  const dragMove = useCallback(
    (e: PointerEvent) => {
      if (dragging.current) {
        e.preventDefault();
        updateControl(e.clientX);
      }
    },
    [updateControl]
  );

  // event handler for drag
  const dragEnd = useCallback(
    (e: PointerEvent) => {
      if (dragging.current) {
        e.preventDefault();
        dragging.current = false;
        positionSliderForValue(value);
      }
    },
    [positionSliderForValue, value]
  );

  // need to reposition the handle on resize (because it's all done in px)
  const onResize = useCallback(() => {
    positionSliderForValue(value);
  }, [positionSliderForValue, value]);

  // directly amend the value (for the MoveButtons)
  const incrementValue = useCallback(
    (diff: number) => {
      const newValue = bound(value + diff, min, max);
      setValue(newValue);
      positionSliderForValue(newValue);
    },
    [max, min, positionSliderForValue, value]
  );

  // use two separate effects to set up event handlers etc

  // this one sets up the referentially stable `down` and `move` handlers
  // thus avoiding re-binding these *during* drag (when the `value` can change)
  // also initialises the position, using the stable `initialValue`
  useEffect(() => {
    const track = trackElement.current as HTMLDivElement; // grab a ref for cleanup

    track.addEventListener('pointerdown', dragStart, false);
    document.addEventListener('pointermove', dragMove, false);

    positionSliderForValue(initialValue);

    return () => {
      track.removeEventListener('pointerdown', dragStart);
      document.removeEventListener('pointermove', dragMove);
    };
  }, [initialValue, dragStart, dragMove, positionSliderForValue]);

  // this one has several (some transitive) dependencies on `value`, so it might be
  // called during drag, but these event handlers are safe to re-bind at that point
  useEffect(() => {
    document.addEventListener('pointerup', dragEnd, false);
    document.addEventListener('pointerleave', dragEnd, false);
    window.addEventListener('resize', onResize, false);

    return () => {
      document.removeEventListener('pointerup', dragEnd);
      document.removeEventListener('pointerleave', dragEnd);
      window.removeEventListener('resize', onResize);
    };
  }, [dragEnd, onResize]);

  return (
    <Flex
      data-test-id={`${name}-slider`}
      sx={{
        alignItems: 'center',
        paddingTop: toPx(INDICATOR_HEIGHT),
      }}
    >
      <MoveButton
        data-test-id={`${name}-left-move`}
        value={value}
        limit={min}
        incrementValue={incrementValue}
        incrementBy={-1}
        isBesavvi={isBesavvi}
      >
        {isBesavvi ? <DecreaseArrowOutline /> : <NovunaMinus />}
      </MoveButton>
      <Box
        data-test-id={`${name}-track-holder`}
        sx={{
          flex: '1 0 auto',
          margin: `auto ${toPx(HANDLE_RADIUS + BUTTON_MARGIN)}`,
          height: toPx(HANDLE_SIZE),
          position: 'relative',
          touchAction: 'none',
        }}
        ref={trackElement}
      >
        <SliderPath name={name} isBesavvi={isBesavvi} sliderX={sliderX} />
        <SliderButton
          name={name}
          isBesavvi={isBesavvi}
          sliderX={sliderX}
          current={dragging.current}
          label={label}
          value={value}
        />
      </Box>
      <MoveButton
        data-test-id={`${name}-right-move`}
        value={value}
        limit={max}
        incrementValue={incrementValue}
        incrementBy={1}
        isBesavvi={isBesavvi}
      >
        {isBesavvi ? <IncreaseArrowOutline /> : <NovunaPlus />}
      </MoveButton>
    </Flex>
  );
};
