import { useFormik } from 'formik';
import { useEffect, useMemo, ChangeEventHandler, FocusEventHandler, useCallback } from 'react';
import { AllOf } from '@plending/validation/utils';
import {
  FieldSpec,
  FieldCollection,
  FieldValues,
  FieldErrors,
  RuleFunction,
  FieldPropSet,
  ValidationGlobals,
  FieldTouched,
} from './types';
import { DEFAULT_MESSAGES } from './utils';

const noopSubmit = () => {};

export function useValidation(
  fields: FieldSpec[],
  { onSubmit = noopSubmit, validate: validateFn, enableReinitialize = false }: ValidationGlobals = {}
) {
  // This is here to map several dependencies to a single one, in a way that keeps ESLint happy
  const memoFields = useMemo(
    () => fields,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...fields]
  );

  // derive initial state from the fields
  const { initialValues, initialTouched, initialErrors, allRules, allMessages } = useMemo(() => {
    const initialValues: FieldValues = {};
    const initialTouched: FieldTouched = {};
    const initialErrors: FieldErrors = {};
    const allMessages: Record<string, Record<string, string>> = {};

    const allRules: { name: string; validate: RuleFunction }[] = [];

    memoFields.forEach(({ name, initialValue = '', rules, messages = {} }) => {
      initialValues[name] = initialValue;
      allMessages[name] = messages;

      const applyRules = AllOf(...rules);
      const validate = (value: string) => {
        const result = applyRules(value);

        if (result) {
          return result;
        }
      };
      initialErrors[name] = validate(initialValue);

      if (initialValue && !initialErrors[name]) {
        initialTouched[name] = true;
      }

      allRules.push({ name, validate });
    });

    return {
      initialValues,
      initialTouched,
      initialErrors,
      allRules,
      allMessages,
    };
  }, [memoFields]);

  const validate = useCallback(
    (values: FieldValues) => {
      if (validateFn) {
        const errors: FieldErrors | undefined = validateFn(values);

        if (errors) {
          Object.keys(errors).forEach((k) => {
            if (!errors[k]) {
              delete errors[k];
            }
          });
        }

        return errors;
      }
    },
    [validateFn]
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    registerField,
    unregisterField,
    submitCount,
    ...restOfFormik
  } = useFormik({
    initialValues,
    initialTouched,
    initialErrors,
    enableReinitialize,
    onSubmit,
    validate,
  });

  // Create the prefab prop sets
  const fieldProps = useMemo(() => {
    const props: FieldCollection<FieldPropSet> = {};

    const { setFieldValue, setFieldError, setFieldTouched, validateField } = restOfFormik;

    memoFields.forEach(({ name, transform, onChange, onBlur }) => {
      let changeHandler: ChangeEventHandler<HTMLInputElement> = handleChange;

      if (transform || onChange) {
        changeHandler = (e) => {
          if (transform) {
            e.currentTarget.value = transform(e.currentTarget.value);
          }
          if (onChange) {
            onChange(e);
          }

          handleChange(e); // Formik's handler
        };
      }

      let blurHandler: FocusEventHandler<HTMLInputElement> = handleBlur;

      if (onBlur) {
        blurHandler = (e) => {
          onBlur(e);
          handleBlur(e); // Formik's handler
        };
      }

      const errorCode = errors[name];
      let error;

      if (errorCode) {
        error = allMessages[name][errorCode] || DEFAULT_MESSAGES[errorCode] || errorCode;
      }

      props[name] = {
        name,
        value: values[name],
        touched: touched[name],
        error,

        // for simple fields
        onChange: changeHandler,
        onBlur: blurHandler,

        // pass through the plain formik functions, for complex fields
        validateField,
        setFieldTouched,
        setFieldError,
        setFieldValue,
      };
    });

    return props;
  }, [allMessages, errors, handleBlur, handleChange, memoFields, restOfFormik, touched, values]);

  // Tell Formik about the fields
  useEffect(() => {
    allRules.forEach(({ name, validate }) => {
      registerField(name, { validate });
    });

    return () => {
      allRules.forEach(({ name }) => {
        unregisterField(name);
      });
    };
  }, [allRules, registerField, unregisterField]);

  // scroll to the topmost error
  useEffect(
    () => {
      // only if an attempt to submit has been made, and so errors are visible
      // submit count increases, so the effect will fire on every submit attempt
      if (submitCount > 0) {
        restOfFormik.validateForm().then((errors) => {
          const pos = Object.keys(errors).reduce((min, name) => {
            if (errors[name]) {
              // select the first element whose name starts with the field key
              const element = document.querySelector(`[name|="${name}"]`);

              // store its top if it's the highest up the page
              if (element) {
                const { top } = element.getBoundingClientRect();

                return Math.min(min, top);
              }
            }

            return min;
          }, 0);

          // only scroll if the error is off screen
          if (pos < 0) {
            window.scrollBy({
              left: 0,
              top: pos - 50,
              behavior: 'smooth',
            });
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitCount] //  ** don't add `errors` here **
    /*
      > `errors` can change even if `submitCount` doesn't
      > if `submitCount` has changed, the effect *will* use the correct copy of `errors`
      The scenario this avoids is when the user corrects an error towards the bottom of
      the page. If `errors` were included as a dep here, the page would scroll to the
      top error once the lower error is cleared, which is undesirable.
    */
  );

  return {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    submitCount,
    ...restOfFormik,
    fieldProps,
  };
}
