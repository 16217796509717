import { AgreementResponse } from '@plending/interfaces/agreement';
import { RemoteState } from '../types';

export enum AgreementActionType {
  CLEAR = 'agreement/CLEAR',
  REQUEST = 'agreement/REQUEST',
  LOAD = 'agreement/LOAD',
  FAILED = 'agreement/FAILED',
}

export interface AgreementState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: AgreementResponse['data'];
}
