import { Application } from '@plending/interfaces/application';
import { BootstrapData } from '@plending/interfaces/bootstrap-data';
import { RemoteAction, RemoteState } from '../types';

export enum ResumeActionType {
  CLEAR = 'resume/CLEAR',
  REQUEST = 'resume/REQUEST',
  LOAD = 'resume/LOAD',
  FAILED = 'resume/FAILED',
}

export interface ResumeAction extends RemoteAction {
  type: ResumeActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface ResumeData {
  redirectUrl: string;
  pathName?: string;
}

export interface ResumeState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: ResumeData;
}

export interface ResumeResponse {
  data: {
    application: Application;
    bootstrapData: BootstrapData;
  };
}
