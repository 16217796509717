import React, { useCallback, useMemo } from 'react';
import {
  jsx,
  InputWrapper,
  Tone,
  SelectField,
  Box,
  ReadOnlyWrapper,
  TextButton,
  TertiaryLink,
  TertiaryLinkDirection,
} from 'compass-design';
import { formatPostCode } from '@plending/common/formatters';
import { addressToLabel } from '../../../utils/formatters';
import { OptionList } from '../OptionList';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { AddressComponentProps } from './types';

export const AddressSelect: React.FC<AddressComponentProps> = ({
  name,
  mainError,
  searchPostcode,
  setEditMode,
  addressList = [],
  setAddressList,
  setFieldValue,
}) => {
  const isBesavvi = useIsBeSavvi();
  const onSelect = useCallback(
    (e) => {
      if (addressList) {
        setFieldValue(name, addressList[e.currentTarget.value]);
      }
    },
    [addressList, name, setFieldValue]
  );

  const resetAddress = useCallback(() => {
    setAddressList([]);
  }, [setAddressList]);

  const setManualEntry = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const fieldName = `addressSelect-${name}`;
  const id = fieldName;
  const tone: Tone = mainError ? 'negative' : 'neutral';

  const labels = useMemo(
    () => addressList.reduce((l, address, i) => ({ ...l, [i]: addressToLabel(address) }), {}),
    [addressList]
  );

  const values = useMemo(() => Object.keys(labels), [labels]);

  const manualAddress = isBesavvi ? (
    <TextButton data-test-id={`manual-entry-button-${name}`} mt={1} onClick={setManualEntry}>
      Enter address manually
    </TextButton>
  ) : (
    <Box mt={3}>
      <TertiaryLink
        dataTestId={`manual-entry-button-${name}`}
        direction={TertiaryLinkDirection.FORWARDS}
        onClick={setManualEntry}
        text="Enter address manually"
      />
    </Box>
  );

  return (
    <Box>
      <Box mb={5}>
        <ReadOnlyWrapper
          label="Postcode"
          tone="positive"
          action={
            <TextButton data-test-id={`restart-button-${name}`} onClick={resetAddress}>
              Change
            </TextButton>
          }
        >
          {formatPostCode(searchPostcode)}
        </ReadOnlyWrapper>
      </Box>
      <Box mb={5} data-test-id={fieldName}>
        <InputWrapper id={id} label="Address" tone={tone} error={mainError}>
          <SelectField onChange={onSelect} id={fieldName} name={fieldName} tone={tone}>
            <OptionList values={values} labels={labels} placeholder={`${values.length} addresses found`} />
          </SelectField>
          {manualAddress}
        </InputWrapper>
      </Box>
    </Box>
  );
};
