import { SessionActionType, SessionAction, SessionState } from './types';

export function reducer(state = {} as SessionState, { type }: SessionAction): SessionState {
  switch (type) {
    case SessionActionType.START_TIMER: {
      return {
        startedAt: performance.now(),
      };
    }
    case SessionActionType.STOP_TIMER: {
      return {};
    }
    default:
      return state;
  }
}
