import { Rate } from '@plending/interfaces/bootstrap-data';
import { termLabel } from '@plending/validation/quote-utils';

export function expandRanges(...limits: [number, number][]): number[] {
  const range: number[] = [];

  limits.forEach(([start, end]) => {
    for (let n = start; n <= end; n++) {
      if (!range.includes(n)) {
        range.push(n);
      }
    }
  });

  range.sort((a, b) => a - b);

  return range;
}

export type RateLimits = {
  minValidLoanPence: number;
  maxValidLoanPence: number;
  minValidTerm: number;
  maxValidTerm: number;
  termList: number[];
};

export function findRateLimits(rates: Rate[] = [], enteredAmount?: string): RateLimits {
  let minValidLoanPence = Number.MAX_SAFE_INTEGER;
  let maxValidLoanPence = Number.MIN_SAFE_INTEGER;
  let minValidTerm = Number.MAX_SAFE_INTEGER;
  let maxValidTerm = Number.MIN_SAFE_INTEGER;
  const restrictions: [number, number][] = [];

  let enteredAmountPence: number;

  if (enteredAmount) {
    enteredAmountPence = parseInt(enteredAmount, 10) * 100;
  }

  rates.forEach((r) => {
    if (r.isTypical) {
      minValidLoanPence = Math.min(minValidLoanPence, r.minAmountPence);
      maxValidLoanPence = Math.max(maxValidLoanPence, r.maxAmountPence);

      minValidTerm = Math.min(minValidTerm, r.minTermMonths);
      maxValidTerm = Math.max(maxValidTerm, r.maxTermMonths);

      if (enteredAmountPence && enteredAmountPence >= r.minAmountPence && enteredAmountPence <= r.maxAmountPence) {
        restrictions.push([r.minTermMonths, r.maxTermMonths]);
      }
    }
  });

  if (
    minValidLoanPence === Number.MAX_SAFE_INTEGER ||
    maxValidLoanPence === Number.MIN_SAFE_INTEGER ||
    minValidTerm === Number.MAX_SAFE_INTEGER ||
    maxValidTerm === Number.MIN_SAFE_INTEGER
  ) {
    return {} as RateLimits;
  }

  let termList: number[];

  if (restrictions.length) {
    termList = expandRanges(...restrictions);
  } else {
    termList = expandRanges([minValidTerm, maxValidTerm]);
  }

  return {
    minValidLoanPence,
    maxValidLoanPence,
    minValidTerm,
    maxValidTerm,
    termList,
  };
}

export function listTermLabels(min: number, max: number) {
  const terms: Record<number, string> = {};

  for (let value = min; value <= max; value++) {
    terms[value] = termLabel(value);
  }

  return terms;
}
