import { AddressDetails, DateGroup, AtSinceGroup } from '@plending/interfaces/application';
import { TitleList, MaritalStatusList, AccommodationStatusList, PurposeOfLoanList } from '@plending/interfaces/enums';
import {
  Required,
  IsPostcode,
  IsDate,
  MinAge,
  LaterThan,
  IsEmail,
  IsMobilePhone,
  RequiredBoolean,
  IsAtSince,
  IsSinceBeforeToday,
  IsSinceLaterThan,
  MaxLength,
  MinLength,
  ValidNameChars,
} from './rules';
import { RuleFunction } from './types';
import { listRule } from './utils';

export const IsAccommodationStatus: RuleFunction = listRule(AccommodationStatusList);
export const IsTitle: RuleFunction = listRule(TitleList);
export const IsMaritalStatus: RuleFunction = listRule(MaritalStatusList);
export const IsPurposeOfLoan: RuleFunction = listRule(PurposeOfLoanList);

export const MyDetailMinLengthValidation = {
  FIRST_NAME: 2,
  LAST_NAME: 2,
};

export const MyDetailMaxLengthValidation = {
  FIRST_NAME: 15,
  LAST_NAME: 30,
  MIDDLE_NAME: 15,
  EMAIL: 60,
  PHONE: 16,
};

export const AddressMaxLengthValidation = {
  HOUSE_NUMBER: 10,
  HOUSE_NAME: 40,
  FLAT: 20,
  STREET: 60,
  TOWN: 30,
  COUNTY: 30,
};

const uc = (t: string | undefined) => (t ? `${t}`.toUpperCase() : undefined);
const pc = (t: string) => `${t}`.replace(/\s*/g, '').toUpperCase();

export const SameAddress = (one: AddressDetails, two: AddressDetails) => {
  if (
    uc(one.town) === uc(two.town) &&
    uc(one.county) === uc(two.county) &&
    uc(one.street) === uc(two.street) &&
    uc(one.flatName) === uc(two.flatName) &&
    pc(one.postcode) === pc(two.postcode) &&
    uc(one.houseName) === uc(two.houseName) &&
    uc(one.houseNumber) === uc(two.houseNumber)
  ) {
    return 'Duplicate Address';
  }
};

export const IsAddress = (value: AddressDetails) => {
  if (!value) {
    return 'Required';
  }

  const { houseNumber = '', houseName = '', town = '', postcode = '' } = value;

  if (Required(postcode)) {
    return 'Postcode required';
  }

  if (IsPostcode(postcode)) {
    return 'Invalid postcode';
  }

  if (!(!Required(houseNumber) || !Required(houseName))) {
    return 'House required';
  }

  if (houseNumber && houseNumber.length > AddressMaxLengthValidation.HOUSE_NUMBER) {
    return 'House Number too long';
  }

  if (Required(town)) {
    return 'Town required';
  }
};

const minThree = MinAge(3);

export const LessThanThreeYears = (atSinceGroup: AtSinceGroup) => {
  const lessThanThree = minThree({ day: 1, ...atSinceGroup });

  return !!lessThanThree;
};

export function getRequireAddressState(atCurrentAddressSince: AtSinceGroup, atSecondAddressSince: AtSinceGroup) {
  return {
    second: !!(atCurrentAddressSince && atCurrentAddressSince.year && LessThanThreeYears(atCurrentAddressSince)),
    third: !!(atSecondAddressSince && atSecondAddressSince.year && LessThanThreeYears(atSecondAddressSince)),
  };
}

export function outOfOrder(currentAtSince: AtSinceGroup, previousAtSince: AtSinceGroup) {
  const error = IsSinceLaterThan(previousAtSince)(currentAtSince);
  if (error) {
    return 'Out of order';
  }
}

export function ValidateAddresses(
  {
    dob,
    currentAddress,
    atCurrentAddressSince,
    secondAddress,
    atSecondAddressSince,
    thirdAddress,
    atThirdAddressSince,
  }: {
    dob: DateGroup;
    currentAddress: AddressDetails;
    atCurrentAddressSince: AtSinceGroup;
    secondAddress: AddressDetails;
    atSecondAddressSince: AtSinceGroup;
    thirdAddress: AddressDetails;
    atThirdAddressSince: AtSinceGroup;
  },
  requireAddresses: Record<string, boolean>
) {
  let errors = {} as Record<string, string | undefined>;

  if (requireAddresses.second) {
    errors = {
      ...errors,
      secondAddress: IsAddress(secondAddress) || SameAddress(currentAddress, secondAddress),
      atSecondAddressSince:
        IsAtSince(atSecondAddressSince) ||
        outOfOrder(atCurrentAddressSince, atSecondAddressSince) ||
        IsSinceLaterThan(dob)(atSecondAddressSince),
    };
  }

  if (requireAddresses.third) {
    errors = {
      ...errors,
      thirdAddress: IsAddress(thirdAddress) || SameAddress(thirdAddress, secondAddress),
      atThirdAddressSince:
        IsAtSince(atThirdAddressSince) ||
        outOfOrder(atSecondAddressSince, atThirdAddressSince) ||
        IsSinceLaterThan(dob)(atThirdAddressSince),
    };
  }

  return errors;
}

export const MyDetailsFieldRules = {
  firstName: [
    Required,
    ValidNameChars,
    MinLength(MyDetailMinLengthValidation.FIRST_NAME),
    MaxLength(MyDetailMaxLengthValidation.FIRST_NAME),
  ],
  middleNames: [ValidNameChars, MaxLength(MyDetailMaxLengthValidation.MIDDLE_NAME)],
  lastName: [
    Required,
    ValidNameChars,
    MinLength(MyDetailMinLengthValidation.LAST_NAME),
    MaxLength(MyDetailMaxLengthValidation.LAST_NAME),
  ],
  title: [Required, IsTitle],
  dob: [IsDate, MinAge(18), LaterThan('1900/1/1')],
  email: [Required, IsEmail, MaxLength(MyDetailMaxLengthValidation.EMAIL)],
  phone: [Required, IsMobilePhone, MaxLength(MyDetailMaxLengthValidation.PHONE)],
  maritalStatus: [Required, IsMaritalStatus],
  accommodationStatus: [Required, IsAccommodationStatus],
  marketingEmail: [RequiredBoolean],
  marketingPost: [RequiredBoolean],
  currentAddress: [IsAddress],
  atCurrentAddressSince(nowFn: () => number, dob: DateGroup) {
    return [IsAtSince, IsSinceBeforeToday(nowFn), IsSinceLaterThan(dob)];
  },
  purposeOfLoan: [Required, IsPurposeOfLoan],
};
