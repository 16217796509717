import React, { Fragment } from 'react';
import { jsx, Flex, Box, AlertCircle, Text } from 'compass-design';

type AddressErrorProps = {
  name: string;
  error?: string;
};

export const AddressError: React.FC<AddressErrorProps> = ({ name, error }) => {
  return (
    <Fragment>
      {error && (
        <Flex mb={4} sx={{ alignItems: 'center' }}>
          <Box sx={{ flex: '0 0 auto' }}>
            <AlertCircle />
          </Box>
          <Text
            data-test-id={`addressMainError-${name}`}
            ml="2"
            pr={['0', null, '3']}
            sx={{ fontSize: '1', color: 'tone.negative' }}
          >
            {error}
          </Text>
        </Flex>
      )}
    </Fragment>
  );
};
