import React, { FC, Fragment } from 'react';
import { jsx, Box, Text, Flex, NovunaHeading, Link, List, ListItem } from 'compass-design';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { PCCIItem } from './PCCIText';

type DataRowProps = {
  label?: string;
  description?: React.ReactNode;
};
const DataRow: React.FC<DataRowProps> = ({ label = '', description, children, ...props }) => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    const labelWidth = ['160px', null, '180px'];

    return (
      <Flex mb={4}>
        <Box
          sx={{
            minWidth: labelWidth,
            maxWidth: labelWidth,
            paddingRight: 4,
          }}
        >
          <Text mb={2} sx={{ fontWeight: 'bold' }}>
            {label}
          </Text>
          {description}
        </Box>
        <Box sx={{ flexGrow: 0 }} {...props}>
          {children}
        </Box>
      </Flex>
    );
  }

  return (
    <Box mb={4}>
      <NovunaHeading as="h3" mb={2}>
        {label}
      </NovunaHeading>
      {description && (
        <Text sx={{ color: 'monochrome.3' }} mb={2}>
          {description}
        </Text>
      )}
      <Text {...props}>{children}</Text>
    </Box>
  );
};

const ContentRow: React.FC<{ isBesavvi: boolean }> = ({ children, isBesavvi }) => {
  if (isBesavvi) {
    return (
      <Box
        sx={{
          borderTopWidth: '2px',
          borderTopStyle: 'solid',
          borderTopColor: 'monochrome.5',
          paddingTop: 4,
          paddingLeft: 4,
        }}
      >
        {children}
      </Box>
    );
  }
  return <Box mb={3}>{children}</Box>;
};

const HeadingRow: React.FC = ({ children }) => {
  return (
    <Box as="h3" mb={4} sx={{ fontSize: 1 }}>
      {children}
    </Box>
  );
};

const Bold: FC = ({ children }) => {
  return (
    <Text as="span" sx={{ fontWeight: 'bold' }}>
      {children}
    </Text>
  );
};

interface UserDataProps {
  data: PCCIItem | PCCIItem[];
}

const UserData: React.FC<UserDataProps> = ({ data }) => {
  if (typeof data === 'string') {
    return <Text as="span">{data}</Text>;
  }
  if (Array.isArray(data)) {
    return (
      <Fragment>
        {data.map((d, k) => (
          <UserData key={k} data={d} />
        ))}
      </Fragment>
    );
  }
  if (!('type' in data)) {
    return (
      <DataRow label={data.label} description={data.description}>
        <Text as="span">
          <UserData data={data.value} />
        </Text>
      </DataRow>
    );
  }
  if (data.type === 'bold') return <Bold>{data.value}</Bold>;
  if (data.type === 'email') {
    return (
      <DataRow label={data.label} description={data.description}>
        <Link sx={{ textDecoration: 'underline', cursor: 'pointer' }} href={`mailto:${data.value}`}>
          {data.value}
        </Link>
      </DataRow>
    );
  }
  if (data.type === 'phone') {
    return (
      <DataRow label={data.label} description={data.description}>
        <Link sx={{ textDecoration: 'underline', cursor: 'pointer' }} href={`tel:${data.value}`}>
          {data.value}
        </Link>
      </DataRow>
    );
  }
  if (data.type === 'list') {
    return (
      <List fontSize="1">
        <UserData data={data.value} />
      </List>
    );
  }
  if (data.type === 'address') {
    return (
      <Fragment>
        {data.value.map((d: string, k: number) => (
          <div key={k}>{d}</div>
        ))}
      </Fragment>
    );
  }
  if (data.type === 'link') {
    return (
      <DataRow label={data.label} description={data.description}>
        <Link
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          href={`${data.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.value}
        </Link>
      </DataRow>
    );
  }
  if (data.type === 'subheading') {
    return <HeadingRow>{data.value}</HeadingRow>;
  }
  if (data.type === 'listItem') {
    return (
      <ListItem>
        <UserData data={data.value} />
      </ListItem>
    );
  }
  return (
    <DataRow label={data.label} description={data.description}>
      <Text as="span">
        <UserData data={data.value} />
      </Text>
    </DataRow>
  );
};

export { DataRow, ContentRow, HeadingRow, UserData };
