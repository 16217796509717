import { jsx, Card, Button, Box, Heading } from 'compass-design';
import React from 'react';

export type PanelButtonProps = {
  title: string;
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
  buttonVariant?: string;
  onClick: () => void;
};

export const PanelButton: React.FC<PanelButtonProps> = ({
  title,
  label,
  icon,
  disabled,
  onClick,
  children,
  buttonVariant = 'primary',
  ...props
}) => (
  <Card
    {...props}
    variant="secondary"
    sx={{ width: '290px' }}
    button={
      <Button
        sx={{ borderRadius: 'cards.secondary.borderRadius' }}
        onClick={onClick}
        disabled={disabled}
        variant={buttonVariant}
      >
        {label} {icon}
      </Button>
    }
  >
    <Box p={4}>
      <Heading variant="secondary" as="h3" visualSize={3} mb={3}>
        {title}
      </Heading>
      {children}
    </Box>
  </Card>
);
