import { FinancialDetails } from '@plending/interfaces/application';
import { BankDetails } from '@plending/interfaces/bank-check';

export enum FinancialDetailsActionType {
  CLEAR = 'financial-details/CLEAR',
  STORE = 'financial-details/STORE',
  STORE_BANK_CHECK = 'financial-details/STORE_BANK_CHECK',
  REQUEST = 'financial-details/REQUEST',
  LOAD = 'financial-details/LOAD',
  FAILED = 'financial-details/FAILED',
}

export interface FinancialDetailsAction {
  type: FinancialDetailsActionType;
  payload?: FinancialDetails | BankDetails;
}
