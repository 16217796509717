/* eslint-disable react/prop-types */
import { jsx, InputWrapper, SelectField, Tone, Box } from 'compass-design';
import React, { ChangeEvent, FocusEvent } from 'react';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { OptionList } from './OptionList';
import { useTone, useHandleChange } from './hooks';

interface DropdownInputProps {
  values: string[];
  labels: Record<number, string>;
  placeholder: string;
  name: string;
  label: string;
  value: string;
  touched?: boolean;
  error?: string;
  stackedLabel?: boolean;
  short?: boolean;
  onValueChange?: (v: string) => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const DropdownInput: React.FC<DropdownInputProps> = (props) => {
  const {
    values = [],
    labels = [],
    placeholder,
    name,
    label,
    value,
    touched = false,
    error,
    stackedLabel = false,
    short = false,
    onValueChange,
    onChange,
    onBlur,
  } = props;

  const id = name;
  const tone: Tone = useTone({ touched, error });

  const handleChange = useHandleChange<HTMLSelectElement>(onChange, onValueChange);
  const isBesavvi = useIsBeSavvi();

  return (
    <Box data-test-id={id} mb={isBesavvi ? 5 : 4}>
      <InputWrapper
        {...{
          id,
          label,
          tone,
          error,
          stacked: stackedLabel,
        }}
      >
        <SelectField
          onChange={handleChange}
          id={name}
          name={name}
          tone={tone}
          onBlur={onBlur}
          value={value}
          short={short}
        >
          <OptionList values={values} labels={labels} placeholder={placeholder} />
        </SelectField>
      </InputWrapper>
    </Box>
  );
};
