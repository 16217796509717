import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { LandingParams, QueryParameters } from '@plending/interfaces/landing';
import { ApplicationFlowType } from '@plending/interfaces/bootstrap-data';
import { jsx } from 'compass-design';
import { useNavigation, getPath } from '../Routing/useNavigation';
import { useGetBootstrapData, useSetConfig } from '../../store/bootstrap/selectors';
import { ERROR_COMPONENTS } from '../Routing/Routes';
import { useQueryParams } from '../../utils/useQueryParams';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';

export const Landing: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const { replaceWith, replaceWithNextStep } = useNavigation();
  const { supplierId = '0' }: LandingParams = useParams();
  const { loanAdvisor } = useQueryParams<QueryParameters>();

  const { loading, data, error } = useGetBootstrapData(supplierId);
  const setLoanAdvisor = useSetConfig({
    loanAdvisor,
    applicationFlowType: data?.supplier?.isPersonalisedQuoteEnabled
      ? ApplicationFlowType.PERSONALISED_QUOTE
      : ApplicationFlowType.DIRECT,
  });

  useEffect(() => {
    if (!loading) {
      if (data) {
        setLoanAdvisor();
        replaceWithNextStep();
      } else if (error) {
        replaceWith(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string);
      }
    }
  }, [data, error, loading, replaceWith, replaceWithNextStep, setLoanAdvisor]);

  const details = (
    <>
      <h1>Setting up...</h1>
      <p>Please wait while the Personal Finance application loads.</p>
    </>
  );

  return isBesavvi ? details : <NovunaContainer>{details}</NovunaContainer>;
};
