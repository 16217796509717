import type { AddressResponse } from './address-lookup';
import type { BankDetails } from './bank-check';
import type { Quote, DecisionOffer } from './quote';
import type { Decision, DecisionResponse } from './decision';
import type {
  EmploymentStatus,
  MaritalStatus,
  AccommodationStatus,
  Title,
  NumberOfDependants,
  PurposeOfLoan,
} from './enums';
import type { Supplier } from './supplier';
import type { AlternativeLoanData } from './alternative-loan';
import type { PersonalisedQuoteSoftSearchResponse, TopUpSoftSearchResponse } from './soft-search';

export const JourneyType = {
  SELF_SERVICE: 'self_service', // top up self service
  LOAN_ADVISOR: 'loan_advisor', // top up with loan advisor
  LOAN_ADVISOR_PERSONALISED_QUOTE: 'loan_advisor_personalised_quote',
} as const;
export type JourneyType = (typeof JourneyType)[keyof typeof JourneyType];

export const ApplicationStatus = {
  DRAFT: 'DRAFT',
  DECISION_PENDING: 'DECISION_PENDING',
  ACCEPT: 'ACCEPT',
  DECLINED: 'DECLINED',
  REFER: 'REFER',
  PROVISIONAL_ACCEPT: 'PROVISIONAL_ACCEPT',
  UNSIGNED: 'UNSIGNED',
  SIGNED: 'SIGNED',
  READY_FOR_PAYOUT: 'READY_FOR_PAYOUT',
  PAYOUT_PENDING: 'PAYOUT_PENDING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  ORPHANED: 'ORPHANED',
  QUOTE_ACCEPT: 'QUOTE_ACCEPT',
  QUOTE_DECLINE: 'QUOTE_DECLINE',
} as const;
export type ApplicationStatus = (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

export const FlowIdentifier = {
  AGGREGATOR: 'A',
  PERSONALISED_QUOTE: 'P',
  DIRECT: 'D',
  LOAN_ADVISOR: 'L',
  TOP_UP: 'T',
} as const;
export type FlowIdentifier = (typeof FlowIdentifier)[keyof typeof FlowIdentifier];

export type WizardType = 'N' | 'Q';

export const APIJourneyIdentifier = {
  API: 'API',
  NON_API: 'NON-API',
};
export type APIJourneyIdentifier = (typeof APIJourneyIdentifier)[keyof typeof APIJourneyIdentifier];

export interface DateGroup {
  day: string;
  month: string;
  year: string;
}

export interface AtSinceGroup {
  month: string;
  year: string;
}

export interface DurationGroup {
  years: string;
  months: string;
}

export type AddressDetails = AddressResponse;

export type Address = {
  address: AddressDetails;
  timeAtAddress?: DurationGroup;
  atAddressSince: AtSinceGroup;
};

export type AddressList = {
  currentAddress: Address;
  secondAddress: Address;
  thirdAddress: Address;
};

interface QuoteFinancialDetails {
  annualIncomeBeforeTax: number;
  employmentStatus: EmploymentStatus;
  numberOfDependants: NumberOfDependants;
  monthlyAccommodationCost?: number;
}

export interface QuoteApplication {
  status?: ApplicationStatus;
  createdDate: string;
  updatedDate?: string;
  supplier?: Supplier;
  loanAdvisor?: string;
  decision?: DecisionResponse;
  quote?: Quote;
  offer?: DecisionOffer;
  supplierId?: string;
  supplierReference?: string;
  applicant: {
    personalDetails: QuotePersonalDetails;
    financialDetails?: QuoteFinancialDetails;
  };
  representativeRate?: number | null;
  representativeQuote?: Quote;
  flowIdentifier?: FlowIdentifier;
  apiJourneyIdentifier?: APIJourneyIdentifier;
  aggregatorRanking?: number;
}

export interface ConvertedQuoteApplication extends QuoteApplication {
  updatedDate: string;
  supplierReference?: string;
  applicant: {
    personalDetails: ConvertedQuotePersonalDetails;
    financialDetails: QuoteFinancialDetails;
  };
}

export interface IovationDevice {
  blackbox?: string;
  ipAddress?: string;
}

export interface Application extends Omit<QuoteApplication, 'decision'> {
  id?: number;
  applicationId?: string;
  alternativeLoanLead?: AlternativeLoanData;
  applicant: Applicant;
  authRegistered?: boolean;
  finalOffer?: FinalOffer;
  decision?: Decision;
  pcci?: Pcci;
  htPrefs?: unknown; // deprecated
  ipAddress?: string;
  signedDate?: string;
  signingId?: string;
  device?: IovationDevice;
  isResumed?: boolean;
  personalisedQuote?: PersonalisedQuoteSoftSearchResponse;
  topUpQuote?: TopUpSoftSearchResponse;
  additionalLoansQuote?: TopUpSoftSearchResponse;
  existingAgreement?: ExistingAgreementDetails;
  journeyType?: JourneyType;
}

export type ApplicationWithApplicationId = Application & { applicationId: string };

export type ApplicationWithId = Application & { applicationId: string; id: number };

export type AcsApplication = Omit<Application, 'createdDate'>;

export interface ExistingAgreementDetails {
  agreementNumber: string;
  monthlyInstalment: number;
  finalMonthlyInstalment: number;
  nextPaymentDate: DateGroup;
  secondNextPaymentDate: DateGroup; // used if the nextPaymentDate has passed
  apr: number;
  remainingTerm: number;
  settlementFigure: number;
  postPaymentDateSettlementFigure: number; // used if the nextPaymentDate has passed (= settlementFigure - monthlyInstalment)
  outstandingBalance: number;
}

export interface Applicant {
  personalDetails: PersonalDetails;
  financialDetails?: FinancialDetails;
  preDecisionConfirmation?: PreDecisionConfirmation;
}

export interface QuotePersonalDetails {
  title: Title;
  firstName: string;
  middleNames?: string;
  lastName: string;
  testParameters?: string;
  dob: DateGroup;
  email: string;
  maritalStatus?: MaritalStatus;
  phone?: string;
  accommodationStatus: AccommodationStatus;
  currentAddress: Address;
  secondAddress?: Address;
  thirdAddress?: Address;
  purposeOfLoan?: PurposeOfLoan;
}

export interface ConvertedQuotePersonalDetails extends QuotePersonalDetails {
  marketingEmail: boolean;
  marketingPost: boolean;
  marketingPreferencesTimestamp?: string;
}

export interface PersonalDetails extends ConvertedQuotePersonalDetails {
  maritalStatus: MaritalStatus;
  phone: string;
  email: string;
}

export enum LoanType {
  PERSONALISED_QUOTE = 'PERSONALISED_QUOTE',
  TOP_UP_LOAN = 'TOP_UP_LOAN',
  ADDITIONAL_LOAN = 'ADDITIONAL_LOAN',
}

export interface PreDecisionConfirmation {
  bankDetails: string;
  creditSearch: string;
  circumstances: string;
  alternativeLoanProviderOptIn?: boolean;
  loanType?: LoanType;
}

export interface FinancialDetails {
  employmentStatus: EmploymentStatus;
  partnerEmploymentStatus?: EmploymentStatus;
  partnerOccupation?: string;
  employerName?: string;
  occupation?: string;
  workPhoneNumber?: string;
  annualIncomeBeforeTax: number;
  numberOfDependants: NumberOfDependants;
  bankHolderName: string;
  bankCheckDetails: BankDetails;
  withBankSince: AtSinceGroup;
  timeAtBank?: DurationGroup;
  monthlyAccommodationCost?: number;
}

export interface FullName {
  firstName: string;
  middleNames?: string;
  lastName: string;
}

export interface Pcci {
  loanPence: number;
  monthlyPence: number;
  totalPence: number;
  apr: number;
  termMonths: number;
}

export interface FinalOffer extends DecisionOffer {
  tailoredLoan: boolean;
}
