import { jsx, Message, NovunaHeading, Styled, Text, TickOutline } from 'compass-design';
import React from 'react';
import { PageTitle, ProgressHeading } from '../../../design-components/heading';
import { LeftBox, LeftRightSplit, RightBox } from '../../../design-components/LeftRightSplit';
import { NovunaContainer } from '../../../design-components/NovunaContainer';
import { useCompleteDataSelector } from '../../../store/complete/selectors';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { DownloadAgreement } from './DownloadAgreement';

export const ProvisionalAcceptAgreement: React.FC = () => {
  const {
    data: { applicationId },
  } = useCompleteDataSelector();

  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <div data-test-id="complete-page">
        <PageTitle>Application e-signing complete</PageTitle>

        <LeftRightSplit>
          <LeftBox>
            <ProgressHeading underline="full" icon={<TickOutline />}>
              All done for now
            </ProgressHeading>
            <Styled.p data-test-id="decision-message">Your credit agreement is signed.</Styled.p>
            <Text sx={{ fontSize: 2, marginBottom: 3 }}>
              All done for now. Your credit agreement is signed. We will now perform our final security checks and be in
              touch within the next 24 hours if we need anything else from you.
            </Text>
            <Text sx={{ fontSize: 2 }}>Application reference: {applicationId}</Text>
          </LeftBox>
          <RightBox>
            <DownloadAgreement />
          </RightBox>
        </LeftRightSplit>
      </div>
    );
  }

  return (
    <NovunaContainer>
      <div data-test-id="complete-page">
        <PageTitle>Application e-signing complete</PageTitle>
        <Message variant="info">
          <NovunaHeading as="h3">All done for now</NovunaHeading>
          <Styled.p data-test-id="decision-message">Your credit agreement is signed.</Styled.p>
          <Text sx={{ fontSize: 2, marginBottom: 3 }}>
            All done for now Your credit agreement is signed. We will now perform our final security checks and be in
            touch within the next 24 hours if we need anything else from you.
          </Text>
          <Text sx={{ fontSize: 2 }}>Application reference: {applicationId}</Text>
          <br></br>
          <DownloadAgreement />
        </Message>
      </div>
    </NovunaContainer>
  );
};
