import React, { FC } from 'react';
import { jsx, Box, Button, Message, NovunaDocument, NovunaHeading, Text } from 'compass-design';
import { useDownload } from '../../utils/useDownload';

export const DownloadPCCI: FC = () => {
  const [downloadPCCI, pcciDownloading, pcciDownloadError] = useDownload('/pcci-pdf', 'PCCI.pdf');
  const linkError = Boolean(pcciDownloadError);

  if (linkError) {
    return (
      <Message data-test-id="error-message" variant="error">
        There was an error retrieving your Pre-Contract Credit Information.
      </Message>
    );
  }

  return (
    <Box mb={5}>
      <Message variant="info" icon={<NovunaDocument />}>
        <Box>
          <NovunaHeading as="h3" mb={2}>
            Your copy
          </NovunaHeading>
          <Text mb={3}>Download a copy of this explanation for your records</Text>
          <Button variant="secondary" onClick={downloadPCCI} disabled={pcciDownloading} data-test-id="print-panel">
            {pcciDownloading ? 'Downloading...' : 'Download'}
          </Button>
        </Box>
      </Message>
    </Box>
  );
};
