export enum SupplierDetailsErrors {
  EMPTY = 'Supplier details are empty',
  LOG_ENTRY_INVALID = 'Supplier details not valid',
}

export enum QuoteDetailsErrors {
  EMPTY = 'Quote details are empty',
  LOG_ENTRY_INVALID = 'Quote not valid',
  RATE_NOT_FOUND = 'Unable to find matching rate from supplier by loan amount and term',
}

export enum SoftSearchApplicationErrors {
  OFFER_EXPIRED = 'Offer has expired',
}

export enum AcsTokenError {
  INVALID = 'Invalid token',
  EXPIRED = 'Token has expired',
  APP_NOT_FOUND = 'Could not find application using id',
}

export enum ValidateQuoteResponse {
  NOT_FOUND = 'Quote not found',
  QUOTE_EXPIRED = 'Quote expired',
  QUOTE_INVALID = 'Quote invalid',
  QUOTE_VALID = 'Quote valid',
}

export enum SoftSearchValidationErrorName {
  MAX_AMOUNT_ERROR = 'Maximum Loan Amount Error',
  MIN_AMOUNT_ERROR = 'Minimum Loan Amount Error',
  TERM_ERROR = 'Term Restriction Error',
}
