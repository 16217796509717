import { useSelector } from 'react-redux';
import { RootState } from '../rootState';
import { RemoteState } from '../types';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { FinalOfferState } from './types';

const selectFinalOffer = (s: RootState) => s.finalOffer;

export function useFinalOfferSelector(): FinalOfferState {
  return useSelector(selectFinalOffer);
}

export function useCallbackFinalOfferSelector(): () => RemoteState {
  return useCallbackSelector(selectFinalOffer);
}
