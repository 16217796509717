import React, { Fragment, useMemo } from 'react';
import { jsx, Card, Text, Box, Flex, SummaryRow, Message, NovunaHeading } from 'compass-design';
import { termLabel, formatApr, penceToPoundsString } from '@plending/validation/quote-utils';
import { Slider } from '../components/Slider/Slider';
import { FinalOfferRequestBody } from '../store/finalOffer/types';
import { useIsBeSavvi } from '../utils/useIsBesavvi';
import { FormHeading } from './heading';

export type TailoringCardProps = {
  maxTerm: number;
  minTerm: number;
  apr: number;
  offerDetails: FinalOfferRequestBody;
  loanAmount: number;
  onChange: (value: number) => void;
};

export const TailoringCard: React.FC<TailoringCardProps> = ({
  maxTerm,
  minTerm,
  apr,
  offerDetails: { termMonths, monthlyPence, totalPence },
  onChange,
  ...props
}) => {
  const isBesavvi = useIsBeSavvi();

  const termMonthsString = useMemo(() => {
    return termLabel(termMonths);
  }, [termMonths]);

  const formattedAPR = useMemo(() => {
    return formatApr(apr);
  }, [apr]);

  const totalPayable = useMemo(() => {
    return penceToPoundsString(totalPence);
  }, [totalPence]);

  const monthlyRepaymentPounds = useMemo(() => {
    return penceToPoundsString(monthlyPence);
  }, [monthlyPence]);

  if (isBesavvi) {
    return (
      <Fragment>
        <FormHeading>Reduce monthly repayment</FormHeading>
        <Card p={[4, null, 6]} mb={6} variant="secondary" sx={{ maxWidth: 'columns.6' }} {...props}>
          <Text sx={{ fontSize: 2, mb: 4 }}>
            You can reduce the monthly repayment by extending the term up to <b>{maxTerm} months</b>.
          </Text>
          <Slider name="tailoring" label="Months" min={minTerm} max={maxTerm} onChange={onChange} />
          <Box>
            <Text
              data-test-id="tailoring-monthly-repayment"
              sx={{
                fontSize: 4,
                fontWeight: 'heading',
                mt: 3,
                textAlign: 'center',
              }}
            >
              {monthlyRepaymentPounds}
            </Text>
            <Text sx={{ fontSize: 2, mt: 1, textAlign: 'center' }}>Monthly repayment</Text>
          </Box>
          <Box mx="auto" mt={4} sx={{ maxWidth: ['100%', null, '230px'] }}>
            <Flex>
              <Box sx={{ marginRight: 'auto' }}>Term</Box>
              <Text data-test-id="tailoring-term-months" sx={{ fontWeight: 'bold' }}>
                {termMonthsString}
              </Text>
            </Flex>
            <Flex mt={2}>
              <Box sx={{ marginRight: 'auto' }}>APR</Box>
              <Text data-test-id="tailoring-apr" sx={{ fontWeight: 'bold' }}>
                {formattedAPR}
              </Text>
            </Flex>
            <Flex mt={2}>
              <Box sx={{ marginRight: 'auto' }}>Total payable</Box>
              <Text data-test-id="tailoring-total-payable" sx={{ fontWeight: 'bold' }}>
                {totalPayable}
              </Text>
            </Flex>
          </Box>
        </Card>
      </Fragment>
    );
  }

  return (
    <Box mb={6}>
      <Message {...props} variant="info">
        <NovunaHeading as="h3">Reduce monthly repayment</NovunaHeading>
        <Text sx={{ mb: 2 }}>You can reduce the monthly repayment by extending the term up to {maxTerm} months.</Text>
        <Box mb={4}>
          <Slider name="tailoring" label="Months" min={minTerm} max={maxTerm} onChange={onChange} />
        </Box>
        <Box mb={4}>
          <SummaryRow data-test-id="tailoring-term-months" label="Repaid over" value={termMonthsString} isWhite />
          <SummaryRow data-test-id="tailoring-apr" label="APR" value={`${formattedAPR} APR`} />
          <SummaryRow data-test-id="tailoring-total-payable" label="Total repayable" value={totalPayable} isWhite />
          <SummaryRow
            data-test-id="tailoring-monthly-repayment"
            label="Monthly repayment"
            value={monthlyRepaymentPounds}
          />
        </Box>
      </Message>
    </Box>
  );
};
