import { ApplicationStatus } from './application';
import { DecisionType } from './decision';

export const Title = {
  MR: 'Mr',
  MRS: 'Mrs',
  MISS: 'Miss',
  MS: 'Ms',
  DR: 'Dr',
} as const;

export type Title = keyof typeof Title;
export const TitleList = Object.keys(Title);

export const MaritalStatus = {
  SINGLE: 'Single',
  MARRIED: 'Married',
  COHABITING: 'Living With Partner',
  CIVIL_PARTNERSHIP: 'Civil partnership',
  WIDOWED: 'Widowed',
  DIVORCED: 'Divorced',
  SEPARATED: 'Separated',
} as const;

export type MaritalStatus = keyof typeof MaritalStatus;
export const MaritalStatusList = Object.keys(MaritalStatus);

export const partnerMaritalStatuses: readonly MaritalStatus[] = ['MARRIED', 'COHABITING', 'CIVIL_PARTNERSHIP'];

export const AccommodationStatus = {
  OWNER_OCCUPIER: 'Homeowner',
  TENANT_FURNISHED: 'Tenant Furnished',
  TENANT_UNFURNISHED: 'Tenant Unfurnished',
  LIVING_WITH_PARENTS: 'Living With Parents',
} as const;

export type AccommodationStatus = keyof typeof AccommodationStatus;
export const AccommodationStatusList = Object.keys(AccommodationStatus);

export const PurposeOfLoan = {
  VEHICLE_PURCHASE: 'Vehicle purchase',
  DEBT_CONSOLIDATION: 'Debt consolidation',
  HOME_IMPROVEMENT: 'Home improvement',
  WEDDING: 'Wedding',
  HOLIDAY: 'Holiday',
  OTHER: 'Other',
} as const;

export type PurposeOfLoan = keyof typeof PurposeOfLoan;
export const PurposeOfLoanList = Object.keys(PurposeOfLoan);

export const EmploymentStatus = {
  EMPLOYED: 'Employed',
  SELF_EMPLOYED: 'Self-employed',
  STUDENT: 'Student',
  HOUSEPERSON: 'Houseperson',
  RETIRED: 'Retired',
} as const;

export type EmploymentStatus = keyof typeof EmploymentStatus;
export const DecisionEmploymentStatus: Record<EmploymentStatus, EmploymentStatus> = {
  EMPLOYED: 'EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  STUDENT: 'STUDENT',
  HOUSEPERSON: 'HOUSEPERSON',
  RETIRED: 'RETIRED',
};
export const EmploymentStatusList = Object.keys(EmploymentStatus);

export const nonWorkingEmploymentStatuses = ['STUDENT', 'HOUSEPERSON'];

export const workingEmploymentStatuses = ['EMPLOYED', 'SELF_EMPLOYED'];

export const NumberOfDependantsList = ['0', '1', '2', '3'] as const;
export type NumberOfDependants = (typeof NumberOfDependantsList)[number];

export const DecisionAddressType = {
  MAIN: 'MAIN',
  PREVIOUS_1: 'PREVIOUS_1',
  PREVIOUS_2: 'PREVIOUS_2',
};

export const acceptStates = [ApplicationStatus.ACCEPT, ApplicationStatus.PROVISIONAL_ACCEPT];

export const revisionStates = [DecisionType.Accept, DecisionType.Decline, DecisionType.AcceptProvisional];

export const decisionEndStates: ReadonlyArray<ApplicationStatus> = [
  ApplicationStatus.ACCEPT,
  ApplicationStatus.DECLINED,
  ApplicationStatus.CANCELLED,
];

export const signedStatuses = [
  ApplicationStatus.PAID,
  ApplicationStatus.PAYOUT_PENDING,
  ApplicationStatus.READY_FOR_PAYOUT,
  ApplicationStatus.SIGNED,
];

export const applicationSource = {
  SOURCE: 'compass',
};

export const IdProof = {
  PROOF_OF_ID: 'NONE',
};

export const serviceType = {
  serviceType: 'IB',
};
