import { jsx, ArrowLeft, Box, TertiaryLink, TertiaryLinkDirection } from 'compass-design';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { DIRECT_JOURNEY, RouteSpec } from '../components/Routing/Routes';
import { useNavigation } from '../components/Routing/useNavigation';
import { useIsBeSavvi } from '../utils/useIsBesavvi';
import { Link } from './Link';

interface BackLinkProps {
  routeList?: RouteSpec[];
}

export const BackLink: React.FC<BackLinkProps> = ({ children, routeList = DIRECT_JOURNEY }) => {
  const { previousRoute: { path = '', name = '' } = {} } = useNavigation(routeList);
  const isBesavvi = useIsBeSavvi();
  const history = useHistory();

  if (isBesavvi) {
    return (
      <Box mb={4}>
        <Link
          data-test-id="backLink"
          to={path}
          sx={{
            fontSize: 1,
          }}
        >
          <ArrowLeft />
          {name || children}
        </Link>
      </Box>
    );
  }

  return (
    <Box paddingLeft={[2, null, 4, 0]}>
      <TertiaryLink
        dataTestId="backLink"
        text={name}
        direction={TertiaryLinkDirection.BACKWARDS}
        onClick={() => history.push(path)}
      />
    </Box>
  );
};
