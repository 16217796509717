import React from 'react';
import { jsx, Text, Box, NovunaHeading, Message } from 'compass-design';
import { FormCard } from '../../design-components/card';
import { ContinueButton } from '../../design-components/button';
import { useValidation } from '../../validation/useValidation';
import { useConfirmationFieldSpec } from '../../utils/specs';
import { FormikSubmit } from '../../validation/types';
import { useAlternativeLoanProvider } from '../../store/bootstrap/selectors';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { AltLoanOptIn } from './AltLoanOptIn';
import { ApplicationCompletionMessage } from './ApplicationCompletionMessage';

interface SubmitProps {
  onSubmit: FormikSubmit;
  submitButtonBusy: boolean;
  isBesavvi: boolean;
}

const fraudCheck = (
  <Box mb={6} data-test-id="scam-information">
    <p>
      Are you being encouraged to borrow money by a third party? Latest information on scams can be found{' '}
      <a
        href="https://www.novunapersonalfinance.co.uk/hints-tips/money/latest-scams-to-look-out-for/"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
    </p>
  </Box>
);

const SubmitPersonalisedQuote: React.FC<SubmitProps> = ({ onSubmit, submitButtonBusy, isBesavvi }) => {
  const { handleSubmit } = useValidation([], {
    onSubmit,
  });

  return (
    <>
      <Box mb={6}>
        <NovunaHeading as="h3" mb={2} data-test-id="confirmation-heading">
          Soft search
        </NovunaHeading>
        <Text sx={{ fontSize: 2, fontWeight: isBesavvi ? 'bold' : 'normal' }}>
          Checking your eligibility will place a soft search on your credit which is not visible to other lenders.
        </Text>
        <Text sx={{ fontSize: 2, fontWeight: isBesavvi ? 'bold' : 'normal' }}>
          By submitting this soft search, you consent that we may send you, by email (as an attachment or a link),
          important documents relating to this process.
        </Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <ContinueButton data-test-id="get-button" busy={submitButtonBusy}>
          Check my eligibility
        </ContinueButton>
      </form>
      {fraudCheck}
    </>
  );
};

const SubmitDecision: React.FC<SubmitProps> = ({ onSubmit, submitButtonBusy, isBesavvi }) => {
  const alternativeLoanProviderEnabled = useAlternativeLoanProvider();

  const {
    bankDetailsConfirmSpec,
    creditSearchConfirmSpec,
    circumstancesConfirmSpec,
    alternativeLoanProviderOptInSpec,
  } = useConfirmationFieldSpec();

  const { handleSubmit, fieldProps } = useValidation(
    [bankDetailsConfirmSpec, creditSearchConfirmSpec, circumstancesConfirmSpec, alternativeLoanProviderOptInSpec],
    {
      onSubmit,
    }
  );

  return (
    <>
      {alternativeLoanProviderEnabled && <AltLoanOptIn fieldProps={fieldProps} />}
      {isBesavvi ? (
        <>
          <FormCard title="Completing your application">
            <ApplicationCompletionMessage isBesavvi={true} fieldProps={fieldProps} />
          </FormCard>
          {fraudCheck}
        </>
      ) : (
        <>
          <Message variant="info">
            <Box>
              <NovunaHeading as="h2" mb={2} data-test-id="confirmation-heading">
                Completing your application
              </NovunaHeading>
              <ApplicationCompletionMessage isBesavvi={false} fieldProps={fieldProps} />
            </Box>
          </Message>
          {fraudCheck}
        </>
      )}
      <form onSubmit={handleSubmit}>
        <ContinueButton data-test-id="get-button" busy={submitButtonBusy}>
          Submit application
        </ContinueButton>
      </form>
    </>
  );
};

interface ConfirmationCardProps {
  onSubmit: FormikSubmit;
  submitButtonBusy?: boolean;
  isPersonalisedQuote?: boolean;
}

export const ConfirmationCard: React.FC<ConfirmationCardProps> = ({
  onSubmit,
  submitButtonBusy = false,
  isPersonalisedQuote = false,
}) => {
  const isBesavvi = useIsBeSavvi();

  return (
    <>
      {isPersonalisedQuote ? (
        <SubmitPersonalisedQuote onSubmit={onSubmit} submitButtonBusy={submitButtonBusy} isBesavvi={isBesavvi} />
      ) : (
        <SubmitDecision onSubmit={onSubmit} submitButtonBusy={submitButtonBusy} isBesavvi={isBesavvi} />
      )}
    </>
  );
};
