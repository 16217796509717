import {
  RuleArgFunction,
  RuleFunctionFactory,
  RuleArgCache,
  RuleArg,
  RuleFunctionAggregator,
  RuleError,
  RuleFunction,
} from './types';

/**
 * Create a rule factory that will be cached against its argument
 * (eg MinLength(2) will factory a rule using the argument `2`, and will subsequntly reuse the same object.)
 * @param ruleFn A function that receive an argument and returns a rule function from that argument.
 */
export function cacheableRule(ruleFn: RuleArgFunction): RuleFunctionFactory {
  const cache: RuleArgCache = {};

  return (arg: RuleArg) => {
    const key = JSON.stringify(arg) || `${arg}`;

    if (!cache[key]) {
      cache[key] = (v: string) => ruleFn(arg, v);
    }

    return cache[key];
  };
}

export function listRule(list: readonly string[]): RuleFunction {
  return (value) => {
    if (!list.includes(value)) {
      return 'Invalid Entry';
    }
  };
}

/**
 * Return  new rule that runs all the rules passed as arguments, in turn. Fails with the first error it finds.
 * @param rules
 */
export const AllOf: RuleFunctionAggregator = (...rules) => {
  return (value: string) => {
    return rules.reduce((error: RuleError, rule) => error || rule(value), undefined);
  };
};
