import type { PersonalDetails, FinancialDetails } from '@plending/interfaces/application';

import { jsx } from 'compass-design';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useStorePersonalDetails } from '../../store/personalDetails/action';
import { useCreateApplication } from '../../store/application/createApplication/actions';
import type { QuotationDetails } from '../../store/quotation/types';
import {
  useSaveFinancialDetails,
  useStoreBankCheck,
  useStoreFinancialDetails,
} from '../../store/financialDetails/actions';
import { useNavigation } from '../Routing/useNavigation';

type User = {
  loanAdvisor?: string;
  personal: PersonalDetails;
  quotation: QuotationDetails;
  financial: FinancialDetails;
};

const users: Record<string, User> = {
  normal: {
    quotation: {
      aprString: '5.0%',
      loanPounds: '£5,000.00',
      monthlyPounds: '£149.60',
      totalPounds: '£5,385.60',
      term: '3 years',
      apr: 5,
      rpm: 0.407,
      loanPence: 500000,
      monthlyPence: 14960,
      totalPence: 538560,
      termMonths: 36,
    },
    personal: {
      title: 'MR',
      firstName: 'Paul',
      middleNames: 'Thomas',
      lastName: 'Popat',
      dob: { day: '20', month: '01', year: '1992' },

      accommodationStatus: 'OWNER_OCCUPIER',
      currentAddress: {
        address: {
          houseNumber: '2',
          houseName: '',
          flatName: '',
          county: 'West Yorkshire',
          street: 'Apex View',
          town: 'Leeds',
          postcode: 'LS11 9BH',
        },
        atAddressSince: { year: '2000', month: '01' },
      },
      purposeOfLoan: 'VEHICLE_PURCHASE',

      maritalStatus: 'MARRIED',
      phone: '07123456789',
      email: 'paul.popat@novuna.co.uk',

      marketingEmail: false,
      marketingPost: false,
      marketingPreferencesTimestamp: new Date().toISOString(),
    },
    financial: {
      employmentStatus: 'EMPLOYED',
      partnerEmploymentStatus: 'EMPLOYED',
      partnerOccupation: 'Software Developer',
      employerName: 'Novuna',
      occupation: 'Software Developer',
      workPhoneNumber: '07123456789',
      annualIncomeBeforeTax: 999_999,
      numberOfDependants: '0',
      bankHolderName: 'Paul Popat',
      bankCheckDetails: { sortCode: '404784', accountNumber: '70872490' },
      withBankSince: { month: '01', year: '2000' },
      monthlyAccommodationCost: 999,
    },
  },
};

const journeys = {
  direct: '54200000',
};

type JourneyType = keyof typeof journeys;

const Input = (props: PropsWithChildren<{ name: string; prefill: string }>) => {
  return (
    <div style={{ margin: 10, display: 'flex', alignItems: 'center' }}>
      <label>
        {props.children}:
        <input type="text" name={props.name} style={{ margin: 10 }} defaultValue={props.prefill} />
      </label>
    </div>
  );
};

const Select = (props: PropsWithChildren<{ name: string; prefill: string; options: Array<string> }>) => {
  return (
    <div style={{ margin: 10, display: 'flex', alignItems: 'center' }}>
      <label>
        {props.children}:
        <select name={props.name} style={{ margin: 10 }} defaultValue={props.prefill}>
          {props.options.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

type Submission = {
  journey: JourneyType;
  testParameters: string;
  user: User;
  step: string;
  email: string;
};

const parseFormData = (data: FormData): Submission => {
  const journey: JourneyType = data.get('journey') as any;
  const testParameters: string = data.get('testParameters') as any;
  const userId: string = data.get('user') as any;
  const email: string = data.get('email') as any;

  return {
    journey,
    testParameters,
    user: users[userId],
    step: data.get('step') as any,
    email,
  };
};

type Journeys = Record<
  JourneyType,
  Array<{ name: string; automate: (data: Submission) => Promise<void>; stopAt: () => void }>
>;

export const TestUtils = () => {
  const [journey, setJourney] = useState<JourneyType>('direct');

  const storePersonalDetails = useStorePersonalDetails();
  const createApplication = useCreateApplication();
  const storeFinancialDetails = useStoreFinancialDetails();
  const storeBankCheckDetails = useStoreBankCheck();
  const saveFinancialDetails = useSaveFinancialDetails();
  const { navigateTo } = useNavigation();

  const steps = useMemo(
    (): Journeys => ({
      direct: [
        {
          name: 'quotation',
          automate: async () => {},
          stopAt: () => {
            navigateTo('/quotation');
          },
        },
        {
          name: 'personal details',
          automate: async ({ user, testParameters, email }: Submission) => {
            const personalDetails = {
              ...user.personal,
              testParameters,
              email,
            };
            storePersonalDetails(personalDetails);
            await createApplication(false, {
              personalDetails: personalDetails,
              quotation: user.quotation,
              supplierId: journeys.direct,
              loanAdvisor: user.loanAdvisor ?? '',
              isTopUpLoan: false,
            });
          },
          stopAt: () => {
            navigateTo('/personal-details');
          },
        },
        {
          name: 'financial details',
          automate: async ({ user }: Submission) => {
            storeFinancialDetails(user.financial);
            const bankCheckDetails = {
              sortCode: user.financial.bankCheckDetails.sortCode,
              accountNumber: user.financial.bankCheckDetails.accountNumber,
            };
            await saveFinancialDetails(true, {
              ...user.financial,
              bankCheckDetails,
            });

            storeBankCheckDetails(bankCheckDetails);
          },
          stopAt: () => {
            navigateTo('/financial-details');
          },
        },
        {
          name: 'submission',
          automate: async () => {
            alert('Not yet implemented');
            throw new Error();
          },
          stopAt: () => {
            navigateTo('/decision');
          },
        },
      ],
    }),
    [
      createApplication,
      navigateTo,
      saveFinancialDetails,
      storeBankCheckDetails,
      storeFinancialDetails,
      storePersonalDetails,
    ]
  );

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const data = parseFormData(formData);

      const journey = steps[data.journey];

      for (const { name, automate: step, stopAt: enter } of journey) {
        if (name === data.step) {
          enter();
          break;
        } else await step(data);
      }
    },
    [steps]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(new FormData(e.currentTarget));
      }}
      onChange={(e) => {
        const data = new FormData(e.currentTarget);
        setJourney(data.get('journey') as any);
      }}
    >
      <h1>Set up journeys</h1>
      <Select name="journey" prefill={journey} options={Object.keys(journeys)}>
        Journey
      </Select>
      <Input name="testParameters" prefill="3->a">
        Test Parameters
      </Input>
      <Select name="user" prefill="normalAccept" options={Object.keys(users)}>
        User
      </Select>
      <Select name="step" prefill={steps[journey].map((s) => s.name)[0]} options={steps[journey].map((s) => s.name)}>
        Stop At
      </Select>
      <Input name="email" prefill={'your.email@here.com'}>
        Email
      </Input>

      <button type="submit">Submit</button>

      <br />
      <h1>Direct Links</h1>
      <p>You may have to reseed db to re use these</p>
      <p>
        The seed data for these are in packages/services/seeds/02_application.js - manipulate it there if you need
        changes
      </p>
      <ul>
        <li>
          <a href="http://localhost:3000/token-landing-page/abcdefg12345678">Top Up Journey - Self Service</a>
        </li>
        <li>
          <a href="http://localhost:3000/token-landing-page/loan-advisor-personalised-quote">
            Loan Advisor Personalised Quote
          </a>
        </li>
      </ul>
    </form>
  );
};
