import { useSelector } from 'react-redux';
import { RootState } from '../rootState';
import { RemoteState } from '../types';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { ExplanationState } from './types';

const selectExplanation = (s: RootState) => s.explanation;

export function useExplanationSelector(): ExplanationState {
  return useSelector(selectExplanation);
}

const selectCallbackExplanation = ({ application }: RootState): RemoteState => {
  return application.progress.explanation;
};

export function useCallbackExplanationSelector(): () => RemoteState {
  return useCallbackSelector(selectCallbackExplanation);
}
