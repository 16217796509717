import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { jsx } from 'compass-design';

type LinkProps = {
  to: string;
};

export const Link: React.FC<LinkProps> = ({ to, children, ...props }) => {
  return (
    <RouterLink
      to={to}
      sx={{
        color: 'link',
      }}
      {
        ...props
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, prettier/prettier
        /* @ts-ignore */
      }
    >
      {children}
    </RouterLink>
  );
};
