import { FinancialDetails } from '@plending/interfaces/application';
import { BankDetails } from '@plending/interfaces/bank-check';
import { FinancialDetailsActionType, FinancialDetailsAction } from './types';

export function reducer(state = {}, { type, payload }: FinancialDetailsAction): Partial<FinancialDetails> {
  switch (type) {
    case FinancialDetailsActionType.STORE: {
      return payload as FinancialDetails;
    }
    case FinancialDetailsActionType.STORE_BANK_CHECK: {
      return {
        ...state,
        bankCheckDetails: payload as BankDetails,
      };
    }
    case FinancialDetailsActionType.CLEAR: {
      return {};
    }
    default:
      return state;
  }
}
