import React from 'react';
import { jsx, Text, Box } from 'compass-design';

import { FieldPropSet, FieldCollection } from '../../validation/types';
import { CheckBoxInput } from '../../design-components/inputs/CheckboxInput';

interface CompletingApplicationProps {
  isBesavvi: boolean;
  fieldProps: FieldCollection<FieldPropSet>;
}

export const ApplicationCompletionMessage: React.FC<CompletingApplicationProps> = ({ isBesavvi, fieldProps }) => (
  <>
    <Text sx={{ fontSize: 2, fontWeight: isBesavvi ? 'bold' : 'normal' }} mb={3}>
      By submitting your application you are confirming that:
    </Text>
    <Box mb={5}>
      <CheckBoxInput {...fieldProps.bankDetailsConfirm}>Your bank details above are correct.</CheckBoxInput>
      <CheckBoxInput {...fieldProps.creditSearchConfirm}>
        You want to apply for a loan and understand that submitting this application will place a full search on your
        credit record. You also consent that we may send you, by email (as an attachment or a link), important documents
        relating to your application.
      </CheckBoxInput>
      <CheckBoxInput {...fieldProps.circumstancesConfirm}>
        You can afford the monthly repayments and you are not aware of any recent or future changes in your
        circumstances that would result in these repayments becoming unaffordable.
      </CheckBoxInput>
    </Box>
  </>
);
