import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useFetchPost } from '../utils/fetch';
import { useCallbackDecisionSelector } from './selectors';
import { DecisionActionType, DecisionState } from './types';

const extractDecision = (d: DecisionState) => d.data;

export function useFetchDecision() {
  return useFetchPost('/decision', useCallbackDecisionSelector(), DecisionActionType, extractDecision);
}

export function useClearDecision() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: DecisionActionType.CLEAR }), [dispatch]);
}
