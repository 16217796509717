import { jsx, Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { PageTitle } from '../../../design-components/heading';
import { NovunaContainer } from '../../../design-components/NovunaContainer';
import { ErrorPage } from '../../Errors/Error';

interface ErrorDecisionProps {
  isBesavvi: boolean;
  decisionError?: string;
}

export const ErrorDecision: React.FC<ErrorDecisionProps> = ({ isBesavvi, decisionError }) => {
  const errorMessage =
    decisionError === 'BUSINESS_ERROR'
      ? 'Sorry, an error has occurred with your application and we are unable to give you a decision. Please try again or call our Loans Team on 0343 351 9112.'
      : 'An error has occurred with your application and we are unable to give you a decision.';

  return isBesavvi && !(decisionError === 'BUSINESS_ERROR') ? (
    <ErrorPage
      errorSource="decision"
      errorReason="error"
      errorData={decisionError || 'no decision'}
      data-test-id="error-message"
    />
  ) : (
    <>
      <NovunaContainer>
        <PageTitle>Application decision</PageTitle>
        <Box mb={4}>
          <Message variant="error">
            <NovunaHeading as="h3" mb={1}>
              Something went wrong
            </NovunaHeading>
            <Text data-test-id="error-message">{errorMessage}</Text>
          </Message>
        </Box>
      </NovunaContainer>
    </>
  );
};
