import React, { FC, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { jsx, Box } from 'compass-design';
import { penceToPoundsString, getDetails } from '@plending/validation/quote-utils';
import { Rate } from '@plending/interfaces/bootstrap-data';
import { AllOf } from '@plending/validation/utils';
import { Required, MinValue, MaxValue, IsNumber } from '@plending/validation/rules';
import { ContinueButton } from '../../../../design-components/button';
import { SingleInput } from '../../../../design-components/inputs/SingleInput';
import { DropdownInput } from '../../../../design-components/inputs/DropdownInput';
import { useQuotationSelector } from '../../../../store/quotation/selectors';
import { findRateLimits, listTermLabels } from '../../../Quotation/quotationHelpers';
import { useBootstrapDataSelector, useIsTopUpLoanSelector } from '../../../../store/bootstrap/selectors';
import { FieldErrors, FieldPropSet } from '../../../../validation/types';
import { QuotationDetails } from '../../../../store/quotation/types';
import { useValidation } from '../../../../validation/useValidation';
import { useStoreQuotation } from '../../../../store/quotation/actions';
import { useClearPersonalisedQuote, useFetchPersonalisedQuote } from '../../../../store/personalisedQuote/actions';
import { useNavigation, getPath } from '../../../Routing/useNavigation';
import { PERSONALISED_QUOTE_COMPONENTS, TOP_UP_LOANS_COMPONENTS } from '../../../Routing/Routes';
import { useClearTopUpLoansQuote, useFetchTopUpLoansQuote } from '../../../../store/topUp/actions';

type LocalFormikBag = {
  // Formik status is used to keep track of the amount for which an invalid term has been cleared
  status: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  setStatus: (status?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  setFieldValue: FieldPropSet['setFieldValue'];
  errors: FieldErrors;
};

export interface LoanAmendModalProps {
  isBesavvi: boolean;
}

export const LoanAmendModal: FC<LoanAmendModalProps> = ({ isBesavvi }: LoanAmendModalProps) => {
  const [quote, setQuote] = useState<QuotationDetails | null>();
  const [rates, setRates] = useState<Rate[]>([]);
  const [isTopUpLoan, setIsTopUpLoan] = useState<boolean>();

  const { data: bootstrapData } = useBootstrapDataSelector();
  const isTopUpLoanSelected = useIsTopUpLoanSelector();

  useEffect(() => {
    if (!!bootstrapData?.config && !!bootstrapData.product) {
      setRates(bootstrapData?.product?.rates);
      setIsTopUpLoan(isTopUpLoanSelected);
    }
  }, [bootstrapData, setRates, setIsTopUpLoan, isTopUpLoanSelected]);

  const { loanPence, termMonths } = useQuotationSelector();
  const storeQuotation = useStoreQuotation();
  const fetchPersonalisedQuote = useFetchPersonalisedQuote();
  const clearPersonalisedQuote = useClearPersonalisedQuote();
  const clearTopUpLoan = useClearTopUpLoansQuote();
  const fetchTopUpLoan = useFetchTopUpLoansQuote();
  const { navigateTo } = useNavigation();

  const prePopulatedLoanAmount = useMemo(() => {
    return loanPence ? `${loanPence / 100}` : '';
  }, [loanPence]);

  const [enteredAmount, setEnteredAmount] = useState<string>(prePopulatedLoanAmount);

  const { minValidLoanPence, maxValidLoanPence, minValidTerm, maxValidTerm, termList } = useMemo(
    () => findRateLimits(rates, enteredAmount),
    [enteredAmount, rates]
  );
  const termValues = useMemo(() => (termList || []).map((t) => `${t}`), [termList]);
  const termLabels = useMemo(() => listTermLabels(minValidTerm, maxValidTerm), [maxValidTerm, minValidTerm]);
  const minLoanPounds = penceToPoundsString(minValidLoanPence, false);
  const maxLoanPounds = penceToPoundsString(maxValidLoanPence, false);
  const amountRules = useMemo(
    () => AllOf(Required, IsNumber, MinValue(minValidLoanPence / 100), MaxValue(maxValidLoanPence / 100)),
    [maxValidLoanPence, minValidLoanPence]
  );
  const amountFieldSpec = useMemo(() => {
    const boundsMessage = `The loan amount should be between ${minLoanPounds} and ${maxLoanPounds}`;

    return {
      name: 'amount',
      initialValue: loanPence ? (loanPence / 100).toFixed(0) : '',
      rules: [],
      messages: {
        'Too small': boundsMessage,
        'Too large': boundsMessage,
      },
    };
  }, [loanPence, maxLoanPounds, minLoanPounds]);

  const termFieldSpec = useMemo(
    () => ({
      name: 'term',
      initialValue: termMonths ? termMonths.toFixed(0) : '',
      rules: [],
      messages: {
        Invalid: 'The loan amount is not available for the term selected. Please select another term.',
      },
    }),
    [termMonths]
  );

  const formikBag = useRef<LocalFormikBag>({
    status: false,
    setStatus: () => {
      /* noop default */
    },
    setFieldValue: () => {
      /* noop default */
    },
    errors: {},
  });
  const validate = useCallback(
    ({ amount, term }) => {
      let details;
      const intAmount: string = amount.replace(/\D/g, '');

      const {
        current: { status, setStatus, setFieldValue },
      } = formikBag;

      const nextErrors = {
        amount: amountRules(intAmount),
        term: Required(term),
      };

      if (!nextErrors.amount && !nextErrors.term) {
        details = getDetails(rates, parseInt(intAmount, 10) * 100, parseInt(term, 10));

        if (!details) {
          setFieldValue('term', '', false);
          setStatus(intAmount);

          nextErrors.term = 'Invalid';
        }
      } else if (status && nextErrors.term === 'Required') {
        nextErrors.term = 'Invalid';
      }

      if (!nextErrors.term) {
        setStatus(false);
      }

      setQuote(details);

      return nextErrors;
    },
    [amountRules, rates]
  );

  const { status, setStatus, setFieldError, fieldProps } = useValidation([amountFieldSpec, termFieldSpec], {
    validate,
  });

  const onAmountChange = useCallback(
    (v) => {
      if (status && status !== v) {
        setStatus(false);
      }
      const intAmount = v.replace(/\D/g, '');
      setEnteredAmount(intAmount);
      setFieldError('term', undefined);
    },
    [setFieldError, setStatus, status]
  );

  const checkEligibility = useCallback(() => {
    if (quote) {
      storeQuotation(quote);
      if (isTopUpLoan) {
        clearTopUpLoan();
        fetchTopUpLoan(true, { quote });
        navigateTo(getPath(TOP_UP_LOANS_COMPONENTS.quoteResult, {}));
      } else {
        clearPersonalisedQuote();
        fetchPersonalisedQuote(true, { quote });
        navigateTo(getPath(PERSONALISED_QUOTE_COMPONENTS.personalisedQuote, { personalisedQuote: 'true' }) as string);
      }
    }
  }, [
    quote,
    storeQuotation,
    fetchPersonalisedQuote,
    navigateTo,
    clearPersonalisedQuote,
    clearTopUpLoan,
    fetchTopUpLoan,
    isTopUpLoan,
  ]);

  return (
    <>
      <Box>
        <SingleInput
          label={isTopUpLoan ? 'Amount requested' : 'How much would you like to borrow?'}
          prefix="£"
          placeholder="0"
          onValueChange={onAmountChange}
          autoComplete="off"
          selectOnFocus
          stackedLabel={true}
          halfWidth={true}
          short
          {...fieldProps.amount}
          value={enteredAmount}
        />
        <DropdownInput
          label={isTopUpLoan ? 'Term (months)' : 'Over how long?'}
          values={termValues}
          labels={termLabels}
          placeholder="Please select"
          stackedLabel={true}
          short
          {...fieldProps.term}
        />
        <Box mt={!isBesavvi ? 5 : 0} mb={!isBesavvi ? 0 : 3}>
          <ContinueButton
            isSecondary={isTopUpLoan}
            disabled={!quote}
            data-test-id="loan-amend-modal-proceed-button"
            onClick={checkEligibility}
          >
            {isTopUpLoan ? 'Amend' : 'Check my eligibility'}
          </ContinueButton>
        </Box>
      </Box>
    </>
  );
};
