import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { jsx } from 'compass-design';
import { useNavigation, getPath } from '../Routing/useNavigation';
import { useGetCompleteData } from '../../store/complete/selectors';
import { ERROR_COMPONENTS } from '../Routing/Routes';
import { useFetchAgreementDownloadData } from '../../store/agreementDownload/actions';
import { PageTitle } from '../../design-components/heading';

interface CompleteLandingParams {
  applicationId?: string;
}

export const CompleteLanding: React.FC = () => {
  const { navigateToNextStep, replaceWith } = useNavigation();
  const { applicationId = '' }: CompleteLandingParams = useParams();

  const { loading, data, error } = useGetCompleteData(applicationId);
  const fetchAgreementDownloadData = useFetchAgreementDownloadData();

  useEffect(() => {
    fetchAgreementDownloadData(); // fire it off, but don't wait for a response, the next step does that
  }, [fetchAgreementDownloadData]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        navigateToNextStep();
      } else if (error) {
        replaceWith(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string);
      }
    }
  }, [data, error, loading, navigateToNextStep, replaceWith]);

  return (
    <>
      <PageTitle>Loading...</PageTitle>
    </>
  );
};
