import { jsx, Text } from 'compass-design';
import React, { useCallback, useRef, useState, Fragment as F, useEffect } from 'react';
import {
  Address,
  DateGroup,
  PersonalDetails as PersonalDetailsState,
  AtSinceGroup,
} from '@plending/interfaces/application';
import {
  AccommodationStatusList,
  AccommodationStatus,
  MaritalStatusList,
  MaritalStatus,
  TitleList,
  Title,
  PurposeOfLoanList,
  PurposeOfLoan,
} from '@plending/interfaces/enums';
import {
  getRequireAddressState,
  ValidateAddresses,
  MyDetailMaxLengthValidation,
} from '@plending/validation/my-details-rules';
import { ContinueButton } from '../../design-components/button';
import { PageTitle, FormHeading } from '../../design-components/heading';
import { useCreateApplication } from '../../store/application/createApplication/actions';
import { useBootstrapDataSelector, useIsTopUpLoanSelector } from '../../store/bootstrap/selectors';
import { useSelfServiceDataSelector } from '../../store/selfService/selectors';
import { useStorePersonalDetails } from '../../store/personalDetails/action';
import { usePersonalDetailsSelector } from '../../store/personalDetails/selectors';
import { useQuotationSelector } from '../../store/quotation/selectors';
import { useValidation } from '../../validation/useValidation';
import { AddressInput } from '../../design-components/inputs/AddressInput/AddressInput';
import { DateInput } from '../../design-components/inputs/DateInput';
import { useNavigation } from '../Routing/useNavigation';
import { AtSinceInput } from '../../design-components/inputs/AtSinceInput';
import { SingleInput } from '../../design-components/inputs/SingleInput';
import { SpreadSelect } from '../../design-components/inputs/SpreadSelect';
import { Step } from '../../design-components/step';
import { BackLink } from '../../design-components/backLink';
import { useStartSessionTimer } from '../../store/session/action';
import { getToken } from '../../utils/tokens';
import { usePersonalDetailsFieldSpec } from '../../utils/specs';
import { MarketingPreferences } from '../../design-components/MarketingPreferences';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useExistingAgreementDetailsSelector } from '../../store/existingAgreementDetails/selectors';
import { uiMocking } from '../../utils/mocking';

export const PersonalDetails: React.FC = () => {
  const startSessionTimer = useStartSessionTimer();

  useEffect(
    () => {
      const token = getToken();

      if (!token) {
        // start a non-JWT based session, but only if there isn't a JWT based session already running
        startSessionTimer();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // strictly on mount
  );

  const { navigateToNextStep } = useNavigation();

  const {
    title,
    firstName,
    middleNames,
    lastName,
    dob = {} as DateGroup,
    email,
    phone,
    maritalStatus,
    accommodationStatus,
    currentAddress = {} as Address,
    secondAddress = {} as Address,
    thirdAddress = {} as Address,
    purposeOfLoan,
    marketingEmail = false,
    marketingPost = false,
  } = usePersonalDetailsSelector();

  const storePersonalDetails = useStorePersonalDetails();
  const createApplication = useCreateApplication();
  const quotationDetails = useQuotationSelector();
  const existingAgreement = useExistingAgreementDetailsSelector();
  const bootstrapData = useBootstrapDataSelector();
  const isTopUpLoan = useIsTopUpLoanSelector();
  const selfServiceData = useSelfServiceDataSelector();
  const applicationId = selfServiceData.data?.application?.applicationId;

  const [requireAddresses, updateRequireAddresses] = useState(() => {
    const { atAddressSince: atCurrentAddressSince } = currentAddress;
    const { atAddressSince: atSecondAddressSince } = secondAddress;

    return getRequireAddressState(atCurrentAddressSince, atSecondAddressSince);
  });

  const setRequireAddresses = useCallback(() => {
    const { atCurrentAddressSince, atSecondAddressSince } = durationStore.current;

    updateRequireAddresses(getRequireAddressState(atCurrentAddressSince, atSecondAddressSince));
  }, []);

  const [enteredDob, setEnteredDob] = useState<DateGroup>(dob);

  const onSubmit = useCallback(
    async ({
      title,
      firstName,
      middleNames,
      lastName,
      dob,
      email,
      phone,
      maritalStatus,
      accommodationStatus,
      currentAddress,
      atCurrentAddressSince,
      secondAddress,
      atSecondAddressSince,
      thirdAddress,
      atThirdAddressSince,
      purposeOfLoan,
      marketingEmail,
      marketingPost,
      testParameters,
    }) => {
      const toStore: PersonalDetailsState = {
        title,
        firstName,
        middleNames,
        lastName,
        dob,
        email,
        phone,
        maritalStatus,
        accommodationStatus,
        currentAddress: {
          address: currentAddress,
          atAddressSince: atCurrentAddressSince,
        },
        secondAddress: {} as Address,
        thirdAddress: {} as Address,
        purposeOfLoan,
        marketingEmail,
        marketingPost,
        testParameters,
      };

      if (requireAddresses.second) {
        toStore.secondAddress = {
          address: secondAddress,
          atAddressSince: atSecondAddressSince,
        };
      }

      if (requireAddresses.third) {
        toStore.thirdAddress = {
          address: thirdAddress,
          atAddressSince: atThirdAddressSince,
        };
      }

      storePersonalDetails(toStore);
      createApplication(true, {
        personalDetails: toStore,
        quotation: quotationDetails,
        existingAgreement,
        supplierId: bootstrapData.data ? bootstrapData.data.supplier?.id : '',
        loanAdvisor: bootstrapData.data?.config ? bootstrapData.data.config.loanAdvisor : '',
        isTopUpLoan,
        applicationId,
      });

      navigateToNextStep();
    },
    [
      requireAddresses.second,
      requireAddresses.third,
      storePersonalDetails,
      createApplication,
      quotationDetails,
      existingAgreement,
      bootstrapData.data,
      isTopUpLoan,
      applicationId,
      navigateToNextStep,
    ]
  );

  const validate = useCallback((values) => ValidateAddresses(values, requireAddresses), [requireAddresses]);

  const {
    titleFieldSpec,
    firstNameFieldSpec,
    middleNamesFieldSpec,
    lastNameFieldSpec,
    dobFieldSpec,
    emailFieldSpec,
    phoneFieldSpec,
    maritalStatusFieldSpec,
    accommodationStatusFieldSpec,
    currentAddressFieldSpec,
    atCurrentAddressSinceFieldSpec,
    secondAddressFieldSpec,
    atSecondAddressSinceFieldSpec,
    thirdAddressFieldSpec,
    atThirdAddressSinceFieldSpec,
    purposeOfLoanFieldSpec,
    marketingEmailFieldSpec,
    marketingPostFieldSpec,
    testParametersFieldSpec,
  } = usePersonalDetailsFieldSpec({
    title,
    firstName,
    middleNames,
    lastName,
    dob,
    enteredDob,
    email,
    phone,
    maritalStatus,
    accommodationStatus,
    currentAddress,
    secondAddress,
    thirdAddress,
    purposeOfLoan: purposeOfLoan ?? '',
    marketingEmail,
    marketingPost,
  });

  const { handleSubmit, fieldProps } = useValidation(
    [
      titleFieldSpec,
      firstNameFieldSpec,
      middleNamesFieldSpec,
      lastNameFieldSpec,
      dobFieldSpec,
      emailFieldSpec,
      phoneFieldSpec,
      maritalStatusFieldSpec,
      accommodationStatusFieldSpec,
      purposeOfLoanFieldSpec,
      currentAddressFieldSpec,
      atCurrentAddressSinceFieldSpec,
      secondAddressFieldSpec,
      atSecondAddressSinceFieldSpec,
      thirdAddressFieldSpec,
      atThirdAddressSinceFieldSpec,
      marketingEmailFieldSpec,
      marketingPostFieldSpec,
      testParametersFieldSpec,
    ],
    {
      onSubmit,
      validate,
    }
  );

  const durationStore = useRef({
    atCurrentAddressSince: {} as AtSinceGroup,
    atSecondAddressSince: {} as AtSinceGroup,
  });

  const onCurrentAtSinceChange = useCallback(
    (atSince: AtSinceGroup) => {
      durationStore.current.atCurrentAddressSince = atSince;
      setRequireAddresses();
    },
    [durationStore, setRequireAddresses]
  );

  const onSecondAtSinceChange = useCallback(
    (atSince: AtSinceGroup) => {
      durationStore.current.atSecondAddressSince = atSince;
      setRequireAddresses();
    },
    [durationStore, setRequireAddresses]
  );

  const isBesavvi = useIsBeSavvi();

  const details = (
    <F>
      <Step step={2} />
      <PageTitle>Personal details</PageTitle>
      <FormHeading>About you</FormHeading>
      <form onSubmit={handleSubmit}>
        {uiMocking.showTesterUtilities && <SingleInput label="Testing Parameters" {...fieldProps.testParameters} />}
        <SpreadSelect label="Title" values={TitleList} labels={Title} {...fieldProps.title} />
        <SingleInput label="First name" maxLength={MyDetailMaxLengthValidation.FIRST_NAME} {...fieldProps.firstName} />
        <SingleInput
          label="Middle name(s)"
          placeholder="Optional"
          maxLength={MyDetailMaxLengthValidation.MIDDLE_NAME}
          {...fieldProps.middleNames}
        />
        <SingleInput label="Last name" maxLength={MyDetailMaxLengthValidation.LAST_NAME} {...fieldProps.lastName} />
        <DateInput label="Date of birth" onDateChange={setEnteredDob} {...fieldProps.dob} />
        <SpreadSelect
          label="Marital status"
          values={MaritalStatusList}
          labels={MaritalStatus}
          {...fieldProps.maritalStatus}
        />
        <FormHeading>Contact details</FormHeading>
        <SingleInput label="Email address" maxLength={MyDetailMaxLengthValidation.EMAIL} {...fieldProps.email} />
        <SingleInput label="Mobile number" maxLength={MyDetailMaxLengthValidation.PHONE} {...fieldProps.phone} />
        <MarketingPreferences
          marketingEmailFieldProps={fieldProps.marketingEmail}
          marketingPostFieldProps={fieldProps.marketingPost}
        />

        <FormHeading>Current address</FormHeading>
        <SpreadSelect
          label="Residential status"
          values={AccommodationStatusList}
          labels={AccommodationStatus}
          {...fieldProps.accommodationStatus}
        />
        <AtSinceInput
          label="When did you move in?"
          onAtSinceChange={onCurrentAtSinceChange}
          {...fieldProps.atCurrentAddressSince}
        />

        <AddressInput {...fieldProps.currentAddress} />

        {requireAddresses.second && (
          <F>
            <FormHeading>Second address</FormHeading>

            <Text mt={0} mb={4} sx={{ fontSize: 2 }}>
              We may ask for up to 2 previous addresses, depending on the length of time you lived at each.
            </Text>

            <AtSinceInput
              label="When did you move in?"
              onAtSinceChange={onSecondAtSinceChange}
              {...fieldProps.atSecondAddressSince}
            />

            <AddressInput {...fieldProps.secondAddress} />
          </F>
        )}

        {requireAddresses.second && requireAddresses.third && (
          <F>
            <FormHeading>Third address</FormHeading>

            <AtSinceInput label="When did you move in?" {...fieldProps.atThirdAddressSince} />

            <AddressInput {...fieldProps.thirdAddress} />
          </F>
        )}

        <FormHeading>Purpose of Loan</FormHeading>
        <SpreadSelect
          label="What will you use the loan for?"
          values={PurposeOfLoanList}
          labels={PurposeOfLoan}
          {...fieldProps.purposeOfLoan}
        />
        {isBesavvi ? (
          <p>
            <ContinueButton data-test-id="proceed-button">Continue</ContinueButton>
          </p>
        ) : (
          <ContinueButton data-test-id="proceed-button">Continue</ContinueButton>
        )}
      </form>
    </F>
  );

  if (isBesavvi) {
    return (
      <F>
        <BackLink />
        {details}
      </F>
    );
  }

  return <NovunaContainer link={<BackLink />}>{details}</NovunaContainer>;
};
