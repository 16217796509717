export enum PcciActionType {
  STORE = 'pcci/STORE',
  CLEAR = 'pcci/CLEAR',
  REQUEST = 'pcci/REQUEST',
  LOAD = 'pcci/LOAD',
  FAILED = 'pcci/FAILED',
}

export interface PcciAction {
  type: PcciActionType;
  payload?: Omit<PcciState, 'accepted'>;
}

export interface PcciState {
  accepted: string;
  loanPence: number;
  monthlyPence: number;
  totalPence: number;
  apr: number;
  termMonths: number;
}
