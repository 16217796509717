import { Novuna } from '../../../utils/staticData';
import { SectionBuilder } from './SectionBuilder';

interface CreditCost {
  aprString: string;
}

export const buildCreditCost: SectionBuilder<CreditCost> = ({ aprString }) => {
  return {
    title: 'Cost of the credit',
    description: 'Including interest rate and guidance on late or missed repayments',
    testId: 'credit-cost',
    items: [
      {
        label: 'The rates of interest which apply to the credit agreement',
        value: [{ value: aprString, type: 'bold' }, ' per annum'],
      },
      {
        label: 'Annual Percentage Rate of charge (APR)',
        description: `This is the total cost expressed as an annual percentage of the amount of credit.
The APR is there to help you compare different offers.`,
        value: { value: aprString, type: 'bold' },
      },
      {
        label: 'Costs in the case of late repayments',
        value: {
          type: 'list',
          label: 'Charges will be payable under this agreement in the following events:',
          value: [
            {
              type: 'listItem',
              value: ['Recalled Direct Debit Charge: ', { value: Novuna.fees.recalledDirectDebit, type: 'bold' }],
            },
            {
              type: 'listItem',
              value: ['Returned or Recalled cheques: ', { value: Novuna.fees.returnedCheque, type: 'bold' }],
            },
            {
              type: 'listItem',
              value: ['Issue of a Default Notice: ', { value: Novuna.fees.defaultNotice, type: 'bold' }],
            },
            {
              type: 'listItem',
              value: [
                'Letters sent to you as a result of a breach of your credit agreement: ',
                { value: Novuna.fees.breachLetter, type: 'bold' },
                ' (but no such charge shall be made in respect of any such letters sent to you after a Default Notice has been issued, until there are no outstanding arrears on your account, when the charge shall become chargeable again until any further Default Notice is issued',
              ],
            },
          ],
        },
      },
      {
        label: '',
        value: `
Charges may also be payable for reasons stated in clauses 7a) and 8b) of the Terms of Lending of the agreement.
We may vary the charges payable under this agreement by giving you reasonable notice to reflect any variation to the cost and expenses to us in out these activities.`,
      },
      {
        label: 'Consequences of missing repayments',
        value: `Missing repayments could have severe consequences and may result in legal proceedings being taken against you.
This could include obtaining a Court Order for a charge on your home.
We will add details of your default to your record with the credit reference agencies, which could make it more difficult or more expensive for you to obtain credit.`,
      },
    ],
  };
};
