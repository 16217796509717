import { ApplicationFlowType } from '@plending/interfaces/bootstrap-data';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { jsx } from 'compass-design';
import { useSetConfig } from '../../store/bootstrap/selectors';
import { useTopUpLoansQuoteSelector } from '../../store/topUp/selectors';
import { TopUpEligibilityResponse, TopUpLoansQuoteState } from '../../store/topUp/types';

export const QuoteAcceptResumeRoute: React.FC = () => {
  const {
    loading: decisionLoading,
    error: decisionError,
    data: { topUpLoansQuote, additionalLoansQuote } = {} as TopUpEligibilityResponse,
  }: TopUpLoansQuoteState = useTopUpLoansQuoteSelector();
  const isTopUpLoan = (topUpLoansQuote || additionalLoansQuote) && !decisionError && !decisionLoading;
  const applicationFlowType = isTopUpLoan ? ApplicationFlowType.TOP_UP : ApplicationFlowType.PERSONALISED_QUOTE;
  const setConfig = useSetConfig({ applicationFlowType });

  useEffect(() => {
    setConfig();
  }, [setConfig]);

  return <Redirect to={isTopUpLoan ? '/top-up-results' : '/personalised-quote'}></Redirect>;
};
