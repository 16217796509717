import React from 'react';
import { jsx } from 'compass-design';

export type LabelList<T extends string> = Partial<Record<T, string>>;

type OptionListProps<T extends readonly string[] = readonly string[]> = {
  values: T;
  labels?: LabelList<T[number]>;
  placeholder?: string;
};

export const OptionList: React.FC<OptionListProps> = ({ values, labels = {}, placeholder = '--' }) => {
  return (
    <>
      {!!placeholder && <option value="">{placeholder}</option>}
      {values.map((text) => (
        <option value={text} key={text}>
          {labels[text] || text}
        </option>
      ))}
    </>
  );
};
