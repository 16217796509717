import { BootstrapData } from '@plending/interfaces/bootstrap-data';
import { RemoteAction, RemoteState } from '../types';

export enum BootstrapActionType {
  CLEAR = 'bootstrap/CLEAR',
  REQUEST = 'bootstrap/REQUEST',
  LOAD = 'bootstrap/LOAD',
  FAILED = 'bootstrap/FAILED',
}

export interface BootstrapAction extends RemoteAction {
  type: BootstrapActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface BootstrapState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: Partial<BootstrapData>;
}

export interface BootstrapResponse {
  data: BootstrapData;
}
