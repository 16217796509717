import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { FinancialDetails } from '@plending/interfaces/application';
import { BankDetails } from '@plending/interfaces/bank-check';
import { useFetchPut } from '../utils/fetch';
import { useCallbackFinancialDetailsSelector } from './selectors';
import { FinancialDetailsActionType } from './types';

const extractFinancialDetails = (res: Record<string, string>) => res.data;

export function useStoreFinancialDetails() {
  const dispatch = useDispatch();

  return useCallback(
    (details: FinancialDetails) =>
      dispatch({
        type: FinancialDetailsActionType.STORE,
        payload: details,
      }),
    [dispatch]
  );
}

export function useStoreBankCheck() {
  const dispatch = useDispatch();

  return useCallback(
    (payload: BankDetails) =>
      dispatch({
        type: FinancialDetailsActionType.STORE_BANK_CHECK,
        payload,
      }),
    [dispatch]
  );
}

export function useSaveFinancialDetails() {
  return useFetchPut(
    '/financial-details',
    useCallbackFinancialDetailsSelector(),
    FinancialDetailsActionType,
    extractFinancialDetails
  );
}

export function useClearFinancialDetails() {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch({
        type: FinancialDetailsActionType.CLEAR,
      }),
    [dispatch]
  );
}
