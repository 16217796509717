import { useSelector } from 'react-redux';
import { useCallbackSelector } from '../../utils/useCallbackSelector';
import { RootState } from '../../rootState';
import { CreateApplicationState } from './types';

const selectCreateApplicationData = (a: RootState) => a.createApplication;

export function useCreateApplicationDataDataSelector(): CreateApplicationState {
  return useSelector(selectCreateApplicationData);
}

export function useCallbackCreateApplicationDataSelector(): () => CreateApplicationState {
  return useCallbackSelector(selectCreateApplicationData);
}
