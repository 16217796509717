import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { QuotationActionType, QuotationDetails } from './types';

export function useStoreQuotation() {
  const dispatch = useDispatch();

  return useCallback(
    (details: QuotationDetails) => dispatch({ type: QuotationActionType.STORE, payload: details }),
    [dispatch]
  );
}

export function useClearQuotation() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: QuotationActionType.CLEAR }), [dispatch]);
}
