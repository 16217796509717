import React, { Fragment, useCallback } from 'react';
import { jsx, Box, Text, NovunaHeading, Link } from 'compass-design';
import { ContinueButton } from '../../design-components/button';
import { PageTitle, ContentHeading } from '../../design-components/heading';
import { List, ListItem } from '../../design-components/list';
import { useSaveExplanation, useStoreExplanation } from '../../store/explanation/actions';
import { Novuna } from '../../utils/staticData';
import { getPath, useNavigation } from '../Routing/useNavigation';
import { NovunaPrintPanel, PrintPanel } from '../../design-components/PrintPanel';
import { NarrowContent } from '../../design-components/NarrowContent';
import { ERROR_COMPONENTS } from '../Routing/Routes';
import { useCallbackExplanationSelector } from '../../store/explanation/selectors';
import { useApplicationSelector } from '../../store/application/selectors';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { usePreDecisionConfirmationSelector } from '../../store/preDecisionConfirmation/selectors';
import { TopUpLoanType } from '../../store/preDecisionConfirmation/types';

const AdexHeading: React.FC = ({ children }) => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <ContentHeading
        variant="tertiary"
        sx={{
          marginTop: 5,
          marginBottom: 4,
        }}
      >
        {children}
      </ContentHeading>
    );
  }
  return (
    <NovunaHeading as="h3" mb={3}>
      {children}
    </NovunaHeading>
  );
};

const AdexList: React.FC<{ isBesavvi: boolean }> = ({ children, isBesavvi }) => (
  <Box
    mb={isBesavvi ? 0 : 4}
    sx={{
      ul: { margin: '0 !important' },
    }}
  >
    <List fontSize="1">{children}</List>
  </Box>
);

export const AdequateExplanation: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const { navigateToNextStep, navigateTo } = useNavigation();
  const storeExplanationAccept = useStoreExplanation();
  const saveExplanationAccept = useSaveExplanation();
  const adequateExplanationSelector = useCallbackExplanationSelector();
  const { loanType } = usePreDecisionConfirmationSelector();

  const {
    progress: {
      explanation: { loading: explanationLoading },
    },
  } = useApplicationSelector();

  const acceptExplanation = useCallback(async () => {
    storeExplanationAccept();
    await saveExplanationAccept();

    const { error: { error } = {} } = adequateExplanationSelector();

    if (error) {
      navigateTo(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string);
    }

    navigateToNextStep();
  }, [navigateToNextStep, saveExplanationAccept, storeExplanationAccept, navigateTo, adequateExplanationSelector]);

  const details = (
    <Fragment>
      <PageTitle>About the loan</PageTitle>

      <Text mb={4}>
        You have applied for a personal loan from Novuna Personal Finance. The amount of your monthly repayment can be
        found in the ‘Repayments’ section in your credit agreement and the total amount payable can be found in the
        section headed ‘The total amount you will have to pay’.
      </Text>
      {loanType === TopUpLoanType.topUpLoan && (
        <Fragment>
          <Text mb={4}>
            If you have chosen the "top up loan" option and are approved, part of the loan will be used to settle your
            existing agreement(s); the remaining loan amount will be paid into your bank account.
          </Text>
          <Text>Please note that by consolidating your existing debt, you may:</Text>
          <AdexList isBesavvi={isBesavvi}>
            <ListItem>Pay a higher rate of interest.</ListItem>
            <ListItem>Pay more in total overall.</ListItem>
            <ListItem>Increase the term of the loan.</ListItem>
          </AdexList>
        </Fragment>
      )}

      <AdexHeading>If you miss payments</AdexHeading>
      <AdexList isBesavvi={isBesavvi}>
        <ListItem>
          We’ll need to tell Credit Reference agencies - this might affect you getting credit in the future.
        </ListItem>
        <ListItem>The total cost of your debt may grow.</ListItem>
        <ListItem>
          We might take legal action against you, and you may have to pay the cost of such proceedings.
        </ListItem>
      </AdexList>

      <AdexHeading>Changing your mind</AdexHeading>
      <AdexList isBesavvi={isBesavvi}>
        <ListItem>You can withdraw from the agreement, without giving any reason, for a period of 14 days.</ListItem>
        <ListItem>The 14 day period will begin on the day after you sign the agreement.</ListItem>
        <ListItem>You can tell us by phone or in writing (by email or post).</ListItem>
        <ListItem css={loanType === TopUpLoanType.topUpLoan ? { '@media print': { pageBreakAfter: 'always' } } : {}}>
          {loanType === TopUpLoanType.topUpLoan
            ? 'If you withdraw you’ll need to pay back the full amount of the loan, including the amount used by us to settle any existing personal loan with Novuna Personal Finance, within 30 days after the day on which you gave us notice.'
            : 'If you withdraw you’ll need to pay back the full amount of the loan, plus the relevant interest, within 30 days after the day on which you gave us notice.'}
        </ListItem>
      </AdexList>

      <AdexHeading>More information about your loan</AdexHeading>
      <AdexList isBesavvi={isBesavvi}>
        <ListItem>
          More information can be found in the Pre-Contract Credit Information which will be provided to you on the next
          page.
        </ListItem>
        <ListItem>
          If you have any questions or would like any further information, please call{' '}
          {Novuna.customerServiceContact.phoneNumber} or email{' '}
          <Link
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            href={`mailto:${Novuna.loansTeamContact.email}`}
          >
            {Novuna.loansTeamContact.email}
          </Link>
        </ListItem>
      </AdexList>

      <Box mt={4}>
        {isBesavvi ? <PrintPanel>Print a copy of this explanation for your records.</PrintPanel> : <NovunaPrintPanel />}

        <ContinueButton busy={explanationLoading} hideInPrint data-test-id="accept-button" onClick={acceptExplanation}>
          Continue
        </ContinueButton>
      </Box>
    </Fragment>
  );

  return isBesavvi ? <NarrowContent>{details}</NarrowContent> : <NovunaContainer>{details}</NovunaContainer>;
};
