import React from 'react';
import { useParams } from 'react-router-dom';
import { jsx, List, ListItem, Styled } from 'compass-design';
import { DIRECT_COMPONENTS } from '../Routing/Routes';
import { getPath } from '../Routing/useNavigation';
import { ErrorBlock } from '../Errors/Error';

interface ExpiredParams {
  supplierId?: string;
}

export const Expired: React.FC = () => {
  const { supplierId }: ExpiredParams = useParams();

  return (
    <ErrorBlock
      data-test-id="error"
      title="Your application has expired."
      message="Your application has expired and you can no longer proceed with it."
    >
      <List fontSize="1">
        <ListItem>
          {!!supplierId && (
            <Styled.a href={getPath(DIRECT_COMPONENTS.landing, { supplierId })} data-test-id="landing-link">
              Start again with a new quotation.
            </Styled.a>
          )}
        </ListItem>
      </List>
    </ErrorBlock>
  );
};
