import { SectionBuilder } from './SectionBuilder';

export const buildLegalAspects: SectionBuilder<void> = () => {
  return {
    title: 'Legal aspects',
    description: 'Other important legal aspects regarding your rights',
    testId: 'legal-aspects',
    items: [
      {
        label: 'Right of withdrawal',
        value:
          'You will have 14 days to withdraw from this credit agreement after it is made. The 14 day period will begin on the day after the agreement is made (which is when it is signed by you).',
      },
      {
        label: 'Early repayment',
        value: 'You have a right to repay the credit early at any time in full or partially.',
      },
      {
        label: 'Consultation with a Credit Reference Agency',
        value:
          'If we reject your credit application on the basis of information obtained from a credit reference agency we must tell you this and must give you details of that agency.',
      },
      {
        label: 'Right to a draft credit agreement',
        value:
          'You have a right upon request to obtain a copy of the draft agreement free of charge. In the event your application is declined we will not provide you with a copy of the agreement.',
      },
    ],
  };
};
