import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { PreDecisionConfirmationActionType, PreDecisionConfirmationState } from './types';

export function useStorePreDecisionConfirmation() {
  const dispatch = useDispatch();

  return useCallback(
    (preDecision: PreDecisionConfirmationState) =>
      dispatch({
        type: PreDecisionConfirmationActionType.STORE,
        payload: preDecision,
      }),
    [dispatch]
  );
}
