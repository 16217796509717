import { useSelector } from 'react-redux';
import { PersonalDetails } from '@plending/interfaces/application';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RemoteState } from '../types';

const selectPersonalDetails = ({ personalDetails }: RootState): RootState['personalDetails'] => personalDetails;

export function usePersonalDetailsSelector(): PersonalDetails {
  return useSelector(selectPersonalDetails);
}

const selectCallbackPersonalDetails = ({ application }: RootState): RemoteState => application.progress.personalDetails;

export function useCallbackPersonalDetailsSelector(): () => RemoteState {
  return useCallbackSelector(selectCallbackPersonalDetails);
}
