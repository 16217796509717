import { UiConfig } from '@plending/interfaces/bootstrap-data';

export enum SessionActionType {
  START_TIMER = 'session/START_TIMER',
  STOP_TIMER = 'session/STOP_TIMER',
}

export interface SessionAction {
  type: SessionActionType;
}

export interface SessionState {
  startedAt?: number;
}

export interface SessionDetails extends SessionState {
  inactivityTimeoutMs?: UiConfig['inactivityTimeoutMs'];
  expiresAt?: number;
  secondsLeft?: number;
}
