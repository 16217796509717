import { Application } from '@plending/interfaces/application';
import { Error } from '@plending/interfaces/error';
import { RemoteAction, RemoteState } from '../types';

export enum AggregatorActionType {
  CLEAR = 'aggregator/CLEAR',
  REQUEST = 'aggregator/REQUEST',
  LOAD = 'aggregator/LOAD',
  FAILED = 'aggregator/FAILED',
}

export interface AggregatorAction extends RemoteAction {
  type: AggregatorActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface AggregatorState extends RemoteState {
  loading: boolean;
  error?: Error;
  data?: {
    redirectUrl: string;
  };
}

export interface AggregatorResponse {
  data: {
    application: Application;
  };
}
