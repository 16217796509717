import { ExistingAgreementDetails } from '@plending/interfaces/application';

export enum ExistingAgreementDetailsActionType {
  STORE = 'existing-agreement-details',
}

export interface ExistingAgreementDetailsAction {
  type: ExistingAgreementDetailsActionType;
  payload: ExistingAgreementDetails;
}
