import React from 'react';
import { jsx, Text, Box } from 'compass-design';

import { FormHeading } from '../../design-components/heading';
import { FieldPropSet, FieldCollection } from '../../validation/types';
import { CheckBoxInput } from '../../design-components/inputs/CheckboxInput';

interface AltLoanOptInProps {
  fieldProps: FieldCollection<FieldPropSet>;
}

export const AltLoanOptIn: React.FC<AltLoanOptInProps> = ({ fieldProps }) => (
  <Box data-test-id="alternativeLoanProviderOptInSection">
    <FormHeading>Alternative loan or credit card</FormHeading>
    <Text>
      Should you be unsuccessful in your application, our partner Aro may be able to find you an alternative loan or
      credit card. An Aro quote will not affect your credit score and will allow you to compare products and rates from
      a range of providers.
    </Text>
    <br />
    <Text mb={4}>
      Aro can offer a range of products including unsecured personal loans, mortgages, second charge mortgages and
      credit cards. If you decide to proceed with one of the products offered to you, Novuna will receive up to 40% of
      the commission amount Aro earn.
    </Text>
    <CheckBoxInput {...fieldProps.alternativeLoanProviderOptIn}>
      Tick the box if you would like your information to be passed to Aro if we are unable to offer you a loan.
    </CheckBoxInput>
  </Box>
);
