import React, { FC } from 'react';
import { jsx } from 'compass-design';

export const NextSteps: FC<{ isProvisionalAccept?: boolean }> = ({ isProvisionalAccept }) => (
  <>
    Once you have read and considered the important information on the next couple of pages you will be able to e-sign
    your credit agreement.
    {isProvisionalAccept && <> Once you have e-signed we will complete our final security checks.</>}
  </>
);

export interface DecisionTextProps {
  isProvisionalAccept?: boolean;
  isPartner: boolean;
  isNotTypical: boolean;
}
export const DecisionText: FC<DecisionTextProps> = ({ isProvisionalAccept, isPartner, isNotTypical }) => {
  const accepted = <>Congratulations, your loan application has been accepted! {<NextSteps />}</>;
  const acceptedDifferentApr = (
    <>Congratulations, your loan application has been accepted, but at a different APR than initially quoted.</>
  );
  const provisionalDifferentApr = (
    <>Your loan application has been accepted, but at a different APR than initially quoted.</>
  );
  const provisional = <>Your loan application has been accepted. {<NextSteps isProvisionalAccept />}</>;

  if (isPartner) {
    return isProvisionalAccept ? provisional : accepted;
  }

  if (isProvisionalAccept) {
    return isNotTypical ? provisionalDifferentApr : provisional;
  }

  return isNotTypical ? acceptedDifferentApr : accepted;
};
