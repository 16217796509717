import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { jsx, Message, NovunaHeading, Styled } from 'compass-design';
import { PageTitle, ProgressHeading } from '../../design-components/heading';
import { ErrorContact } from '../Errors/ErrorContact';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';

interface CancelledParams {
  applicationId?: string;
}

export const Cancelled: React.FC = () => {
  const { applicationId }: CancelledParams = useParams();
  const isBesavvi = useIsBeSavvi();

  return (
    <>
      {isBesavvi ? (
        <Fragment>
          <PageTitle>Application</PageTitle>

          <ProgressHeading>Cancelled</ProgressHeading>

          <Styled.p data-test-id="cancelled-message">
            Your application
            {applicationId && <> (reference {applicationId})</>}
            &nbsp;has been cancelled.
          </Styled.p>

          <p>
            <ErrorContact />
          </p>
        </Fragment>
      ) : (
        <NovunaContainer>
          <PageTitle>Application Cancelled</PageTitle>
          <Message variant="error">
            <NovunaHeading as="h3" data-test-id="cancelled-message">
              Your application
              {applicationId && <> (reference {applicationId})</>}
              &nbsp;has been cancelled.
            </NovunaHeading>

            <p>
              <ErrorContact />
            </p>
          </Message>
        </NovunaContainer>
      )}
    </>
  );
};
