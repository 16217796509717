import { matchPath, useHistory, generatePath } from 'react-router';
import { useMemo } from 'react';
import { RouteSpec, DIRECT_JOURNEY, ALL_ROUTES } from './Routes';

interface LocationState {
  location: { pathname: string };
}

function routerFinder({ location: { pathname } }: LocationState) {
  return ({ path }: RouteSpec) => matchPath(pathname, { path, exact: true });
}

export function getCurrentRouteIndex(history: LocationState, routeList: RouteSpec[]) {
  const index = routeList.findIndex(routerFinder(history));

  return index;
}

export function getRouteIndex(pathname: string, routeList: RouteSpec[] = DIRECT_JOURNEY) {
  return getCurrentRouteIndex({ location: { pathname } }, routeList);
}

export function getRoute(pathname: string, routeList: RouteSpec[] = ALL_ROUTES) {
  const index = getRouteIndex(pathname, routeList);

  return routeList[index];
}

function getNextRoute(history: LocationState, routeList: RouteSpec[]) {
  let nextRoute;

  const index = getCurrentRouteIndex(history, routeList);

  // next is not allowed on any error page
  // (probably won't get called, but worth the protection)
  if (index !== -1 && index + 1 < routeList.length) {
    nextRoute = routeList[index + 1];
  }

  return nextRoute;
}

function getPreviousRoute(history: LocationState, routeList: RouteSpec[]) {
  let previousRoute;

  const index = getCurrentRouteIndex(history, routeList);

  if (index !== -1 && index - 1 > 0) {
    previousRoute = routeList[index - 1];
  }

  return previousRoute;
}

/**
 * Hook to derive the path of the next step form the path of the current one.
 */
export function useNavigation(routeList: RouteSpec[] = DIRECT_JOURNEY) {
  const history = useHistory();

  return useMemo(
    () => ({
      /** Returns the next route definition (if there is one), so that it can be queried for meta data */
      get nextRoute(): RouteSpec | undefined {
        return getNextRoute(history, routeList);
      },
      /** Returns the path of the next route (if there is one), for direct use in (eg) a `Link` */
      get nextRoutePath(): string | undefined {
        const { path } = getNextRoute(history, routeList) || {};

        return path;
      },
      /** Returns the path of the next route (if there is one), for direct use in (eg) a `Link` */
      get previousRoute(): RouteSpec | undefined {
        return getPreviousRoute(history, routeList);
      },
      /** can be called to navigate onwards, in (eg) a callback */
      navigateToNextStep() {
        const nextRoute = getNextRoute(history, routeList);

        if (nextRoute) {
          history.push(nextRoute.path);
        }
      },
      /** can be called to navigate onwards, in (eg) a callback */
      replaceWithNextStep() {
        const nextRoute = getNextRoute(history, routeList);

        if (nextRoute) {
          history.replace(nextRoute.path);
        }
      },
      get navigationIndex() {
        return getCurrentRouteIndex(history, routeList);
      },
      navigateTo(path: string) {
        history.push(path);
      },
      replaceWith(path: string) {
        history.replace(path);
      },
    }),
    [history, routeList]
  );
}

export function getPath(route: RouteSpec, args: Record<string, string | number>): string {
  const { path } = route;
  return generatePath(path, args);
}
