import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useFetchGet } from '../utils/fetch';
import { useCallbackAlternativeLoanDataSelector } from './selectors';
import { AlternativeLoanActionType, AlternativeLoanResponse } from './types';

const extractAlternativeLoanData = (d: AlternativeLoanResponse) => d.data;

export function useFetchAlternativeLoanData() {
  return useFetchGet(
    '/alternative-loan-provider-decision',
    useCallbackAlternativeLoanDataSelector(),
    AlternativeLoanActionType,
    extractAlternativeLoanData
  );
}

export function useClearAlternativeLoanData() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: AlternativeLoanActionType.CLEAR }), [dispatch]);
}
