export enum PrivacyActionType {
  STORE = 'privacy/STORE',
}

export interface PrivacyAction {
  type: PrivacyActionType;
  payload?: PrivacyState;
}

export interface PrivacyState {
  accepted: string;
}
