import { DateGroup, AtSinceGroup } from '@plending/interfaces/application';
import { calculateDurationByMonthAndYear } from './date-helper';
import { cacheableRule } from './utils';
import { RuleFunction, RuleFunctionFactory } from './types';

/**
 *
 * Inform BI when changing validation rules, as they will need to update the validation on their end
 *
 * Update this page:
 * https://iw-novuna.atlassian.net/wiki/spaces/CPL/pages/1857748993/Field+Validation
 *
 * Notify novuna-plending-bi of the change
 *
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isProvided(value: any) {
  return !!value && !!`${value}`.trim();
}

export const Required: RuleFunction = (value) => {
  if (!isProvided(value)) {
    return 'Required';
  }
};
const BOOLEAN_REGEX = /^(true|false)$/;
export const RequiredBoolean: RuleFunction = (value) => {
  if (!BOOLEAN_REGEX.test(`${value}`.trim())) {
    return 'RequiredBoolean';
  }
};

const NUMBER_REGEX = /^[0-9]+(?:\.[0-9]+)?$/;
export const IsNumber: RuleFunction = (value) => {
  if (!NUMBER_REGEX.test(`${value}`.trim())) {
    return 'Not a number';
  }
};

const ONE_DECIMAL_REGEX = /^\d+(\.\d{0,1})?$/;
export const Is1Decimal: RuleFunction = (value) => {
  if (!ONE_DECIMAL_REGEX.test(`${value}`.trim())) {
    return 'Invalid amount';
  }
};

const TWO_DECIMAL_REGEX = /^\d+(\.\d{0,2})?$/;
export const Is2Decimal: RuleFunction = (value) => {
  if (!TWO_DECIMAL_REGEX.test(`${value}`.trim())) {
    return 'Invalid amount';
  }
};

const WHOLE_NUMBER_REGEX = /^[1-9]\d*$/;
export const IsNotDecimal: RuleFunction = (value) => {
  if (!WHOLE_NUMBER_REGEX.test(`${value}`.trim())) {
    return 'Must contain only pounds e.g £10000';
  }
};

export function numberTransform(value: string) {
  return value.replace(/[^0-9]/g, '');
}

// Taken from AJV - https://github.com/ajv-validator/ajv-formats/blob/ce49433448384b4c0b2407adafc345e43b85f8ea/src/formats.ts#L51
const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const IsEmail: RuleFunction = (value) => {
  if (!EMAIL_REGEX.test(`${value}`.trim())) {
    return 'Not an email';
  }
};

const PHONE_REGEX = /^0[0-9]{9,10}?$/;
const PHONE_VALID_STRIP_REGEX = /[ -]/g;

export const IsPhone: RuleFunction = (value) => {
  if (isProvided(value) && !PHONE_REGEX.test(`${value}`.replace(PHONE_VALID_STRIP_REGEX, ''))) {
    return 'Not a phone number';
  }
};

const MOBILE_PHONE_REGEX = /^07[0-9]{9}?$/;

export const IsMobilePhone: RuleFunction = (value) => {
  if (isProvided(value) && !MOBILE_PHONE_REGEX.test(`${value}`.replace(PHONE_VALID_STRIP_REGEX, ''))) {
    return 'Not a mobile phone number';
  }
};

export const MinValue: RuleFunctionFactory = cacheableRule((arg, value) => {
  if (parseFloat(value) < arg) {
    return 'Too small';
  }
});

export const MaxValue: RuleFunctionFactory = cacheableRule((arg, value) => {
  if (parseFloat(value) > arg) {
    return 'Too large';
  }
});

export const MinLength: RuleFunctionFactory = cacheableRule((arg, value) => {
  if (`${value}`.length < arg) {
    return 'Too short';
  }
});

export const MaxLength: RuleFunctionFactory = cacheableRule((arg, value) => {
  if (isProvided(value) && `${value}`.length > arg) {
    return 'Too long';
  }
});

function dateFromValue(value: DateGroup) {
  const { day = '', month = '', year = '' } = value;
  const [yearNum, monthNum, dayNum] = [year, month, day].map((n) => parseInt(n, 10));
  const date = new Date(yearNum, monthNum - 1, dayNum);

  return {
    day,
    month,
    year,
    dayNum,
    monthNum,
    yearNum,
    date,
  };
}

export const MinAge: RuleFunctionFactory = cacheableRule((minAge, value: DateGroup) => {
  const latestDate = new Date(); // now
  latestDate.setHours(23, 59, 59); // end of the day (today might be my birthday)
  latestDate.setFullYear(latestDate.getFullYear() - (minAge as number));

  const { date: enteredDate } = dateFromValue(value);

  if (enteredDate > latestDate) {
    return 'Too young';
  }
});

export const LaterThan: RuleFunctionFactory = cacheableRule((date, value: DateGroup) => {
  const [year, month = '1', day = '1'] = (date as string).split('/');
  const { date: earliestDate, year: earliestYear } = dateFromValue({ day, month, year });
  const { date: enteredDate, year: enteredYear } = dateFromValue(value);

  if (enteredDate <= earliestDate || (enteredYear && enteredYear < earliestYear)) {
    return 'Too early';
  }
});

// See HPL-1597 for context on BFPO numbers and postcodes
const POSTCODE_VALID_STRIP_REGEX = / /g;
const POSTCODE_REGEX = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?[0-9][A-Z]{2}$/;

export const IsPostcode: RuleFunction = (value) => {
  const testValue = `${value}`.replace(POSTCODE_VALID_STRIP_REGEX, '').toUpperCase();

  if (!POSTCODE_REGEX.test(testValue)) {
    return 'Invalid postcode';
  }
};

const SORT_CODE_REGEX = /^(\d){6}$/;
export const IsSortCode: RuleFunction = (value) => {
  if (!SORT_CODE_REGEX.test(value)) {
    return 'Invalid Sort Code';
  }
};

const ACCOUNT_NUMBER_REGEX = /^(\d){7,8}$/;
export const IsAccountNumber: RuleFunction = (value) => {
  if (!ACCOUNT_NUMBER_REGEX.test(value)) {
    return 'Invalid Account Number';
  }

  if (value.length === 7) {
    return 'Invalid 7 digit Account Number';
  }
};

const AGREEMENT_NUMBER_REGEX = /^(\d){9}$/;
export const IsAgreementNumber: RuleFunction = (value) => {
  if (!AGREEMENT_NUMBER_REGEX.test(value)) {
    return 'Invalid Agreement Number';
  }
};

export const IsAtSince = (value: AtSinceGroup) => {
  if (!value) {
    return 'Required';
  }

  const { years } = calculateDurationByMonthAndYear(value);
  if (parseInt(years, 10) >= 100) {
    return 'Longer than 100 years';
  }

  const { year = '', month = '' } = value;

  const error =
    Required(month) ||
    Required(year) ||
    IsNumber(month) ||
    IsNumber(year) ||
    MinValue(1000)(year) ||
    MinValue(1)(month) ||
    MaxValue(12)(month);

  if (error) {
    return error;
  }
};

export const IsSinceBeforeToday: RuleFunctionFactory = cacheableRule(
  (nowFn: () => number, { month, year }: AtSinceGroup) => {
    const today = new Date(nowFn());
    today.setDate(1);
    today.setHours(0, 0, 0, 0);

    const { date: enteredDate } = dateFromValue({ day: '1', month, year });

    if (enteredDate > today) {
      return 'After today';
    }
  }
);

export const IsSinceLaterThan: RuleFunctionFactory = cacheableRule(
  (
    { month: earliestMonth, year: earliestYear }: AtSinceGroup,
    { month: enteredMonth, year: enteredYear }: AtSinceGroup
  ) => {
    const { date: earliestDate } = dateFromValue({
      day: '1',
      month: earliestMonth,
      year: earliestYear,
    });

    const { date: enteredDate } = dateFromValue({
      day: '1',
      month: enteredMonth,
      year: enteredYear,
    });

    if (enteredDate < earliestDate) {
      return 'Too early';
    }
  }
);

function shortFormat(dd: number, mm: number, yy: number) {
  return [dd, mm, yy % 100].map((n) => `${n}`.padStart(2, '0')).join('/');
}

export const IsDate = (value: DateGroup) => {
  if (!value) {
    return 'Required';
  }

  const { day, month, year, dayNum, monthNum, yearNum, date } = dateFromValue(value);

  // basic validation
  const error =
    Required(day) ||
    Required(month) ||
    Required(year) ||
    MinLength(4)(year) ||
    IsNumber(day) ||
    IsNumber(month) ||
    IsNumber(year);

  if (error) {
    return error;
  }

  // check that it's a valid date
  try {
    // Check for "Invalid Date"
    if (isNaN(date.getTime())) {
      return 'Invalid';
    }

    // JS will happily convert 99th day of 99th month 2000 to 20 August 2012, so we need to catch that
    // build "dd/mm/yy" for the input...
    const input = shortFormat(dayNum, monthNum, yearNum);

    // ...and the Date parsed from it...
    const parsed = shortFormat(date.getDate(), date.getMonth() + 1, date.getFullYear());

    // ...to check that they are the same
    if (input !== parsed) {
      return 'Invalid';
    }
  } catch (e) {
    // belt and braces - its possible that some of the above might throw
    //an error for some input values
    return 'Invalid';
  }
};

export const IsChecked: RuleFunction = (value) => {
  if (!value) {
    return 'Mandatory';
  }
};

const VALID_NAME_CHARS = /^[A-z]([A-z\040\047\055]*[A-z])?$/;
export const ValidNameChars: RuleFunction = (value) => {
  if (value && !VALID_NAME_CHARS.test(value)) {
    return 'Value should start and end with a letter, and contain only letters, hyphens, apostrophes and spaces.';
  }
};

const VALID_USERNAME_CHARS = /^[a-zA-Z]([A-z0-9\040\047\055.!#$%&*+\\/=?^_`{|}~-]*)+@?[a-zA-Z0-9.-]*$/;
export const ValidUsernameChars: RuleFunction = (value) => {
  if (value && !VALID_USERNAME_CHARS.test(value)) {
    return 'Value should start with a letter and contain only characters valid in an email.';
  }
};
