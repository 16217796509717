import { PrivacyAction, PrivacyState, PrivacyActionType } from './types';

export function reducer(state = {}, { type, payload }: PrivacyAction): Partial<PrivacyState> {
  switch (type) {
    case PrivacyActionType.STORE: {
      return payload as PrivacyState;
    }
    default:
      return state;
  }
}
