import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useFetchGet } from '../utils/fetch';
import { useCallbackBootstrapDataSelector } from './selectors';
import { BootstrapActionType, BootstrapResponse } from './types';

const extractBootstrapData = (d: BootstrapResponse) => d.data;

export function useFetchBootstrapData(supplierId: string) {
  return useFetchGet(
    `/bootstrap/${supplierId}`,
    useCallbackBootstrapDataSelector(),
    BootstrapActionType,
    extractBootstrapData
  );
}

export function useClearBootstrapData() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: BootstrapActionType.CLEAR }), [dispatch]);
}
