import { jsx, Message, NovunaHeading } from 'compass-design';
import React, { useEffect, useState } from 'react';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { PageTitle } from '../../design-components/heading';

export const Waiting: React.FC = ({ children }) => {
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTimeoutMessage(true);
    }, 31000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return showTimeoutMessage ? (
    <NovunaContainer>
      <PageTitle>Application decision</PageTitle>
      <Message variant="error">
        <NovunaHeading>It's taking longer than usual</NovunaHeading>
        <p>We are working on getting you a decision and will notify you by email as soon as we have it.</p>
      </Message>
    </NovunaContainer>
  ) : (
    <>{children}</>
  );
};
