import { Reducer, AnyAction } from 'redux';

export function chainReducers(reducers: Reducer[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (state: any, action: AnyAction) {
    let intermediateState = state;

    reducers.forEach((reducer) => {
      intermediateState = reducer(intermediateState, action);
    });

    return intermediateState;
  };
}
