import { jsx, NovunaHeading } from 'compass-design';
import React from 'react';
import { NovunaContainer } from '../../design-components/NovunaContainer';

export const Loading: React.FC = () => (
  <NovunaContainer>
    <NovunaHeading data-test-id="page-title">Loading...</NovunaHeading>
    <p>Please be patient, this might take a few seconds.</p>
  </NovunaContainer>
);
