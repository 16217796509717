export enum ExplanationActionType {
  STORE = 'explanation/STORE',
  CLEAR = 'explanation/CLEAR',
  REQUEST = 'explanation/REQUEST',
  LOAD = 'explanation/LOAD',
  FAILED = 'explanation/FAILED',
}

export interface ExplanationAction {
  type: ExplanationActionType;
  payload?: ExplanationState;
}

export interface ExplanationState {
  accepted: string;
}
