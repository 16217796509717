import { Supplier, BootstrapData, Product } from '@plending/interfaces/bootstrap-data';
import { DecisionClientResponse } from '@plending/interfaces/decision';
import { StoreEnhancer, Reducer } from 'redux';
import { RouterState, LOCATION_CHANGE } from 'connected-react-router';

import { ApplicationStatus } from '@plending/interfaces/application';
import { penceToPoundsNoSymbol } from '@plending/validation/quote-utils';
import { BootstrapActionType, BootstrapState } from '../store/bootstrap/types';
import { QuotationActionType, QuotationDetails } from '../store/quotation/types';
import { DecisionActionType, DecisionState } from '../store/decision/types';
import { ResumeActionType, ResumeState, ResumeData } from '../store/resume/types';
import { RootState } from '../store/rootState';
import { internationaliseNumber, removeWhiteSpace } from './formatters';

declare const dataLayer: Record<string, unknown>[];

export type AnalyticsEvent = 'virtualPageView';
export type LayerData = Record<string, unknown>;

export type DataLayerValueMap = {
  [key: string]: (value: string) => string;
};

function reduxDevTools() {
  return (
    (process.env.NODE_ENV === 'development' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
    undefined
  );
}

export function addLayerData(data: LayerData) {
  // only push if there's something to push, and somewhere to push it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).dataLayer && dataLayer.push && data && Object.keys(data).length) {
    dataLayer.push(data);
  }
}

export const DATA_LAYER_VALUE_MAP: DataLayerValueMap = {
  decision: (value: string): string => {
    return value
      .split('_')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
      .join('');
  },
};

export const transformDataLayerValue = (key: string, value: string) => {
  if (DATA_LAYER_VALUE_MAP[key]) {
    return DATA_LAYER_VALUE_MAP[key](value);
  }

  return value;
};

export const buildUrl = (router: RouterState, data: Record<string, string>): string => {
  const params: string = Object.keys(data)
    .reduce<Array<string>>((acc, key: string) => {
      if (data[key]) {
        return acc.concat(`${key}=${transformDataLayerValue(key, data[key])}`);
      }

      return acc;
    }, [])
    .join('&');

  return `${router.location.pathname}?${params}`;
};

/*
  Set this up as a raw enhancer so that we catch initialisation as well as dispatched actions
 */

// Lot's of `any`s here because Redux...
/* eslint-disable @typescript-eslint/no-explicit-any */
export const dataLayerReduxEnhancer: StoreEnhancer = (createStore: any) => (reducer: any, initialState: any) => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  setDataLayerFromReduxState(initialState, 'initial');

  const monitoredReducer: Reducer = (state, action) => {
    const newState = reducer(state, action);
    setDataLayerFromReduxState(newState, action.type);

    return newState;
  };

  return createStore(monitoredReducer, initialState, reduxDevTools());
};

export function setDataLayerFromReduxState(reduxState: Partial<RootState> = {}, trigger: string) {
  let newLayerData: Record<string, unknown> = {};

  // only need to react to initialisation or the dispatch of specific actions
  function shouldTrigger(desired: string, test: string | number) {
    return !!test && ['initial', desired].includes(trigger);
  }

  const {
    router = {
      location: {
        pathname: '/',
      },
    } as RouterState,
    bootstrapData: {
      data: {
        product: { channel, subChannel } = {} as Product,
        supplier: { id: supplierId, name: supplierName } = {} as Supplier,
      } = {} as BootstrapData,
    } = {} as BootstrapState,
    quotation: { loanPence } = {} as QuotationDetails,
    decision: { data: { applicationId, decision, offer } = {} as DecisionClientResponse } = {} as DecisionState,
    resume: { data: { redirectUrl, pathName } = {} as ResumeData } = {} as ResumeState,
    personalDetails,
  } = reduxState;

  if (shouldTrigger(BootstrapActionType.LOAD, supplierId)) {
    newLayerData = {
      ...newLayerData,
      supplierId,
      supplierName,
      channel,
      subChannel,
    };
  }

  if (shouldTrigger(QuotationActionType.STORE, loanPence)) {
    newLayerData = {
      ...newLayerData,
      loanAmount: loanPence / 100,
    };
  }

  if (shouldTrigger(DecisionActionType.LOAD, applicationId)) {
    newLayerData = {
      ...newLayerData,
      event: 'virtualPageView' as AnalyticsEvent,
      applicationId,
      decision,
      virtualUrl: buildUrl(router, { supplierId, decision }),
    };

    const { email, firstName, lastName, phone, currentAddress } = personalDetails || {};
    const postcode = currentAddress?.address?.postcode;

    const shouldShowLoanDetails =
      decision == ApplicationStatus.ACCEPT || decision == ApplicationStatus.PROVISIONAL_ACCEPT;
    const apr = shouldShowLoanDetails ? offer?.apr?.toString() : undefined;
    const term = shouldShowLoanDetails ? offer?.termMonths?.toString() : undefined;
    const totalPayable = shouldShowLoanDetails
      ? offer?.totalPence && penceToPoundsNoSymbol(offer.totalPence)
      : undefined;

    newLayerData = {
      ...newLayerData,
      email,
      'phone_number': phone && removeWhiteSpace(internationaliseNumber(phone)),
      'address.first_name': firstName,
      'address.last_name': lastName,
      'address.postcode': postcode && removeWhiteSpace(postcode),
      'address.country': 'GB',
      apr,
      term,
      'total_payable': totalPayable,
    };
  }

  if (shouldTrigger(ResumeActionType.LOAD, redirectUrl)) {
    const step = pathName ?? 'Decision';
    newLayerData = {
      ...newLayerData,
      applicationStep: step,
      applicationResumed: true,
      applicationId,
    };
  }

  if (shouldTrigger(LOCATION_CHANGE, supplierId || decision)) {
    newLayerData = {
      ...newLayerData,
      event: 'virtualPageView' as AnalyticsEvent,
      virtualUrl: buildUrl(router, { supplierId, decision }),
    };
  }

  addLayerData(newLayerData);
}
