import {
  EmploymentStatus,
  EmploymentStatusList,
  NumberOfDependantsList,
  MaritalStatus,
  workingEmploymentStatuses,
  partnerMaritalStatuses,
  nonWorkingEmploymentStatuses,
} from '@plending/interfaces/enums';
import { BankDetails } from '@plending/interfaces/bank-check';
import { DateGroup } from '@plending/interfaces/application';
import {
  Required,
  IsPhone,
  IsNumber,
  IsSortCode,
  IsAccountNumber,
  IsSinceBeforeToday,
  isProvided,
  IsAtSince,
  IsSinceLaterThan,
  MaxLength,
  IsNotDecimal,
} from './rules';
import { RuleFunction } from './types';
import { listRule, AllOf } from './utils';

export const BANK_CHECK_ERROR_MESSAGE = 'Bank details not found';

export const FinancialDetailMaxLengthValidation = {
  OCCUPATION: 25,
  PARTNER_OCCUPATION: 25,
  EMPLOYER: 30,
  BANK_HOLDER_NAME: 200,
  PHONE: 16,
  SORT_CODE: 6,
  ACCOUNT_NUMBER: 8,
  ANNUAL_INCOME: 7,
};

export const IsEmploymentStatus: RuleFunction = listRule(EmploymentStatusList);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IsValidBankCheck = (value?: Partial<BankDetails>) => {
  if (value && isProvided(value)) {
    // Required should be applied separately
    if (Required(value.sortCode) && Required(value.accountNumber)) {
      return 'Account Details required';
    }

    if (Required(value.sortCode)) {
      return 'SortCode required';
    }

    if (Required(value.accountNumber)) {
      return 'AccountNumber required';
    }

    return IsSortCode(value.sortCode) || IsAccountNumber(value.accountNumber);
  }
};

export const IsNumberOfDependants: RuleFunction = listRule(NumberOfDependantsList);

export const getConditionalFieldStatus = (
  maritalStatusValue: MaritalStatus,
  employmentStatusValue: EmploymentStatus,
  partnerEmploymentStatusValue?: EmploymentStatus
) => {
  const occupation = workingEmploymentStatuses.includes(employmentStatusValue);
  const employerName = workingEmploymentStatuses.includes(employmentStatusValue);
  const employerPhoneNumber = workingEmploymentStatuses.includes(employmentStatusValue);

  const partnerEmploymentStatus =
    partnerMaritalStatuses.includes(maritalStatusValue) && nonWorkingEmploymentStatuses.includes(employmentStatusValue);

  const partnerOccupation =
    partnerEmploymentStatus &&
    !!partnerEmploymentStatusValue &&
    workingEmploymentStatuses.includes(partnerEmploymentStatusValue);

  return {
    partnerEmploymentStatus,
    occupation,
    employerName,
    employerPhoneNumber,
    partnerOccupation,
  };
};

export function ValidateFinancialDetails(
  {
    partnerEmploymentStatus,
    partnerOccupation,
    occupation,
    employerName,
  }: {
    partnerEmploymentStatus: EmploymentStatus;
    partnerOccupation: string;
    occupation: string;
    employerName: string;
  },
  pageFields: Record<string, boolean>
) {
  let errors = {} as Record<string, string | undefined>;

  if (pageFields.partnerEmploymentStatus) {
    errors = {
      ...errors,
      partnerEmploymentStatus: AllOf(Required, IsEmploymentStatus)(partnerEmploymentStatus),
    };
  }

  if (pageFields.partnerOccupation && pageFields.partnerEmploymentStatus) {
    errors = {
      ...errors,
      partnerOccupation: AllOf(
        Required,
        MaxLength(FinancialDetailMaxLengthValidation.PARTNER_OCCUPATION)
      )(partnerOccupation),
    };
  }

  if (pageFields.occupation) {
    errors = {
      ...errors,
      occupation: AllOf(Required, MaxLength(FinancialDetailMaxLengthValidation.OCCUPATION))(occupation),
    };
  }

  if (pageFields.employerName) {
    errors = {
      ...errors,
      employerName: AllOf(Required, MaxLength(FinancialDetailMaxLengthValidation.EMPLOYER))(employerName),
    };
  }

  return errors;
}

export const FinancialDetailsFieldRules = {
  employmentStatus: [Required, IsEmploymentStatus],
  workPhoneNumber: [IsPhone, MaxLength(FinancialDetailMaxLengthValidation.PHONE)],
  annualIncomeBeforeTax: [
    Required,
    IsNumber,
    IsNotDecimal,
    MaxLength(FinancialDetailMaxLengthValidation.ANNUAL_INCOME),
  ],
  numberOfDependants: [Required, IsNumberOfDependants],
  bankHolderName: [Required, MaxLength(FinancialDetailMaxLengthValidation.BANK_HOLDER_NAME)],
  bankCheckDetails: [Required, IsValidBankCheck],
  withBankSince(nowFn: () => number, dob: DateGroup) {
    return [IsAtSince, IsSinceBeforeToday(nowFn), IsSinceLaterThan(dob)];
  },
  sortCode: [Required, IsSortCode, MaxLength(FinancialDetailMaxLengthValidation.SORT_CODE)],
  accountNumber: [Required, IsAccountNumber, MaxLength(FinancialDetailMaxLengthValidation.ACCOUNT_NUMBER)],
  occupation: [MaxLength(FinancialDetailMaxLengthValidation.OCCUPATION)],
  partnerOccupation: [MaxLength(FinancialDetailMaxLengthValidation.PARTNER_OCCUPATION)],
};
