import { ApplicationStatus } from '@plending/interfaces/application';
import { Decision } from '@plending/interfaces/decision';
import React, { useMemo } from 'react';
import { jsx } from 'compass-design';
import { useDecisionSelector } from '../../store/decision/selectors';
import { DecisionState } from '../../store/decision/types';
import { UnhandledDecision } from '../Decision/results/Unhandled';
import { AcceptAgreement } from './results/AcceptAgreement';
import { ProvisionalAcceptAgreement } from './results/ProvisionalAcceptAgreement';

const Components: Record<string, React.ElementType> = {
  [ApplicationStatus.PROVISIONAL_ACCEPT]: ProvisionalAcceptAgreement,
  [ApplicationStatus.ACCEPT]: AcceptAgreement,
};

export const ShowAgreement: React.FC = () => {
  const { data: { decision: decisionString } = {} as Decision }: DecisionState = useDecisionSelector();

  const Component = useMemo(() => {
    return Components[decisionString] || UnhandledDecision;
  }, [decisionString]);

  return (
    <>
      <Component />
    </>
  );
};
