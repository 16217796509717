import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { RootState } from '../rootState';

/**
 * Returns a selector function that can be called asyncronously (ie outside the Hook lifecycle)
 * Useful for those situations where plain `useSelector` can't be used because;
 *  * It's a hook so can't be used outside the top level of a component or a hook, eg in a callback.
 *  * it returns the data, and so can't be used when referential integrity is important ( eg to provide data for `useMemo` or `useEffect`).
 * @param selectorFn Called by the returned function with the current root state, plus any arguments passed verbatim from the call.
 * @returns a function that returns the selected state. Any arguments will be passed verbatim to the `selectorFn`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCallbackSelector = <A extends any[], S>(
  selectorFn: (state: RootState, ...args: A) => S
): ((...args: A) => S) => {
  const store = useStore();

  return useCallback((...args) => selectorFn(store.getState(), ...args), [selectorFn, store]);
};
