import React, { useMemo, useCallback, useEffect, Fragment } from 'react';
import { AddressDetails } from '@plending/interfaces/application';
import { Required } from '@plending/validation/rules';
import { AddressMaxLengthValidation } from '@plending/validation/my-details-rules';
import {
  jsx,
  Box,
  ReadOnlyWrapper,
  TextButton,
  Button,
  Card,
  Continue,
  TertiaryLink,
  TertiaryLinkDirection,
} from 'compass-design';
import { formatPostCode } from '@plending/common/formatters';
import { useValidation } from '../../../validation/useValidation';
import { simpleOptionalField, simpleRequiredField, normalisePunctuation } from '../../../validation/utils';
import { FieldSpec, FieldValues } from '../../../validation/types';
import { SingleInput } from '../SingleInput';
import { ContentHeading } from '../../heading';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';
import { AddressComponentProps } from './types';
import { AddressError } from './AddressError';

const fields: {
  fieldName: keyof AddressDetails;
  fieldLabel: string;
  halfWidth?: boolean;
  required?: boolean;
  maxLength?: number;
  handleValueChange?: (v: string) => void;
}[] = [
  {
    fieldName: 'houseNumber',
    fieldLabel: 'House Number',
    halfWidth: true,
    maxLength: AddressMaxLengthValidation.HOUSE_NUMBER,
  },
  {
    fieldName: 'flatName',
    fieldLabel: 'Flat',
    maxLength: AddressMaxLengthValidation.FLAT,
  },
  {
    fieldName: 'houseName',
    fieldLabel: 'House Name',
    maxLength: AddressMaxLengthValidation.HOUSE_NAME,
  },
  {
    fieldName: 'street',
    fieldLabel: 'Street',
    maxLength: AddressMaxLengthValidation.STREET,
  },
  {
    fieldName: 'town',
    fieldLabel: 'Town',
    required: true,
    maxLength: AddressMaxLengthValidation.TOWN,
  },
  {
    fieldName: 'county',
    fieldLabel: 'County',
    maxLength: AddressMaxLengthValidation.COUNTY,
  },
];

export const AddressEntry: React.FC<AddressComponentProps> = ({
  value: initialValue,
  mainError,
  name,
  searchPostcode,
  setAddressList,
  setEditMode,
  setFieldValue,
}) => {
  const isBesavvi = useIsBeSavvi();
  const [fieldSpecs, namedFields] = useMemo(() => {
    const specs: FieldSpec[] = [];
    const props: {
      fieldLabel: string;
      fullName: string;
      halfWidth: boolean;
      maxLength?: number;
      handleValueChange?: (v: string) => void;
    }[] = [];

    fields.forEach(({ fieldLabel, fieldName, halfWidth = false, required, maxLength, handleValueChange }) => {
      const fullName = `${name}-${fieldName}`;
      props.push({ fieldLabel, fullName, halfWidth, maxLength, handleValueChange });
      if (required) {
        specs.push({
          ...simpleRequiredField(fullName, initialValue[fieldName]),
          transform: normalisePunctuation,
        });
      } else {
        specs.push({
          ...simpleOptionalField(fullName, initialValue[fieldName]),
          transform: normalisePunctuation,
        });
      }
    });

    return [specs, props];
  }, [initialValue, name]);

  const mapToValue = useCallback(
    (values: FieldValues) => {
      const len = name.length + 1;

      const value = Object.keys(values).reduce(
        (o, key) => ({
          ...o,
          [key.substring(len)]: values[key],
        }),
        {} as AddressDetails
      );

      value.postcode = searchPostcode;

      return value;
    },
    [name, searchPostcode]
  );

  const validate = useCallback(
    (values) => {
      const numberFieldName = `${name}-houseNumber`;
      const nameFieldName = `${name}-houseName`;

      let error;

      if (!!Required(values[numberFieldName]) && !!Required(values[nameFieldName])) {
        error = 'Please provide either a house number or a house name';
      }

      return {
        [numberFieldName]: error,
        [nameFieldName]: error,
      };
    },
    [name]
  );

  const onSubmit = useCallback(
    (values) => {
      const mapped = mapToValue(values);

      setFieldValue(name, mapped);
      setEditMode(false);
    },
    [mapToValue, name, setEditMode, setFieldValue]
  );

  const { submitForm, fieldProps } = useValidation(fieldSpecs, {
    onSubmit,
    validate,
  });

  // clear the main value on mount
  useEffect(() => {
    setFieldValue(name, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAddress = useCallback(() => {
    setAddressList([]);
    setEditMode(false);
  }, [setAddressList, setEditMode]);

  const confirmAddress = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const details = (
    <Fragment>
      <ContentHeading variant="tertiary">Enter address manually</ContentHeading>
      <AddressError name={name} error={mainError} />
      {namedFields.map(({ fieldLabel, fullName, halfWidth, handleValueChange, maxLength }) => (
        <SingleInput
          key={fullName}
          label={fieldLabel}
          halfWidth={halfWidth}
          maxLength={maxLength}
          onValueChange={handleValueChange}
          {...fieldProps[fullName]}
        />
      ))}
      <Box mb={5}>
        <ReadOnlyWrapper
          label="Postcode"
          action={
            isBesavvi ? (
              <TextButton data-test-id={`restart-button-${name}`} onClick={resetAddress}>
                Change
              </TextButton>
            ) : (
              <TertiaryLink
                text="Change"
                direction={TertiaryLinkDirection.FORWARDS}
                data-test-id={`restart-button-${name}`}
                onClick={resetAddress}
              ></TertiaryLink>
            )
          }
        >
          {formatPostCode(searchPostcode)}
        </ReadOnlyWrapper>
      </Box>
      <Button data-test-id={`confirm-button-${name}`} variant="secondary" type="button" onClick={confirmAddress}>
        Confirm address <Continue />
      </Button>
    </Fragment>
  );

  return isBesavvi ? (
    <Card p={4} mb={5} variant="secondary">
      {details}
    </Card>
  ) : (
    <Box mb={5}>{details}</Box>
  );
};
