import { jsx, Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';

export const SoftSearchMessage: React.FC = () => (
  <Box mb={4}>
    <Message variant="info">
      <NovunaHeading as="h3" mb={1}>
        Soft search
      </NovunaHeading>
      <Text data-test-id="personalised-quote-message">
        Checking your eligibility will place a soft search on your credit file which is not visible to other lenders.
      </Text>
    </Message>
  </Box>
);
