import React, { useMemo } from 'react';
import { Decision } from '@plending/interfaces/decision';
import { ApplicationStatus } from '@plending/interfaces/application';
import { jsx } from 'compass-design';
import { useDecisionSelector } from '../../store/decision/selectors';
import { DecisionState } from '../../store/decision/types';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { Waiting } from '../Waiting/Waiting';
import { DecisionAccept } from './results/Accept/AcceptPage';
import { UnhandledDecision } from './results/Unhandled';
import { DecisionDecline } from './results/Decline';
import { DecisionRefer } from './results/Refer';
import { ErrorDecision } from './results/Error';
import { LoadingDecision } from './results/Loading';

const Components: Record<string, React.ElementType> = {
  [ApplicationStatus.ACCEPT]: DecisionAccept,
  [ApplicationStatus.DECLINED]: DecisionDecline,
  [ApplicationStatus.REFER]: DecisionRefer,
  [ApplicationStatus.PROVISIONAL_ACCEPT]: DecisionAccept,
  [ApplicationStatus.QUOTE_ACCEPT]: DecisionAccept,
};

export const ShowDecision: React.FC = () => {
  const {
    pendingLegacy,
    loading: decisionLoading,
    error: decisionError,
    data: { decision: decisionString } = {} as Decision,
  }: DecisionState = useDecisionSelector();

  const showDecision = !!decisionString && !decisionError && !decisionLoading;
  const showError = !!decisionError || (!decisionLoading && !decisionString && !pendingLegacy);

  const Component: React.FC = useMemo(() => {
    if (showDecision) {
      return Components[decisionString] || UnhandledDecision;
    }

    return React.Fragment;
  }, [decisionString, showDecision]) as unknown as React.FC;

  const isBesavvi = useIsBeSavvi();

  return (
    <>
      {decisionLoading && (
        <Waiting>
          <LoadingDecision isBesavvi={isBesavvi} />
        </Waiting>
      )}
      {showDecision &&
        (isBesavvi ? (
          <Component />
        ) : (
          <NovunaContainer>
            <Component />
          </NovunaContainer>
        ))}
      {showError && <ErrorDecision isBesavvi={isBesavvi} decisionError={decisionError} />}
    </>
  );
};
