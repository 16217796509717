import { useSelector } from 'react-redux';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { TopUpLoansQuoteState } from './types';

const selectTopUpLoansQuote = (s: RootState) => s.topUpLoans;

export function useTopUpLoansQuoteSelector(): TopUpLoansQuoteState {
  return useSelector(selectTopUpLoansQuote);
}

export function useCallbackTopUpLoansQuoteSelector(): () => TopUpLoansQuoteState {
  return useCallbackSelector(selectTopUpLoansQuote);
}
