import React, { Fragment as F } from 'react';
import { jsx, Box, BeSavvi } from 'compass-design';

const svgSx = {
  svg: {
    verticalAlign: 'top',
  },
};

export const BeSavviHeaderLogo: React.FC = () => {
  return (
    <F>
      <Box
        sx={{
          ...svgSx,
          marginTop: '24px',
          marginLeft: ['16px', '16px', '94px'],
        }}
      >
        <BeSavvi />
      </Box>
    </F>
  );
};
