import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { isoNow } from '../../utils/serverTime';
import { PrivacyActionType } from './types';

export function useStorePrivacy() {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch({
        type: PrivacyActionType.STORE,
        payload: {
          accepted: isoNow(),
        },
      }),
    [dispatch]
  );
}
