import { jsx, NovunaSpinner, Text, Flex } from 'compass-design';
import React from 'react';
import { PageTitle } from '../../../design-components/heading';
import { NovunaContainer } from '../../../design-components/NovunaContainer';

export const LoadingDecision: React.FC<{ isBesavvi: boolean }> = ({ isBesavvi }) => {
  return isBesavvi ? (
    <>
      <PageTitle data-test-id="loading-message">Making a decision...</PageTitle>
      <p>Please be patient, this might take a few seconds.</p>
    </>
  ) : (
    <>
      <NovunaContainer>
        <PageTitle>Application decision</PageTitle>
        <Flex mb={4} sx={{ alignItems: 'center' }}>
          <NovunaSpinner />
          <Text ml={3} sx={{ fontSize: 3, color: 'secondaryPurple', fontWeight: 'heading' }}>
            Hold on…
          </Text>
        </Flex>
        <Text mb={4}>We are submitting your application for a decision, sometimes this can take a few minutes.</Text>
        <Text>Please don’t refresh or close the browser.</Text>
      </NovunaContainer>
    </>
  );
};
