import React from 'react';
import { jsx, Flex, Box } from 'compass-design';

export const LeftRightSplit: React.FC = ({ children }) => (
  <Flex mb={4} sx={{ flexWrap: ['wrap', 'wrap', 'nowrap'] }}>
    {children}
  </Flex>
);

export const LeftBox: React.FC = ({ children }) => <Box mr="auto">{children}</Box>;

export const RightBox: React.FC = ({ children, ...props }) => (
  <Box ml={[0, 0, 4]} mt={[4, 4, 0]} sx={{ flexShrink: 0 }} {...props}>
    {children}
  </Box>
);
