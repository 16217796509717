const NovunaName = 'Novuna Personal Finance';
const NovunaCapitalName = 'Novuna Capital (UK) PLC';

export const Novuna = {
  longName: 'Mitsubishi HC Capital UK PLC trading as Novuna Personal Finance (we or us).',
  name: NovunaName,
  altName: NovunaCapitalName,
  address: ['2 Apex View', 'Leeds', 'West Yorkshire', 'LS11 9BH'],
  customerServiceContact: {
    phoneNumber: '0344 375 5500',
    email: 'customerservice@novunapersonalfinance.co.uk',
    complaintsEmail: 'complaintsteam@novunapersonalfinance.co.uk',
    hours: [
      ['Monday to Friday', '8:45am to 7:30pm'],
      ['Saturday', '8:45am to noon'],
    ],
  },
  loansTeamContact: {
    phoneNumber: '0343 351 9112',
    email: 'loansteam@novunapersonalfinance.co.uk',
    hours: [
      ['Monday to Friday', '8:45am to 7:30pm'],
      ['Saturday', '8:45am to 2pm'],
    ],
  },
  registered: {
    address: ['Novuna House', 'Thorpe Road', 'Staines-upon-Thames', 'Surrey', 'TW18 3HP'],
    number: 1630491,
    registeredIn: 'Cardiff',
  },
  FSRN: '704348',
  fees: {
    recalledDirectDebit: '£22',
    returnedCheque: '£25',
    defaultNotice: '£25',
    breachLetter: '£25',
  },
};
