import React, { useState } from 'react';
import { Container, Box, Flex, Link, jsx } from 'compass-design';
export interface AccessibilityBannerProps {
  uiType: 'fullWidth' | 'center';
  beSavvi?: boolean;
}

export const AccessibilityBanner = ({ uiType, beSavvi = false }: AccessibilityBannerProps) => {
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) return <></>;
  const NeedHelp = () => (
    <Link
      color="white"
      sx={{
        'fontWeight': 'bold',
        '&:hover': { color: 'white', opacity: '0.9' },
      }}
      href="https://www.novunapersonalfinance.co.uk/accessibility-policy"
      target="_blank"
      aria-label="need help reading this page?"
      rel="noreferrer"
      data-test-id="compass-accessibility-banner-link"
      data-testid="compass-accessibility-banner-link"
    >
      Need help reading this?
    </Link>
  );
  const DismissBtn = () => (
    <Box
      color="white"
      sx={{
        'fontWeight': 'bold',
        'cursor': 'pointer',
        'mr': '0.5em',
        '&:hover': { color: 'white', opacity: '0.9' },
      }}
      onClick={() => setDismissed(true)}
      data-test-id="compass-accessibility-banner-dismiss"
      data-testid="compass-accessibility-banner-dismiss"
    >
      X
    </Box>
  );
  switch (uiType) {
    case 'center':
      return (
        <Box bg={beSavvi ? 'black' : 'secondaryPurple'} p={'0.25em 0.5em'}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Container
              variant={beSavvi ? 'layout.header' : undefined}
              pb={[0, 0, 0, 0]}
              sx={beSavvi ? {} : { px: [3, null, 6] }}
              style={beSavvi ? {} : { maxWidth: '1286px' }}
              data-test-id="compass-accessibility-banner"
            >
              <Box
                sx={
                  beSavvi
                    ? {
                        marginLeft: ['16px', '16px', '94px'],
                      }
                    : {
                        mx: [-2, -2, -2],
                      }
                }
              >
                <NeedHelp />
              </Box>
            </Container>
            <DismissBtn />
          </Flex>
        </Box>
      );
    case 'fullWidth':
      return (
        <Box bg={beSavvi ? 'black' : 'secondaryPurple'} p={'0.25em 0.5em'}>
          <Flex
            data-test-id="compass-accessibility-banner"
            data-testid="compass-accessibility-banner"
            variant={beSavvi ? 'layout.header' : undefined}
            sx={{ justifyContent: 'space-between' }}
          >
            <NeedHelp />
            <DismissBtn />
          </Flex>
        </Box>
      );
    default:
      return <></>;
  }
};

// eslint-disable-next-line import/no-default-export
export default AccessibilityBanner;
