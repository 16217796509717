import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { chainReducers } from './chainReducers';
import { reducer as globalReducer } from './global/reducers';
import { reducer as application } from './application/reducers';
import { reducer as alternativeLoan } from './alternativeLoan/reducers';
import { reducer as decision } from './decision/reducers';
import { reducer as bootstrapData } from './bootstrap/reducers';
import { reducer as quotation } from './quotation/reducers';
import { reducer as agreement } from './agreement/reducers';
import { reducer as personalDetails } from './personalDetails/reducers';
import { reducer as existingAgreementDetails } from './existingAgreementDetails/reducers';
import { reducer as preDecisionConfirmation } from './preDecisionConfirmation/reducers';
import { reducer as explanation } from './explanation/reducers';
import { reducer as pcci } from './pcci/reducers';
import { reducer as privacy } from './privacy/reducers';
import { reducer as complete } from './complete/reducers';
import { reducer as agreementDownload } from './agreementDownload/reducers';
import { reducer as financialDetails } from './financialDetails/reducers';
import { reducer as authentication } from './authentication/reducers';
import { reducer as createApplication } from './application/createApplication/reducers';
import { reducer as resume } from './resume/reducers';
import { reducer as session } from './session/reducers';
import { reducer as finalOffer } from './finalOffer/reducers';
import { reducer as aggregator } from './aggregator/reducers';
import { reducer as broker, flagsReducer as brokerFlags } from './broker/reducers';
import { reducer as personalisedQuote } from './personalisedQuote/reducers';
import { reducer as topUpLoans } from './topUp/reducers';
import { reducer as selfServiceData } from './selfService/reducers';

export const createRootReducer = (history: History) =>
  chainReducers([
    // order of chained reducers is important
    globalReducer,
    combineReducers({
      router: connectRouter(history),
      application,
      alternativeLoan,
      decision,
      finalOffer,
      bootstrapData,
      selfServiceData,
      quotation,
      agreement,
      personalDetails,
      existingAgreementDetails,
      preDecisionConfirmation,
      explanation,
      pcci,
      privacy,
      complete,
      agreementDownload,
      financialDetails,
      authentication,
      createApplication,
      resume,
      session,
      aggregator,
      broker,
      brokerFlags,
      personalisedQuote,
      topUpLoans,
    }),
  ]);
