import { jsx, Box, Container } from 'compass-design';
import React, { FC, ReactNode, RefAttributes } from 'react';

const boxPadding = [3, null, 4, 6];
const mtWithoutLink = [2, null, 3, null, 6];
const mtWithLink = 3;

interface ContainerProps extends RefAttributes<HTMLDivElement> {
  link?: ReactNode;
  key?: string;
}

const SingleColumnLayout: FC<ContainerProps> = ({ children, link, ...params }) => (
  <Container {...params} mt={link ? mtWithLink : mtWithoutLink} mb={[2, null, 4, 6]}>
    <Container as="main" px={[3, null, 6]}>
      {link && <Box mb={3}>{link}</Box>}
      <Box
        p={boxPadding}
        backgroundColor="white"
        sx={{
          borderRadius: 'panel',
        }}
      >
        {children}
      </Box>
    </Container>
  </Container>
);

export const NovunaContainer: FC<ContainerProps> = ({ children, link, ...params }) => (
  <SingleColumnLayout {...params} link={link}>
    <Box sx={{ maxWidth: '620px', mx: 'auto' }}>{children}</Box>
  </SingleColumnLayout>
);
