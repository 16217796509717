import React, { Fragment, useCallback } from 'react';
import { jsx, Box, Text, Link } from 'compass-design';
import { FinalOffer } from '@plending/interfaces/application';
import { DecisionClientResponse } from '@plending/interfaces/decision';
import { DecisionOffer } from '@plending/interfaces/quote';
import { Supplier } from '@plending/interfaces/bootstrap-data';
import { ContinueButton } from '../../design-components/button';
import { PageTitle } from '../../design-components/heading';
import { useStorePcci } from '../../store/pcci/actions';
import { useNavigation } from '../Routing/useNavigation';
import { BackLink } from '../../design-components/backLink';
import { useFetchAgreementData } from '../../store/agreement/actions';
import { useFinalOfferSelector } from '../../store/finalOffer/selectors';
import { useDecisionSelector } from '../../store/decision/selectors';
import { useDownload } from '../../utils/useDownload';
import { ErrorMessage } from '../../design-components/ErrorMessage';
import { useBootstrapDataSelector } from '../../store/bootstrap/selectors';
import { BootstrapState } from '../../store/bootstrap/types';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { PCCIView } from './PCCIView';
import { getPCCIContent } from './PCCIText';
import { DownloadPCCI } from './DownloadPCCI';

export const PreContractCreditInfo: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const { navigateToNextStep } = useNavigation();

  const { data: finalOffer = {} as FinalOffer } = useFinalOfferSelector();

  const { data: { supplier = {} as Supplier } = {} as NonNullable<BootstrapState['data']> } =
    useBootstrapDataSelector();

  const { data: { offer: decisionOffer = {} as DecisionOffer, applicationId } = {} as DecisionClientResponse } =
    useDecisionSelector();

  const offer = finalOffer.loanPence ? finalOffer : decisionOffer;
  const { loanPence, monthlyPence, totalPence, termMonths, apr = 0 } = offer;
  const storePcciAccept = useStorePcci();
  const savePcci = useFetchAgreementData();

  const acceptPcci = useCallback(() => {
    const dataToStore = {
      loanPence,
      monthlyPence,
      totalPence,
      apr,
      termMonths,
    };
    storePcciAccept(dataToStore);
    savePcci(true, dataToStore);
    navigateToNextStep();
  }, [apr, loanPence, monthlyPence, navigateToNextStep, savePcci, storePcciAccept, termMonths, totalPence]);

  const [downloadAgreement, agreementDownloading, agreementDownloadError] = useDownload(
    '/draft-agreement',
    `${applicationId}.pdf`
  );

  const linkError = agreementDownloadError;
  const showLink = !linkError && !agreementDownloading;

  const content = getPCCIContent(supplier, offer);

  const details = (
    <Fragment>
      <PageTitle>Pre-Contract Credit Information</PageTitle>

      <Box
        mb={4}
        sx={{
          'borderBottomWidth': '2px',
          'borderBottomStyle': 'solid',
          'borderBottomColor': 'monochrome.4',
          '@media print': {
            'a[href]': {
              '&:after': {
                content: 'none',
              },
            },
          },
          '@page': {
            margin: 0,
            marginTop: '-100px',
          },
        }}
      >
        <PCCIView content={content} isBesavvi={isBesavvi} />
      </Box>

      <DownloadPCCI />

      <Box sx={{ minHeight: '3em' }}>
        {showLink && (
          <Text data-test-id="draft-agreement-link">
            The next step is to e-sign the credit agreement. You may also review a{' '}
            <Link
              data-test-id="draft-agreement-link-on-click"
              onClick={downloadAgreement}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              draft copy
            </Link>{' '}
            of the agreement first.
          </Text>
        )}

        {agreementDownloading && <Text data-test-id="draft-agreement-loading">Preparing draft agreement...</Text>}

        {linkError && (
          <ErrorMessage data-test-id="draft-agreement-error">
            Failed to generate draft agreement, try again later.
          </ErrorMessage>
        )}
      </Box>

      <Box mt={5}>
        <ContinueButton hideInPrint data-test-id="accept-button" onClick={acceptPcci}>
          Continue
        </ContinueButton>
      </Box>
    </Fragment>
  );

  return <NovunaContainer link={<BackLink />}>{details}</NovunaContainer>;
};
