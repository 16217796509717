import { useSelector } from 'react-redux';
import { Application } from '@plending/interfaces/application';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { useFetchOnMount } from '../../utils/useFetchOnMount';
import { AgreementState } from './types';
import { useFetchAgreementData } from './actions';

const selectAgreementData = (a: RootState) => a.agreement;

export function useAgreementDataSelector(): AgreementState {
  return useSelector(selectAgreementData);
}

export function useCallbackAgreementDataSelector(): () => AgreementState {
  return useCallbackSelector(selectAgreementData);
}

export function useGetAgreementData(application: Application): AgreementState {
  const fetchAgreementData = useFetchAgreementData();

  return useFetchOnMount(selectAgreementData, fetchAgreementData, application);
}
