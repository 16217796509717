import { Address, AtSinceGroup, DateGroup, ExistingAgreementDetails } from '@plending/interfaces/application';
import { FinancialDetailsFieldRules } from '@plending/validation/financial-details-rules';
import { MyDetailsFieldRules } from '@plending/validation/my-details-rules';
import { useMemo } from 'react';
import { SoftSearchCurrentAddress, SoftSearchPreviousAddress } from '@plending/interfaces/soft-search';
import { ExistingAgreementDetailsRules } from '@plending/validation/existing-agreement-details-rules';
import { ADDRESS_ERROR_MESSAGES } from '../design-components/inputs/AddressInput/AddressInput';
import {
  numberTransform,
  normalisePunctuation,
  requiredCheckField,
  simpleCheckField,
  simpleOptionalField,
} from '../validation/utils';
import { now } from './serverTime';
interface BrokerCompleteFieldSpec {
  addresses?: {
    current?: SoftSearchCurrentAddress;
    previous1?: SoftSearchPreviousAddress;
    previous2?: SoftSearchPreviousAddress;
  };
}

export const useBrokerCompleteFieldSpec = ({ addresses }: BrokerCompleteFieldSpec) => ({
  atSecondAddressSinceFieldSpec: useMemo(() => {
    const dateGroup = {
      year: addresses?.previous1?.fromYear || '',
      month: addresses?.previous1?.fromMonth || '',
    } as AtSinceGroup;

    return {
      name: 'atSecondAddressSince',
      initialValue: dateGroup,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
        'Out of order': 'You must have moved here before you moved to your current address',
      },
      rules: [],
    };
  }, [addresses]),
  atThirdAddressSinceFieldSpec: useMemo(() => {
    const dateGroup = {
      year: addresses?.previous2?.fromYear || '',
      month: addresses?.previous2?.fromMonth || '',
    } as AtSinceGroup;

    return {
      name: 'atThirdAddressSince',
      initialValue: dateGroup,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
        'Out of order': 'You must have moved here before you moved to your current address',
      },
      rules: [],
    };
  }, [addresses]),
});

export const useExistingAgreementDetailsSpec = ({
  agreementNumber,
  monthlyInstalment,
  nextPaymentDate = { day: '', month: '', year: '' },
  secondNextPaymentDate = { day: '', month: '', year: '' },
  apr,
  remainingTerm,
  settlementFigure,
  postPaymentDateSettlementFigure,
  outstandingBalance,
  finalMonthlyInstalment,
}: ExistingAgreementDetails) => ({
  agreementNumberFieldSpec: useMemo(
    () => ({
      name: 'agreementNumber',
      initialValue: agreementNumber,
      rules: ExistingAgreementDetailsRules.agreementNumber,
    }),
    [agreementNumber]
  ),
  monthlyInstalmentFieldSpec: useMemo(
    () => ({
      name: 'monthlyInstalment',
      initialValue: monthlyInstalment,
      rules: ExistingAgreementDetailsRules.monthlyInstalment,
    }),
    [monthlyInstalment]
  ),
  finalMonthlyInstalmentFieldSpec: useMemo(
    () => ({
      name: 'finalMonthlyInstalment',
      initialValue: finalMonthlyInstalment,
      rules: ExistingAgreementDetailsRules.finalMonthlyInstalment,
    }),
    [finalMonthlyInstalment]
  ),
  nextPaymentDateFieldSpec: useMemo(
    () => ({
      name: 'nextPaymentDate',
      initialValue: nextPaymentDate,
      rules: ExistingAgreementDetailsRules.paymentDate,
    }),
    [nextPaymentDate]
  ),
  secondNextPaymentDateFieldSpec: useMemo(
    () => ({
      name: 'secondNextPaymentDate',
      initialValue: secondNextPaymentDate,
      rules: ExistingAgreementDetailsRules.paymentDate,
    }),
    [secondNextPaymentDate]
  ),
  aprFieldSpec: useMemo(
    () => ({
      name: 'apr',
      initialValue: apr,
      rules: ExistingAgreementDetailsRules.apr,
    }),
    [apr]
  ),
  remainingTermFieldSpec: useMemo(
    () => ({
      name: 'remainingTerm',
      initialValue: remainingTerm,
      rules: ExistingAgreementDetailsRules.remainingTerm,
    }),
    [remainingTerm]
  ),
  settlementFigureFieldSpec: useMemo(
    () => ({
      name: 'settlementFigure',
      initialValue: settlementFigure,
      rules: ExistingAgreementDetailsRules.settlementFigure,
    }),
    [settlementFigure]
  ),
  postPaymentDateSettlementFigureFieldSpec: useMemo(
    () => ({
      name: 'postPaymentDateSettlementFigure',
      initialValue: postPaymentDateSettlementFigure,
      rules: ExistingAgreementDetailsRules.settlementFigure,
    }),
    [postPaymentDateSettlementFigure]
  ),
  outstandingBalanceFieldSpec: useMemo(
    () => ({
      name: 'outstandingBalance',
      initialValue: outstandingBalance,
      rules: ExistingAgreementDetailsRules.outstandingBalance,
    }),
    [outstandingBalance]
  ),
});

interface PersonalDetailsFieldSpec {
  title?: string;
  firstName?: string;
  middleNames?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  maritalStatus?: string;
  dob?: DateGroup;
  enteredDob?: DateGroup;
  accommodationStatus?: string;
  currentAddress?: Address;
  secondAddress?: Address;
  thirdAddress?: Address;
  purposeOfLoan?: string;
  marketingEmail?: boolean;
  marketingPost?: boolean;
  testParameters?: string;
}

export const usePersonalDetailsFieldSpec = ({
  title = '',
  firstName = '',
  middleNames = '',
  lastName = '',
  email = '',
  phone = '',
  maritalStatus = '',
  dob = { day: '', month: '', year: '' },
  enteredDob = { day: '', month: '', year: '' },
  accommodationStatus = '',
  currentAddress = {} as Address,
  secondAddress = {} as Address,
  thirdAddress = {} as Address,
  purposeOfLoan = '',
  marketingEmail = false,
  marketingPost = false,
  testParameters = '',
}: PersonalDetailsFieldSpec) => ({
  titleFieldSpec: useMemo(
    () => ({
      name: 'title',
      initialValue: title,
      rules: MyDetailsFieldRules.title,
    }),
    [title]
  ),
  firstNameFieldSpec: useMemo(
    () => ({
      name: 'firstName',
      initialValue: firstName,
      rules: MyDetailsFieldRules.firstName,
      transform: normalisePunctuation,
    }),
    [firstName]
  ),
  middleNamesFieldSpec: useMemo(
    () => ({
      name: 'middleNames',
      initialValue: middleNames,
      transform: normalisePunctuation,
      rules: MyDetailsFieldRules.middleNames,
    }),
    [middleNames]
  ),
  lastNameFieldSpec: useMemo(
    () => ({
      name: 'lastName',
      initialValue: lastName,
      rules: MyDetailsFieldRules.lastName,
      transform: normalisePunctuation,
    }),
    [lastName]
  ),
  dobFieldSpec: useMemo(
    () => ({
      name: 'dob',
      initialValue: dob,
      messages: {
        'Invalid': 'Please enter a valid date with a full year',
        'Too short': 'Please enter a valid date with a full year',
        'Not a number': 'Please enter a valid date with a full year',
        'Too young': 'You must be at least 18 years of age',
        'Too early': 'Your date of birth must be after 1900',
      },
      rules: MyDetailsFieldRules.dob,
    }),
    [dob]
  ),
  emailFieldSpec: useMemo(
    () => ({
      name: 'email',
      initialValue: email,
      transform: normalisePunctuation,
      rules: MyDetailsFieldRules.email,
    }),
    [email]
  ),
  phoneFieldSpec: useMemo(
    () => ({
      name: 'phone',
      initialValue: phone,
      transform: normalisePunctuation,
      rules: MyDetailsFieldRules.phone,
    }),
    [phone]
  ),
  maritalStatusFieldSpec: useMemo(
    () => ({
      name: 'maritalStatus',
      initialValue: maritalStatus,
      rules: MyDetailsFieldRules.maritalStatus,
    }),
    [maritalStatus]
  ),
  accommodationStatusFieldSpec: useMemo(
    () => ({
      name: 'accommodationStatus',
      initialValue: accommodationStatus,
      rules: MyDetailsFieldRules.accommodationStatus,
    }),
    [accommodationStatus]
  ),
  currentAddressFieldSpec: useMemo(
    () => ({
      name: 'currentAddress',
      initialValue: currentAddress?.address,
      rules: MyDetailsFieldRules.currentAddress,
      messages: {
        ...ADDRESS_ERROR_MESSAGES,
      },
    }),
    [currentAddress]
  ),
  atCurrentAddressSinceFieldSpec: useMemo(
    () => ({
      name: 'atCurrentAddressSince',
      initialValue: currentAddress.atAddressSince,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
      },
      rules: MyDetailsFieldRules.atCurrentAddressSince(now, enteredDob),
    }),
    [currentAddress, enteredDob]
  ),
  secondAddressFieldSpec: useMemo(() => {
    return {
      name: 'secondAddress',
      initialValue: secondAddress.address,
      rules: [],
      messages: {
        ...ADDRESS_ERROR_MESSAGES,
        'Duplicate Address': 'Second address can not be the same as your current address',
      },
    };
  }, [secondAddress]),
  atSecondAddressSinceFieldSpec: useMemo(() => {
    return {
      name: 'atSecondAddressSince',
      initialValue: secondAddress.atAddressSince,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
        'Out of order': 'You must have moved here before you moved to your current address',
      },
      rules: [],
    };
  }, [secondAddress]),
  thirdAddressFieldSpec: useMemo(() => {
    return {
      name: 'thirdAddress',
      initialValue: thirdAddress.address,
      rules: [],
      messages: {
        ...ADDRESS_ERROR_MESSAGES,
        'Duplicate Address': 'Third address can not be the same as your second address',
      },
    };
  }, [thirdAddress]),
  atThirdAddressSinceFieldSpec: useMemo(() => {
    return {
      name: 'atThirdAddressSince',
      initialValue: thirdAddress.atAddressSince,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
        'Out of order': 'You must have moved here before you moved to your second address',
      },
      rules: [],
    };
  }, [thirdAddress]),
  purposeOfLoanFieldSpec: useMemo(
    () => ({
      name: 'purposeOfLoan',
      initialValue: purposeOfLoan,
      rules: MyDetailsFieldRules.purposeOfLoan,
    }),
    [purposeOfLoan]
  ),
  marketingEmailFieldSpec: useMemo(() => simpleCheckField('marketingEmail', marketingEmail), [marketingEmail]),
  marketingPostFieldSpec: useMemo(() => simpleCheckField('marketingPost', marketingPost), [marketingPost]),
  testParametersFieldSpec: useMemo(
    () => ({
      name: 'testParameters',
      initialValue: testParameters,
      rules: [],
    }),
    [testParameters]
  ),
});

interface FinancialDetailsFieldSpec {
  employerName?: string;
  occupation?: string;
  bankHolderName?: string;
  sortCode?: string;
  accountNumber?: string;
  dob?: DateGroup;
  numberOfDependants?: string;
  annualIncomeBeforeTax?: number;
  workPhoneNumber?: string;
  partnerOccupation?: string;
  partnerEmploymentStatus?: string;
  employmentStatus?: string;
  withBankSince?: AtSinceGroup;
}

export const useFinancialDetailsFieldSpec = ({
  employerName = '',
  occupation = '',
  bankHolderName = '',
  sortCode = '',
  accountNumber = '',
  dob = { day: '', month: '', year: '' },
  numberOfDependants = '',
  annualIncomeBeforeTax,
  workPhoneNumber = '',
  partnerOccupation = '',
  partnerEmploymentStatus = '',
  employmentStatus = '',
  withBankSince = { month: '', year: '' },
}: FinancialDetailsFieldSpec) => ({
  employerNameSpec: useMemo(() => simpleOptionalField('employerName', employerName), [employerName]),
  occupationSpec: useMemo(() => simpleOptionalField('occupation', occupation), [occupation]),
  bankHolderNameSpec: useMemo(
    () => ({
      name: 'bankHolderName',
      initialValue: bankHolderName,
      transform: normalisePunctuation,
      rules: FinancialDetailsFieldRules.bankHolderName,
    }),
    [bankHolderName]
  ),
  sortCodeFieldSpec: useMemo(
    () => ({
      name: 'sortCode',
      initialValue: sortCode,
      rules: FinancialDetailsFieldRules.sortCode,
      transform: numberTransform,
      messages: {
        'SortCode required': 'Sort Code is a required field',
        'Invalid Sort Code': 'Please enter a valid 6 digit sort code',
      },
    }),
    [sortCode]
  ),
  accountNumberFieldSpec: useMemo(
    () => ({
      name: 'accountNumber',
      initialValue: accountNumber,
      rules: FinancialDetailsFieldRules.accountNumber,
      messages: {
        'AccountNumber required': 'Account Number is a required field',
        'Invalid Account Number': 'Please enter a valid account number',
        'Invalid 7 digit Account Number':
          'Please enter a valid account number. If your account number has 7 digits, please add a zero at the beginning',
      },
    }),
    [accountNumber]
  ),
  withBankSinceSpec: useMemo(
    () => ({
      name: 'withBankSince',
      initialValue: withBankSince,
      messages: {
        'Not a number': 'Please enter a valid month and year',
        'Too small': 'Please enter a valid month and year',
        'Too large': 'Please enter a valid month and year',
        'After today': 'Please enter a date before today',
        'Too early': 'Please enter a date after your date of birth',
      },
      rules: FinancialDetailsFieldRules.withBankSince(now, dob),
    }),
    [withBankSince, dob]
  ),
  numberOfDependantsSpec: useMemo(
    () => ({
      name: 'numberOfDependants',
      initialValue: numberOfDependants,
      rules: FinancialDetailsFieldRules.numberOfDependants,
    }),
    [numberOfDependants]
  ),
  annualIncomeBeforeTaxSpec: useMemo(
    () => ({
      name: 'annualIncomeBeforeTax',
      initialValue: annualIncomeBeforeTax,
      rules: FinancialDetailsFieldRules.annualIncomeBeforeTax,
      messages: {
        'Not a number': 'Please enter a valid number',
      },
    }),
    [annualIncomeBeforeTax]
  ),
  workPhoneNumberSpec: useMemo(
    () => ({
      name: 'workPhoneNumber',
      initialValue: workPhoneNumber,
      rules: FinancialDetailsFieldRules.workPhoneNumber,
    }),
    [workPhoneNumber]
  ),
  partnerOccupationSpec: useMemo(
    () => simpleOptionalField('partnerOccupation', partnerOccupation),
    [partnerOccupation]
  ),
  partnerEmploymentStatusSpec: useMemo(
    () => simpleOptionalField('partnerEmploymentStatus', partnerEmploymentStatus),
    [partnerEmploymentStatus]
  ),
  employmentStatusSpec: useMemo(
    () => ({
      name: 'employmentStatus',
      initialValue: employmentStatus,
      rules: FinancialDetailsFieldRules.employmentStatus,
    }),
    [employmentStatus]
  ),
});

export const useConfirmationFieldSpec = ({
  bankDetailsConfirm = false,
  creditSearchConfirm = false,
  circumstancesConfirm = false,
  alternativeLoanProviderOptIn = false,
} = {}) => ({
  bankDetailsConfirmSpec: useMemo(
    () =>
      requiredCheckField('bankDetailsConfirm', bankDetailsConfirm, {
        Mandatory: 'Confirm your account details',
      }),
    [bankDetailsConfirm]
  ),
  creditSearchConfirmSpec: useMemo(
    () =>
      requiredCheckField('creditSearchConfirm', creditSearchConfirm, {
        Mandatory: 'Confirm that you understand and consent',
      }),
    [creditSearchConfirm]
  ),
  circumstancesConfirmSpec: useMemo(
    () =>
      requiredCheckField('circumstancesConfirm', circumstancesConfirm, {
        Mandatory: 'Confirm that you understand',
      }),
    [circumstancesConfirm]
  ),
  alternativeLoanProviderOptInSpec: useMemo(
    () => simpleCheckField('alternativeLoanProviderOptIn', alternativeLoanProviderOptIn),
    [alternativeLoanProviderOptIn]
  ),
});
