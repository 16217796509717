import { useFetchGet } from '../utils/fetch';
import { useCallbackSelfServiceDataSelector } from './selectors';
import { SelfServiceActionType, SelfServiceResponse } from './types';

const extractSelfServiceData = (d: SelfServiceResponse) => d.data;

export function useFetchSelfServiceData(token: string) {
  return useFetchGet(
    `/acs/${token}`,
    useCallbackSelfServiceDataSelector(),
    SelfServiceActionType,
    extractSelfServiceData
  );
}
