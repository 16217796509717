import { TopUpSoftSearchResponse } from '@plending/interfaces/soft-search';
import { RemoteAction, RemoteState } from '../types';

export enum TopUpLoansQuoteActionType {
  CLEAR = 'topUpLoans/CLEAR',
  REQUEST = 'topUpLoans/REQUEST',
  LOAD = 'topUpLoans/LOAD',
  FAILED = 'topUpLoans/FAILED',
}

export interface TopUpLoansQuoteAction extends RemoteAction {
  type: TopUpLoansQuoteActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface TopUpEligibilityResponse {
  topUpLoansQuote?: TopUpSoftSearchResponse;
  additionalLoansQuote?: TopUpSoftSearchResponse;
}

export interface TopUpLoansQuoteState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: TopUpEligibilityResponse;
}
