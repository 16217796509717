import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { SessionActionType } from './types';

export const START_SESSION_ACTION = {
  type: SessionActionType.START_TIMER,
} as const;

export function useStartSessionTimer() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(START_SESSION_ACTION);
  }, [dispatch]);
}

export function useStopSessionTimer() {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch({
        type: SessionActionType.STOP_TIMER,
      }),
    [dispatch]
  );
}
