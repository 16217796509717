import React from 'react';
import { jsx, Flex, Container, Ribbon, NovunaPFHeader, NovunaPFFooter } from 'compass-design';
import { Header } from '../Header/Header';
import { useScrollToTop } from '../Routing/useScrollToTop';
import { GlobalThemeHolder } from '../../design-components/globalThemeHolder';
import { useUiConfig } from '../../store/bootstrap/selectors';
import { useTheme } from '../../utils/useTheme';
import AccessibilityBanner from '../../design-components/AccessibilityBanner';

const HeaderPicker: React.FC = () => {
  const theme = useTheme();
  if (theme.channel === 'BESAVVI') {
    return <Header />;
  }

  return (
    <>
      <NovunaPFHeader />
      <AccessibilityBanner uiType="center" />
    </>
  );
};

const FooterPicker: React.FC = () => {
  return <NovunaPFFooter />;
};

/**
 * Sets up the site wide page. Header, scroll behaviour etc.
 */
export const PageHolder: React.FC = (props) => {
  useScrollToTop();

  return <StaticPageHolder {...props} />;
};

// Need a "static" version that has no context dependencies, for the <App> Error Boundary
export const StaticPageHolder: React.FC = ({ children }) => {
  const { loanAdvisor } = useUiConfig();

  return (
    <GlobalThemeHolder>
      <Flex
        sx={{
          flexDirection: 'column',
          minHeight: '100vh',
          alignContent: 'stretch',
        }}
      >
        <HeaderPicker />
        {loanAdvisor && (
          <Ribbon>
            <span>
              Agent <b>{loanAdvisor}</b> proposing on behalf of the customer
            </span>
          </Ribbon>
        )}
        <Container
          variant="layout.content"
          py={0}
          sx={{
            flex: '1 0 auto',
          }}
        >
          {children}
        </Container>
        <FooterPicker />
      </Flex>
    </GlobalThemeHolder>
  );
};
