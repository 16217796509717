import React, { useMemo, ChangeEventHandler } from 'react';
import { jsx, InlineCheckbox, Box, Tone } from 'compass-design';
import { FieldPropSet } from '../../validation/types';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useTone } from './hooks';

type CheckBoxInputProps = {
  name: string;
  value: boolean;
  setFieldValue: FieldPropSet['setFieldValue'];
  onBlur: FieldPropSet['onBlur'];
  error?: string;
  touched?: boolean;
};

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  children,
  name,
  value,
  setFieldValue,
  error,
  touched,
  onBlur,
}) => {
  const handlers = useMemo(() => {
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      setFieldValue(name, e.target.checked);
    };

    return {
      onChange: changeHandler,
    };
  }, [name, setFieldValue]);

  const tone: Tone = useTone({ touched, error });

  const isBesavvi = useIsBeSavvi();

  return (
    <Box mb={isBesavvi ? 0 : 3} data-test-id={name}>
      <InlineCheckbox id={name} name={name} checked={value} tone={tone} error={error} onBlur={onBlur} {...handlers}>
        {children}
      </InlineCheckbox>
    </Box>
  );
};
