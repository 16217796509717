declare const serverTimeAtLoad: number;

// using `performance.now()` means there is no effect if the user changes their
// system clock after the offset has been calculated

export function now(): number {
  try {
    return serverTimeAtLoad + performance.now();
  } catch (e) {
    return Date.now();
  }
}

export function dateNow(): Date {
  return new Date(now());
}

export function isoNow(): string {
  return dateNow().toISOString();
}
