import { Supplier } from '@plending/interfaces/bootstrap-data';
import { Novuna } from '../../../utils/staticData';
import type { Section } from '../PCCIText';

export const buildContactInfo: (props: Supplier) => Section = ({
  address: supplierAddress,
  legalEntityName,
  isCreditIntermediary,
}) => {
  const {
    address: novunaAddress,
    longName,
    customerServiceContact: { phoneNumber },
  } = Novuna;
  return {
    title: 'Contact details',
    description: 'For us, the creditor',
    testId: 'contact-info',
    items: [
      {
        label: 'Creditor',
        value: longName,
      },
      {
        label: 'Address',
        value: novunaAddress.join(', '),
      },
      {
        label: 'Telephone number(s)',
        value: phoneNumber,
      },
      isCreditIntermediary
        ? {
            testId: 'pcci-credit-intermediary',
            label: 'Credit intermediary address',
            value: { type: 'address', value: [legalEntityName, supplierAddress] },
          }
        : null,
    ].filter(Boolean),
  };
};
