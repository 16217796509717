import { jsx, ReactModal, useIsModalOpen } from 'compass-design';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React, { useCallback, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AnyAction, Store } from 'redux';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useSafeStore } from '../../store/createSafeStore';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { Loading } from '../Loading/Loading';
import { ALL_ROUTES, ERROR_COMPONENTS } from '../Routing/Routes';
import { getPath } from '../Routing/useNavigation';
import { useSafeHistory } from '../Routing/useSafeHistory';
import { TimeoutHandler } from '../TimeoutHandler/TimeoutHandler';
import { PageHolder } from './PageHolder';

export const PLLoading = () => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return <Loading />;
  }
  return (
    <NovunaContainer>
      <Loading />
    </NovunaContainer>
  );
};

/**
 * Set up the site wide Redux store, routing
 */
export const Page: React.FC<{
  history?: History;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store?: Store<any, AnyAction>;
}> = (props) => {
  const [history, blockMessage, clearBlock] = useSafeHistory(props.history);
  const store = useSafeStore(history, props.store);
  const [modalIsOpen, toggleModal] = useIsModalOpen();

  const toggleModalIsOpen = useCallback(
    (isOpen) => {
      toggleModal(isOpen);
      if (!isOpen) {
        clearBlock(); // clear the block when the modal is closed
      }
    },
    [clearBlock, toggleModal]
  );

  useEffect(() => {
    if (blockMessage) {
      toggleModalIsOpen(true);
    }
  }, [blockMessage, toggleModalIsOpen]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PageHolder>
          <TimeoutHandler />
          <Switch>
            {ALL_ROUTES.map(({ path, Component, componentProps }) => (
              <Route key={path} path={path} exact>
                <React.Suspense fallback={<PLLoading />}>
                  <Component {...componentProps} />
                </React.Suspense>
              </Route>
            ))}
            <Redirect path="*" to={getPath(ERROR_COMPONENTS.default, { code: 404 }) as string} />
          </Switch>
          <ReactModal
            data-test-id="back-button-modal"
            size="medium"
            title="Unable to go back"
            isOpen={modalIsOpen}
            setIsOpen={toggleModalIsOpen}
          >
            You cannot go back to previous screens once the application has been submitted.
          </ReactModal>
        </PageHolder>
      </ConnectedRouter>
    </Provider>
  );
};
