import { Rate, RateParameters } from '@plending/interfaces/bootstrap-data';
import { LoanData, Quote } from '@plending/interfaces/quote';
import { SoftSearchValidationErrorName } from '@plending/interfaces/enum-errors';
import { SoftSearchValidationError } from '@plending/interfaces/soft-search';
import { LoanData as SharedLoanData } from 'compass-shared-services/build/decision/types';
import { QuotationDetails } from '../ui/src/store/quotation/types';

export function findMatchingRate(rates: Rate[], loanPence: number, termMonths: number): Rate {
  let matches = rates.filter(
    (r) =>
      loanPence >= r.minAmountPence &&
      loanPence <= r.maxAmountPence &&
      termMonths >= r.minTermMonths &&
      termMonths <= r.maxTermMonths
  );

  if (matches.length > 1) {
    const typicalMatches = matches.filter((m) => m.isTypical);
    if (typicalMatches.length > 1) {
      matches = typicalMatches;
    }
  }

  return matches[0];
}

function monthlyFactor(rpm: number): number {
  return rpm / 100 + 1;
}

export function convertToPence(amount: number | string) {
  return Math.round(Number(amount) * 100);
}

export function calculateTotalAmountPayable(monthlyInstalment: number, termInMonths: number) {
  return convertToPence(monthlyInstalment * termInMonths);
}

export function novunaRounding(n: number) {
  // This unusual rounding stategy is how Novuna systems such as InPro or HQuote work
  // so we have to work that way too.
  return Math.floor(n + 0.9);
}

export function monthlyPayment(loanPence: number, termMonths: number, rpm: number): number {
  const fMonth = monthlyFactor(rpm);
  const fTerm = Math.pow(fMonth, termMonths);

  return novunaRounding((loanPence * fTerm * (fMonth - 1)) / (fTerm - 1));
}

export function penceToPoundsNoSymbol(amountPence: number, forcePence = true) {
  const [pounds, pence] = (amountPence / 100).toFixed(2).split('.');
  let poundString = pounds;

  if (pence !== '00' || forcePence) {
    poundString = `${poundString}.${pence}`;
  }

  return poundString;
}

export function penceToPoundsString(amountPence: number, forcePence = true) {
  const [pounds, pence] = (amountPence / 100).toFixed(2).split('.');
  let poundString = pounds.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  poundString = `£${poundString}`;

  if (pence !== '00' || forcePence) {
    poundString = `${poundString}.${pence}`;
  }

  return poundString;
}

export function formatApr(apr: number): string {
  const twoDecimalApr = apr.toFixed(2);
  return String(twoDecimalApr).slice(-1) === '0' ? `${apr.toFixed(1)}%` : `${twoDecimalApr}%`;
}

export function pluralise(n: number, word: string, plural = `${word}s`) {
  return n === 1 ? word : plural;
}

export function termLabel(term: number, hideYearLabel = false): string {
  const years = Math.floor(term / 12);
  const months = term % 12;
  const yearComponent = `${years} ${!hideYearLabel || months ? pluralise(years, 'year') : ''}`;
  const monthComponent = `${months ? `, ${months} ${pluralise(months, 'month')}` : ''}`;
  return `${yearComponent}${monthComponent}`;
}

export function calculateInstalments(rate: RateParameters, loanPence: number, termMonths: number): QuotationDetails {
  const { rpm, apr } = rate;
  const monthlyPence = monthlyPayment(loanPence, termMonths, rpm);
  const totalPence = monthlyPence * termMonths;
  const monthlyPounds = penceToPoundsString(monthlyPence);
  const totalPounds = penceToPoundsString(totalPence);
  const loanPounds = penceToPoundsString(loanPence);
  const term = termLabel(termMonths);
  const aprString = formatApr(apr);

  return {
    aprString,
    loanPounds,
    monthlyPounds,
    totalPounds,
    term,

    apr,
    rpm,
    loanPence,
    monthlyPence,
    totalPence,
    termMonths,
  };
}

export function getDetails(rates: Rate[], loanPence: number, termMonths: number): QuotationDetails | null {
  const matchingRate = findMatchingRate(rates, loanPence, termMonths);

  if (matchingRate) {
    return calculateInstalments(matchingRate, loanPence, termMonths);
  }

  return null;
}

export function calculateRPM(apr: number): number {
  let rpm;

  // Official RPM calculation | RPM = 100*(((1+ APR/100)^(1/12))-1)
  rpm = 100 * (Math.pow(apr / 100 + 1, 1 / 12) - 1);
  rpm = Math.floor(rpm * 1000);
  rpm = rpm / 1000;

  return rpm;
}

export function calculateQuotationDetails(apr: number, loanTerm: number, loanPence: number): Quote {
  const rpm = calculateRPM(apr);

  const rate: RateParameters = {
    apr,
    rpm,
  };

  const {
    loanPounds,
    monthlyPounds,
    totalPounds,
    term,
    apr: quoteApr,
    rpm: quoteRpm,
    loanPence: quoteLoanPence,
    monthlyPence,
    totalPence,
    termMonths,
  } = calculateInstalments(rate, loanPence, loanTerm);

  return {
    loanPounds,
    monthlyPounds,
    totalPounds,
    term,
    apr: quoteApr,
    aprString: formatApr(quoteApr),
    rpm: quoteRpm,
    loanPence: quoteLoanPence,
    monthlyPence,
    totalPence,
    termMonths,
  };
}

export const craftDeclineMessage = (validationError: SoftSearchValidationError, minValidLoanPence: number): string => {
  switch (validationError.errorName) {
    case SoftSearchValidationErrorName.TERM_ERROR:
      return `The term should be between ${termLabel(validationError.minTerm, true)} and ${termLabel(
        validationError.maxTerm
      )} for this loan amount. Please amend the term below.`;

    case SoftSearchValidationErrorName.MAX_AMOUNT_ERROR:
      if (minValidLoanPence > validationError.maxAmountPence) return '';
      return `The maximum loan amount is ${penceToPoundsString(
        validationError.maxAmountPence,
        false
      )}. Please amend the amount requested below.`;

    case SoftSearchValidationErrorName.MIN_AMOUNT_ERROR:
      const minAmount = Math.max(minValidLoanPence, validationError.minAmountPence);
      return `The minimum loan amount is ${penceToPoundsString(
        minAmount,
        false
      )}. Please amend the amount requested below.`;
  }

  return '';
};

export function getMaxLoanAmountFromRates(rates: Rate[] = []): number {
  return rates && Math.max(...rates.map((rate) => (rate?.isTypical ? rate?.maxAmountPence : 0)));
}

export function getMinLoanAmountFromRates(rates: Rate[] = []): number {
  return rates && Math.min(...rates.map((rate) => (rate?.isTypical ? rate?.minAmountPence : 0)));
}

export const getTermRestrictions = (rates: Rate[], loanPence: number): [number, number] => {
  const restrictions: number[] = [];
  rates.forEach((r) => {
    if (r.isTypical && loanPence >= r.minAmountPence && loanPence <= r.maxAmountPence) {
      restrictions.push(r.minTermMonths, r.maxTermMonths);
    }
  });

  if (!restrictions.length) {
    throw new Error('Loan pence does not match any existing rate');
  }

  return [Math.min(...restrictions), Math.max(...restrictions)];
};

// eslint-disable-next-line prettier/prettier
export const validateAdditionalLoanData = (
  rates: Rate[],
  loanData: LoanData | SharedLoanData
): SoftSearchValidationError | undefined => {
  const { term } = loanData;
  const loanPence = convertToPence(loanData.loanAmount);

  const maxValidLoanAmount = getMaxLoanAmountFromRates(rates);
  if (loanPence > maxValidLoanAmount)
    return {
      errorName: SoftSearchValidationErrorName.MAX_AMOUNT_ERROR,
      maxAmountPence: maxValidLoanAmount,
    };

  const minValidLoanAmount = getMinLoanAmountFromRates(rates);
  if (loanPence < minValidLoanAmount)
    return {
      errorName: SoftSearchValidationErrorName.MIN_AMOUNT_ERROR,
      minAmountPence: minValidLoanAmount,
    };

  const [minTermMonths, maxTermMonths] = getTermRestrictions(rates, loanPence);
  if (term < minTermMonths || term > maxTermMonths) {
    return {
      errorName: SoftSearchValidationErrorName.TERM_ERROR,
      minTerm: minTermMonths,
      maxTerm: maxTermMonths,
    };
  }
};

export const validateTopupLoanData = (
  rates: Rate[],
  loanData: LoanData | SharedLoanData,
  settlementFigure: number | undefined
): SoftSearchValidationError | undefined => {
  const { term } = loanData;
  const loanPence = convertToPence(loanData.loanAmount);

  if (!settlementFigure) throw Error('Insufficient information to validate top up loan amount');
  const settlementFigurePence = convertToPence(settlementFigure);

  const maxValidLoanAmount = getMaxLoanAmountFromRates(rates);
  if (loanPence > maxValidLoanAmount) {
    const maxAmountPence = maxValidLoanAmount - settlementFigurePence; // NOTE: maxAmountPence can be a minus if settlementFigure is greater than maxValidAmount, this case should be handled in CPL-1361
    return {
      errorName: SoftSearchValidationErrorName.MAX_AMOUNT_ERROR,
      maxAmountPence,
    };
  }

  const minValidLoanAmount = getMinLoanAmountFromRates(rates);
  if (loanPence < minValidLoanAmount) {
    const minAmountPence = minValidLoanAmount - settlementFigurePence;
    return {
      errorName: SoftSearchValidationErrorName.MIN_AMOUNT_ERROR,
      minAmountPence,
    };
  }

  const [minTermMonths, maxTermMonths] = getTermRestrictions(rates, loanPence);
  if (term < minTermMonths || term > maxTermMonths) {
    return {
      errorName: SoftSearchValidationErrorName.TERM_ERROR,
      minTerm: minTermMonths,
      maxTerm: maxTermMonths,
    };
  }
};
