import { jsx, Flex, Box, AlertCircle, Text } from 'compass-design';
import React from 'react';

export const ErrorMessage: React.FC = ({ children, ...props }) => {
  return (
    <Flex my={2} sx={{ alignItems: 'top' }}>
      <Box sx={{ flex: '0 0 auto' }}>
        <AlertCircle />
      </Box>
      <Text {...props} ml="2" pr={['0', null, '3']} sx={{ fontSize: '1', color: 'tone.negative' }}>
        {children}
      </Text>
    </Flex>
  );
};
