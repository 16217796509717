/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RemoteState } from '../types';
import { CompleteActionType } from './types';

/*

Stubbed response until a real one is available, at which point a standard Remote response should be used.

*/

const dummyResponse = {};

export function useFetchCompleteData<TResponse, TState extends RemoteState>(completeId: string) {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch({ type: CompleteActionType.REQUEST });

    return new Promise((resolve) => {
      setTimeout(() => {
        dispatch({
          type: CompleteActionType.LOAD,
          payload: {
            data: {
              applicationId: completeId,
              ...dummyResponse,
            },
          },
        });
        resolve(dummyResponse);
      }, 500);
    });
  }, [completeId, dispatch]);
}

export function useClearCompleteData() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch({ type: CompleteActionType.CLEAR }), [dispatch]);
}
