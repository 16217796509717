import { remoteReducer } from '../utils/remoteReducer';
import { BrokerFlags } from '../broker/actions';
import { BrokerActionType, BrokerFlagsAction, BrokerState } from './types';

export const reducer = remoteReducer<BrokerState>(BrokerActionType);

export function flagsReducer(state = {}, { type, payload }: BrokerFlagsAction): Partial<BrokerFlags> {
  if (!payload) {
    return state;
  }
  if (type === BrokerActionType.STORE_FLAGS) {
    return payload;
  }
  return state;
}
