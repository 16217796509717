import { Novuna } from '../../../utils/staticData';
import { SectionBuilder } from './SectionBuilder';

interface AdditionalInformationProps {
  address: Array<string>;
  complaintsEmail: string;
}

export const buildAdditionalInformation: SectionBuilder<AdditionalInformationProps> = ({
  address,
  complaintsEmail,
}) => {
  return {
    title: 'Additional information',
    description: 'Regarding the supervisory authority and complaint handling',
    testId: 'additional-information',
    items: [
      {
        value: 'a) Concerning the creditor',
        type: 'subheading',
      },
      {
        label: 'Registration number',
        value: ['Our Financial Services Register number is ', { type: 'bold', value: Novuna.FSRN }],
      },
      {
        label: 'The supervisory authority',
        value: 'The Financial Conduct Authority',
      },
      {
        value: 'b) Concerning the credit agreement',
        type: 'subheading',
      },
      {
        label:
          'The law taken by the creditor as a basis for the establishment of relations with you before the conclusion of the credit agreement',
        value: 'The law of the country in which you are domiciled, being England, Wales, Scotland or Northern Ireland.',
      },
      {
        label: 'The law applicable to the credit agreement and/or the competent court',
        value:
          'Our relations with you are based on the law of the country in the United Kingdom where you are domiciled, being England, Wales, Scotland or Northern Ireland and disputes may be referred to the courts of that country.',
      },
      {
        label: 'Language to be used in connection with the credit agreement',
        value:
          'The contractual terms and conditions of the credit agreement and the information contained on this page will be supplied to you in English. Unless you and we agree otherwise all communications relating to the credit agreement will be in English.',
      },
      {
        value: 'c) Concerning redress',
        type: 'subheading',
        label: '',
      },
      {
        label: 'Access to out-of-court complaint and redress mechanism',
        value: {
          label: `We operate our complaint handling procedure in line with the rules and guidance of the Financial Conduct Authority.
If you have a complaint about this agreement you can contact us in any of the following ways:`,
          type: 'list',
          value: [
            { type: 'listItem', value: ['Email us at: ', { type: 'email', value: complaintsEmail }] },
            { type: 'listItem', value: ['Call us on: ', { type: 'phone', value: '0344 375 5500' }] },
            {
              type: 'listItem',
              value: ['Or write to us at: ', { type: 'address', value: address }],
            },
          ],
        },
      },
      {
        label:
          'If our complaints process has not resolved it to your satisfaction then you have a right to complain to the Financial Ombudsman Service at the following address:',
        value: {
          label: 'The Financial Ombudsman Service',
          type: 'list',
          value: {
            type: 'address',
            value: ['Financial Ombudsman Service Exchange Tower', 'London', 'E14 9SR'],
          },
        },
      },
      {
        label: 'Or visit their website at',
        value: 'www.financial-ombudsman.org.uk',
        type: 'link',
      },
    ],
  };
};
