import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function useQueryParams<QueryParams>() {
  let rawValue = useLocation().search;
  rawValue = rawValue.replace(/^\?+/, '');

  const map = useMemo(() => {
    const pairs = rawValue.split('&').map((c) => c.trim().split('='));

    return pairs.reduce((o, [key, value]) => ({ ...o, [key]: decodeURIComponent(value) }), {});
  }, [rawValue]);

  return map as QueryParams;
}
