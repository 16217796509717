import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFetchPost } from '../utils/fetch';
import { PersonalisedQuoteActionType, PersonalisedQuoteState } from './types';
import { useCallbackPersonalisedQuoteSelector } from './selectors';

const extractPersonalisedQuote = (p: PersonalisedQuoteState) => p.data;

export function useClearPersonalisedQuote() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch({ type: PersonalisedQuoteActionType.CLEAR }), [dispatch]);
}

export function useFetchPersonalisedQuote() {
  return useFetchPost(
    '/personalised-quote',
    useCallbackPersonalisedQuoteSelector(),
    PersonalisedQuoteActionType,
    extractPersonalisedQuote
  );
}
