import React from 'react';
import { jsx, Heading, Box, Flex, NovunaHeading } from 'compass-design';
import { useTheme } from '../utils/useTheme';
import { useIsBeSavvi } from '../utils/useIsBesavvi';

export const PageTitle: React.FC = ({ children, ...props }) => {
  const isBesavvi = useIsBeSavvi();
  const theme = useTheme();

  if (isBesavvi) {
    return (
      <Heading
        as="h1"
        data-test-id="page-title"
        variant="primary"
        visualSize={5}
        underline={theme.headings?.underline || 'partial'}
        mb={5}
        {...props}
      >
        {children}
      </Heading>
    );
  }
  return (
    <NovunaHeading data-test-id="page-title" as="h1" mt={0} mb={3}>
      {children}
    </NovunaHeading>
  );
};

export const FormHeading: React.FC = ({ children, ...props }) => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <Heading as="h2" variant="secondary" visualSize={4} mb={5} {...props}>
        {children}
      </Heading>
    );
  }
  return (
    <NovunaHeading as="h2" mt={0} mb={2} {...props}>
      {children}
    </NovunaHeading>
  );
};

export const FormSubHeading: React.FC = ({ children, ...props }) => {
  return (
    <Heading as="h3" variant="secondary" visualSize={3} mb={5} {...props}>
      {children}
    </Heading>
  );
};

export type ContentHeadingProps = {
  variant?: string;
};

export const ContentHeading: React.FC<ContentHeadingProps> = ({ children, variant = 'secondary', ...props }) => {
  const isBesavvi = useIsBeSavvi();

  if (isBesavvi) {
    return (
      <Heading
        as="h2"
        variant={variant}
        visualSize={3}
        mb={4}
        sx={{ fontWeight: 'bold' }}
        underlineMargin="0.2em"
        {...props}
      >
        {children}
      </Heading>
    );
  }
  return (
    <Box mb={4}>
      <NovunaHeading as="h3">{children}</NovunaHeading>
    </Box>
  );
};

export const ContentSubHeading: React.FC = ({ children, ...props }) => (
  <Heading as="h3" visualSize={3} underline="none" mt={4} {...props}>
    {children}
  </Heading>
);

export const ModalSubHeading: React.FC = ({ children, ...props }) => (
  <Heading as="h3" variant="secondary" visualSize={3} mt={5} {...props}>
    {children}
  </Heading>
);

export const PlainHeading: React.FC = ({ children, ...props }) => (
  <Heading as="h3" variant="primary" visualSize={4} mt={2} mb={3} {...props}>
    {children}
  </Heading>
);

export type ProgressHeadingProps = {
  icon?: React.ReactNode;
  underline?: 'full' | 'partial' | 'none';
};

export const ProgressHeading: React.FC<ProgressHeadingProps> = ({ children, icon, underline = 'none', ...props }) => {
  const theme = useTheme();

  return (
    <Flex>
      {!!icon && <Box mr={3}>{icon}</Box>}
      <Heading
        as="h2"
        variant="tertiary"
        visualSize={4}
        underlineMargin="1"
        underline={theme.headings.underline || underline}
        mb={2}
        sx={{ fontWeight: 'bold', display: 'inline-block' }}
        {...props}
      >
        {children}
      </Heading>
    </Flex>
  );
};
