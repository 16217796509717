import { SelfServiceData } from '@plending/interfaces/self-service-data';
import { RemoteAction, RemoteState } from '../types';

export enum SelfServiceActionType {
  CLEAR = 'selfService/CLEAR',
  REQUEST = 'selfService/REQUEST',
  LOAD = 'selfService/LOAD',
  FAILED = 'selfService/FAILED',
}

export interface SelfServiceAction extends RemoteAction {
  type: SelfServiceActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface SelfServiceState extends RemoteState {
  loading: boolean;
  error?: { error: string };
  data?: Partial<SelfServiceData>;
}

export interface SelfServiceResponse {
  data: SelfServiceData;
}
