import React, { FC } from 'react';
import { jsx, Styled, Text, Message, NovunaHeading } from 'compass-design';
import { PageTitle, ProgressHeading } from '../../../../design-components/heading';
import { LeftBox, LeftRightSplit } from '../../../../design-components/LeftRightSplit';
import { LoanAmend } from './LoanAmend';

export interface PersonalisedQuoteDeclinedProps {
  isBesavvi: boolean;
}

export const PersonalisedQuoteDeclined: FC<PersonalisedQuoteDeclinedProps> = ({
  isBesavvi,
}: PersonalisedQuoteDeclinedProps) => {
  const heading = 'Unsuccessful';
  const message = [
    'Thank you for checking your eligibility with us.',
    'Unfortunately, based on the information you have given us we are unable to offer you a loan for the amount you have entered.',
  ].join(' ');
  const messageTestId = 'pq-decline-decision-message';

  return (
    <>
      <PageTitle>Soft search decision</PageTitle>
      {isBesavvi ? (
        <>
          <LeftRightSplit>
            <LeftBox>
              <ProgressHeading>{heading}</ProgressHeading>
              <Styled.p data-test-id={messageTestId}>{message}</Styled.p>
              <LoanAmend isBesavvi={true} />
            </LeftBox>
          </LeftRightSplit>
        </>
      ) : (
        <>
          <Message variant="info">
            <NovunaHeading as="h3" mb={2}>
              {heading}
            </NovunaHeading>
            <Text data-test-id={messageTestId} mb={4}>
              {message}
            </Text>
            <LoanAmend isBesavvi={false} />
          </Message>
        </>
      )}
    </>
  );
};
