import { PersonalisedQuoteSoftSearchResponse } from '@plending/interfaces/soft-search';
import { RemoteAction, RemoteState } from '../types';

export enum PersonalisedQuoteActionType {
  CLEAR = 'personalisedQuote/CLEAR',
  REQUEST = 'personalisedQuote/REQUEST',
  LOAD = 'personalisedQuote/LOAD',
  FAILED = 'personalisedQuote/FAILED',
}

export interface PersonalisedQuoteAction extends RemoteAction {
  type: PersonalisedQuoteActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface PersonalisedQuoteState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: PersonalisedQuoteSoftSearchResponse;
}
