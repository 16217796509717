export class ShouldMock {
  static readonly #externalDevToolsEnv = ['local', 'dev', 'test', 'syst4', 'demo'];
  static readonly #internalDevToolsEnv = ['local', 'dev'];

  readonly #area: 'ui' | 'server';

  // We perform this at run time to allow for easy mocking
  get #environment(): string {
    switch (this.#area) {
      case 'ui':
        return process.env.REACT_APP_STAGE ?? '';
      case 'server':
        return process.env.STAGE ?? '';
    }
  }

  constructor(area: 'ui' | 'server') {
    this.#area = area;
  }

  get showTesterUtilities() {
    return ShouldMock.#externalDevToolsEnv.includes(this.#environment);
  }

  get useDevMocks() {
    return ShouldMock.#internalDevToolsEnv.includes(this.#environment);
  }
}
