import {
  PreDecisionConfirmationAction,
  PreDecisionConfirmationState,
  PreDecisionConfirmationActionType,
} from './types';

export function reducer(
  state = {},
  { type, payload }: PreDecisionConfirmationAction
): Partial<PreDecisionConfirmationState> {
  switch (type) {
    case PreDecisionConfirmationActionType.STORE: {
      return payload as PreDecisionConfirmationState;
    }
    default:
      return state;
  }
}
