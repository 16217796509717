import { ApplicationStatus } from '@plending/interfaces/application';
import { RemoteState, RemoteAction } from '../types';

export interface ApplicationState {
  progress: ApplicationProgress;
  applicationStatus: ApplicationStatus;
}

export interface ApplicationProgress {
  personalDetails: RemoteState;
  financialDetails: RemoteState;
  pcciDetails: RemoteState;
  explanation: RemoteState;
  finalOffer: RemoteState;
}

export enum ApplicationActionType {
  SET_STATUS = 'application/SET_STATUS',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationAction extends RemoteAction {}
