import { jsx, ReactModal, Text, NovunaHeading, useIsModalOpen, Styled, Modal } from 'compass-design';
import React, { FC } from 'react';
import { FindButton } from '../../../../design-components/button';
import { ProgressHeading } from '../../../../design-components/heading';
import { LoanAmendModal } from './LoanAmendModal';

interface LoanAmendProps {
  isBesavvi?: boolean;
}

export const LoanAmend: FC<LoanAmendProps> = ({ isBesavvi }: LoanAmendProps) => {
  const [modalIsOpen, toggleModal] = useIsModalOpen();
  const handleAmend = () => {
    toggleModal(true);
  };

  const heading = 'Different loan amount';
  const message = 'To check another loan amount and term, click the amend button below.';
  const buttonText = 'Amend';

  const messageTestId = 'loan-amend-message';
  const buttonTestId = 'loan-amend-modal-button';
  const modalTestId = 'loan-amend-modal';

  return (
    <>
      {isBesavvi ? (
        <>
          <ProgressHeading>{`${heading}?`}</ProgressHeading>
          <Styled.p data-test-id={messageTestId}>{message}</Styled.p>
          <FindButton data-test-id={buttonTestId} onClick={handleAmend} sx={{ mt: 0 }}>
            {buttonText}
          </FindButton>
          <Modal
            size="medium"
            title={heading}
            isOpen={modalIsOpen}
            onSetIsOpen={toggleModal}
            data-test-id={modalTestId}
          >
            <LoanAmendModal isBesavvi={true} />
          </Modal>
        </>
      ) : (
        <>
          <NovunaHeading as="h3" mb={2}>
            {`${heading}?`}
          </NovunaHeading>
          <Text data-test-id={messageTestId} mb={4}>
            {message}
          </Text>
          <FindButton data-test-id={buttonTestId} onClick={handleAmend} sx={{ mt: 4 }}>
            {buttonText}
          </FindButton>
          <ReactModal
            data-test-id={modalTestId}
            isOpen={modalIsOpen}
            setIsOpen={toggleModal}
            size="medium"
            title={heading}
          >
            <LoanAmendModal isBesavvi={false} />
          </ReactModal>
        </>
      )}
    </>
  );
};
