import { FinalOffer } from '@plending/interfaces/application';
import { RemoteState } from '../types';

export enum FinalOfferActionType {
  CLEAR = 'finalOffer/CLEAR',
  REQUEST = 'finalOffer/REQUEST',
  LOAD = 'finalOffer/LOAD',
  FAILED = 'finalOffer/FAILED',
}

export interface FinalOfferAction {
  type: FinalOfferActionType;
  payload?: FinalOfferState;
}

export interface FinalOfferState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: FinalOffer;
}

export interface FinalOfferRequestBody {
  monthlyPence: number;
  termMonths: number;
  totalPence: number;
}
