import { DecisionOffer } from '@plending/interfaces/quote';
import { formatApr, penceToPoundsString } from '@plending/validation/quote-utils';
import { jsx, ReactModal, useIsModalOpen, Text, InfoBox, InfoSegment, SummaryRow, Box, Modal } from 'compass-design';
import React, { FC } from 'react';
import { ContinueButton } from '../../../design-components/button';
import { ModalSubHeading } from '../../../design-components/heading';
import { FinalOfferRequestBody } from '../../../store/finalOffer/types';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';

export interface ConvertQuoteProps {
  offer: DecisionOffer;
  applicationId: string;
  isFinalOfferLoading: boolean;
  confirmFinalOffer: (finalOffer: FinalOfferRequestBody) => void;
  finalOffer: FinalOfferRequestBody;
  isDisabled: boolean;
}
export const ConvertQuote: FC<ConvertQuoteProps> = ({
  applicationId,
  offer,
  isFinalOfferLoading,
  confirmFinalOffer,
  finalOffer,
  isDisabled,
}) => {
  const isBesavvi = useIsBeSavvi();
  const [modalIsOpen, toggleModal] = useIsModalOpen();

  const { termMonths, apr, loanPence } = offer;

  const loanPounds = penceToPoundsString(loanPence, false);
  const aprString = formatApr(apr);

  const handleContinue = (finalOffer: FinalOfferRequestBody, termMonths: number) => {
    if (termMonths !== finalOffer.termMonths) {
      toggleModal(true);
    } else {
      confirmFinalOffer(finalOffer);
    }
  };
  const handleTailoringModalContinue = (finalOffer: FinalOfferRequestBody) => {
    toggleModal(false);
    confirmFinalOffer(finalOffer);
  };

  return (
    <>
      <Box mt={isBesavvi ? 0 : 5}>
        <ContinueButton
          busy={isFinalOfferLoading}
          data-test-id="proceed-button"
          onClick={() => handleContinue(finalOffer, termMonths)}
          disabled={isDisabled}
        >
          Apply now
        </ContinueButton>
      </Box>
      {isBesavvi ? (
        <Modal
          size="medium"
          data-test-id="tailoring-confirmation-modal"
          title="Loan confirmation"
          isOpen={modalIsOpen}
          onSetIsOpen={toggleModal}
        >
          <Text sx={{ fontSize: 2, maxWidth: 'columns.6' }}>Check you are happy with your new loan details below.</Text>
          <ModalSubHeading sx={{ marginTop: 5 }}>Loan details</ModalSubHeading>
          <InfoBox
            data-test-id="tailoring-modal-offer-info"
            sx={{ marginTop: 5, marginBottom: 6, maxWidth: 'columns.6' }}
          >
            <InfoSegment data-test-id="tailoring-modal-loan-amount" label="Loan amount">
              {loanPounds}
            </InfoSegment>
            <InfoSegment data-test-id="tailoring-modal-term-length" label="Months">
              {finalOffer.termMonths}
            </InfoSegment>
            <InfoSegment data-test-id="tailoring-modal-monthly-amount" label="Monthly repayment">
              {penceToPoundsString(finalOffer.monthlyPence)}
            </InfoSegment>
            <InfoSegment data-test-id="tailoring-modal-total-amount" label="Total payable">
              {penceToPoundsString(finalOffer.totalPence)}
            </InfoSegment>
            <InfoSegment data-test-id="tailoring-modal-rep-apr" label="APR">
              {aprString}
            </InfoSegment>
            <InfoSegment label="Application ID">{applicationId}</InfoSegment>
          </InfoBox>
          <ContinueButton
            busy={isFinalOfferLoading}
            data-test-id="tailoring-modal-confirm-button"
            onClick={() => handleTailoringModalContinue(finalOffer)}
          >
            Confirm
          </ContinueButton>
        </Modal>
      ) : (
        <ReactModal
          isOpen={modalIsOpen}
          setIsOpen={toggleModal}
          size="medium"
          title="Loan confirmation"
          data-test-id="tailoring-confirmation-modal"
        >
          <Text sx={{ fontSize: 2, maxWidth: 'columns.6' }}>Check you are happy with your new loan details below.</Text>
          <ModalSubHeading sx={{ marginTop: 5 }}>Loan details</ModalSubHeading>
          <br></br>
          <Box mb={4}>
            <SummaryRow label="Application ID" value={applicationId} />
            <SummaryRow data-test-id="tailoring-modal-loan-amount" label="Loan amount" value={loanPounds} isWhite />
            <SummaryRow
              data-test-id="tailoring-modal-term-length"
              label="Months"
              value={String(finalOffer.termMonths)}
            />
            <SummaryRow data-test-id="tailoring-modal-rep-apr" label="APR" value={aprString} isWhite />
            <SummaryRow
              data-test-id="tailoring-modal-total-amount"
              label="Total payable"
              value={penceToPoundsString(finalOffer.totalPence)}
            />
            <SummaryRow
              data-test-id="tailoring-modal-monthly-amount"
              label="Monthly repayment"
              value={penceToPoundsString(finalOffer.monthlyPence)}
              isWhite
            />
            <ContinueButton
              busy={isFinalOfferLoading}
              data-test-id="tailoring-modal-confirm-button"
              onClick={() => handleTailoringModalContinue(finalOffer)}
            >
              Confirm
            </ContinueButton>
          </Box>
        </ReactModal>
      )}
    </>
  );
};
