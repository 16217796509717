import { DecisionOffer } from '@plending/interfaces/quote';
import { Supplier } from '@plending/interfaces/bootstrap-data';
import { formatApr, penceToPoundsString, termLabel } from '@plending/validation/quote-utils';
import { Novuna } from '../../utils/staticData';
import { buildContactInfo } from './sections/contactInfo';
import { buildKeyFeatures } from './sections/keyFeatures';
import { buildCreditCost } from './sections/creditCost';
import { buildLegalAspects } from './sections/legalAspects';
import { buildAdditionalInformation } from './sections/additionalInformation';

export type PCCIMultipleItemType = 'list' | 'listItem';
export type PCCIItemType = 'bold' | 'email' | 'phone' | 'link' | 'subheading';
export interface DefaultItem {
  label?: string;
  value: PCCIItem | PCCIItem[];
  description?: string;
}
export interface MultipleItem extends DefaultItem {
  type: PCCIMultipleItemType;
}
export interface SingleItem extends DefaultItem {
  type: PCCIItemType;
  value: PCCIItem;
}

export interface AddressItem extends DefaultItem {
  type: 'address';
  value: string[];
}

export type PCCIItem = DefaultItem | SingleItem | MultipleItem | AddressItem | string;

export interface Section {
  title: string;
  description: string;
  items: ReadonlyArray<PCCIItem | null>;
  testId: string;
}

export interface PCCIContent {
  sections: Section[];
}
export const getPCCIContent = (supplier: Supplier, decisionOffer: DecisionOffer): PCCIContent => {
  const { loanPence, monthlyPence, totalPence, termMonths, apr = 0 } = decisionOffer;
  const [loanPounds, monthlyPounds, totalPounds] = [loanPence, monthlyPence, totalPence].map((v) =>
    penceToPoundsString(v)
  );
  const {
    address,
    customerServiceContact: { complaintsEmail },
  } = Novuna;
  const aprString = formatApr(apr);
  const term = termLabel(termMonths);
  return {
    sections: [
      buildContactInfo(supplier),
      buildKeyFeatures({ loanPounds, monthlyPounds, totalPounds, termMonths, term }),
      buildCreditCost({ aprString }),
      buildLegalAspects(),
      buildAdditionalInformation({ address, complaintsEmail }),
    ],
  };
};
