import { Decision } from '@plending/interfaces/decision';
import React, { useMemo } from 'react';
import { jsx, Styled, Text, DownloadCircle, Message, NovunaHeading, Button } from 'compass-design';
import { useDecisionSelector } from '../../../store/decision/selectors';
import { DecisionState } from '../../../store/decision/types';
import { useDownload } from '../../../utils/useDownload';
import { ErrorMessage } from '../../../design-components/ErrorMessage';
import { PageTitle, ProgressHeading } from '../../../design-components/heading';
import { LeftRightSplit, LeftBox, RightBox } from '../../../design-components/LeftRightSplit';
import { PanelButton } from '../../../design-components/PanelButton';
import { useAlternativeLoanProvider } from '../../../store/bootstrap/selectors';
import { AlternativeLoanProvider } from '../AlternativeLoanProvider';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';

export const DecisionDecline: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const alternativeLoanProviderEnabled = useAlternativeLoanProvider();

  const {
    data: { applicationId, showAlternativeLoanProvider: decisionCorrectAlternativeLoan } = {} as Decision,
  }: DecisionState = useDecisionSelector();

  const showAlternativeLoanProvider = useMemo(
    () => alternativeLoanProviderEnabled && decisionCorrectAlternativeLoan,
    [alternativeLoanProviderEnabled, decisionCorrectAlternativeLoan]
  );

  const [downloadDeclineLetter, declineLetterDownloading, downloadError] = useDownload(
    '/get-decline-letter',
    `${applicationId}.pdf`
  );

  const novunaDecline = (
    <Message variant="info">
      <NovunaHeading as="h3" mb={2}>
        Unsuccessful
      </NovunaHeading>
      <Text data-test-id="decision-message" mb={4}>
        Thank you for applying with us. Unfortunately based on the information you have given us we are unable to offer
        you a loan.
      </Text>
      <NovunaHeading as="h3" mb={2}>
        About the decision
      </NovunaHeading>
      <Text mb={4}>This letter gives you more information on how we came to our decision.</Text>
      <Button
        data-test-id="download-panel-button"
        variant="secondary"
        onClick={downloadDeclineLetter}
        disabled={declineLetterDownloading}
      >
        Download letter
      </Button>
      {downloadError && <ErrorMessage>Something went wrong. Try again?</ErrorMessage>}
    </Message>
  );

  return (
    <>
      <PageTitle>Application decision</PageTitle>

      {isBesavvi ? (
        <LeftRightSplit>
          <LeftBox>
            <ProgressHeading>Unsuccessful</ProgressHeading>

            <Styled.p data-test-id="decision-message">
              Thank you for applying with us. Unfortunately based on the information you have given us we are unable to
              offer you a loan.
            </Styled.p>
          </LeftBox>
          <RightBox>
            <PanelButton
              data-test-id="download-panel"
              title="Decline letter"
              label={declineLetterDownloading ? 'Downloading...' : 'Download'}
              disabled={declineLetterDownloading}
              icon={<DownloadCircle />}
              onClick={downloadDeclineLetter}
            >
              <Text>This letter gives you more information on how we came to our decision.</Text>
              {downloadError && <ErrorMessage>Something went wrong. Try again?</ErrorMessage>}
            </PanelButton>
          </RightBox>
        </LeftRightSplit>
      ) : (
        novunaDecline
      )}

      {showAlternativeLoanProvider && <AlternativeLoanProvider />}
    </>
  );
};
