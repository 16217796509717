import { useCallback } from 'react';
import z from 'zod';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../utils/serviceCall';
import { getToken } from '../../utils/tokens';
import { getIovationFingerprint } from '../../utils/getIovationFingerprint';
import { useCallbackDecisionRequestPayloadSelector } from '../decision/selectors';
import { DecisionActionType } from '../decision/types';

const appQueueingFeatureFlag = z.object({
  isFeatureEnabled: z.boolean(),
  websocketUrl: z.optional(z.string()),
  applicationId: z.optional(z.string()),
});

type FeatureFlag = z.infer<typeof appQueueingFeatureFlag>;

const appSuccessEvent = z.object({
  application_id: z.string(),
  data: z.object({
    type: z.literal('DECISION'),
    decision: z.unknown(),
  }),
});

type AppSuccessEvent = z.infer<typeof appSuccessEvent>;

const appErrorEvent = z.object({
  application_id: z.string(),
  data: z.object({
    type: z.literal('DECISION_ERROR'),
    errorType: z.string(),
  }),
});

type AppErrorEvent = z.infer<typeof appErrorEvent>;

const appEvent = z.union([appSuccessEvent, appErrorEvent]);

const getFeatureFlag = async () => {
  try {
    const data = await fetchData('/features/app-queueing');
    return appQueueingFeatureFlag.parse(data);
  } catch {
    return {
      isFeatureEnabled: false,
    };
  }
};

export function useAppQueueingSubmission() {
  const getDecisionRequest = useCallbackDecisionRequestPayloadSelector();

  const dispatch = useDispatch();

  const openConnection = useCallback(
    async (
      featureFlag: FeatureFlag,
      onSuccess: (data: AppSuccessEvent) => void,
      onFail: (data: AppErrorEvent) => void
    ) => {
      const applicationId = featureFlag.applicationId ?? '';
      const url = new URL(featureFlag.websocketUrl ?? '');
      url.searchParams.set('filter', applicationId);
      url.searchParams.set('token', getToken());
      const websocket = new WebSocket(url);

      websocket.onmessage = (ev) => {
        const parsed = appEvent.safeParse(
          JSON.parse(ev.data, (key, value) => (key === 'data' && typeof value === 'string' ? JSON.parse(value) : value))
        );
        if (!parsed.success) {
          // eslint-disable-next-line no-console
          console.error('Failed to parse', ev.data);
          return;
        }

        const data = parsed.data;

        if (applicationId !== data.application_id) return;

        switch (data.data.type) {
          case 'DECISION':
            onSuccess(data as AppSuccessEvent);
            websocket.close();
            break;
          case 'DECISION_ERROR':
            switch (data.data.errorType) {
              case 'SystemErrorEvent':
                break;
              case 'BusinessErrorEvent':
                dispatch({ type: DecisionActionType.BUSINESS_ERROR });
                break;
              case 'FatalErrorEvent':
              default:
                onFail(data as AppErrorEvent);
                break;
            }
        }
      };

      await new Promise<void>((res) => {
        websocket.onopen = () => res();
      });
    },
    [dispatch]
  );

  const submit = useCallback(
    async ({ topUpLoanType }: { topUpLoanType?: string }) => {
      dispatch({ type: DecisionActionType.REQUEST });
      const featureFlag = await getFeatureFlag();
      if (!featureFlag.isFeatureEnabled) {
        dispatch({ type: DecisionActionType.LEGACY });
        return 'unhandled' as const;
      }

      await openConnection(
        featureFlag,
        (event) => {
          dispatch({
            type: DecisionActionType.LOAD,
            payload: { data: event.data.decision },
          });
        },
        (event) => {
          dispatch({
            type: DecisionActionType.FAILED,
            payload: { error: event.data.errorType, data: '' },
          });
        }
      );

      fetchData('/decision', {
        method: 'POST',
        body: JSON.stringify({ ...getDecisionRequest(), ...getIovationFingerprint(), topUpLoanType }) as unknown,
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      } as Request);
    },
    [dispatch, openConnection, getDecisionRequest]
  );

  return { submit };
}
