import { useSelector } from 'react-redux';
import { RootState } from '../rootState';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { PersonalisedQuoteState } from './types';

const selectPersonalisedQuote = (s: RootState) => s.personalisedQuote;

export function usePersonalisedQuoteSelector(): PersonalisedQuoteState {
  return useSelector(selectPersonalisedQuote);
}

export function useCallbackPersonalisedQuoteSelector(): () => PersonalisedQuoteState {
  return useCallbackSelector(selectPersonalisedQuote);
}
