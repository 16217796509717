import React from 'react';
import { jsx, Message, NovunaHeading, Styled, Text } from 'compass-design';
import { PageTitle, ProgressHeading } from '../../../design-components/heading';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';

export const DecisionRefer: React.FC = () => {
  const isBesavvi = useIsBeSavvi();

  const title = 'Referred';

  const text =
    'Your application has been received and we’ll get in touch with you if we need any more information. We’ll aim to be in contact within the next 48 hours.';

  return (
    <>
      <PageTitle>Application decision</PageTitle>

      {isBesavvi ? (
        <>
          <ProgressHeading>{title}</ProgressHeading>
          <Styled.p data-test-id="decision-message">{text}</Styled.p>
        </>
      ) : (
        <Message variant="info">
          <NovunaHeading as="h3" mb={1}>
            {title}
          </NovunaHeading>
          <Text data-test-id="decision-message">{text}</Text>
        </Message>
      )}
    </>
  );
};
