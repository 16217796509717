import { jsx, Button, DownloadCircle, Message, Text } from 'compass-design';
import React from 'react';
import { ErrorMessage } from '../../../design-components/ErrorMessage';
import { PanelButton } from '../../../design-components/PanelButton';
import {
  useAgreementDownloadDataSelector,
  useAgreementDownloadRetriesSelector,
} from '../../../store/agreementDownload/selectors';
import { AgreementDownloadRemoteState } from '../../../store/agreementDownload/types';
import { useCompleteDataSelector } from '../../../store/complete/selectors';
import { useDownload } from '../../../utils/useDownload';
import { useIsBeSavvi } from '../../../utils/useIsBesavvi';

export const DownloadAgreement: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const {
    data: { applicationId },
  } = useCompleteDataSelector();

  const {
    loading: downloadLoading,
    error: downloadError,
    data: { downloadUrl } = {} as NonNullable<AgreementDownloadRemoteState['data']>,
  }: AgreementDownloadRemoteState = useAgreementDownloadDataSelector();

  const downloadRetries = useAgreementDownloadRetriesSelector();

  const [downloadAgreement, agreementDownloading, agreementDownloadError] = useDownload(
    '/get-document',
    `${applicationId}.pdf`
  );

  const linkError = !!downloadError || agreementDownloadError;
  const showLink = !!downloadUrl && !linkError && !downloadLoading;

  if (isBesavvi) {
    return (
      <>
        <PanelButton
          data-test-id="download-panel"
          title="Your copy"
          label={agreementDownloading ? 'Downloading...' : 'Download'}
          disabled={!showLink || agreementDownloading}
          icon={<DownloadCircle />}
          onClick={downloadAgreement}
        >
          {downloadLoading && (
            <Text>
              {downloadRetries < 2
                ? 'Your signed agreement will be available to download shortly.'
                : 'Still trying, it’s taking a little longer than usual...'}
            </Text>
          )}
          {showLink && <Text>Download the agreement for your records.</Text>}
          {linkError && (
            <ErrorMessage>
              There was an error retrieving your agreement. Please try again once you have received an email.
            </ErrorMessage>
          )}
        </PanelButton>
      </>
    );
  }
  if (linkError) {
    return (
      <Message data-test-id="error-message" variant="error">
        There was an error retrieving your agreement. Please try again once you have received an email.
      </Message>
    );
  }

  return (
    <Button
      variant="secondary"
      data-test-id="download-panel-button"
      disabled={!showLink || agreementDownloading}
      onClick={downloadAgreement}
    >
      {agreementDownloading ? 'Downloading...' : 'Download agreement'}
    </Button>
  );
};
