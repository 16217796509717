import { jsx, InfoBox, InfoSegment, Box, SummaryRow, Text } from 'compass-design';
import React from 'react';
import { FormSubHeading } from '../../design-components/heading';
import { QuotationDetails } from '../../store/quotation/types';

interface QuotationSummaryProps {
  isBesavvi: boolean;
  quote?: QuotationDetails | null;
}

export const QuotationSummary: React.FC<QuotationSummaryProps> = ({ isBesavvi, quote }) => {
  return (
    <>
      <FormSubHeading>Quote information</FormSubHeading>
      {isBesavvi ? (
        <InfoBox data-test-id="quote-info" sx={{ marginBottom: [2, null, 6], maxWidth: 'columns.6' }}>
          <InfoSegment data-test-id="quote-info-term" label="Months">
            {quote ? quote.termMonths : '-'}
          </InfoSegment>
          <InfoSegment data-test-id="quote-info-monthly-repayment" label="Monthly repayment">
            {quote ? quote.monthlyPounds : '-'}
          </InfoSegment>
          <InfoSegment data-test-id="quote-info-apr" label="Representative APR*">
            {quote ? quote.aprString : '-'}
          </InfoSegment>
          <InfoSegment data-test-id="quote-info-total-payable" label="Total payable">
            {quote ? quote.totalPounds : '-'}
          </InfoSegment>
        </InfoBox>
      ) : (
        <Box mb={4} data-test-id="quote-info">
          <SummaryRow
            data-test-id="quote-info-term"
            label="Term"
            value={quote?.termMonths ? `${quote.termMonths} Months` : ''}
            isWhite
          />
          <SummaryRow
            data-test-id="quote-info-apr"
            label="Representative APR*"
            value={quote?.aprString ? `${quote.aprString} APR` : ''}
          />
          <SummaryRow
            data-test-id="quote-info-total-payable"
            label="Total payable"
            value={quote?.totalPounds ?? ''}
            isWhite
          />
          <SummaryRow
            data-test-id="quote-info-monthly-repayment"
            label="Monthly repayment"
            value={quote?.monthlyPounds ?? ''}
          />
        </Box>
      )}

      <Text my={4} sx={{ fontSize: 2 }}>
        Credit is subject to status and an assessment of your financial circumstances.
      </Text>
      <Text my={4} sx={{ fontSize: 2 }}>
        *The APR we offer you will depend on your credit assessment, the loan amount and duration of your loan. We can
        only confirm the APR once you have gone through our application process.
      </Text>
      <Text my={4} sx={{ fontSize: 2 }}>
        If at the end of the process we have offered you a higher APR, you may be able to increase the term of your loan
        to reduce the monthly instalment. Extending the term of the loan will increase the total amount payable.
      </Text>
    </>
  );
};
