import { useSelector } from 'react-redux';
import { FinancialDetails } from '@plending/interfaces/application';
import { RootState } from '../rootState';
import { RemoteState } from '../types';
import { useCallbackSelector } from '../utils/useCallbackSelector';

const selectFinancialDetails = (s: RootState) => s.financialDetails;

export function useFinancialDetailsSelector(): FinancialDetails {
  return useSelector(selectFinancialDetails);
}

const selectCallbackFinancialDetails = ({ application }: RootState): RemoteState => {
  return application.progress.financialDetails;
};

export function useCallbackFinancialDetailsSelector(): () => RemoteState {
  return useCallbackSelector(selectCallbackFinancialDetails);
}
