import { useSelector } from 'react-redux';
import { useCallbackSelector } from '../utils/useCallbackSelector';
import { RootState } from '../rootState';
import { useFetchOnMount } from '../../utils/useFetchOnMount';
import { CompleteState } from './types';
import { useFetchCompleteData } from './actions';

const selectCompleteData = (a: RootState) => a.complete;

export function useCompleteDataSelector(): CompleteState {
  return useSelector(selectCompleteData);
}

export function useCallbackCompleteDataSelector(): () => CompleteState {
  return useCallbackSelector(selectCompleteData);
}

export function useGetCompleteData(completeId: string): CompleteState {
  const fetchCompleteData = useFetchCompleteData(completeId);

  return useFetchOnMount(selectCompleteData, fetchCompleteData);
}
