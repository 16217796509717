import React, { FC } from 'react';
import { jsx, AccordionGroup, AccordionGroupEntry } from 'compass-design';
import { ContentRow, UserData } from './PCCIComponents';
import { PCCIContent, PCCIItem } from './PCCIText';

interface PCCIViewProps {
  content: PCCIContent;
  isBesavvi: boolean;
}

const isitem = (i: PCCIItem | null): i is PCCIItem => Boolean(i);

export const PCCIView: FC<PCCIViewProps> = ({ content, isBesavvi }) => {
  const { sections } = content;
  return (
    <AccordionGroup headingAs="h2">
      {sections.map((section, i) => (
        <AccordionGroupEntry
          key={i}
          title={section.title}
          initiallyOpen
          sectionNumber={i + 1}
          description={section.description}
          headingVariant="tertiary"
          breakAfter={true}
        >
          <div data-test-id={section.testId}>
            {section.items.filter(isitem).map((item, j) => (
              <ContentRow isBesavvi={isBesavvi} key={`row-${i}-${j}`}>
                <UserData data={item} />
              </ContentRow>
            ))}
          </div>
        </AccordionGroupEntry>
      ))}
    </AccordionGroup>
  );
};
