import React, { useEffect, useCallback } from 'react';
import { jsx, Card, Heading, Text, Flex, Box, AlertCircle, Spinner, NovunaHeading } from 'compass-design';
import { AlternativeLoanData } from '@plending/interfaces/alternative-loan';
import { ContinueButton } from '../../design-components/button';
import { usePreDecisionConfirmationSelector } from '../../store/preDecisionConfirmation/selectors';
import { useFetchAlternativeLoanData } from '../../store/alternativeLoan/actions';
import { useAlternativeLoanDataSelector } from '../../store/alternativeLoan/selectors';
import { Image } from '../../design-components/Image';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import logo1x from './images/Aro_Logo.png';
import logo2x from './images/Aro_Logo@2x.png';
import logo3x from './images/Aro_Logo@3x.png';

interface OptInProps {
  buttonCallback: () => void;
  error?: boolean;
}

interface EligibleProps {
  redirectUrl: string;
}

const Logo: React.FC = () => (
  <Box my={4}>
    <Image alt="Aro" height="64" width="228" x1src={logo1x} x2src={logo2x} x3src={logo3x} />
  </Box>
);

const OptIn: React.FC<OptInProps> = ({ error = false, buttonCallback }) => {
  const isBesavvi = useIsBeSavvi();

  return (
    <>
      {isBesavvi ? (
        <Heading
          data-test-id="alternative-loan-heading"
          as="h3"
          visualSize={3}
          mt={0}
          sx={{ fontWeight: 'bold' }}
          underline="none"
        >
          Alternative loan or credit card from Aro
        </Heading>
      ) : (
        <NovunaHeading data-test-id="alternative-loan-heading" as="h2" mb={3} mt={5}>
          Alternative loan or credit card from Aro
        </NovunaHeading>
      )}
      <Logo />
      <Text mb={4}>
        Our partner, Aro, a UK credit broker may be able to find you an alternative loan or credit card. Click ‘Check my
        eligibility’ if you are happy for your details to be sent to them. This will not impact your credit score.
      </Text>
      <ContinueButton data-test-id="alternative-loan-btn" onClick={buttonCallback}>
        Check my eligibility
      </ContinueButton>
      <Text mt={4} sx={{ fontWeight: 'bold' }}>
        Or call on 0800 587 8755.
      </Text>

      {!!error && (
        <Flex mt={4} data-test-id="alternative-loan-error">
          <Box sx={{ flex: '0 0 auto' }}>
            <AlertCircle />
          </Box>
          <Text ml="2" pr={['0', null, '3']} sx={{ fontSize: '1', color: 'tone.negative' }}>
            Unfortunately there has been an error and your information has not been sent to Aro. Please try again later.
            <br />
            <br />
            Alternatively if you would like to discuss your options with Aro, please call them on 0800 587 8755.
          </Text>
        </Flex>
      )}
    </>
  );
};

const Loading: React.FC = () => {
  return (
    <>
      <Flex>
        <Spinner mr={3} />
        <Heading
          data-test-id="alternative-loan-heading"
          as="h3"
          visualSize={3}
          mt="0.5em"
          sx={{ fontWeight: 'bold' }}
          underline="none"
        >
          Hold on...
        </Heading>
      </Flex>
      <Logo />
      <Text>Aro is checking your eligibility. This shouldn’t take long.</Text>
    </>
  );
};

const useRedirectToAlternativeLoanProvider = (redirectUrl: string) => {
  return useCallback(() => {
    window.location.replace(redirectUrl);
  }, [redirectUrl]);
};

const Eligible: React.FC<EligibleProps> = ({ redirectUrl }) => {
  const redirect = useRedirectToAlternativeLoanProvider(redirectUrl);

  return (
    <>
      <Heading
        data-test-id="alternative-loan-heading"
        as="h3"
        visualSize={3}
        mt={0}
        sx={{ fontWeight: 'bold' }}
        underline="none"
      >
        Good news, Aro may be able to offer you an alternative loan or credit card
      </Heading>
      <Logo />

      <Text mb={4}>
        Our partner Aro, a UK based credit broker, maybe able to offer you an alternative loan or credit card. To see
        the options available to you, click ‘Next’ and follow their instructions. Alternatively, Aro will contact you
        within the next 48 hours to discuss the options available to you.
      </Text>
      <ContinueButton data-test-id="alternative-loan-link" onClick={redirect}>
        Next
      </ContinueButton>
      <Text mt={4} sx={{ fontWeight: 'bold' }}>
        Or call on 0800 587 8755.
      </Text>
    </>
  );
};

const Declined: React.FC = () => {
  return (
    <>
      <Heading
        data-test-id="alternative-loan-heading"
        as="h3"
        visualSize={3}
        mt={0}
        sx={{ fontWeight: 'bold' }}
        underline="none"
      >
        Sorry, Aro are unable to offer you a loan or credit card
      </Heading>
      <Logo />

      <Text data-test-id="alternative-loan-decline-message" mb={4}>
        Thank you for your interest but unfortunately Aro do not have a lender on their panel that is able to offer you
        a loan or credit card.
      </Text>
    </>
  );
};

export const AlternativeLoanProvider: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  const { alternativeLoanProviderOptIn } = usePreDecisionConfirmationSelector();
  const fetchAlternativeLoanDecision = useFetchAlternativeLoanData();

  useEffect(
    () => {
      if (alternativeLoanProviderOptIn) {
        fetchAlternativeLoanDecision();
      }
    },
    // Only what this to be called once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { loading, data = {} as AlternativeLoanData, error } = useAlternativeLoanDataSelector();

  const eligible = data && data.redirectUrl && data.accepted && !loading && !error;
  const declined = !loading && data && data.accepted === false && !error;
  const showOptIn =
    (!alternativeLoanProviderOptIn && !eligible && !declined && !loading) || (!!error && (!eligible || !declined));
  const isLoading = loading && !error;

  const content = (
    <>
      {showOptIn && <OptIn buttonCallback={fetchAlternativeLoanDecision} error={!!error} />}
      {eligible && <Eligible redirectUrl={data.redirectUrl || ''} />}
      {declined && <Declined />}
      {isLoading && <Loading />}
    </>
  );

  return isBesavvi ? (
    <Card data-test-id="alternative-loan" variant="secondary" p={4} mt={6}>
      {content}
    </Card>
  ) : (
    <div data-test-id="alternative-loan">{content}</div>
  );
};
