import 'react-app-polyfill/stable';
import { jsx } from 'compass-design';
import ReactDOM from 'react-dom';
import { App } from './App';

if (process.env.NODE_ENV === 'development') {
  import('./utils/devtools');
}

try {
  ReactDOM.render(<App />, document.getElementById('root'));
  document.body.className = '';
} catch (e) {
  // noop - handled in index.html
}
