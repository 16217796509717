import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { JSONSerializable } from '@plending/services/types/json';
import { fetchData } from '../../utils/serviceCall';
import { RemoteActionType, RemoteState } from '../types';

const defaultFetchOptions = (_body: JSONSerializable): Request => ({} as Request);

/**
 * Checks the redux state using the given selector and will use that as a cache if populated, unless `forceReload` is true.
 * @returns a function that takes `forceReload` and `body` to the requesting function. The body is sent to the API endpoint as the request body.
 * @param path string The route to call - missing /api/ from routes.ts
 * @param callbackSelector The redux selector for the data we want to fetch
 * @param ActionType an enum with the redux action types we want to use
 * @param extractData a function to get the data that we need for this request from the data returned by redux.
 * @param fetchOptions populated by the deriving functions, what type of fetch call we are making
 */
export function useFetch<TResponse, TState extends RemoteState>(
  path: string,
  callbackSelector: () => TState,
  ActionType: RemoteActionType,
  extractData: (r: TResponse) => TState['data'],
  fetchOptions = defaultFetchOptions
) {
  const dispatch = useDispatch();

  return useCallback(
    (forceReload = false, body?) => {
      const { data, loading } = callbackSelector();

      if ((forceReload || !data) && !loading) {
        dispatch({ type: ActionType.REQUEST });

        return fetchData<TResponse>(path, fetchOptions(body)).then(
          (newData) => {
            const data = extractData(newData);

            dispatch({
              type: ActionType.LOAD,
              payload: {
                data,
              },
            });

            return data;
          },
          (error) => {
            dispatch({
              type: ActionType.FAILED,
              payload: { error },
            });
          }
        );
      }

      return Promise.resolve();
    },
    [callbackSelector, dispatch, ActionType, path, extractData, fetchOptions]
  );
}

/**
 * Checks the redux state using the given selector and will use that as a cache if populated, unless `forceReload` is true.
 * @returns a function that takes `forceReload` and `body` to the requesting function. The body is sent to the API endpoint as the request body.
 * @param path string The route to call - missing /api/ from routes.ts
 * @param callbackSelector The redux selector for the data we want to fetch
 * @param ActionType an enum with the redux action types we want to use
 * @param extractData a function to get the data that we need for this request from the data returned by redux.
 */
export function useFetchGet<TResponse, TState extends RemoteState>(
  path: string,
  callbackSelector: () => TState,
  ActionType: RemoteActionType,
  extractData: (r: TResponse) => TState['data']
) {
  const getOptions = useCallback(
    (body: JSONSerializable): Request =>
      ({
        method: 'GET',
        body: JSON.stringify(body) as unknown,
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      } as Request),
    []
  );
  return useFetch(path, callbackSelector, ActionType, extractData, getOptions);
}

/**
 * Checks the redux state using the given selector and will use that as a cache if populated, unless `forceReload` is true.
 * @returns a function that takes `forceReload` and `body` to the requesting function. The body is sent to the API endpoint as the request body.
 * @param path string The route to call - missing /api/ from routes.ts
 * @param callbackSelector The redux selector for the data we want to fetch
 * @param ActionType an enum with the redux action types we want to use
 * @param extractData a function to get the data that we need for this request from the data returned by redux.
 */
export function useFetchPost<TResponse, TState extends RemoteState, TBody extends JSONSerializable>(
  path: string,
  callbackSelector: () => TState,
  ActionType: RemoteActionType,
  extractData: (r: TResponse) => TState['data']
) {
  const getOptions = useCallback(
    (body: TBody): Request =>
      ({
        method: 'POST',
        body: JSON.stringify(body) as unknown,
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      } as Request),
    []
  );

  return useFetch(path, callbackSelector, ActionType, extractData, getOptions);
}

/**
 * Checks the redux state using the given selector and will use that as a cache if populated, unless `forceReload` is true.
 * @returns a function that takes `forceReload` and `body` to the requesting function. The body is sent to the API endpoint as the request body.
 * @param path string The route to call - missing /api/ from routes.ts
 * @param callbackSelector The redux selector for the data we want to fetch
 * @param ActionType an enum with the redux action types we want to use
 * @param extractData a function to get the data that we need for this request from the data returned by redux.
 */
export function useFetchPut<TResponse, TState extends RemoteState>(
  path: string,
  callbackSelector: () => TState,
  ActionType: RemoteActionType,
  extractData: (r: TResponse) => TState['data']
) {
  const getOptions = useCallback(
    (body: JSONSerializable): Request =>
      ({
        method: 'PUT',
        body: JSON.stringify(body) as unknown,
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      } as Request),
    []
  );

  return useFetch(path, callbackSelector, ActionType, extractData, getOptions);
}
