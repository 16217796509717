import { Required, IsChecked } from '@plending/validation/rules';
import React from 'react';
import { FieldPropSet } from './types';

export const DEFAULT_MESSAGES: Record<string, string> = {
  'Required': 'This is a required field',
  'Not an email': 'Please enter a valid email address',
  'Not a phone number': "Please enter a valid 10 or 11 digit phone number, starting with '0'",
  'Not a mobile phone number': "Please enter a valid 11 digit mobile phone number, starting with '07'",
  'Invalid postcode': 'Please enter a valid postcode',
  'Longer than 100 years': 'Please enter a date less than 100 years ago',
};

export function numberTransform(value: string) {
  return value.replace(/[^0-9]/g, '');
}

const PHONE_TRANSFORM_REGEX = /[^0-9 -]/g;
export function phoneTransform(value: string) {
  return `${value}`.replace(PHONE_TRANSFORM_REGEX, '');
}

export function postcodeTransform(value: string) {
  return value.toUpperCase();
}

export function normalisePunctuation(value: string) {
  // replace dashes with hyphens
  // replace accent and quotation marks with ascii apostrophe
  return value.replace(/[—–]/g, '-').replace(/[´`’‘]/g, "'");
}

export function simpleRequiredField(name: string, initialValue?: string) {
  return {
    name,
    initialValue,
    rules: [Required],
  };
}

export function simpleOptionalField(name: string, initialValue?: string) {
  return {
    name,
    initialValue,
    rules: [],
  };
}

export function simpleCheckField(name: string, initialValue?: boolean) {
  return {
    name,
    initialValue,
    rules: [],
  };
}

export function requiredCheckField(name: string, initialValue?: boolean, messages?: Record<string, string>) {
  return {
    name,
    initialValue,
    rules: [IsChecked],
    messages,
  };
}

export function onNumberPaste(event: React.ClipboardEvent<HTMLInputElement>, field: FieldPropSet, length: number) {
  event.preventDefault();
  let numericOnly = event.clipboardData.getData('Text').replace(/\D/g, '');

  // This handles pasting when text in the field already exists
  const input = event.currentTarget;
  const start = input.selectionStart || 0;
  const originString = input.value;
  const end = input.selectionEnd || originString.length;
  const stringStart = originString.substring(0, start);
  const stringEnd = originString.substring(end, originString.length);
  numericOnly = numericOnly.slice(0, length - stringStart.length - stringEnd.length);
  const newString = stringStart + numericOnly + stringEnd;

  field.setFieldValue(field.name, newString);

  // update cursor to position where text will be after paste
  const currentPosition = (originString.substring(0, start) + numericOnly).length;
  setTimeout(function () {
    input.focus();
    input.setSelectionRange(currentPosition, currentPosition);
  }, 0);
}
