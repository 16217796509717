import React, { useCallback } from 'react';
import { jsx, Box, Message, NovunaHeading, Text } from 'compass-design';
import { FormHeading } from '../../../../design-components/heading';
import { ContinueButton } from '../../../../design-components/button';
import { PreDecisionConfirmationState } from '../../../../store/preDecisionConfirmation/types';
import { isoNow } from '../../../../utils/serverTime';
import { useStorePreDecisionConfirmation } from '../../../../store/preDecisionConfirmation/actions';
import { useFetchPersonalisedQuote } from '../../../../store/personalisedQuote/actions';
import { PERSONALISED_QUOTE_COMPONENTS } from '../../../Routing/Routes';
import { useNavigation, getPath } from '../../../Routing/useNavigation';

interface QuoteDescriptionProps {
  acceptLikelihood: number;
  isValidQuote?: boolean;
}

export const QuoteDescription: React.FC<QuoteDescriptionProps> = ({ acceptLikelihood, isValidQuote }) => {
  const storeConfirmation = useStorePreDecisionConfirmation();
  const fetchPersonalisedQuote = useFetchPersonalisedQuote();
  const { navigateTo } = useNavigation();

  const onRefreshSubmit = useCallback(
    ({ alternativeLoanProviderOptIn }) => {
      const toStore: PreDecisionConfirmationState = {
        bankDetails: isoNow(),
        creditSearch: isoNow(),
        circumstances: isoNow(),
        alternativeLoanProviderOptIn,
      };

      storeConfirmation(toStore);
      fetchPersonalisedQuote(true);
      navigateTo(getPath(PERSONALISED_QUOTE_COMPONENTS.personalisedQuote, { personalisedQuote: 'true' }) as string);
    },
    [storeConfirmation, fetchPersonalisedQuote, navigateTo]
  );

  switch (true) {
    case isValidQuote === false:
      return (
        <Message variant="error" mb={4}>
          <NovunaHeading as="h3" mb={1}>
            Sorry, the quote has expired
          </NovunaHeading>
          <Text data-test-id="pq-expired-error-message">Please refresh your results below.</Text>
          <Box mt={5} mb={2}>
            <ContinueButton data-test-id="pq-refresh-button" onClick={onRefreshSubmit}>
              Refresh
            </ContinueButton>
          </Box>
        </Message>
      );
    case Number(acceptLikelihood) <= 50:
      return (
        <Box mb={4}>
          <Text data-test-id="pq-likelihood-decision-message">
            You may be accepted with us, but you will need to submit the application for a full search before we can
            confirm our decision. <br /> <b>The rates offered are only valid at the time of application.</b>
          </Text>
        </Box>
      );
    default:
      return (
        <Box mb={4}>
          <FormHeading>Good news</FormHeading>
          <Text data-test-id="pq-likelihood-decision-message">
            You have been pre-approved for a loan with us at the APR shown below. <br />{' '}
            <b>The rates offered are only valid at the time of application.</b>
          </Text>
        </Box>
      );
  }
};
