import React, { ChangeEvent, FocusEvent, useCallback, ClipboardEvent } from 'react';
import { jsx, InputWrapper, TextField, Box, Tone } from 'compass-design';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';
import { useTone, useHandleChange } from './hooks';

type SingleInputProps = {
  name: string;
  label: string;
  description?: string;
  prefix?: string;
  value: string;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  autoComplete?: string;
  maxLength?: number;
  halfWidth?: boolean;
  stackedLabel?: boolean;
  placeholder?: string;
  forceNegativeInput?: boolean;
  short?: boolean;
  selectOnFocus?: boolean;
  onValueChange?: (v: string) => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
};

export const SingleInput: React.FC<SingleInputProps> = ({
  name,
  label,
  description,
  prefix,
  value,
  touched,
  error,
  disabled = false,
  autoComplete,
  maxLength,
  halfWidth = false,
  stackedLabel = false,
  placeholder,
  forceNegativeInput,
  short = false,
  selectOnFocus = false,
  onValueChange,
  onChange,
  onBlur,
  onPaste,
}) => {
  const onFocus = useCallback(
    function ({ target }: React.FocusEvent<HTMLInputElement>) {
      if (selectOnFocus) {
        target.setSelectionRange(0, target.value.length);
      }
    },
    [selectOnFocus]
  );

  const handleChange = useHandleChange<HTMLInputElement>(onChange, onValueChange);

  const id = name;
  const tone: Tone = useTone({ touched, error });
  const isBesavvi = useIsBeSavvi();

  let mb = 4;

  if (isBesavvi) {
    mb = description ? 3 : 5;
  }

  return (
    <Box data-test-id={name} mb={mb}>
      <InputWrapper
        {...{
          id,
          label,
          tone: forceNegativeInput ? 'neutral' : tone,
          error,
          description,
          stacked: stackedLabel,
        }}
      >
        <Box
          sx={{
            width: halfWidth ? 'columns.2' : '100%',
          }}
        >
          <TextField
            onChange={handleChange}
            {...{
              id,
              name,
              maxLength,
              tone: forceNegativeInput ? 'negative' : tone,
              value,
              autoComplete,
              prefix,
              placeholder,
              onBlur,
              onFocus,
              disabled,
              short,
              onPaste,
            }}
          />
        </Box>
      </InputWrapper>
    </Box>
  );
};
