import { jsx, Message, NovunaHeading, Styled } from 'compass-design';
import React, { Fragment } from 'react';
import { NarrowContent } from '../../design-components/NarrowContent';
import { PageTitle } from '../../design-components/heading';
import { NovunaContainer } from '../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../utils/useIsBesavvi';

export const ExpiredSession: React.FC = () => {
  const isBesavvi = useIsBeSavvi();
  return (
    <Fragment>
      {isBesavvi ? (
        <NarrowContent>
          <PageTitle>Application timed out</PageTitle>
          <Styled.p sx={{ fontSize: 3, fontWeight: 'bold' }}>
            Sorry, your application has timed out due to inactivity.
          </Styled.p>
          <Styled.p sx={{ fontSize: 2 }}>
            You can sign in to your application again by following the link in the email you have received from us.
          </Styled.p>
        </NarrowContent>
      ) : (
        <NovunaContainer>
          <PageTitle>Application timed out</PageTitle>
          <Message variant="error">
            <NovunaHeading as="h3">Sorry, your application has timed out due to inactivity.</NovunaHeading>
            <p>
              You can sign in to your application again by following the link in the email you have received from us.
            </p>
          </Message>
        </NovunaContainer>
      )}
    </Fragment>
  );
};
