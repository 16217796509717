import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { PersonalDetails } from '@plending/interfaces/application';
import { PersonalDetailsActionType } from './types';

export function useStorePersonalDetails() {
  const dispatch = useDispatch();

  return useCallback(
    (details: PersonalDetails) =>
      dispatch({
        type: PersonalDetailsActionType.STORE,
        payload: details,
      }),
    [dispatch]
  );
}

export function useClearPersonalDetails() {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch({
        type: PersonalDetailsActionType.CLEAR,
      }),
    [dispatch]
  );
}
