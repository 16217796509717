import React, { useCallback, useEffect, useMemo } from 'react';
import { ApplicationStatus } from '@plending/interfaces/application';
import { PersonalisedQuoteSoftSearchResponse } from '@plending/interfaces/soft-search';
import { jsx } from 'compass-design';
import { UnhandledDecision } from '../Unhandled';
import { NovunaContainer } from '../../../../design-components/NovunaContainer';
import { useIsBeSavvi } from '../../../../utils/useIsBesavvi';
import { PersonalisedQuoteState } from '../../../../store/personalisedQuote/types';
import { usePersonalisedQuoteSelector } from '../../../../store/personalisedQuote/selectors';
import { ErrorDecision } from '../Error';
import { LoadingDecision } from '../Loading';
import { DIRECT_COMPONENTS, DIRECT_JOURNEY, ERROR_COMPONENTS } from '../../../Routing/Routes';
import { getPath, useNavigation } from '../../../Routing/useNavigation';
import { useFetchDecision } from '../../../../store/decision/actions';
import { useCallbackDecisionRequestPayloadSelector } from '../../../../store/decision/selectors';
import { useStorePreDecisionConfirmation } from '../../../../store/preDecisionConfirmation/actions';
import { isoNow } from '../../../../utils/serverTime';
import { PreDecisionConfirmationState } from '../../../../store/preDecisionConfirmation/types';
import { getIovationFingerprint } from '../../../../utils/getIovationFingerprint';
import { useAppQueueingSubmission } from '../../../../store/app-queueing';
import { PersonalisedQuoteDeclined } from './PersonalisedQuoteDeclined';
import { PersonalisedQuoteAccept } from './PersonalisedQuoteAccept';

const Components: Record<string, React.ElementType> = {
  [ApplicationStatus.QUOTE_ACCEPT]: PersonalisedQuoteAccept,
  [ApplicationStatus.QUOTE_DECLINE]: PersonalisedQuoteDeclined,
};

export const PersonalisedQuote: React.FC = () => {
  const appQueueing = useAppQueueingSubmission();
  const {
    loading: softSearchDecisionLoading,
    error: decisionError,
    data: { decision: softSearchDecisionString } = {} as PersonalisedQuoteSoftSearchResponse,
  }: PersonalisedQuoteState = usePersonalisedQuoteSelector();
  const showLoading = softSearchDecisionLoading;
  const showDecision = !!softSearchDecisionString && !decisionError && !showLoading;
  const showError = !!decisionError || (!softSearchDecisionLoading && !softSearchDecisionString);
  const { navigateTo } = useNavigation(DIRECT_JOURNEY);

  const Component = useMemo(() => {
    if (showDecision) {
      return Components[softSearchDecisionString] || UnhandledDecision;
    }

    return React.Fragment;
  }, [softSearchDecisionString, showDecision]) as unknown as React.FC<{
    decision: any;
    isBesavvi: boolean;
    applyNow: () => Promise<void>;
  }>;

  const isBesavvi = useIsBeSavvi();
  const fetchDecision = useFetchDecision();
  const getDecisionRequest = useCallbackDecisionRequestPayloadSelector();
  const storeConfirmation = useStorePreDecisionConfirmation();

  useEffect(() => {
    if (!!decisionError && !softSearchDecisionLoading) {
      navigateTo(getPath(ERROR_COMPONENTS.default, { code: 500 }) as string);
    }
  }, [decisionError, softSearchDecisionLoading, navigateTo]);

  const applyNow = useCallback(async () => {
    const preDecisionConfirmation: PreDecisionConfirmationState = {
      bankDetails: isoNow(),
      creditSearch: isoNow(),
      circumstances: isoNow(),
      alternativeLoanProviderOptIn: false,
    };

    storeConfirmation(preDecisionConfirmation);

    navigateTo(getPath(DIRECT_COMPONENTS.showDecision, { code: 200 }) as string);
    if ((await appQueueing.submit({})) !== 'unhandled') {
      return;
    }
    fetchDecision(true, { ...getDecisionRequest(), ...getIovationFingerprint() });
  }, [appQueueing, fetchDecision, getDecisionRequest, navigateTo, storeConfirmation]);

  return (
    <>
      {showLoading && <LoadingDecision isBesavvi={isBesavvi} />}
      {showDecision &&
        (isBesavvi ? (
          <Component decision={softSearchDecisionString} isBesavvi={isBesavvi} applyNow={applyNow} />
        ) : (
          <NovunaContainer>
            <Component decision={softSearchDecisionString} isBesavvi={isBesavvi} applyNow={applyNow} />
          </NovunaContainer>
        ))}
      {showError && <ErrorDecision isBesavvi={isBesavvi} decisionError={decisionError} />}
    </>
  );
};
