import { DecisionClientResponse } from '@plending/interfaces/decision';
import { RemoteAction, RemoteState } from '../types';
import { PreDecisionConfirmationState } from '../preDecisionConfirmation/types';

export enum DecisionActionType {
  CLEAR = 'decision/CLEAR',
  REQUEST = 'decision/REQUEST',
  LOAD = 'decision/LOAD',
  FAILED = 'decision/FAILED',
  LEGACY = 'decision/LEGACY',
  BUSINESS_ERROR = 'decision/BUSINESS_ERROR',
}

export interface DecisionAction extends RemoteAction {
  type: DecisionActionType;
  payload?: {
    data?: string;
    error?: string;
  };
}

export interface DecisionState extends RemoteState {
  loading: boolean;
  error?: string;
  data?: DecisionClientResponse;
  pendingLegacy?: boolean;
}

export interface DecisionRequestPayload {
  preDecisionConfirmation: PreDecisionConfirmationState;
}
